import { ThemeProvider, useTheme } from '@mui/material'
import '@tremor/react/dist/esm/tremor.css'
import { saveAs } from 'file-saver'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { NetworksComponentList } from './FiltersComponent'
import {
	ReportGrid,
	ReportItem,
	ReportItemHeading,
	StyledBox,
} from './ReportsStyled'
import ReportsPageLayout from '../layouts/ReportsPageLayout'
import ReportTableSectionLayout from '../layouts/ReportTableSectionLayout'
import { useDispatch } from 'react-redux'
import { handleAdjustedDateChange } from '../../../utils/helpers/helperFuncs'
import { useSearchParams } from 'react-router-dom'

export interface NetworkFieldsInterface {
	from_date: string
	to_date: string
	breakdowns: string[]
	statistics: string[]
	filters: NetworkFilterInterface
}
export interface NetworkFilterInterface {
	app_id: string[]
	app_name: string[]
	media_source_pid: string[]
	platform: string[]
	agency: string[]
	pmd_af_prt: string[]
	username: string[]
}

const NetworkPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: string | null
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	setViewRecord: any
}) => {
	const [reportFields, setReportFields] = useState<NetworkFieldsInterface>({
		from_date: '',
		to_date: '',
		breakdowns: [],
		statistics: [],
		filters: {
			app_id: [''],
			app_name: [''],
			media_source_pid: [''],
			platform: [''],
			pmd_af_prt: [''],
			agency: [''],
			username: [''],
		},
	})
	const theme = useTheme()
	const [viewDataFor, setViewDataFor] = useState<string>('Yesterday')
	const [startDay, setStartDay] = useState<string>('')
	const [endDay, setEndDay] = useState<string>('')
	const [breakdownFields, setBreakdownFields] = useState<string[]>([])
	const [statisticsFields, setStatisticsFields] = useState<string[]>([])
	const [appIds, setAppIds] = useState<string[]>([])
	const [appName, setAppName] = useState<string[]>([])
	const [agency, setAgency] = useState<string[]>([])
	const [username, setUsername] = useState<string[]>([])
	const [pid, setPid] = useState<string[]>([])
	const [platform, setPlatform] = useState<string[]>([])
	const [pmdAfPrt, setPmdAfPrt] = useState<string[]>([])
	const { login, reports } = useTypedSelector((state) => state)
	const {
		saveAppsflyerAction,
		getRefreshNetworkAction,
		getUpdatedAtAction,
		getNetworksFiltersAction,
		getNetworkAction,
		loadAllReportsAction,
	} = useActions()
	const [lastUpdatedAt, setLastUpdatedAt] = useState<string | undefined>(
		undefined,
	)
	const [urlQuery] = useSearchParams()
	const [isReportOpen, setIsReportOpen] = useState<boolean>(false)
	const [fileDownload, setFileDownload] = useState<any>()
	const [presetVisible, setPresetVisible] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [chartData, setChartData] = useState<any>(null)
	const [loading, setLoading] = useState(false)
	const downloadButtonRef = useRef<HTMLButtonElement | null>(null)
	const reportSectionRef = useRef<HTMLDivElement>(null)
	const dispatch = useDispatch()
	const [selectedReportData, setSelectedReportData] = useState<any>(null)
	const [smallLoading, setSmallLoading] = useState(false)

	const statisticOptions = ['Impressions', 'Clicks', 'Installs', 'CR']

	const getLastRefreshed = () => {
		getUpdatedAtAction('network', setLastUpdatedAt)
	}

	useEffect(() => {
		props.setLoading(false)
	}, [props.setLoading])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
		handleAdjustedDateChange(
			startDate,
			endDate,
			setStartDay,
			setEndDay,
			setReportFields,
		)
	}

	useEffect(() => {
		const data = {
			from_date: startDay,
			to_date: endDay,
			breakdowns: breakdownFields,
			statistics: statisticsFields,
			filters: {
				app_id: appIds,
				app_name: appName,
				media_source_pid: pid,
				platform: platform,
				agency: agency,
				pmd_af_prt: pmdAfPrt,
				username: username,
			},
		}
		setReportFields(data)

		getLastRefreshed()
	}, [
		viewDataFor,
		startDay,
		endDay,
		appIds,
		appName,
		agency,
		pid,
		platform,
		pmdAfPrt,
		username,
	])

	useEffect(() => {
		if (login.user && login.user.email) {
			getNetworkAction()
			loadAllReportsAction(login.user.email)
		}
		if (urlQuery.get('report')) {
			setIsReportOpen(true)
		}
	}, [])

	const email = login.user.email
	const networkReports = reports.reports[email]?.network || []

	useEffect(() => {
		if (email && networkReports.length > 0) {
			props.setViewRecord(networkReports)
		}
	}, [reports, login.user.email])

	const handleRunReport = async () => {
		await loadAllReportsAction(login.user.email)
	}

	return (
		<ThemeProvider theme={theme}>
			<ReportsPageLayout
				filtersComponentList={
					<NetworksComponentList
						setAppIds={setAppIds}
						setAppName={setAppName}
						setAgency={setAgency}
						setUsername={setUsername}
						setPid={setPid}
						setPlatform={setPlatform}
						setPmdAfPrt={setPmdAfPrt}
						dataObj={reportFields}
						setDataObj={setReportFields}
						onDateChange={handleDateChange}
					/>
				}
				breakdownOptions={[
					'Date',
					'Month',
					'Day',
					'Day of the Week',
					'App ID',
					'Media Source Pid',
					'Platform',
					'Agency',
					'Username',
				]}
				statisticOptions={statisticOptions}
				reportFields={reportFields}
				setReportFields={setReportFields}
				getReportAction={getNetworksFiltersAction}
				saveAction={saveAppsflyerAction}
				refreshAction={getRefreshNetworkAction}
				setLoading={setLoading}
				setErrorMessage={props.setErrorMessage}
				isErrorPromptOpened={props.isErrorPromptOpened}
				setIsErrorPromptOpened={props.setIsErrorPromptOpened}
				setIsWarningPromptOpened={props.setIsWarningPromptOpened}
				setReport={props.setViewRecord}
				viewRecord={props.setViewRecord}
				setViewRecord={props.setViewRecord}
				breakdownFields={breakdownFields}
				setBreakdownFields={setBreakdownFields}
				statisticsFields={statisticsFields}
				setStatisticsFields={setStatisticsFields}
				presetVisible={presetVisible}
				setPresetVisible={setPresetVisible}
				isSaving={isSaving}
				setIsSaving={setIsSaving}
				isReportOpen={isReportOpen}
				setIsReportOpen={setIsReportOpen}
				downloadButtonRef={downloadButtonRef}
				ReportGrid={ReportGrid}
				ReportStyles={{ StyledBox, ReportItem, ReportItemHeading }}
				fileDownload={fileDownload}
				setFileDownload={setFileDownload}
				login={login}
				dispatch={dispatch}
				report={networkReports}
				loading={loading}
				reportType='network'
				userEmail={login.user.email}
				selectedReport={selectedReportData}
				onRunReport={handleRunReport}
			/>

			{networkReports.length > 0 && (
				<ReportTableSectionLayout
					report={networkReports}
					chartData={chartData}
					reportFields={reportFields}
					isReportOpen={isReportOpen}
					setIsReportOpen={setIsReportOpen}
					customReportFields={statisticOptions}
					reportType='network'
					setSelectedReportData={setSelectedReportData}
					smallLoading={smallLoading}
					setSmallLoading={setSmallLoading}
				/>
			)}
		</ThemeProvider>
	)
}

export default NetworkPage
