import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react'
import { PageWrapper, TableWrapper } from '../../components/reusableComponents'
import { ThemeProvider } from '@emotion/react'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
	generateRowsWithIds,
	searchFor,
} from '../../../utils/helpers/tableHelper'
import {
	GridColDef,
	GridRowModesModel,
	GridRowModes,
	GridActionsCellItem,
	GridEventListener,
	GridRowEditStopReasons,
	GridEditSingleSelectCell,
	useGridApiRef,
} from '@mui/x-data-grid-pro'
import { Grid, MenuItem, Typography, useTheme } from '@mui/material'
import '../finance.css'
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-icon.svg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg'
import {
	findAdvertiserByOwner,
	findCreatorFromId,
	findPublisherFromPid,
	formatDateToMonth,
	getApplicableRules,
	getMonthAbbreviation,
	getYear,
	handleFinanceStatusColor,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../../utils/helpers/helperFuncs'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import Guidelines from '../Guidelines'
import { DropList } from '../../components/SelectableInputs'
import { TableComponent } from '../../components/TableComponents'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../../state/actions'
import { SmallLoading } from '../../../assets/svg/loading'
import { useNavigate, useParams } from 'react-router-dom'
import { InputFieldText } from '../../components/Inputs'
import { SubmitButton, UnborderedButton } from '../../components/Buttons'
import { TitleComponent } from '../../components/Informative'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import { HeaderFilterComponent } from '../../components/TableHelperComponents'

//this is the first page of finance.
//In finance we have 4 pages:
// - Personal: Where the user is able to upload his excel
// - Global: Where the user can see all the data of the company
// - Supply: Where the user can see the data ordered by publisher
// - Demand: Where the user can see the data ordered by advertiser
// The idea of personal page is that the user can download an excel template that he will be able to fill and upload to the system
//After that, the system will show all the data with error messages if it's missing data (publisher or advertiser)
//Inside personal the user is also able to edit row by row if he wants or to upload a new excel that will UPDATE / INSERT data
// based on what he has on his file. IE: Same campaign, period and pid will update the row instead of adding a new one.
const CreatorPersonalPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const { login, finance, publisher, advertiser, users, settings, creator } =
		useTypedSelector((state) => state)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_FINANCE),
		delete: useIsAuthorized(permissionNames.DELETE_FINANCE),
	}
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const params = useParams()
	const {
		downloadFinanceCreatorTemplateAction,
		downloadFinanceFilledCreatorTemplateAction,
		uploadFinanceCreatorTemplate,
		getInternalFinanceData,
		uploadFinanceCreatorRow,
		deleteFinanceCreatorRow,
		editCreatorFinanceRow,
	} = useActions()
	const sessionKey = 'filters-finance-creator'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const month = params?.id?.split('-creator-')[1].replaceAll('-', '/')
	const [file, setFile] = useState(null)
	const [oldSearchValue, setOldSearchValue] = useState('')
	const fileInputRef = useRef()
	const [pageSize, setPageSize] = useState(15)
	const [update, setUpdate] = useState(false)
	const [filteredFinance, setFilteredFinance] = useState<any>(
		Array.isArray(finance.finance) ? finance.finance : [],
	)
	const rowCount = 8
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [tableRows, setTableRows] = useState(
		generateRowsWithIds(filteredFinance),
	)
	const [openFilter, setOpenFilter] = useState(false)
	const [errorFilter, setErrorFilter] = useState<boolean>(
		sessionFilters.errorFilter ? sessionFilters.errorFilter : false,
	)
	const [financeFilterStatus, setFinanceFilterStatus] = useState(
		sessionFilters.financeFilterStatus ? sessionFilters.financeFilterStatus : 0,
	)
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					campaign: [],
					platform: [],
					advertiser: [],
					pid: [],
					publisher: [],
			  },
	)
	const [applyFilter, setApplyFilter] = useState(false)
	const [infoOpened, setInfoOpened] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		campaign: [],
		platform: [],
		advertiser: [],
		pid: [],
		publisher: [],
	})
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const apiRef = useGridApiRef()
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray ? sessionFilters.monthFilterArray : [],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray ? sessionFilters.yearFilterArray : [],
	)
	const [pinnedBottomRow, setPinnedBottomRow] = useState<any>([])
	const advertiserList = advertiser.advertiser.map(
		(el: any) => el.advertiser_name,
	)
	const [dataFetched, setDataFetched] = useState(false)
	const dispatch = useDispatch()
	const hasFetched = useRef(false)
	const [smallLoading, setSmallLoading] = useState(true)
	const [campaignName, setCampaignName] = useState('')
	const [changesDone, setChangedDone] = useState(false)
	const [mainRow, setMainRow] = useState<any>({})
	const [currentCampaignName, setCurrentCampaignName] = useState('')
	const [advertiserName, setAdvertiserName] = useState('')
	const [platform, setPlatform] = useState('')
	const [revenue, setRevenue] = useState(0)
	const navigate = useNavigate()
	//this is the function we use to download the template. We open a blank page in order to not update the current page.
	const downloadTemplate = async () => {
		const url: any = await downloadFinanceCreatorTemplateAction(mainRow.uuid)
		window.open(url, '_blank')
	}
	const downloadCurrentData = async () => {
		const url: any = await downloadFinanceFilledCreatorTemplateAction(
			tableRows,
			mainRow.uuid,
		)
		window.open(url, '_blank')
	}
	const fileSelectedHandler = (event: any) => {
		setFile(event.target.files[0])
	}
	const fileInputClickHandler = () => {
		setFile(null)
		;(fileInputRef.current as any).value = ''
		;(fileInputRef.current as any).click()
	}
	const closeDetailedView = () => {
		props.setLoading(true)
		props.setSearch(oldSearchValue)
		navigate('/finance/creator')
	}
	const updateCampaign = async () => {
		props.setLoading(true)
		const obj = {
			campaign: campaignName,
			advertiser: advertiserName,
			revenue,
			platform,
			uuid: params?.id,
		}
		// console.log(obj)
		const failed = await editCreatorFinanceRow(
			obj,
			login.user.email,
			props.setErrorMessage,
		)
		if (!failed) {
			props.setIsSuccessPromptOpened(true)
			setCurrentCampaignName(campaignName)
		}
		props.setLoading(false)
	}
	//function to upload data with the excel file.
	const fetchData = async () => {
		let i = 0
		let result
		do {
			result = await getInternalFinanceData(
				'creator',
				props.errorMessage,
				params.id!,
				login.user.email,
				50,
				i,
			)
			i++
			setDataFetched(true)
			if (result.length === 0) {
				setSmallLoading(false)
			}
		} while (result && result.length > 0)
	}
	//TODO: change the excel file and functions in backend
	const uploadData = async () => {
		if (!file) {
			return
		}
		props.setLoading(true)
		const formData = new FormData()
		formData.append('finance', file)
		formData.append('email', login.user.email)
		formData.append('uuid', mainRow.uuid)
		const failed = await uploadFinanceCreatorTemplate(
			formData,
			props.setErrorMessage,
		)
		if (!failed) {
			props.setIsSuccessPromptOpened(true)
			fetchData()
		}
		setFile(null)
		;(fileInputRef.current as any).value = ''
		props.setLoading(false)
	}

	useEffect(() => {
		if (file) {
			uploadData()
		}
	}, [file])

	useEffect(() => {
		dispatch(emptyFinance())
		setOldSearchValue(props.search)
		props.setSearch('')
		//this needs to be added for the StrictMode. Otherwise data is duplicated.
		if (hasFetched.current) {
			return
		}
		hasFetched.current = true
		fetchData()
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('creator')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(sessionFilters.monthFilterArray)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(sessionFilters.yearFilterArray)
			sessionFilters.errorFilter && setErrorFilter(sessionFilters.errorFilter)
			sessionFilters.financeFilterStatus &&
				setFinanceFilterStatus(sessionFilters.financeFilterStatus)
		}
	}, [])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				monthFilterArray,
				yearFilterArray,
				errorFilter,
				financeFilterStatus,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [
		applyFilter,
		monthFilterArray,
		yearFilterArray,
		errorFilter,
		financeFilterStatus,
	])

	useEffect(() => {
		if (Array.isArray(finance.finance)) {
			const uuid = params?.id?.split('-creator-')[0]
			if (dataFetched) {
				setMainRow(finance.finance.filter((el) => el.uuid === uuid)[0])
			}

			setFilteredFinance(
				finance.finance.filter((el) => !el.is_empty_creator_row),
			)
		} else {
			setFilteredFinance([])
		}
	}, [finance.finance])
	useEffect(() => {
		if (mainRow) {
			setCampaignName(mainRow.campaign)
			setCurrentCampaignName(mainRow.campaign)
			setAdvertiserName(mainRow.advertiser)
			setRevenue(mainRow.revenue)
			setPlatform(mainRow.platform)
		}
	}, [mainRow])

	useEffect(() => {
		if (dataFetched) {
			setOptionsFilter({
				campaign: new Set(
					tableRows
						.map((element: any) => element.campaign)
						.filter((el) => el.length > 0),
				),
				platform: new Set(
					tableRows
						.map((element: any) => element.platform)
						.filter((el) => el.length > 0),
				),
				advertiser: new Set(
					tableRows
						.map((element: any) => element.advertiser)
						.filter((el) => el.length > 0),
				),
				pid: new Set(
					tableRows
						.map((element: any) => element.pid)
						.filter((el) => el.length > 0),
				),
				publisher: new Set(
					tableRows
						.map((element: any) => element.publisher)
						.filter((el) => el.length > 0),
				),
			})
		}
	}, [tableRows])

	//same filter that we always use. In this case, instead of 3 status we have 4. The logic behind relies the same.
	const possibleFinanceFilter = ['All', 'Yes', 'No', 'On Hold']
	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, filteredFinance, columns)
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = searched
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'yes',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			}
		} else {
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = filteredFinance
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'yes',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'on hold',
				)
			}
		}
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true
					foundByKey = customFilter[key].includes(data[key])
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}
		//this handles the year
		if (yearFilterArray.length === 0) {
			arr = generateRowsWithIds(arr)
		} else {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = generateRowsWithIds(filteredData)
		}
		//this handles the months
		if (monthFilterArray.length === 0) {
			arr = generateRowsWithIds(arr)
		} else {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = generateRowsWithIds(filteredData)
		}
		if (errorFilter) {
			const errorRows = arr
				.map((el: any) => {
					if (
						!el.publisher ||
						!advertiserList.includes(el.advertiser) ||
						(!el.revenue && el.revenue !== 0)
					) {
						return el
					}
					return undefined
				})
				.filter((el: any) => el !== undefined)
			// setTableRows(errorRows)
			arr = errorRows
		}
		setTableRows(arr)
		setUnfiltered(arr)
		setPinnedBottomRow(calculatePinnedTotalRow(arr))
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [
		financeFilterStatus,
		props.search,
		monthFilterArray,
		yearFilterArray,
		applyFilter,
		filteredFinance,
		errorFilter,
	])

	const handleApplyFilter = () => {
		setApplyFilter(!applyFilter)
	}
	//these next functions are used to track the edit, remove, save and cancel of an specific row.
	const handleEditClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}
	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}
	const handleDeleteClick = (id: any) => async () => {
		const deleted = window.confirm(
			'Deleting this will remove the entire row from the database. Are you sure?',
		)
		if (deleted) {
			let rowToRemove
			const arr = tableRows.filter((element: any) => {
				if (element.id !== id) {
					return true
				} else {
					rowToRemove = element
					return false
				}
			})
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr))

			await deleteFinanceCreatorRow(
				rowToRemove,
				params?.id?.split('-creator-')[0] as string,
				login.user.email,
				props.setErrorMessage,
			)
		}
	}
	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}

	const downloadOptions = (
		<div>
			{/* <MenuItem onClick={handleClose}>Preview</MenuItem> */}
			<MenuItem key={'menuption_template'} onClick={downloadTemplate}>
				Empty Template
			</MenuItem>
			<MenuItem key={'menuption_current'} onClick={downloadCurrentData}>
				Current Data
			</MenuItem>
		</div>
	)

	const columns: GridColDef[] = [
		// {
		// 	field: 'campaign',
		// 	headerName: 'Campaign',
		// 	width: rowWidth * 1.4,
		// 	editable: true,
		// 	renderHeader: () => {
		// 		return (
		// 			<HeaderFilterComponent
		// 				label={'Campaign'}
		// 				width={rowWidth * 1.2}
		// 				openFilter={openFilter}
		// 				setOpenFilter={setOpenFilter}
		// 				field={'campaign'}
		// 				customFilter={customFilter}
		// 				currentFilters={currentFilters}
		// 				setCurrentFilters={setCurrentFilters}
		// 				setFilterLabel={setFilterLabel}
		// 				optionsFilters={optionsFilters}
		// 			/>
		// 		)
		// 	},
		// 	renderCell: (row) => {
		// 		if (row.row.id === 9999999999) {
		// 			return (
		// 				<span
		// 					style={{
		// 						fontWeight: theme.font.weight.normal,
		// 						fontSize: theme.font.size.body,
		// 						justifyContent: row.id === 9999999999 ? 'center' : 'left',
		// 						display: 'flex',
		// 						width: '100%',
		// 					}}
		// 				>
		// 					{row.value}
		// 					{smallLoading && (
		// 						<div style={{ display: 'relative' }}>
		// 							<SmallLoading />
		// 						</div>
		// 					)}
		// 				</span>
		// 			)
		// 		}

		// 		return (
		// 			<Typography
		// 				sx={{
		// 					fontWeight: 600,
		// 					fontSize: 14,
		// 					justifyContent: row.id === 9999999999 ? 'center' : 'left',
		// 					display: 'flex',
		// 					width: '100%',
		// 				}}
		// 			>
		// 				{row.value}
		// 			</Typography>
		// 		)
		// 	},
		// 	cellClassName: (params) => {
		// 		if (params.colDef.editable === false) {
		// 			return 'is-not-editable'
		// 		}
		// 		return 'is-editable'
		// 	},
		// },
		// {
		// 	field: 'agency_account',
		// 	headerName: 'Agency Account',
		// 	width: rowWidth * 0.6,
		// 	align: 'center',
		// 	renderCell: (row) => {
		// 		if (row.row.agency_account === 'Other') {
		// 			return <span>{row.row.agency_account_specification}</span>
		// 		} else {
		// 			return <span>{row.row.agency_account}</span>
		// 		}
		// 	},
		// 	cellClassName: (params) => {
		// 		if (params.colDef.editable === false) {
		// 			return 'is-not-editable'
		// 		}
		// 		return 'is-editable'
		// 	},
		// },
		{
			field: 'platform',
			headerName: 'Platform',
			width: rowWidth * 0.8,
			editable: false,
			type: 'singleSelect',
			align: 'center',
			valueOptions: ['IM', 'UGC'],
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Platform'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'platform'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'publisher',
			headerName: 'Creator',
			width: rowWidth * 0.9,
			editable: true,
			type: 'singleSelect',
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Creator'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderEditCell: (params) => {
				let options = []
				if (params && params.row && publisher.publisher) {
					options = findCreatorFromId(params.row.pid, creator.creator)
				}

				return (
					<DropList
						onChange={(e: string) => {
							apiRef.current.setEditCellValue({
								id: params.row.id,
								field: 'publisher',
								value: e,
							})
						}}
						options={options}
						label={''}
						value={params.value}
						noBackground
						autoCompleteStyle={{
							border: 'none',
							borderRight: 'none',
						}}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'pid',
			headerName: 'Creator ID',
			width: rowWidth,
			editable: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Creator ID'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'pid'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},

		// {
		// 	field: 'revenue',
		// 	headerName: 'Revenue',
		// 	width: rowWidth * 0.85,
		// 	type: 'number',
		// 	align: 'left',
		// 	headerAlign: 'left',
		// 	editable: true,
		// 	renderCell: (row) => {
		// 		const value = row.value
		// 		if (row.id === 9999999999) {
		// 			return (
		// 				<span>
		// 					{Number(value).toLocaleString('en-US', {
		// 						minimumFractionDigits: 2,
		// 						maximumFractionDigits: 2,
		// 					})}
		// 				</span>
		// 			)
		// 		}
		// 		return <span>{Number(value).toFixed(2)}</span>
		// 	},
		// 	cellClassName: (params) => {
		// 		if (params.colDef.editable === false) {
		// 			return 'is-not-editable'
		// 		}
		// 		return 'is-editable'
		// 	},
		// },
		{
			field: 'cost',
			headerName: 'Cost',
			width: rowWidth * 0.85,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		// {
		// 	field: 'approved_conversions',
		// 	headerName: 'Conversion',
		// 	width: rowWidth * 0.8,
		// 	type: 'number',
		// 	align: 'left',
		// 	headerAlign: 'left',
		// 	editable: true,
		// 	renderCell: (row) => {
		// 		const value = row.value
		// 		if (row.id === 9999999999) {
		// 			return <span>{Number(value).toLocaleString('en-US')}</span>
		// 		}
		// 		return <span>{Number(value)}</span>
		// 	},
		// 	cellClassName: (params) => {
		// 		if (params.colDef.editable === false) {
		// 			return 'is-not-editable'
		// 		}
		// 		return 'is-editable'
		// 	},
		// },
		// {
		// 	field: 'deduction_amount',
		// 	headerName: 'Deduction',
		// 	width: rowWidth * 0.6,
		// 	type: 'number',
		// 	align: 'left',
		// 	headerAlign: 'left',
		// 	editable: true,
		// 	renderCell: (row) => {
		// 		const value = row.value
		// 		if (row.id === 9999999999) {
		// 			return (
		// 				<span>
		// 					{Number(value).toLocaleString('en-US', {
		// 						minimumFractionDigits: 2,
		// 						maximumFractionDigits: 2,
		// 					})}
		// 				</span>
		// 			)
		// 		}
		// 		return <span>{Number(value).toFixed(2)}</span>
		// 	},
		// 	cellClassName: (params) => {
		// 		if (params.colDef.editable === false) {
		// 			return 'is-not-editable'
		// 		}
		// 		return 'is-editable'
		// 	},
		// },
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.6,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			type: 'singleSelect',
			valueOptions: ['Yes', 'No', 'On Hold'],
			editable: true,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				if (value === '') {
					return <></>
				}
				return (
					<div
						style={{
							borderRadius: '20px',
							color: handleFinanceStatusColor(
								value === 'On Hold' ? 'on hold yellow' : value,
								theme,
							),
							width: '70px',
							height: '30px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							textTransform: 'uppercase',
							fontWeight: theme.font.weight.bolder,
							fontSize: theme.font.size.caption,
						}}
					>
						{value}
					</div>
				)
			},
		},
		{
			field: 'final_amount',
			headerName: 'Final Number',
			headerAlign: 'center',
			width: rowWidth * 0.9,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			editable: false,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'finance-final-cell is-not-editable'
				}
				return 'finance-final-cell is-editable'
			},
		},
		(permissions.edit || (permissions.delete as any)) && {
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			getActions: ({ id }) => {
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
				//9999999999 this id is the hardcoded id we use for the bottom pinned row.
				if (id === 9999999999) {
					return []
				}
				//based on if the edit mode is triggered we will have the options to save, cancel or edit, remove
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.text.titles,
										cursor: 'pointer',
										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'action-button'}
								>
									<CheckIcon fontSize={'inherit'} />
								</div>
							}
							label='Save'
							sx={{
								color: 'primary.main',
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.text.titles,
										cursor: 'pointer',
										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'action-button'}
								>
									<ClearIcon fontSize={'inherit'} />
								</div>
							}
							label='Cancel'
							className='textPrimary'
							onClick={handleCancelClick(id)}
							color='inherit'
						/>,
					]
				}

				return [
					permissions.edit ? (
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.text.titles,
										height: '28px',
										width: '28px',
										fontSize: '16px',
										cursor: 'pointer',
									}}
									className={'action-button'}
								>
									<EditIcon
										style={{ color: 'inherit', scale: '0.8' }}
										className={'action-button'}
									/>
								</div>
							}
							label='Edit'
							className='textPrimary'
							onClick={handleEditClick(id)}
							color='inherit'
						/>
					) : (
						<></>
					),
					permissions.delete ? (
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										height: '28px',
										width: '28px',
										fontSize: '16px',
										color: theme.colors.text.titles,
										cursor: 'pointer',
									}}
									className={'action-button'}
								>
									<DeleteIcon
										style={{ color: 'inherit', scale: '0.8' }}
										className={'action-button'}
									/>
								</div>
							}
							label='Edit'
							className='textPrimary'
							onClick={handleDeleteClick(id)}
							color='inherit'
						/>
					) : (
						<></>
					),
				]
			},
		},
	]
	//this is the logic we use to update the rows everytime a change is done.
	//for every time the user presses "save" it will trigger the update.
	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		const updatedRow = { ...newRow, isNew: false }
		const oldRow = tableRows.find((el: any) => el.uuid === newRow.uuid)
		// console.log(oldRow)
		const data = { old_row: oldRow, new_row: newRow }
		const failed = await uploadFinanceCreatorRow(
			data,
			login.user.email,
			props.setErrorMessage,
		)
		const selectedAdvertiser = advertiser.advertiser.filter(
			(el: any) =>
				el.advertiser_name.toLowerCase() ===
				updatedRow.advertiser.toLowerCase(),
		)
		if (selectedAdvertiser.length > 0) {
			updatedRow.account_manager = selectedAdvertiser[0].email
		}
		updatedRow.final_amount = updatedRow.cost
		if (!failed) {
			const arr = tableRows.map((row: any) =>
				row.uuid === newRow.uuid ? updatedRow : row,
			)
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr))
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
			setPinnedBottomRow(calculatePinnedTotalRow(notUpdatedData))
		}
		return updatedRow
	}
	//we do this in order to prevent the double click to allow to enter the edit mode.
	const handleDoubleCellClick = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params, event) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params, event) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])
	//basic function for style
	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		const advertiserRegistered = advertiserList.includes(row.advertiser)
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		if (row.id === 9999999999) {
			return 'pinned-row'
		}
		if (
			(!advertiserRegistered || row.publisher === '' || row.revenue === '') &&
			!isInEditMode
		) {
			return 'personal-row-no-data'
		}
		return ''
	}
	const renderCustomMessage = (hoveredRow: any, position: any) => {
		let possiblePublishers = []
		if (creator.creator) {
			possiblePublishers = findCreatorFromId(hoveredRow.pid, creator.creator)
		}
		return (
			!hoveredRow.publisher && (
				<div
					style={{
						position: 'absolute',
						top: position.y,
						left: position.x,
						width: 'auto',
						height: 'auto',
						backgroundColor: theme.colors.base.white,
						border: '1px solid rgb(196,91,80)',
						display: 'flex',
						borderRadius: '4px',
					}}
				>
					<span
						style={{
							color: 'rgb(196,91,80)',
							fontSize: '14px',
							backgroundColor: 'rgba(240,215,217,1)',
							padding: '8px 30px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{!hoveredRow.publisher && possiblePublishers.length > 20 && (
							<span>
								The row is missing a{' '}
								<span style={{ fontWeight: '600' }}>Creator.</span> The Creator
								ID was not found.
							</span>
						)}
						{!hoveredRow.publisher && possiblePublishers.length <= 20 && (
							<span>
								This Creator ID is connected to{' '}
								<span style={{ fontWeight: '600' }}>more than one</span>{' '}
								Creator.
							</span>
						)}
					</span>
				</div>
			)
		)
	}
	calculatePinnedTotalRow(tableRows)

	const guidelinesIntro = [
		{
			explanation:
				"The deadline for adding your numbers is the 10th of each month. You must finish the calculations and remove any entries marked 'On Hold' by the 20th of every month. If your advertiser's approval is delayed, there might be a possibility of extending this deadline.",
		},
	]
	const guidelines = [
		{
			title: 'Platform',
			explanation: 'You can choose between the following: \nIM, UGC.',
		},
		{
			title: 'Creator',
			explanation: `Creator's name. It is not necessary to add the creator name, it will come automatically from the system when you write the creator ID.`,
		},
		{
			title: 'Creator ID',
			explanation: 'ID of the creator. \nExample: 10007.',
		},
		{
			title: 'Cost',
			explanation: 'Amount payable to the creator.',
		},
		{
			title: 'Period',
			explanation:
				'Activity Month + Year. \nFor Example, Jan/2024, Feb/2024, Mar/2024, Apr/2024, May/2024, Jun/2024, Jul/2024, Aug/2024.',
		},
		{
			title: 'Status',
			explanation: `3 different statuses: \n   * On Hold: you add the numbers but they are yet to be confirmed by the advertiser. \n   * Yes: The line is approved and we will pay our source that specific amount indicated in the total amount approved. \n   * No: unconfirmed or fully deducted.`,
		},
		{
			title: 'Final Number',
			explanation:
				'What we will pay to the creator for that offer on this campaign. Cost-Deduction.',
		},
	]

	return (
		<ThemeProvider theme={theme}>
			{infoOpened && (
				<div
					style={{
						background: 'rgba(0,0,0,0.4)',
						width: '100%',
						height: '100%',
						zIndex: '20',
						display: 'flex',
						position: 'absolute',
						top: '0',
						left: '0',
					}}
				></div>
			)}
			<div
				style={{
					// borderBottom: '1px solid rgba(0,0,0,0.12',
					width: '90%',
					paddingTop: '20px',
					marginLeft: '2%',
					// position: 'absolute',
					display: 'flex',
					// justifyContent: 'flex-start',
					zIndex: '11',
					// top: 140,
					// left: 40,
				}}
			>
				<UnborderedButton
					style={{
						width: '80px',
						display: 'flex',
						justifyContent: 'flex-start',
						zIndex: 12,
						// margin: 'auto',
						// position: 'absolute',
						// top: 26,
					}}
					onClick={() => closeDetailedView()}
					icon={
						<ArrowBackIosIcon
							style={{ height: '16px', marginRight: '0px', marginLeft: -10 }}
						/>
					}
				>
					Back
				</UnborderedButton>
				<TitleComponent
					style={{
						marginBottom: '6px',
						marginLeft: 20,
						fontSize: theme.font.size.titles,
						color: theme.colors.text.titles,
						display: 'flex',
						gap: '8px',
					}}
				>
					{currentCampaignName} for{' '}
					{moment(month, 'DD/MM/YYYY').format('MMM-YYYY')}
				</TitleComponent>
			</div>
			<PageWrapper
				style={{
					display: 'flex',
					alignItems: 'flex-start',
					flexDirection: 'column',
					marginLeft: '1%',
					marginTop: 12,
				}}
			>
				<input
					type='file'
					onChange={fileSelectedHandler}
					ref={fileInputRef as any}
					style={{ display: 'none' }}
				/>
				{infoOpened && (
					<Guidelines
						data={guidelines}
						closeFunc={() => setInfoOpened(false)}
						intro={guidelinesIntro}
					></Guidelines>
				)}
				<Grid
					container
					columns={12}
					columnSpacing={6}
					rowSpacing={4}
					direction='row'
					style={{ width: '99%' }}
				>
					<Grid item xs={4}>
						<InputFieldText
							label='Campaign Name'
							type='text'
							value={campaignName}
							onChange={(e: any) => {
								setChangedDone(true)
								setCampaignName(e)
							}}
							// errormessage={!campaignName ? 'Campaign is mandatory' : undefined}
						/>
					</Grid>
					<Grid item xs={1}>
						<DropList
							label='Platform'
							options={['IM', 'UGC']}
							value={platform}
							onChange={(value: any) => {
								setChangedDone(true)
								setPlatform(value)
							}}
							// errormessage={!platform ? 'Platform is mandatory' : undefined}
						/>
					</Grid>
					<Grid item xs={2}>
						<DropList
							label='Advertiser'
							options={advertiserList || []}
							value={advertiserName}
							onChange={(value: any) => {
								setChangedDone(true)
								setAdvertiserName(value)
							}}
							// errormessage={
							// 	!advertiserName ? 'Advertiser is mandatory' : undefined
							// }
						/>
					</Grid>

					<Grid item xs={2}>
						<InputFieldText
							label='Total Revenue'
							type='number'
							value={revenue}
							onChange={(e: any) => {
								setChangedDone(true)
								setRevenue(e)
							}}
							// errormessage={!revenue ? 'Total Revenue is mandatory' : undefined}
						/>
					</Grid>
					<Grid item xs={3}>
						<SubmitButton
							onClick={() => updateCampaign()}
							style={{ marginTop: 23 }}
							disabled={
								!campaignName ||
								!advertiserName ||
								!revenue ||
								!changesDone ||
								!platform
							}
						>
							Update
						</SubmitButton>
					</Grid>
				</Grid>
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{
						paddingBottom: '60px',
						width: '80%',
						marginTop: 12,
						marginLeft: 55,
					}}
				>
					<TableComponent
						columns={columns}
						// columns={[]}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFilterStatus}
						setFilterStatus={setFinanceFilterStatus}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						fileInputClickHandler={
							permissions.edit ? fileInputClickHandler : undefined
						}
						getRowClassName={handleRowStyle}
						customSx={{
							'& .personal-row-no-data.MuiDataGrid-row:hover': {
								backgroundColor: theme.colors.base.red100,
								color: 'red !important',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
							'& ::-webkit-scrollbar': {
								height: '7px',
								width: '10px',
							},
						}}
						customMessage={renderCustomMessage}
						pinnedBottomRow={pinnedBottomRow}
						apiRef={apiRef}
						searchWithTimer={true}
						checkboxButtonValue={errorFilter}
						setCheckboxButtonValue={setErrorFilter}
						checkboxButtonLabel={'Only Errors'}
						downloadButtonFolderOptions={downloadOptions}
						infoOpened={infoOpened}
						setInfoOpened={setInfoOpened}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default CreatorPersonalPage

const calculatePinnedTotalRow = (rows: any) => {
	const pinnedRow = {
		campaign: 'TOTAL',
		platform: '',
		advertiser: '',
		pid: '',
		publisher: '',
		revenue: 0,
		cost: 0,
		approved_conversions: 0,
		deduction_amount: 0,
		period: '',
		status: '',
		final_amount: 0,
		id: 9999999999,
	}
	for (const row of rows) {
		if (row.cost) {
			pinnedRow.cost = Number(pinnedRow.cost) + Number(row.cost)
		}
		if (row.revenue) {
			pinnedRow.revenue = Number(pinnedRow.revenue) + Number(row.revenue)
		}
		if (row.approved_conversions) {
			pinnedRow.approved_conversions =
				Number(pinnedRow.approved_conversions) +
				Number(row.approved_conversions)
		}
		if (row.deduction_amount) {
			pinnedRow.deduction_amount =
				Number(pinnedRow.deduction_amount) + Number(row.deduction_amount)
		}
		if (row.final_amount) {
			pinnedRow.final_amount =
				Number(pinnedRow.final_amount) + Number(row.final_amount)
		}
	}
	pinnedRow.revenue = Number(pinnedRow.revenue).toFixed(2) as any
	pinnedRow.cost = Number(pinnedRow.cost).toFixed(2) as any
	pinnedRow.deduction_amount = Number(pinnedRow.deduction_amount).toFixed(
		2,
	) as any
	pinnedRow.final_amount = Number(pinnedRow.final_amount).toFixed(2) as any
	return [pinnedRow]
}
