import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useEffect } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTheme } from '@mui/material'

export const UploadInstructionsFinance = (props: {
	row: any
	mainRow: any
	setOpenInstructionsPreview: any
	setErrorMessage: any
	setLoading: any
	setInvoiceInstructions: any
	invoiceInstructions: string
	uploadInvoiceTriggered: boolean
	setUploadInvoiceTriggered: any
	selectedRow: any
	setSelectedRow: any
	refreshFunc: any
	type: string
}) => {
	const theme = useTheme()
	const { updateInvoiceInstructionsFinance } = useActions()

	const handleOpen = async () => {
		let instructions = ''
		if (props.type === 'demand') {
			instructions = props.row.row.invoice_instructions
		} else if (props.type === 'publisher') {
			instructions = props.row.row.invoice_instructions_publisher
		}
		if (instructions) {
			props.setInvoiceInstructions(instructions)
		}
		props.setLoading(true)
		props.setSelectedRow(props.row.row)
		props.setOpenInstructionsPreview(true)
		props.setLoading(false)
	}

	useEffect(() => {
		if (
			props.uploadInvoiceTriggered === true &&
			props.row.row.uuid === props.selectedRow.uuid
		) {
			const updatedRow = {
				...props.row.row,
				invoice_instructions: props.invoiceInstructions,
			}
			const data = { new_row: updatedRow, main_row: props.mainRow }
			if (props.type === 'demand') {
				updatedRow.invoice_instructions = props.invoiceInstructions
			} else if (props.type === 'publisher') {
				updatedRow.invoice_instructions_publisher = props.invoiceInstructions
			}
			console.log(data)
			updateInvoiceInstructionsFinance(
				data,
				props.type,
				props.refreshFunc,
				props.setErrorMessage,
			)
			props.setUploadInvoiceTriggered(false)
			props.setOpenInstructionsPreview(false)
			props.setInvoiceInstructions('')
			props.setSelectedRow(null)
		}
	}, [props.uploadInvoiceTriggered])

	return (
		<>
			{(props.type === 'demand' && props.row.row.invoice_instructions) ||
			(props.type === 'publisher' &&
				props.row.row.invoice_instructions_publisher) ? (
				<button
					style={{
						display: 'flex',
						alignItems: 'center',
						border: 'none',
						// background: 'rgba(22, 130, 20, 0.1)',
						// borderRadius: '100px',
						color: theme.colors.text.titles,
						height: '28px',
						width: '28px',
						fontSize: '16px',
						cursor: 'pointer',
					}}
					className={'finance-action-button'}
					onClick={handleOpen}
				>
					<VisibilityOutlinedIcon
						fontSize={'inherit'}
						style={{ color: 'inherit' }}
					/>
				</button>
			) : (
				<button
					style={{
						display: 'flex',
						alignItems: 'center',
						border: 'none',
						// background: 'rgba(64, 19, 129, 0.1)',
						// borderRadius: '100px',
						color: theme.colors.text.titles,
						height: '28px',
						width: '28px',
						fontSize: '16px',
						cursor: 'pointer',
					}}
					className={'finance-action-button'}
					onClick={handleOpen}
				>
					<AddOutlinedIcon fontSize={'inherit'} />
				</button>
			)}
		</>
	)
}
