import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// import AdvertiserCompanyEdit from '../advertisers/AdvertiserCompanyPreviewPage'
// import PublisherCompanyEdit from '../publishers/PublisherCompanyEdit'
import CreatorCompanyEdit from '../publishers/CreatorCompanyEdit'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import AdvertiserEditPage from '../advertisers/AdvertiserCompanyEditPage'
import PublisherCompanyEdit from '../publishers/PublisherCompanyEdit'

type CompanyEditProps = {
	setLoading: any
	setErrorMessage: any
	setIsErrorPromptOpened: any
	setIsSuccessPromptOpened: any
	setPopUpVisible: any
	companyId?: number
	onSave: (data: any) => void
	companyData: any
	companyType?: CompanyType
}

type CompanyType = string /*'advertiser' | 'publisher' | 'creator' | 'agency'*/

const companyEditComponents: Record<
	CompanyType,
	React.ComponentType<CompanyEditProps>
> = {
	advertiser: AdvertiserEditPage,
	publisher: PublisherCompanyEdit,
	creator: CreatorCompanyEdit,
}

type CompanyPersonalPageEditProps = Omit<
	CompanyEditProps,
	'onSave' | 'companyData'
>

const CompanyPersonalPageEdit: React.FC<CompanyPersonalPageEditProps> = ({
	setLoading,
	setErrorMessage,
	setIsErrorPromptOpened,
	setIsSuccessPromptOpened,
	setPopUpVisible,
	companyId,
	companyType,
}) => {
	const [currentCompany, setCurrentCompany] = useState<any>(null)
	const { getAdvertiserByCompanyId, getCreatorsAction, insertCreatorAction } =
		useActions()
	const { publisher, creator } = useTypedSelector((state) => state)
	const navigate = useNavigate()

	useEffect(() => {
		fetchData()
	}, [companyId])

	const fetchData = async () => {
		try {
			setLoading(true)
			switch (companyType) {
				case 'advertiser':
					const advertiserData = await getAdvertiserByCompanyId(companyId!)
					setCurrentCompany(advertiserData)
					break
				case 'creator':
					getCreatorsAction()
					const currCreator = creator.creator.find(
						(item: any) => item.company_id === companyId,
					)
					setCurrentCompany(currCreator)
					break
				case 'publisher':
					const currPublisher = publisher.publisher.find(
						(item: any) => item.company_id === companyId,
					)
					setCurrentCompany(currPublisher)
					break
				case 'agency':
					// we need to handle agency case
					break
				default:
					console.error('Invalid company type')
					return null
			}
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleSave = async (updatedData: any) => {
		try {
			setLoading(true)
			switch (companyType) {
				case 'advertiser':
					// save to db
					// update store?
					break
				case 'creator':
					// save to db
					await insertCreatorAction(
						{ ...updatedData, company_id: companyId },
						setErrorMessage,
					)
					// update store?
					break
				case 'publisher':
					const currPublisher = publisher.publisher.find(
						(item: any) => item.company_id === companyId,
					)
					setCurrentCompany(currPublisher)
					break
				case 'agency':
					// we need to handle agency case
					break
				default:
					console.error('Invalid company type')
					return null
			}
			setIsSuccessPromptOpened(true)
			navigate('/companySettings')
		} catch (error) {
			console.error(error)
			setErrorMessage('Error updating company data')
			setIsErrorPromptOpened(true)
		} finally {
			setLoading(false)
		}
	}

	if (!companyType || !currentCompany) {
		return <div>Loading...</div>
	}

	const EditComponent = companyEditComponents[companyType]

	return (
		<EditComponent
			setLoading={setLoading}
			setErrorMessage={setErrorMessage}
			setIsErrorPromptOpened={setIsErrorPromptOpened}
			setIsSuccessPromptOpened={setIsSuccessPromptOpened}
			setPopUpVisible={setPopUpVisible}
			onSave={handleSave}
			companyData={currentCompany}
		/>
	)
}

export default CompanyPersonalPageEdit
