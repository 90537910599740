import React, { useEffect, useState } from 'react'
import {
	GradientTitleComponent,
	PageWrapper,
	TableWrapper,
} from '../components/reusableComponents'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { useNavigate, useParams } from 'react-router'
import { InfoField } from '../advertisers/AdvertiserPreviewPage'
import { CheckOutlined } from '@mui/icons-material'
import { GridColDef } from '@mui/x-data-grid'
import { Grid, Typography, useTheme } from '@mui/material'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { TableComponent } from '../components/TableComponents'
import { StatusTitleTag, TitleTag } from '../components/Informative'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'

/*
this component renders the 'General Info' section of the campaign preview
takes 4 props:
campaign: the info to render is in this prop
closeDetailedView: the function to go back to campaigns page
openEdit: the function to transition to 'edit mode' from the preview
permissionsGeneral: the permissions rule set for the logged in user
*/
const GeneralInfo = (props: {
	campaign: any
	closeDetailedView: any
	openEdit: any
	permissionsGeneral: any
}) => {
	const theme = useTheme()
	return (
		<>
			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						width: '95%',
						marginBottom: 12,
					}}
				>
					<UnborderedButton
						style={{
							width: '80px',
							display: 'flex',
							justifyContent: 'flex-start',
							color: theme.colors.text.titles,
						}}
						onClick={props.closeDetailedView}
						icon={
							<ArrowBackIosIcon
								style={{
									height: '16px',
									marginRight: '0px',
									marginLeft: -10,
									color: theme.colors.text.titles,
								}}
							/>
						}
					>
						Back
					</UnborderedButton>
					{props.permissionsGeneral && (
						<UnborderedButton
							style={{
								display: 'flex',
								marginLeft: 'auto',
								color: theme.colors.text.titles,
							}}
							onClick={props.openEdit}
							icon={<EditIcon />}
						>
							Edit
						</UnborderedButton>
					)}
				</div>
				<div style={{ display: 'flex' }}>
					<TitleTag style={{ marginLeft: 0 }}>
						{props.campaign.campaign_name}
					</TitleTag>
					<StatusTitleTag status={props.campaign.status} />
				</div>

				<Grid
					container
					columnSpacing={2}
					rowSpacing={2}
					columns={12}
					direction='row'
					style={{
						fontSize: theme.font.size.body,
						marginTop: 6,
					}}
				>
					<Grid item xs={3}>
						<InfoField
							label={'App Name'}
							value={props.campaign.campaign_name.split('-')[0]}
							whiteSpace={
								props.campaign.campaign_name &&
								props.campaign.campaign_name.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Advertiser Name'}
							value={props.campaign.advertiser_name}
							whiteSpace={
								props.campaign.advertiser_name &&
								props.campaign.advertiser_name.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'App ID'}
							value={props.campaign.app_id}
							whiteSpace={
								props.campaign.app_id && props.campaign.app_id.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Privacy Level'}
							value={props.campaign.privacy_level}
							whiteSpace={
								props.campaign.privacy_level &&
								props.campaign.privacy_level.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Fraud Tool'}
							value={
								props.campaign.fraud_tool ? props.campaign.fraud_tool : 'None'
							}
							whiteSpace={
								props.campaign.fraud_tool &&
								props.campaign.fraud_tool.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Verticals'}
							value={
								props.campaign.vertical?.length
									? props.campaign.vertical.join(',')
									: 'None'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Agency Account'}
							value={
								props.campaign.network_account.length
									? props.campaign.network_account.join(', ')
									: 'Not Available'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Incent Allow'}
							value={props.campaign.incentive ? 'Yes' : 'No'}
							whiteSpace={
								props.campaign.incentive && props.campaign.incentive.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>

					<Grid item xs={3}>
						<InfoField
							label={'AppsFlyer C Parameter'}
							value={props.campaign.c_parameter}
							whiteSpace={
								props.campaign.c_parameter &&
								props.campaign.c_parameter.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<InfoField
							label={'Preview link'}
							value={props.campaign.preview_url}
							whiteSpace={
								props.campaign.preview_url &&
								props.campaign.preview_url.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label='VTA Allowed'
							value={props.campaign.vta_allowed ? 'Yes' : 'No'}
						/>
					</Grid>
				</Grid>
			</div>
		</>
	)
}

/*
this component renders the 'Guidelines' section of the campaign preview
takes 1 prop:
campaign: the info to render is in this prop
*/
const Guidelines = (props: { campaign: any }) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 24,
			}}
		>
			{/* <div style={{ display: 'flex', gap: '20px' }}>
				{props.campaign.vta_allowed && (
					<span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
						<CheckOutlined style={{ fill: theme.colors.base.green300 }} /> VTA
						Allowed
					</span>
				)}
				{props.campaign.prob_vta && (
					<span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
						<CheckOutlined style={{ fill: theme.colors.base.green300 }} />{' '}
						Probabilistic VTA
					</span>
				)}
				{props.campaign.revenue_needed && (
					<span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
						<CheckOutlined style={{ fill: theme.colors.base.green300 }} />{' '}
						Revenue
					</span>
				)}
				{props.campaign.organic_needed && (
					<span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
						<CheckOutlined style={{ fill: theme.colors.base.green300 }} />{' '}
						Organic Data
					</span>
				)}
				{props.campaign.retargeting && (
					<span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
						<CheckOutlined style={{ fill: theme.colors.base.green300 }} />{' '}
						Retargeting
					</span>
				)}
			</div> */}
			<Grid
				container
				columns={12}
				columnSpacing={2}
				rowSpacing={2}
				direction={'row'}
				style={{ fontSize: theme.font.size.body }}
			>
				<Grid item xs={3}>
					<InfoField
						label={'Campaign Flow'}
						value={
							props.campaign.campaign_flow
								? props.campaign.campaign_flow
								: 'Not available'
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label={'Special Notes'}
						value={props.campaign.note ? props.campaign.note : 'None'}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label={'Traffic Restrictions'}
						value={
							props.campaign.traffic
								? props.campaign.traffic.length
									? props.campaign.traffic.join(', ')
									: 'None'
								: 'None'
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label={'Existing Channels Client is Using'}
						value={
							props.campaign.channels.length
								? props.campaign.channels.join(', ')
								: 'None'
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label={'Payable Events'}
						value={
							props.campaign.tracked_event
								? props.campaign.tracked_event
										.map((ev: any) => {
											if (ev.defaultCost !== 0 || ev.defaultRevenue !== 0) {
												return ev.eventName
											} else return undefined
										})
										.filter((ev: any) => ev !== undefined)
										.join(' - ')
								: ''
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<span
						style={{
							color: theme.colors.base.grey600,
							fontWeight: theme.font.weight.normal,
							fontSize: theme.font.size.body,
							textDecoration: 'underline',
						}}
					>
						Target Audience:
					</span>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label={'Gender'}
						value={props.campaign.gender ? props.campaign.gender : 'None'}
					/>
				</Grid>
				<Grid
					item
					xs={9}
					style={{ display: 'flex', flexDirection: 'row', gap: 6 }}
				>
					<InfoField
						label={'From Age'}
						value={props.campaign.age_from ? props.campaign.age_from : 'None'}
					/>
					<InfoField
						label={'To'}
						value={props.campaign.age_to ? props.campaign.age_to : 'None'}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label={'Campaign End Date'}
						value={
							props.campaign.end_date
								? getFormattedDate(props.campaign.end_date)
								: 'None'
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label={'Time Frame'}
						value={props.campaign.timeframe ? props.campaign.timeframe : 'None'}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label={'Budget'}
						value={
							props.campaign.budget_amount
								? props.campaign.budget_amount
								: 'None'
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label={'Cap'}
						value={props.campaign.cap ? props.campaign.cap : 'None'}
					/>
				</Grid>

				<Grid
					item
					xs={6}
					style={{ display: 'flex', gap: 8, flexDirection: 'column' }}
				>
					<span
						style={{
							color: theme.colors.base.grey600,
							fontWeight: theme.font.weight.normal,
							fontSize: theme.font.size.body,
						}}
					>
						Hard KPI
					</span>
					<div
						style={{
							border: '1px solid ' + theme.colors.base.grey300,
							padding: '4px 14px 4px 14px',
							fontSize: '14px',
							borderRadius: '8px',
							width: '88%',
							minHeight: '8vh',
							lineHeight: '1.4',
							whiteSpace: 'pre-wrap',
							color: theme.colors.base.grey900,
						}}
					>
						<p>
							{props.campaign.hard_kpi
								? props.campaign.hard_kpi
								: 'No Hard KPI'}
						</p>
					</div>
				</Grid>
				<Grid
					item
					xs={6}
					style={{ display: 'flex', gap: 8, flexDirection: 'column' }}
				>
					<span
						style={{
							color: theme.colors.base.grey600,
							fontWeight: theme.font.weight.normal,
							fontSize: theme.font.size.body,
						}}
					>
						Soft KPI
					</span>
					<div
						style={{
							border: '1px solid ' + theme.colors.base.grey300,
							padding: '4px 14px 4px 14px',
							fontSize: '14px',
							borderRadius: '8px',
							width: '88%',
							minHeight: '8vh',
							lineHeight: '1.4',
							whiteSpace: 'pre-wrap',
							color: theme.colors.base.grey900,
						}}
					>
						<p>
							{props.campaign.soft_kpi
								? props.campaign.soft_kpi
								: 'No soft KPI'}
						</p>
					</div>
				</Grid>
			</Grid>
			<hr
				style={{
					background: theme.colors.base.grey200,
					height: '1px',
					width: '96%',
					border: 'none',
					marginLeft: 0,
					marginTop: 34,
				}}
			></hr>
		</div>
	)
}

/*
this component renders the 'Targeting and Affiliates' section of the campaign preview
takes 1 prop:
campaign: the info to render is in this prop
*/
const getFormattedDate = (dateStr: string): string => {
	const date = new Date(dateStr)

	const day = String(date.getDate()).padStart(2, '0')
	const month = String(date.getMonth() + 1).padStart(2, '0')
	const year = date.getFullYear()

	const formattedDate = `${day}-${month}-${year}`
	return formattedDate
}
const TargetingAndAffiliates = (props: { campaign: any }) => {
	return <></>
}

/*
this component renders the 'Targeting and Affiliates' section of the campaign preview
the component renders a table displaying the unique payouts for the campaign
take 2 props:
campaign: the info to render is in this prop
setLoading: the function to trigger the UI loader
*/
const UniquesTable = (props: { uniques: any; setLoading: any }) => {
	const theme = useTheme()
	let eventNames = props.uniques?.M?.tracked_event?.map((ev: any) => ev.eventName) || [];

	const [rowCount, setRowCount] = useState<number>(3.5)

	useEffect(() => {
		if (eventNames.length === 2) {
			setRowCount(3)
		} else {
			setRowCount(3.5)
		}
	}, [eventNames])

	const [pageSize, setPageSize] = useState(15)

	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)

	/*
	this function handles generating key value pairs for
	events revenue and cost
	the function goal is to return an array of the revenue and cost
	values to display in the unique payouts table in an orderly way
	takes 1 argument:
	unique: the unique holds the info of the events and payouts
	*/
	const generateEventsValues = (unique: any) => {
		let costsArr = []
		for (let i = 0; i < eventNames.length; i++) {
			if (unique.tracked_event[i]) {
				const eventNameRev = unique.tracked_event[i].eventName + ' (Revenue)'
				let rev = unique.tracked_event[i].defaultRevenue
				costsArr.push({ [eventNameRev]: rev })
				const eventNameCost = unique.tracked_event[i].eventName + ' (Cost)'
				let cost = unique.tracked_event[i].defaultCost
				costsArr.push({ [eventNameCost]: cost })
			}
		}
		return costsArr
	}
	const uniquesArr = Object.entries(props.uniques)

	const columns: GridColDef[] = [
		{
			field: 'media_source_pid',
			headerName: 'Media Source PID',
			align: 'left',
			width: rowWidth / 3,
			renderCell: (row) => (
				<Typography
					title={row.row['media_source_pid'].join(', ')}
					sx={{
						fontWeight: 600,
						fontSize: 14,
						overflow: 'auto',
						display: 'flex',
						gap: '4px',
						width: '95%',
						maxHeight: '50px',
						overflowY: 'auto',
						flexWrap: 'wrap',
					}}
				>
					{row.row['media_source_pid'].map(
						(pid: string, index: number, array: string[]) =>
							index === array.length - 1 ? (
								<span key={pid + index}>{pid}</span>
							) : (
								<span key={pid + index}>{pid}, </span>
							),
					)}
				</Typography>
			),
		},
		{
			field: 'country',
			headerName: 'Country',
			align: 'left',
			width: rowWidth / 3,
			renderCell: (row) => {
				return (
					<div
						style={{
							width: '95%',
							maxHeight: '50px',
							overflowY: 'auto',
							marginTop: '0px',
						}}
					>
						<Typography
							sx={{
								fontWeight: 600,
								fontSize: 14,
								display: 'flex',
								alignItems: 'center',
								gap: '5px',
								flexWrap: 'wrap',
							}}
						>
							{row.row.country[0] !== ''
								? row.row.country.map((country: string) => (
										<InfoField
											key={country}
											label={''}
											value={country.toUpperCase()}
											isCountry
										/>
								  ))
								: 'GLOBAL'}
						</Typography>
					</div>
				)
			},
		},
		...eventNames.flatMap((name: string) => [
			{
				field: name + ' (Revenue)',
				headerName: name + ' (Revenue)',
				align: 'left',
				width: rowWidth / 3.8,
				renderCell: (row: any) => (
					<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
						{row.row[name + ' (Revenue)'] ? row.row[name + ' (Revenue)'] : '0'}
					</Typography>
				),
			},
			{
				field: name + ' (Cost)',
				headerName: name + ' (Cost)',
				align: 'left',
				width: rowWidth / 3.8,
				renderCell: (row: any) => (
					<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
						{row.row[name + ' (Cost)'] ? row.row[name + ' (Cost)'] : '0'}
					</Typography>
				),
			},
		]),
	]

	const tableRows = uniquesArr.map((unique: any) => {
		const uniqueData = unique[1]
		const eventsValues = generateEventsValues(uniqueData)
		const eventsValuesObject = eventsValues.reduce(
			(acc, cur) => ({ ...acc, ...cur }),
			{},
		)
		return {
			id: Math.random(),
			media_source_pid: uniqueData.media_source_pid,
			country: uniqueData.country,
			...eventsValuesObject,
		}
	})

	return (
		<TableWrapper
			theme={theme}
			rowCount={rowCount}
			setRowWidth={setRowWidth}
			height='auto'
			style={{
				paddingBottom: '60px',
				width: '80%',
				paddingInlineStart: '56px',
				height: 'auto',
				overflow: 'auto',
				marginTop: 12,
			}}
		>
			<TableComponent
				columns={columns}
				rows={tableRows}
				setPageSize={setPageSize}
				pageSize={pageSize}
				rowsPerPageOptions={[6, 12, 24]}
				setLoading={props.setLoading}
				rowHeight={80}
				style={{ height: 'fit-content', width: '94%' }}
				customSx={{
					'& .MuiDataGrid-columnHeaderTitle': {
						lineHeight: '18px',
						whiteSpace: 'pre-line',
					},
				}}
			></TableComponent>
		</TableWrapper>
	)
}

const CampaignPreviewPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	setUniversalEdit: any
	viewRecord: any
	setViewRecord: any
	setUniversalDelete: any
	PopUpVisible: any
	setPopUpVisible: any
}) => {
	const { campaign, users, settings, login } = useTypedSelector(
		(state) => state,
	)
	const [currCampaign, setCurrCampaign] = useState<any>(null)
	const [uniques, setUniques] = useState<any>(null)
	const [sameCompanyId, setSameCompanyId] = useState(false)
	const canEdit =
		useIsAuthorized(permissionNames.ADD_EDIT_CAMPAIGN) && sameCompanyId
	const theme = useTheme()
	const navigate = useNavigate()
	const params = useParams()

	const closeDetailedView = () => {
		navigate('/campaigns')
		props.setPopUpVisible(false)
	}
	const openEdit = () => {
		navigate('/campaigns/' + currCampaign.uuid)
	}

	useEffect(() => {
		const foundCampaign = campaign.campaign.find(
			(e: any) => e.uuid.trim() === params.id!.trim(),
		)
		setCurrCampaign(foundCampaign)
		setSameCompanyId(foundCampaign.company_id === login.user.company_id)
		const foundUniques = formUniqueDictionary(foundCampaign)
		setUniques(foundUniques)
	}, [params])

	/*
	this function handles creating an object that can hold the info
	for the unique payouts and media source pids and geos.
	the function goal is to return a dict of the unique payouts each with
	all the relevant data to have it displayed properly in the component
	*/
	const formUniqueDictionary = (currentCampaign: any) => {
		if (params.id) {
			const foundCampaign: any = campaign.campaign.find(
				(e: any) => e.uuid === params.id && e.role === 'M',
			)
			const foundCampaignsArray: any = campaign.campaign.filter(
				(e: any) => e.campaign_name === foundCampaign.campaign_name,
			)

			let result: any = {}
			for (let e of foundCampaignsArray) {
				Object.assign(result, {
					[e.role]: {
						media_source_pid: e.media_source_pid,
						country: e.country,
						tracked_event: e.tracked_event,
						is_revenue_share: e.is_revenue_share,
						uuid: e.uuid,
						cost_from_data: e.cost_from_data,
					},
				})
			}
			if (Object.keys(result).length === 0) {
				Object.assign(result, {
					M: {
						media_source_pid: [],
						country: [''],
						tracked_event: [
							{
								order: 1,
								eventName: 'installs',
								defaultCost: 0,
								defaultRevenue: 0,
							},
						],
						uuid: undefined,
					},
				})
			}
			return result
		}
	}

	if (currCampaign) {
		return (
			<PageWrapper
				style={{
					color: theme.colors.text.titles,
					display: 'flex',
					// marginTop: '20px',
					flexDirection: 'column',
					gap: 14,
					paddingBottom: '30px',
				}}
			>
				<GeneralInfo
					campaign={currCampaign}
					closeDetailedView={closeDetailedView}
					openEdit={openEdit}
					permissionsGeneral={canEdit}
				/>
				<Guidelines campaign={currCampaign} />
				<UniquesTable uniques={uniques} setLoading={props.setLoading} />
			</PageWrapper>
		)
	} else {
		return <></>
	}
}

export default CampaignPreviewPage
