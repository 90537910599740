import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { PageWrapper } from '../components/reusableComponents'
import { Grid, useTheme } from '@mui/material'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { countryList } from '../components/countryList'
import { useActions } from '../../hooks/useActions'
import { InputFieldText, StyledTextAreaField } from '../components/Inputs'
import { CountryListSelector, DropList } from '../components/SelectableInputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

const WishListAdd = (props: {
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	setIsSuccessPromptOpened: any
	setIsAdding: any
	setAddVisible: any
	row: any
	setRow: any
}) => {
	const theme = useTheme()
	const { addPublisherWishList } = useActions()
	const { login, publisher } = useTypedSelector((state) => state)
	const [publisherName, setPublisherName] = useState<string>('')
	const [appId, setAppId] = useState<string | undefined>()
	const [appName, setAppName] = useState<string | undefined>()
	const [requestedPO, setRequestedPO] = useState<string | undefined>()
	const [previewLink, setPreviewLink] = useState<string | undefined>()
	const [platform, setPlatform] = useState<string | undefined>()
	const [comments, setComments] = useState<string>('')
	const [geo, setGeo] = useState<string[]>([])

	const publisherList = publisher.publisher.map((el: any) => el.publisher_name)

	const isValid = () => {
		if (publisherName && appId) {
			return true
		}
		return false
	}

	useEffect(() => {
		if (props.row) {
			setPublisherName(props.row.publisher)
			setAppId(props.row.app)
			setAppName(props.row.app_name)
			setRequestedPO(props.row.requested_PO)
			setPreviewLink(props.row.preview_link)
			setPlatform(props.row.platform)
			setComments(props.row.comments)
			setGeo(props.row.geo)
		}
	}, [])

	const uploadData = async () => {
		const data: any = {
			publisher: publisherName,
			app: appId,
			app_name: appName,
			requested_PO: requestedPO,
			preview_link: previewLink,
			platform,
			comments,
			geo,
			uuid: props.row ? props.row.uuid : undefined,
		}
		for (const key in data) {
			const typedKey = key as keyof typeof data
			if (typeof data[typedKey] === 'string') {
				data[typedKey] = (data[typedKey] as string).trim()
			}
		}

		// const headers = {
		// 	Authorization: `Token ${login.user.token}`,
		// }
		// props.setLoading(true)

		const failed = await addPublisherWishList(data, props.setErrorMessage)
		if (!failed) {
			props.setIsSuccessPromptOpened(true)
			if (props.row) {
				props.setAddVisible(false)
				props.setIsAdding(false)
				props.setRow(undefined)
			}
		}
	}

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper style={{ marginTop: '10px' }}></PageWrapper>
			<Grid
				container
				spacing={0}
				direction={'row'}
				alignItems={'center'}
				width={'100%'}
			></Grid>
			<div
				style={{
					width: '80%',
					gap: '48px',
					display: 'flex',
					justifyContent: 'center',
					padding: '4vh 0% 4vh 6vw',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						gap: '18px',
					}}
				>
					<div
						style={{
							width: '50%',
							gap: '18px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div>
							<DropList
								value={publisherName}
								onChange={(e: any) => setPublisherName(e)}
								options={publisherList}
								label={'Publisher'}
								errored={
									publisherName === undefined ||
									publisherName === null ||
									publisherName === ''
								}
							/>
						</div>
						<div>
							<InputFieldText
								label='App ID'
								type='text'
								value={appId}
								onChange={setAppId}
								style={{ boxShadow: !appId ? 'red 0px 0px 7px' : 'none' }}
							/>
						</div>
						<div>
							<InputFieldText
								label='App Name'
								type='text'
								value={appName}
								onChange={setAppName}
							/>
						</div>
						<div>
							<InputFieldText
								label='Requested PO'
								type='text'
								value={requestedPO}
								onChange={setRequestedPO}
							/>
						</div>
					</div>
					<div
						style={{
							width: '50%',
							gap: '18px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div>
							<InputFieldText
								label='Preview Link'
								type='text'
								value={previewLink}
								onChange={setPreviewLink}
							/>
						</div>
						<div>
							<InputFieldText
								label='Platform'
								type='text'
								value={platform}
								onChange={setPlatform}
							/>
						</div>
						<div>
							<CountryListSelector
								label='Geo'
								options={countryList}
								value={geo}
								singular
								onChange={(e: any) => setGeo(e)}
							/>
						</div>
						<div>
							<StyledTextAreaField
								label='Comments'
								value={comments}
								onChange={setComments}
							/>
						</div>
					</div>
				</div>
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<UnborderedButton
						style={{ margin: 'auto' }}
						onClick={() => {
							props.setAddVisible(false)
							props.setIsAdding(false)
							props.setRow(undefined)
						}}
						label={'Cancel'}
					></UnborderedButton>
					<SubmitButton
						style={{ margin: 'auto' }}
						disabled={!isValid()}
						onClick={() => {
							uploadData()
						}}
					>
						{props.row ? 'Update' : 'Save'}
					</SubmitButton>
				</div>
			</div>
		</ThemeProvider>
	)
}

export default WishListAdd
