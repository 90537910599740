import AdapterDateFns from '@date-io/date-fns'
import { ThemeProvider } from '@emotion/react'
import {
	Checkbox,
	Grid,
	Paper,
	Switch,
	Typography,
	useTheme,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchData } from '../../utils/helpers/navigationHelper'
import {
	findUsernameByEmail,
	generateRowsWithIds,
	searchFor,
} from '../../utils/helpers/tableHelper'
import {
	GradientedText,
	PageWrapper,
	TableWrapper,
} from '../components/reusableComponents'
import { StyledCheckboxFormControl } from '../reports/aggregated/FiltersStyled'
import DetailedLogs from './DetailedLogs'
import { usersAction } from '../../state/action-creators'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { TableComponent } from '../components/TableComponents'
import { TitleComponent } from '../components/Informative'
import { DatePicker } from '../components/dateInputs/DateInputs'
import { SwitchComponent } from '../components/ToggleComponents'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'

const LogsPage = (props: {
	setLoading: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const rowCount = 5
	const [pageSize, setPageSize] = useState(15)

	const { getLogsAction, getLogsByDateAction } = useActions()
	const { login, logs, users, settings } = useTypedSelector((state) => state)
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])
	const [row, setRow] = useState()
	const [found, setFound] = useState<any[]>([])
	const [fromDate, setFromDate] = useState(new Date())
	const [toDate, setToDate] = useState(new Date())
	const [isDateEnabled, setIsDateEnabled] = useState(false)
	const [update, setUpdate] = useState(false)
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)

	const permissions = {
		view: useIsAuthorized(permissionNames.VIEW_LOGS),
		viewDetailed: useIsAuthorized(permissionNames.VIEW_DETAILED_LOGS),
	}

	useEffect(() => {
		if (props.search.length > 0 && props.search !== '') {
			setFound(searchFor(props.search, logs.logs, columns))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.search])
	useEffect(() => {
		const handleUpdate = async () => {
			await fetchData(getLogsAction, logs.logs)
			isDateEnabled
				? await getLogsByDateAction(fromDate, toDate)
				: await getLogsAction()

			setUpdate(!update)
		}
		if (
			isDateEnabled === false &&
			toDate.toLocaleDateString() !== new Date().toLocaleDateString() &&
			fromDate.toLocaleDateString() !== new Date().toLocaleDateString()
		) {
			setToDate(new Date())
			setFromDate(new Date())
		}
		handleUpdate()
	}, [isDateEnabled, toDate, fromDate])

	const handleEdit = (row: any) => {
		setRow(row.row)
		props.setPopUpVisible(true)
	}
	const handleClose = async () => {
		props.setPopUpVisible(false)
		setRow(undefined)
		await fetchData(usersAction, logs.logs)
		setUpdate(!update)
	}

	const columns: GridColDef[] = [
		{
			field: 'email',
			headerName: 'Owner',
			width: rowWidth / 2.7,
			valueFormatter: ({ value }) =>
				value ? findUsernameByEmail(value.toString(), users.users) : 'User',
		},
		{
			field: 'change',
			headerName: 'Change',
			width: rowWidth * 2.6,
			renderCell: ({ value }) => value,
		},
		{
			field: 'object',
			headerName: 'Changed Object',
			width: rowWidth / 1.3,
			renderCell: ({ value }) => value,
		},
		{
			field: 'createdAt',
			headerName: 'Date',
			width: rowWidth / 3,
			valueFormatter: ({ value }) =>
				value ? format(new Date(value.toString()), 'dd/MM/yyyy') : 'Now',
		},
		{
			field: 'time',
			headerName: 'Time (GMT +3)',
			width: rowWidth / 2,
			valueFormatter: ({ value }) => value,
		},
	]

	return (
		<ThemeProvider theme={theme}>
			{permissions.view && (
				<PageWrapper>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '94%',
						}}
					>
						<TitleComponent style={{ color: theme.colors.text.titles }}>
							Logs
						</TitleComponent>

						<Paper
							style={{
								display: 'flex',
								alignItems: 'center',
								boxShadow: 'none',
								background: 'inherit',
								gap: 34,
							}}
						>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<SwitchComponent
									onChange={setIsDateEnabled}
									value={isDateEnabled}
									label={'Timeframe'}
									disabled={false}
								/>

								<div style={{ display: 'flex', gap: 34 }}>
									<DatePicker
										label={'Start Date'}
										onChange={setFromDate}
										value={fromDate}
										disabled={!isDateEnabled}
										format='dd/MM/yyyy'
									/>
									<DatePicker
										label='End Date'
										onChange={setToDate}
										value={toDate}
										disabled={!isDateEnabled}
										format='dd/MM/yyyy'
									/>
								</div>

								{/*
									<StyledFormControl
										style={{ width: '150px', top: '-15px', left: '19px' }}
									>
										<DateComponent
											label='End Date'
											onChange={(e: any) => {
												setToDate(e)
											}}
											value={toDate}
											inputFormat='dd/MM/yyyy'
											disabled={!isDateEnabled}
											OpenPickerButtonProps={{
												style: { paddingRight: '20px', paddingLeft: '0px' },
											}}
											renderInput={(params: any) => (
												<StyledTextInputField
													{...params}
													focused
													fullWidth
													InputLabelProps={{
														style: { top: '-20%' },
													}}
												/>
											)}
										/>
									</StyledFormControl> */}
							</LocalizationProvider>
						</Paper>
					</div>
					{row && permissions.viewDetailed && (
						<DetailedLogs
							PopUpVisible={props.PopUpVisible}
							setPopUpVisible={handleEdit}
							handleClose={handleClose}
							setLoading={props.setLoading}
							row={row}
						/>
					)}
					<TableWrapper
						theme={theme}
						rowCount={rowCount}
						setRowWidth={setRowWidth}
						style={{ marginTop: '12px', paddingBottom: '60px', width: '90%' }}
					>
						<TableComponent
							columns={columns}
							rows={generateRowsWithIds(
								props.search.length > 0 ? found : logs.logs,
							)}
							setPageSize={setPageSize}
							pageSize={pageSize}
							rowsPerPageOptions={[15, 25, 50]}
							setLoading={props.setLoading}
							onCellClick={handleEdit}
						/>
					</TableWrapper>
				</PageWrapper>
			)}
		</ThemeProvider>
	)
}
export default LogsPage
