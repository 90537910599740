import { Grid, useTheme } from '@mui/material'
import {
	checkValidBudgets,
	generateNodesFromArray,
} from '../../utils/helpers/helperFuncs'
import { TreeCheckboxContainer } from '../components/TreeCheckBox'
import { InputFieldText } from '../components/Inputs'
import { TitleComponent } from '../components/Informative'

// Tab 1: Traffic & Budget Component for editing advertiser.
export const TrafficBudgetComponent = (props: {
	loginRole: string
	settings: any
	trafficRestrictions: string[]
	setTrafficRestrictions: (value: string[]) => void
	existingChannels: string[]
	setExistingChannels: (value: string[]) => void
	dailyBudgetLimit: number
	setDailyBudgetLimit: (value: number) => void
	monthlyBudgetLimit: number
	setMonthlyBudgetLimit: (value: number) => void
	totalBudgetLimit: number
	setTotalBudgetLimit: (value: number) => void
	permissions: any
}) => {
	const permissions = props.permissions
	const theme = useTheme()
	// Generate nodes for traffic and channels based on settings
	const generateNodesTrafficRestriction = () => {
		return generateNodesFromArray(props.settings.settings.trafficRestrictions, {
			value: '',
			label: '',
		})
	}
	const generateNodesExistingChannels = () => {
		return generateNodesFromArray(props.settings.settings.existingChannels, {
			value: '',
			label: '',
		})
	}

	return (
		<Grid
			container
			columnSpacing={0}
			columns={12}
			style={{
				minHeight: '50vh',
				justifyContent: 'center',
				width: '96%',
			}}
		>
			{/* Traffic Restrictions */}
			<Grid item xs={4}>
				<TreeCheckboxContainer
					nodes={generateNodesTrafficRestriction()}
					selectedElements={props.trafficRestrictions}
					setSelectedElements={props.setTrafficRestrictions}
					label={'Traffic Restrictions'}
					expandFilter={true}
					disabled={!permissions.edit}
				/>
			</Grid>

			{/* Existing Channels */}
			<Grid item xs={4}>
				<TreeCheckboxContainer
					nodes={generateNodesExistingChannels()}
					selectedElements={props.existingChannels}
					setSelectedElements={props.setExistingChannels}
					label={'Existing Channels'}
					expandFilter={true}
					disabled={!permissions.edit}
				/>
			</Grid>

			{/* Budget Section */}
			<Grid item xs={4}>
				<TitleComponent
					style={{
						fontSize: theme.font.size.buttons,
						color: theme.colors.text.titles,
						fontWeight: 'bold',
						marginLeft: '14px',
						marginBottom: '8px',
						marginTop: 10,
					}}
				>
					Budget (USD):
				</TitleComponent>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						border: '1px solid' + theme.colors.lightGray,
						padding: '4px 14px 8px 14px',
						fontSize: '14px',
						borderRadius: '8px',
						gap: '12px',
					}}
				>
					{/* Daily Budget */}
					<div>
						<InputFieldText
							label='Daily Budget'
							type='number'
							value={props.dailyBudgetLimit}
							onChange={(e: any) => props.setDailyBudgetLimit(e)}
							disabled={!permissions.edit}
							errormessage={
								!!checkValidBudgets(
									props.dailyBudgetLimit,
									props.monthlyBudgetLimit,
								)
									? "Daily budget can't be bigger than monthly budget."
									: undefined
							}
						/>
					</div>

					{/* Monthly Budget */}
					<div>
						<InputFieldText
							label='Monthly Budget'
							type='number'
							value={props.monthlyBudgetLimit}
							onChange={(e: any) => props.setMonthlyBudgetLimit(e)}
							disabled={!permissions.edit}
							errormessage={
								!!checkValidBudgets(
									props.dailyBudgetLimit,
									props.monthlyBudgetLimit,
								)
									? "Monthly budget can't be smaller than daily budget."
									: undefined
							}
						/>
					</div>

					{/* Total Budget */}
					<InputFieldText
						label='Total Budget'
						type='number'
						value={props.totalBudgetLimit}
						onChange={(e: any) => props.setTotalBudgetLimit(e)}
						disabled={!permissions.edit}
					/>
				</div>
			</Grid>
		</Grid>
	)
}
