import { ThemeProvider, useTheme } from '@mui/material'
import '@tremor/react/dist/esm/tremor.css'
import { saveAs } from 'file-saver'
import { useEffect, useRef, useState } from 'react'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { PresetsPopup } from './PresetsComponent'
import {
	ReportGrid,
	ReportItem,
	ReportItemHeading,
	StyledBox,
} from './ReportsStyled'
import ReportsPageLayout from '../layouts/ReportsPageLayout'
import ReportTableSectionLayout from '../layouts/ReportTableSectionLayout'
import { useDispatch } from 'react-redux'
import { FiltersComponentList } from './FiltersComponent'
import { handleAdjustedDateChange } from '../../../utils/helpers/helperFuncs'
import {
	getAccountsAction,
	getPublisherAction,
} from '../../../state/action-creators'
import { useSearchParams } from 'react-router-dom'

export interface ReportFieldsInterface {
	from_date: string
	to_date: string
	breakdowns: string[]
	statistics: string[]
	filters: ReportFilterInterface
}
export interface ReportFilterInterface {
	app_id: string[]
	advertiser_name: string[]
	media_source_pid: string[]
	publisher_name: string[]
	email: string[]
	campaign_name: string[]
	campaign_id: string[]
	country: string[]
	agencyAccounts: string[]
}

const AggreagatedReportPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: string | null
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	viewRecord: any
	setViewRecord: any
}) => {
	const theme = useTheme()
	const [report, setReport] = useState<any>()
	const [reportFields, setReportFields] = useState<ReportFieldsInterface>({
		from_date: '',
		to_date: '',
		breakdowns: [],
		statistics: [],
		filters: {
			app_id: [''],
			advertiser_name: [''],
			media_source_pid: [''],
			publisher_name: [''],
			email: [''],
			campaign_name: [''],
			campaign_id: [''],
			country: [''],
			agencyAccounts: [''],
		},
	})

	const [viewDataFor, setViewDataFor] = useState<string>('Yesterday')
	const [startDay, setStartDay] = useState<string>('')
	const [endDay, setEndDay] = useState<string>('')
	const [breakdownFields, setBreakdownFields] = useState<string[]>([])
	const [statisticsFields, setStatisticsFields] = useState<string[]>([])
	const [appIds, setAppIds] = useState<string[]>([])
	const [advertisers, setAdvertisers] = useState<string[]>([])
	const [user, setUser] = useState<string[]>([])
	const [pid, setPid] = useState<string[]>([])
	const [publishers, setPublishers] = useState<string[]>([])
	const [campaignNames, setCampaignNames] = useState<string[]>([])
	const [campaignIds, setCampaignIds] = useState<string[]>([])
	const [countries, setCountries] = useState<string[]>([])
	const [agencyAccounts, setAgencyAccounts] = useState<string[]>([])
	const { login, reports } = useTypedSelector((state) => state)
	const [smallLoading, setSmallLoading] = useState(false)

	const {
		saveAppsflyerAction,
		getRefreshAction,
		getUpdatedAtAction,
		getFiltersAction,
		loadAllReportsAction,
	} = useActions()
	const [lastUpdatedAt, setLastUpdatedAt] = useState<string | undefined>(
		undefined,
	)
	const [urlQuery] = useSearchParams()
	const [isReportOpen, setIsReportOpen] = useState<boolean>(false)
	const [fileDownload, setFileDownload] = useState<any>()
	const [presetVisible, setPresetVisible] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [chartData, setChartData] = useState<any>(null)
	const [loading, setLoading] = useState(false)
	const downloadButtonRef = useRef<HTMLButtonElement | null>(null)
	const reportSectionRef = useRef<HTMLDivElement>(null)
	const dispatch = useDispatch()
	const [selectedReportData, setSelectedReportData] = useState<any>(null) // New state for selected report data

	useEffect(() => {
		getPublisherAction()
		getAccountsAction()
		props.setLoading(false)
	}, [])

	const statisticOptions = [
		'Impressions',
		'Clicks',
		'Installs',
		'Re-attributions',
		'Re-engagements',
		'Total Revenue',
		'Level 2 Event',
		'Level 3 Event',
		'Level 4 Event',
		'Level 5 Event',
		'Revenue',
		'Cost',
	]

	const getLastRefreshed = () => {
		getUpdatedAtAction('aggregated', setLastUpdatedAt)
	}

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
		handleAdjustedDateChange(
			startDate,
			endDate,
			setStartDay,
			setEndDay,
			setReportFields,
		)
	}

	const handleRunReport = async () => {
		await loadAllReportsAction(login.user.email)
	}

	useEffect(() => {
		const data = {
			from_date: startDay,
			to_date: endDay,
			breakdowns: breakdownFields,
			statistics: statisticsFields,
			filters: {
				app_id: appIds,
				advertiser_name: advertisers,
				media_source_pid: pid,
				publisher_name: publishers,
				email: user,
				campaign_name: campaignNames,
				campaign_id: campaignIds,
				country: countries,
				agencyAccounts: agencyAccounts,
			},
		}
		setReportFields(data)
		getLastRefreshed()
	}, [
		viewDataFor,
		startDay,
		endDay,
		appIds,
		advertisers,
		user,
		pid,
		publishers,
		campaignNames,
		campaignIds,
		countries,
		agencyAccounts,
	])

	// Scroll into view when the report is generated
	useEffect(() => {
		if (isReportOpen && reportSectionRef.current) {
			const topPosition =
				reportSectionRef.current.getBoundingClientRect().top + window.scrollY
			window.scrollTo({ top: topPosition, behavior: 'smooth' })
		}
	}, [report, isReportOpen])

	useEffect(() => {
		if (login.user && login.user.email) {
			loadAllReportsAction(login.user.email)
		}
		if (urlQuery.get('report')) {
			setIsReportOpen(true)
		}
	}, [])

	const email = login.user.email
	const aggregatedReports = reports.reports[email]?.aggregated || []

	useEffect(() => {
		if (email && aggregatedReports.length > 0) {
			props.setViewRecord(aggregatedReports)
		}
	}, [reports, login.user.email])

	return (
		<ThemeProvider theme={theme}>
			<ReportsPageLayout
				filtersComponentList={
					<FiltersComponentList
						setAppIds={setAppIds}
						setAdvertisers={setAdvertisers}
						setUser={setUser}
						setPid={setPid}
						setPublishers={setPublishers}
						setCampaignNames={setCampaignNames}
						setCampaignIds={setCampaignIds}
						setCountries={setCountries}
						setAgencyAccounts={setAgencyAccounts}
						dataObj={reportFields}
						setDataObj={setReportFields}
						onDateChange={handleDateChange}
					/>
				}
				breakdownOptions={[
					'Date',
					'Month',
					'Day',
					'Day of the Week',
					'Country',
					'App ID',
					'OS',
					'Advertiser Name',
					'Advertiser Owner',
					'Campaign Name',
					'Media Source Pid',
					'Publisher Name',
					'Agency Account',
				]}
				statisticOptions={statisticOptions}
				reportFields={reportFields}
				setReportFields={setReportFields}
				getReportAction={getFiltersAction}
				saveAction={saveAppsflyerAction}
				refreshAction={getRefreshAction}
				setLoading={setLoading}
				setErrorMessage={props.setErrorMessage}
				isErrorPromptOpened={props.isErrorPromptOpened}
				setIsErrorPromptOpened={props.setIsErrorPromptOpened}
				setIsWarningPromptOpened={props.setIsWarningPromptOpened}
				setReport={setReport}
				viewRecord={props.setViewRecord}
				setViewRecord={props.setViewRecord}
				breakdownFields={breakdownFields}
				setBreakdownFields={setBreakdownFields}
				statisticsFields={statisticsFields}
				setStatisticsFields={setStatisticsFields}
				presetVisible={presetVisible}
				setPresetVisible={setPresetVisible}
				isSaving={isSaving}
				setIsSaving={setIsSaving}
				isReportOpen={isReportOpen}
				setIsReportOpen={setIsReportOpen}
				downloadButtonRef={downloadButtonRef}
				ReportGrid={ReportGrid}
				ReportStyles={{ StyledBox, ReportItem, ReportItemHeading }}
				fileDownload={fileDownload}
				setFileDownload={setFileDownload}
				login={login}
				dispatch={dispatch}
				report={aggregatedReports}
				loading={loading}
				reportType='aggregated'
				userEmail={login.user.email}
				selectedReport={selectedReportData}
				onRunReport={handleRunReport}
			/>
			{aggregatedReports.length > 0 && (
				<ReportTableSectionLayout
					report={aggregatedReports}
					chartData={chartData}
					reportFields={reportFields}
					isReportOpen={isReportOpen}
					setIsReportOpen={setIsReportOpen}
					customReportFields={statisticOptions}
					reportType='aggregated'
					setSelectedReportData={setSelectedReportData}
					smallLoading={smallLoading}
					setSmallLoading={setSmallLoading}
				/>
			)}
		</ThemeProvider>
	)
}

export default AggreagatedReportPage
