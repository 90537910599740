import type {} from '@mui/lab/themeAugmentation'
import {
	createTheme,
	responsiveFontSizes,
	Theme,
	Theme2024,
	ThemeOptions2024,
} from '@mui/material'
import { deepmerge } from '@mui/utils'
declare module '@mui/material/styles' {
	//theme is temporally added here
	export interface Theme {
		colors: {
			white: string
			black: string
			gradient: string
			buttonGradient: string
			lightGradient: string
			activeGradient: string
			disabledGradient: string
			titleGradient: string
			green: string
			gray: string
			lightGray: string
			purple: string
			yellow: string
			red: string
			blue: string
			gradientMenu: string
			gradientLoginPage: string
			base: any
			text: any
			chip: any
			alerts: any
			status: {
				green: string
				grey: string
				red: string
			}
		}
		animatedItem: any
		shadow: {
			boxShadow: string
			lightShadow: string
		}
		dimentions: {
			drawerWidth: number
			smallerRadius: string
			biggerRadius: string
		}
	}

	export interface ThemeOptions {
		colors?: {
			white?: string
			black?: string
			gradient?: string
			buttonGradient?: string
			lightGradient?: string
			activeGradient?: string
			disabledGradient?: string
			titleGradient?: string
			green?: string
			gray?: string
			lightGray?: string
			purple?: string
			yellow?: string
			red?: string
			blue?: string
			gradientMenu?: string
			gradientLoginPage?: string
			base?: any
			text?: any
		}
		animatedItem?: any
		shadow?: {
			boxShadow?: string
			lightShadow?: string
		}
		dimentions?: {
			drawerWidth?: number
			smallerRadius?: string
			biggerRadius?: string
		}
	}
	interface Theme {
		font: any
		customComponents: any
	}
	export interface Theme2024 extends Theme {
		colors: any
		font: any
		customComponents: any
		shadow: {
			boxShadow: string
			lightShadow: string
		}
		dimentions: {
			drawerWidth: number
			smallerRadius: string
			biggerRadius: string
		}
	}

	export interface ThemeOptions2024 {
		font?: any
		colors?: any
		shadow?: {
			boxShadow?: string
			lightShadow?: string
		}
		dimentions?: {
			drawerWidth?: number
			smallerRadius?: string
			biggerRadius?: string
		}
	}
}
const smallerRadius = '6px'
const biggerRadius = '15px'
const fontFamily = 'Lato, Arial, sans-serif'
const font = {
	fontFamily: fontFamily,
	size: {
		titles: '22px',
		buttons: '0.9rem',
		body: '14px',
		caption: '12px',
		small: '10px',
	},
	weight: {
		skinny: '300',
		normal: '400',
		bold: '500',
		mediumBold: '600',
		bolder: '700',
	},
}
const baseColors = {
	base: {
		white: 'rgba(255,255,255,1)',
		white2: 'rgba(255,255,255,1)',
		black: 'rgba(0, 0, 0, 1)',

		lightGreen: 'rgba(202, 240, 78, 1)',
		grey: 'rgba(251, 251, 251, 1)',
		lightGrey: 'rgba(242, 242, 242, 1)',
		grey2: 'rgba(233, 236, 239, 1)',
		grey3: 'rgba(138, 146, 166, 1)',

		// greys
		grey50: 'rgba(251, 251, 251, 1)', // originally grey
		grey80: 'rgba(241, 241, 241, 1)',
		grey100: 'rgba(242, 242, 242, 1)', // originally lightGrey
		grey150: 'rgba(216, 216, 216, 1)', // originally grey
		grey200: 'rgba(233, 236, 239, 1)', // originally grey2
		grey250: 'rgba(242, 242, 242, 1)',
		grey300: 'rgba(217, 217, 217, 1)',
		grey500: 'rgba(138, 146, 166, 1)', // originally grey3
		grey600: 'rgba(146, 146, 157, 1)',
		grey700: 'rgba(92, 92, 92, 1)',
		grey900: 'rgba(36, 45, 50, 1)',

		// greens
		green20: 'rgba(235, 255, 211, 1)',
		green50: 'rgba(228, 253, 197, 1)',
		green100: 'rgba(202, 240, 78, 1)', // originally lightGreen
		green200: 'rgba(181, 250, 97, 1)',
		green300: 'rgba(186, 220, 72, 1)',
		green400: 'rgba(163, 225, 87, 1)',
		green500: 'rgba(18, 174, 39, 1)',

		//yellows:
		yellow300: 'rgba(218, 174, 31, 1)', //error
		yellow400: 'rgba(248, 208, 79, 1)', //error
		//reds:
		red100: 'rgba(252, 234, 235,1)',
		red300: 'rgba(236, 60, 40, 1)', //error
		red400: 'red',
		//blues:
		blue100: 'rgba(69, 164, 196, 1)',
		blue300: 'rgba(72, 76, 255, 1)', //error
		blue400: 'rgba(66, 114, 246, 1)', //error
	},
	status: {
		green: 'rgba(113, 220, 54, 1)', //active
		grey: 'rgba(214, 214, 214, 1)', //inactive
		red: 'rgba(192, 50, 33, 1)', //error
	},
	chip: {
		textInactive: 'rgba(146, 146, 157, 1)',
		textActive: 'rgba(36, 45, 50, 1)',
		border: 'rgba(186, 220, 72, 1)',
		fill: 'rgba(36, 45, 50, 1)',
	},
	switch: {
		white: 'rgba(255,255,255,1)',
	},
	text: {
		grey: 'rgba(146, 146, 157, 1)',
		inactive: 'rgba(173, 185, 189, 1)', //grey darker
		titles: 'rgba(0, 0, 0, 1)', //black
		general: 'rgba(36, 45, 50, 1)', //black
		colored: 'rgba(98, 219, 123, 1)', //green
	},
	alerts: {
		grey50: 'rgba(251, 251, 251, 1)',
		grey250: 'rgba(242, 242, 242, 1)',
		grey300: 'rgba(217, 217, 217, 1)',
		grey600: 'rgba(146, 146, 157, 1)',
		grey900: 'rgba(36, 45, 50, 1)',
		white: 'rgba(255,255,255,1)',
		green: 'rgba(158, 219, 98, 1)',
	},
}
const darkBaseColors = {
	base: {
		white: 'rgba(26,26,26,1)',
		white2: 'rgba(138, 138, 138, 1)',
		black: 'rgba(0,0,0,1)',
		lightGreen: 'rgba(216,251,62, 1)',
		grey: 'rgba(4, 4, 4, 1)',
		lightGrey: 'rgba(13, 13, 13, 1)',
		grey2: 'rgba(48, 48, 48, 1)',
		grey3: 'rgba(173,185,189, 1)',

		// greys
		grey50: 'rgba(43,43,43, 1)',
		grey80: 'rgba(43,43,43, 1)',
		grey100: 'rgba(113,131,37, 1)', // originally lightGrey
		grey150: 'rgba(43,43,43, 1)',
		grey200: 'rgba(43,43,43, 1)',
		grey250: 'rgba(61, 61, 61, 1)',
		grey300: 'rgba(173,185,189, 1)',
		grey500: 'rgba(239,239,240, 1)',
		grey600: 'rgba(160, 160, 160, 1)',
		grey700: 'rgba(239,239,240, 1)',
		grey900: 'rgba(239,239,240, 1)',

		// greens
		green20: 'rgba(196, 228, 54, 1)',
		green50: 'rgba(113,131,37, 1)',
		green100: 'rgba(216,251,62, 1)', // originally lightGreen
		green200: 'rgba(216,251,62, 1)',
		green300: 'rgba(216,251,62, 1)',
		green400: 'rgba(216,251,62, 1)',
		green500: 'rgba(216,251,62, 1)',

		// yellows:
		yellow300: 'rgba(218, 174, 31, 1)', //error
		yellow400: 'rgba(248, 208, 79, 1)', //error
		//reds:
		red100: 'rgba(64, 36, 36, 1)',
		red300: 'rgba(198, 51, 34, 1)', //error
		red400: 'red',
		//blues:
		blue100: 'rgba(35, 85, 102, 1)',
		blue300: 'rgba(72, 76, 255, 1)', //error
		blue400: 'rgba(66, 114, 246, 1)', //error
	},
	status: {
		green: 'rgba(216,251,62, 1)', //active
		grey: 'rgba(173,185,189, 1)', //inactive
		red: 'rgba(192, 50, 33, 1)', //error
	},
	chip: {
		textInactive: 'rgba(239,239,240, 1)',
		textActive: 'rgba(216,251,62, 1)',
		border: 'rgba(216,251,62, 1)',
		fill: 'rgba(26,26,26,1)',
	},
	switch: {
		white: 'rgba(255,255,255,1)',
	},
	text: {
		grey: 'rgba(131,131,141, 1)',
		inactive: 'rgba(173,185,189, 1)', //grey darker
		titles: 'rgba(239,239,240, 1)', //black
		general: 'rgba(239,239,240, 1)', //black
		colored: 'rgba(216,251,62, 1)', //green
	},
	alerts: {
		grey50: 'rgba(251, 251, 251, 1)',
		grey250: 'rgba(242, 242, 242, 1)',
		grey300: 'rgba(217, 217, 217, 1)',
		grey600: 'rgba(146, 146, 157, 1)',
		grey900: 'rgba(36, 45, 50, 1)',
		white: 'rgba(255,255,255,1)',
		green: 'rgba(158, 219, 98, 1)',
	},
	arrows: {
		white: '#ffffff', //overrides the colors of the arrows in frop list
		black: '#000000',
	},
}

const customComponents = {
	button: {
		green: baseColors.base.green200,
		iconHeight: '20px',
		iconMargin: '1px 6px 0px 0px',
	},
	input: {
		borderRadius: '4px',
		border: `1px solid ${baseColors.base.grey2}`,
		backgroundColor: baseColors.base.lightGrey,
	},
}
const darkCustomComponents = {
	button: {
		green: darkBaseColors.base.green200,
		iconHeight: '20px',
		iconMargin: '1px 6px 0px 0px',
	},
	input: {
		borderRadius: '4px',
		border: `1px solid ${darkBaseColors.base.grey2}`,
		backgroundColor: darkBaseColors.base.lightGrey,
	},
}

//green - yellow gradient
const gradientColors = {
	primary: 'rgba(98, 239, 141, 1)', //green
	secondary: 'rgba(233, 219, 45, 1)', //yellow
	primarySecondaryMix: 'rgba(182, 227, 82, 1)', //mix light
	primarySecondaryMixDarker: 'rgba(152, 231, 105, 1)', //mix darker
}
const darkGradientColors = {
	primary: 'rgba(98, 239, 141, 1)', //green
	secondary: 'rgba(233, 219, 45, 1)', //yellow
	primarySecondaryMix: 'rgba(182, 227, 82, 1)', //mix light
	primarySecondaryMixDarker: 'rgba(152, 231, 105, 1)', //mix darker
}

const colors = {
	...baseColors,
	gradientMenu: `conic-gradient(from 170deg, ${gradientColors.primary} 23%, ${gradientColors.secondary} 28%, ${gradientColors.primarySecondaryMix} 30%,${gradientColors.primarySecondaryMixDarker} 74%, ${gradientColors.primary} 80%)`,
	gradientLoginPage: `conic-gradient(from 175deg, ${gradientColors.primary} 0%, ${gradientColors.secondary} 22%,  ${gradientColors.primary}100%)`,
}

const darkColors = {
	...darkBaseColors,
	gradientMenu: `
      conic-gradient(from 170deg,
        ${darkGradientColors.primary} 23%,
        ${darkGradientColors.secondary} 28%,
        ${darkGradientColors.primarySecondaryMix} 30%,
        ${darkGradientColors.primarySecondaryMixDarker} 74%,
        ${darkGradientColors.primary} 80%),
   radial-gradient(circle at center, transparent -2%, rgba(0, 0, 0, 1) 70%)
    `,
	gradientLoginPage: `conic-gradient(from 175deg, ${darkGradientColors.primary} 0%, ${darkGradientColors.secondary} 22%,  ${darkGradientColors.primary}100%)`,
}

const getThemeColors = (isDarkTheme: boolean) => {
	if (isDarkTheme) {
		return {
			colors: darkColors,
			customComponents: darkCustomComponents,
		}
	} else {
		return { colors, customComponents }
	}
}

export const createCustomTheme = (isDarkTheme: boolean): Theme2024 => {
	const themeColors = getThemeColors(isDarkTheme)

	return responsiveFontSizes(
		createTheme({
			palette: {
				mode: isDarkTheme ? 'dark' : 'light',
			},
			colors: themeColors.colors,
			font: { ...font },
			customComponents: { ...themeColors.customComponents },
			shadow: {
				boxShadow: '-8px 8px 17px 0px rgb(0 0 0 / 21%)',
				lightShadow: '-8px 8px 17px 0px rgb(0 0 0 / 10%)',
			},
			dimentions: {
				drawerWidth: 40,
				smallerRadius: smallerRadius,
				biggerRadius: biggerRadius,
			},
			components: {
				MuiCheckbox: {
					styleOverrides: {
						colorPrimary: {
							color: 'inherit',
						},
						colorSecondary: {
							color: 'inherit',
						},

						root: {
							'&.Mui-checked': {
								color: 'inherit',
								backgroundColor: 'inherit',
							},
							'&.Mui-checked:hover': {
								color: 'inherit',
								backgroundColor: 'inherit',
							},
							'&.Mui-checked:active': {
								color: 'inherit',
								backgroundColor: 'inherit',
							},
							'&.Mui-checked:focus': {
								color: 'inherit',
								backgroundColor: 'inherit',
							},
						},
						// disabled: { color: 'gray', backgroundColor: 'gray' },
					},
				},
				MuiTablePagination: {
					styleOverrides: {
						root: {
							display: 'block',
							color: themeColors.colors.text.titles,
							'& .MuiTablePagination-selectLabel': {
								display: 'block !important',
							},
							'& .MuiTablePagination-input': {
								display: 'inline-flex !important',
							},
							'& .MuiTablePagination-input svg': {
								color: themeColors.colors.text.titles,
							},
						},
					},
				},
				MuiOutlinedInput: {
					styleOverrides: {
						root: {
							borderRadius: smallerRadius,
							padding: '0px',
							'& .MuiOutlinedInput-notchedOutline': {
								border: 'none',
								maxHeight: '14px',
							},
							'& .MuiOutlinedInput-input': {
								padding: '0px',
							},
						},
					},
				},

				MuiFormLabel: {
					styleOverrides: {
						asterisk: {
							color: '#ee1d4a',
						},
						root: {
							// color: '#808080',
							// fontWeight: 'bolder',
							// top: '25%',
							'&.Mui-focused': {
								color: themeColors.colors.base.grey600,
								// fontWeight: 'bolder',
								// top: '0%',
							},
						},
					},
				},
				MuiSwitch: {
					styleOverrides: {
						root: {
							width: 46,
							height: 26,
							padding: 0,
							'& .MuiSwitch-switchBase': {
								padding: 0,
								margin: 2,
								transitionDuration: '200ms',
								'&.Mui-checked': {
									transform: 'translateX(20px)',

									'& + .MuiSwitch-track': {
										opacity: 1,
										border: 0,
									},
									'&.Mui-disabled + .MuiSwitch-track': {
										opacity: 0.5,
									},
								},
								'&.Mui-focusVisible .MuiSwitch-thumb': {
									color: themeColors.colors.base.green200,
									border: '6px solid #fff',
								},
								'&.Mui-disabled .MuiSwitch-thumb': {
									color: themeColors.colors.base.grey200,
								},
								'&.Mui-disabled + .MuiSwitch-track': {
									opacity: 0.7,
								},
							},
							'& .MuiSwitch-thumb': {
								boxSizing: 'border-box',
								width: 22,
								height: 22,
								marginLeft: '2px',
								backgroundColor: themeColors.colors.switch.white,
							},
							'& .MuiSwitch-track': {
								borderRadius: 13,
								backgroundColor: themeColors.colors.base.grey600,
								opacity: 1,
							},
						},
						switchBase: {
							'&.Mui-checked': {
								color: '#d0d0d0',
							},
							'&.Mui-checked+.MuiSwitch-track': {
								background: themeColors.colors.base.green200,
								opacity: '1',
							},
						},
					},
				},

				// MuiGrid: {
				// 	styleOverrides: {
				// 		item: {
				// 			padding: '0px',
				// 		},
				// 		root: {
				// 			'&.MuiGrid-item': {
				// 				paddingTop: '0px',
				// 				paddingLeft: '0px',
				// 			},
				// 			'&&': {
				// 				paddingLeft: '0px',
				// 			},
				// 		},
				// 	},
				// },
				// MuiFormControl: {
				// 	styleOverrides: {
				// 		root: {
				// 			margin: '0px',
				// 			padding: '0px',
				// 		},
				// 	},
				// },
				// MuiFormControlLabel: {
				// 	styleOverrides: {
				// 		root: {
				// 			color: '#ffffff',
				// 			'&.MuiDisabled': {
				// 				color: '#aaaaaa',
				// 			},
				// 		},
				// 	},
				// },
				muiList: {
					styleOverrides: {
						root: {
							backgroundColor: themeColors.colors.base.white,
							color: themeColors.colors.text.titles,
						},
					},
				},
				MuiListItem: {
					styleOverrides: {
						root: {
							backgroundColor: themeColors.colors.base.white,
							color: themeColors.colors.text.titles,
							fontWeight: 600,
						},
					},
				},
				MuiPopover: {
					styleOverrides: {
						paper: {
							backgroundColor: themeColors.colors.base.white + ' !important',
						},
					},
				},
				MuiMenu: {
					styleOverrides: {
						paper: {
							backgroundColor: themeColors.colors.base.white + ' !important',
							color: themeColors.colors.text.titles + ' !important',
							border: '1px solid ' + themeColors.colors.text.titles,
						},
					},
				},

				MuiButton: {
					styleOverrides: {
						startIcon: {
							color: '#ee1d4a',
						},
						endIcon: {
							color: '#ee1d4a',
						},
						root: {
							'&:hover': {
								background: 'transparent',
								color: themeColors.colors.base.green300,
								// border: '0px !important'
								borderColor: 'transparent',
							},
						},
					},
				},
				MuiIconButton: {
					styleOverrides: {
						root: {
							'&:hover': {
								background: 'inherit',
								// color: colors.colors.base.green300,
								// border: '0px !important'
								borderColor: 'transparent',
							},
						},
					},
				},
				MuiTouchRipple: {
					styleOverrides: {
						root: {
							'&:active': {
								background: 'inherit',
								// color: colors.colors.base.green300,
								// border: '0px !important'
								backgroundColor: 'inherit',
							},
							'&:focus': {
								background: 'inherit',
								// color: colors.colors.base.green300,
								// border: '0px !important'
								backgroundColor: 'inherit',
							},
						},
					},
				},
				MuiPaper: {
					styleOverrides: {
						root: {
							borderRadius: smallerRadius,
							boxShadow: 'none',
						},
					},
				},

				MuiMenuItem: {
					styleOverrides: {
						root: {
							backgroundColor: themeColors.colors.base.white,
							'&:hover': {
								background: 'transparent',
								color: themeColors.colors.base.green300,
								borderRadius: smallerRadius,
							},
							'&.Mui-selected': {
								color: themeColors.colors.base.green300 + ' !important',
								background: 'transparent',
							},
						},
					},
				},
				MuiListItemButton: {
					styleOverrides: {
						root: {
							stroke: 'black',
							'&:hover': {
								background: 'transparent',
								color: themeColors.colors.base.green300,
								stroke: 'white',
							},
						},
					},
				},
				MuiLinearProgress: {
					styleOverrides: {
						bar: {
							// color: '#ee1d4a!important',
							// background: 'rgba(238,29,66,1)',
						},

						barColorPrimary: {
							color: themeColors.colors.base.green200 + '!important',
							background: `linear-gradient(90deg, ${themeColors.colors.base.green200} 0%, ${themeColors.colors.base.green400} 100%)`,
						},
						barColorSecondary: {
							color: themeColors.colors.base.green400 + '!important',
							background: `linear-gradient(90deg, ${themeColors.colors.base.green200} 0%, ${themeColors.colors.base.green400} 100%)`,
						},
					},
				},
				MuiPickersDay: {
					styleOverrides: {
						root: {
							'&:hover': {
								backgroundColor: themeColors.colors.base.grey100,
								color: themeColors.colors.text.titles,
							},
							'&[aria-selected=true]': {
								backgroundColor: themeColors.colors.base.green200,
							},
							'&[aria-selected=true]:hover': {
								backgroundColor: themeColors.colors.base.green200,
							},
							'&[aria-selected=true]:focus': {
								backgroundColor: themeColors.colors.base.green200,
							},
						},
					},
				},
				MuiDateRangePickerInput: {
					styleOverrides: {
						root: {
							width: '100%',
							'& .MuiFormControl-root.MuiTextField-root': {
								width: '100%',
							},
						},
					},
				},
				MuiDataGrid: {
					styleOverrides: {
						root: {
							'& .MuiDataGrid-columnHeaderTitleContainerContent': {
								color: themeColors.colors.base.grey900,
							},
							'& .MuiDataGrid-virtualScrollerRenderZone': {
								color: themeColors.colors.base.grey900,
							},
							'& .MuiDataGrid-row.Mui-selected': {
								backgroundColor: themeColors.colors.base.grey50,
							},
							'& .MuiDataGrid-row:hover': {
								backgroundColor: themeColors.colors.base.grey50,
							},
							'& .MuiDataGrid-row.Mui-selected:hover': {
								backgroundColor: themeColors.colors.base.grey50,
							},
							'& .MuiDataGrid-row.Mui-selected:focus-visible': {
								backgroundColor: themeColors.colors.base.grey50,
							},
							'& .MuiDataGrid-columnHeader:focus-within': {
								outline: 'none',
							},
							'& .MuiDataGrid-cell:focus-within': {
								outline: 'none',
							},
							'& .MuiDataGrid-iconButtonContainer .MuiBadge-badge': {
								fontSize: '0px', // Hides the number in the badge
							},
							'& .MuiDataGrid-iconButtonContainer svg': {
								color: themeColors.colors.text.titles, // Hides the number in the badge
							},
							'& [data-field="__tree_data_group__"].MuiDataGrid-cell--withRenderer svg':
								{
									color: themeColors.colors.base.green400 + ' !important',
								},
							'& [data-field="__tree_data_group__"].MuiDataGrid-cell--withRenderer span':
								{
									fontWeight: font.weight.normal + '!important',
								},
						},
					},
				},
				MuiChip: {
					styleOverrides: {
						deleteIcon: {
							color: themeColors.colors.text.titles,
						},
					},
				},
				MuiAutocomplete: {
					styleOverrides: {
						input: {
							fontSize: '14px',
							fontWeight: 500,
						},
						noOptions: {
							color: themeColors.colors.text.titles,
						},
						option: {
							background: themeColors.colors.base.white + ' !important',
							backgroundColor: 'none !important',
							fontSize: font.size.body,
							'&.Mui-focused': {
								background: themeColors.colors.base.white,
								backgroundColor: themeColors.colors.base.white,
								color: themeColors.colors.base.green300,
							},
							'&.Mui-focusVisible': {
								background: themeColors.colors.base.white,
								backgroundColor: themeColors.colors.base.white,
								color: themeColors.colors.base.green300,
							},
							'&[aria-selected=true]': {
								background: themeColors.colors.base.white + ' !important',
								backgroundColor: themeColors.colors.base.white + ' !important',
								color: themeColors.colors.base.green300,
							},
							'&[aria-selected=true].Mui-focused': {
								background: themeColors.colors.base.white,
								backgroundColor: themeColors.colors.base.white,
								color: themeColors.colors.base.green300,
							},
							'&[aria-selected=true] .MuiMenuItem-root': {
								background: themeColors.colors.base.white + ' !important',
								backgroundColor: themeColors.colors.base.white + ' !important',
								fontWeight: font.weight.normal + ' !important',
							},
						},

						paper: {
							background: themeColors.colors.base.white + ' !important',
							width: 'fit-content!important',
						},
						root: {
							padding: '0px',
							'& .MuiOutlinedInput-root': {
								padding: '0px 65px 0px 8px',
								minHeight: '40px',
								fontSize: '14px',
							},
						},
					},
				},
				MuiTextField: {
					styleOverrides: {
						root: {
							// background: themeColors.colors.base.white,
							color: themeColors.colors.text.titles,
							'& .MuiOutlinedInput-notchedOutline legend': { display: 'none' },
							'& .MuiOutlinedInput-notchedOutline': {
								top: '0px',
							},
						},
					},
				},
				MuiCalendarPicker: {
					styleOverrides: {
						root: {
							background: themeColors.colors.base.white,
							border: '2px solid ' + themeColors.colors.base.grey600,
							color: themeColors.colors.text.titles,
							width: 'auto',
							margin: 0,
						},
						'& .MuiDayPicker-header': {
							color: themeColors.colors.text.titles + ' !important',
						},
					},
				},
				MuiCalendarOrClockPicker: {
					styleOverrides: {
						root: {
							width: 'auto !important',
						},
					},
				},
				MuiDayPicker: {
					styleOverrides: {
						weekDayLabel: {
							color: themeColors.colors.text.titles,
						},
					},
				},
				MuiMonthPicker: {
					styleOverrides: {
						root: {
							'& .MuiTypography-root.PrivatePickersMonth-root:disabled': {
								color: themeColors.colors.base.grey300,
							},
							'& .MuiTypography-root.PrivatePickersMonth-root.Mui-selected': {
								backgroundColor: themeColors.colors.base.green200,
							},
							color: themeColors.colors.base.green200,
						},
					},
				},
				MuiYearPicker: {
					styleOverrides: {
						root: {
							'& .PrivatePickersYear-yearButton.Mui-selected': {
								backgroundColor: themeColors.colors.base.green200,
							},
							'& .PrivatePickersYear-yearButton.Mui-selected:focus': {
								backgroundColor: themeColors.colors.base.green200,
							},
							color: themeColors.colors.base.green200,
						},
					},
				},

				// MuiListItemText: {
				// 	styleOverrides: {
				// 		root: {
				// 			color: 'inherit',
				// 		},
				// 	},
				// },
				MuiSvgIcon: {
					styleOverrides: {
						root: {
							color: isDarkTheme
								? darkBaseColors.arrows.white
								: darkBaseColors.arrows.black, // White in dark mode, black in light mode
						},
						colorDisabled: {
							color: themeColors.colors.base.grey300,
						},
						colorPrimary: {
							color: themeColors.colors.text.titles,
						},
						colorSecondary: {
							color: 'white',
						},
					},
				},
				MuiSelect: {
					styleOverrides: {
						icon: { color: '#000000' },
						nativeInput: {
							padding: '0px',
						},
					},
				},
				MuiDialog: {
					styleOverrides: {
						paper: {
							borderRadius: biggerRadius,
							width: '500px',
						},
					},
				},
			},
		} as ThemeOptions2024),
	)
}
