import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { Grid, MenuItem, Switch, Typography, useTheme } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { AppInterface } from '../../models/model.interface'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import AppPopUp from './AppsAdd'
import { CounterComponent } from './AppsComponents'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { TableComponent } from '../components/TableComponents'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { SmallLoading } from '../../assets/svg/loading'

const AppsPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	viewRecord: any
	setViewRecord: any
	setUniversalEdit: any
	PopUpVisible: boolean
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const navigate = useNavigate()
	const theme = useTheme()
	const params = useParams()

	const rowCount = 4
	const { advertiser, users, settings } = useTypedSelector((state) => state)
	const [found, setFound] = useState<any[]>([])
	const [activeCount, setActiveCount] = useState(0)
	const [loadingRows, setLoadingRows] = useState<Record<string, boolean>>({})

	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [appList, setAppList] = useState<any[]>([])
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [sortModel, setSortModel] = useState([
		{ field: 'advertiser_name', sort: 'asc' },
	])
	const [row, setRow] = useState()
	const [update, setUpdate] = useState(false)
	// const [PopUpVisible, setPopUpVisible] = useState(false)
	const { app, login } = useTypedSelector((state) => state)
	const { updateAppsAction, getAppsAction } = useActions()
	const [pageSize, setPageSize] = useState(15)
	const canViewApps = useIsAuthorized(permissionNames.ACCESS_TO_APPS)
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])
	const columns: GridColDef[] = [
		{
			field: 'advertiser_name',
			headerName: 'Advertiser Name',
			width: rowWidth,
		},
		{
			field: 'app_id',
			headerName: 'App Id',
			width: rowWidth,
			renderCell: ({ value }) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>{value}</Typography>
			),
		},
		{
			field: 'advertiser_owner',
			headerName: 'Advertiser Manager',
			width: rowWidth,
			renderCell: ({ value }) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>{value}</Typography>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth,
			renderCell: ({ row, value }) => (
				<div
					style={{
						position: 'relative',
						display: 'inline-flex',
						alignItems: 'center',
					}}
				>
					<Switch
						checked={value}
						disabled={!!loadingRows[row.app_id]}
						onChange={() => {
							setRow(row)
							setIsWarningPromptOpened(true)
						}}
					/>
					{loadingRows[row.app_id] && (
						<SmallLoading
							style={{
								position: 'absolute',
								left: '50%',
								top: '50%',
								transform: 'translate(-50%, -50%)',
							}}
						/>
					)}
				</div>
			),
		},

		// {
		// 	field: 'actions',
		// 	headerName: 'Actions',
		// 	resizable: false,
		// 	headerAlign: 'center',
		// 	align: 'center',
		// 	// width: rowWidth,
		// 	sortable: false,
		// 	renderCell: (row) => {
		// 		return (
		// 			<SimpleActionsButton
		// 				width={rowWidth}
		// 				row={row.row}
		// 				options={actionOptions(row.row)}
		// 				style={{ width: '40px', height: '22px', fontSize: '30px' }}
		// 				arrowColor={'rgba(0,0,0,0.75)'}
		// 			/>
		// 		)
		// 	},
		// },
	]

	function capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	const assignAdvertiserOwner = () => {
		let result = []
		for (let element of app.app) {
			// let ad_obj = advertiser.advertiser.filter(
			// 	(ad: any) => element.advertiser_name === ad.advertiser_name,
			// )
			let advFound: any = {}
			for (const adv of advertiser.advertiser) {
				if (adv.advertiser_name === element.advertiser_name) {
					advFound = adv
					break
				}
			}
			if (advFound.advertiser_name) {
				const owner = users.users.filter(
					(us: any) => us.email === advFound.email,
				)
				result.push({
					...element,
					advertiser_owner: owner && owner[0] ? owner[0].name : 'Unassigned',
					advertiser_id: advFound.id,
				})
			} else {
				result.push({ ...element, advertiser_owner: '-', advertiser_id: -1 })
			}
		}
		const rowsWithIds = result.map((item, index) => {
			return {
				...item,
				id: index,
			}
		})
		return rowsWithIds
	}

	const actionOptions = (row: any) => {
		return (
			<div>
				{/* <MenuItem onClick={() => handlePreview(row)}>Preview</MenuItem> */}
				<MenuItem onClick={() => handleEdit(row.app_id)}>Edit</MenuItem>
			</div>
		)
	}
	// const handlePreview = (row: any) => {
	// 	props.setViewRecord(row)
	// 	props.setUniversalEdit(() => () => {
	// 		handleEdit(row.app_id)
	// 	})
	// }
	const handleEdit = (app_id: any) => {
		navigate('/apps/' + app_id)
		props.setPopUpVisible(!props.PopUpVisible)
	}
	// if (PopUpVisible && params.id) {
	//  handleEdit(params.id)
	// }
	const updateAppsHandler = async (row: AppInterface) => {
		setLoadingRows((prev) => ({ ...prev, [row.app_id]: true }))

		try {
			await updateAppsAction(
				{
					app_id: row.app_id,
					status: !row.status,
					email: login.user.email,
					created_by: row.created_by,
				},
				row,
				props.setErrorMessage,
			)

			await getAppsAction()
			setUpdate(!update)
		} catch (error) {
			console.error('Error updating app status:', error)
			props.setErrorMessage('Failed to update app status.')
		} finally {
			setLoadingRows((prev) => ({ ...prev, [row.app_id]: false }))
			props.setLoading(false)
		}
	}

	const handlePopUp = async () => {
		props.setPopUpVisible(!props.PopUpVisible)
		if (props.PopUpVisible) {
			// await fetchData(login, getAppsAction, app.app)
			getAppsAction()
			setUpdate(!update)
			setRow(undefined)
		}
	}
	useEffect(() => {
		if (props.search.length > 0) {
			setFound(searchFor(props.search, appList, columns))
		}
	}, [props.search, appList])
	useEffect(() => {
		getAppsAction()
		if (!props.PopUpVisible && params.id) {
			handleEdit(params.id)
		}
	}, [])
	useEffect(() => {
		const countActiveApps = () => {
			let count = 0
			appList.forEach((a: AppInterface) => {
				if (a.status) {
					count++
				}
			})
			setActiveCount(count)
		}
		countActiveApps()
		props.setLoading(false)
	}, [update, appList, props])

	useEffect(() => {
		const data = assignAdvertiserOwner()
		setAppList(data)
	}, [app.app, advertiser.advertiser, users.users])

	return !props.PopUpVisible ? (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				{/* <Grid
					container
					spacing={0}
					direction={'row'}
					alignItems={'center'}
					width={'100%'}
				>
					<Grid item xs={4}>
						<GradientedText>
							<TitleComponent>Apps</TitleComponent>
						</GradientedText>
					</Grid>
					<Grid item xs={3}>
						<CounterComponent
							activeCount={activeCount}
							overallCount={app.app.length}
						/>
					</Grid>
					<Grid item xs={3}>
						<TableSearchComponent setSearch={setSearch} search={search} />
					</Grid>

					<Grid item xs={1}>
						<AddButton onClick={handlePopUp}>+ Add</AddButton>
					</Grid>
				</Grid> */}

				{canViewApps ? (
					<TableWrapper
						theme={theme}
						rowCount={rowCount}
						setRowWidth={setRowWidth}
						style={{ paddingBottom: '60px', width: '90%' }}
					>
						<TableComponent
							//style={{ top: '100px' }}
							columns={columns}
							rows={generateRowsWithIds(
								props.search.length > 0 ? found : appList,
							)}
							setPageSize={setPageSize}
							pageSize={pageSize}
							rowsPerPageOptions={[15, 25, 50]}
							setLoading={props.setLoading}
							handlePopUp={handlePopUp}
							sortModel={sortModel}
							onSortModelChange={(model: any) => setSortModel(model)}
						></TableComponent>

						<WarningPrompt
							message={'App data will NOT be fetched anymore'}
							PopUpVisible={isWarningPromptOpened}
							setPopUpVisible={setIsWarningPromptOpened}
							onAgree={updateAppsHandler}
							onCancel={() => props.setLoading(false)}
							obj={row}
						/>
					</TableWrapper>
				) : (
					<></>
				)}
			</PageWrapper>
		</ThemeProvider>
	) : (
		<AppPopUp
			PopUpVisible={props.PopUpVisible}
			setPopUpVisible={handlePopUp}
			setLoading={props.setLoading}
			advertisers={advertiser.advertiser}
			errorMessage={props.errorMessage}
			setErrorMessage={props.setErrorMessage}
			isErrorPromptOpened={props.isErrorPromptOpened}
			setIsErrorPromptOpened={props.setIsErrorPromptOpened}
			setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
			isSuccessPromptOpened={props.isSuccessPromptOpened}
		/>
	)
}

export default AppsPage
