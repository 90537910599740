import React, { CSSProperties, useState, useEffect } from 'react'
import {
	Grid,
	InputLabel,
	Menu,
	Tooltip,
	styled,
	IconButton,
	useTheme,
} from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { SubmitButton, UnborderedButton } from './Buttons'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ReactCountryFlag from 'react-country-flag'
import VisibilityIcon from '@mui/icons-material/Visibility'
import PasswordsPopUp from '../passwords/PasswordsShow'
import { DoorSlidingSharp, Public } from '@mui/icons-material'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import {
	canAccessResource,
	decryptIfNeeded,
} from '../../utils/helpers/helperFuncs'
import { decryptMessageForDataSplit } from '../../crypto/cryptoUtils'
import { ReactComponent as CopyIcon } from '../../assets/svg/copy-icon.svg'

export const StatusTag = ({
	label,
	style,
}: {
	label: string
	style?: React.CSSProperties
}) => {
	const theme = useTheme()
	return (
		<span
			style={{
				color: theme.colors.text.grey,
				fontSize: theme.font.size.buttons,
				fontWeight: theme.font.weight.skinny,
				...style,
			}}
		>
			{label}
		</span>
	)
}
export const InfoTag = (props: {
	title: string
	style?: React.CSSProperties
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				position: 'relative',
				width: 'auto',
				...props.style,
			}}
		>
			<Tooltip title={props.title}>
				<span
					style={{
						color: theme.colors.base.grey900,
						top: '-0.2vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						height: '15px',
						width: '15px',
						border: '2px solid ' + theme.colors.base.grey900,
						borderRadius: '500px',
						alignItems: 'center',
						textAlign: 'center',
						justifyContent: 'center',
						padding: '0px',
						fontSize: '15px',
						fontWeight: '500',
						...props.style,
					}}
				>
					i
				</span>
			</Tooltip>
		</div>
	)
}

export const ErrorTag = (props: { title: string; color?: string }) => {
	const theme = useTheme()
	return (
		<div
			style={{
				position: 'relative',
				width: '20%',
			}}
		>
			<Tooltip title={props.title}>
				{/* <ErrorOutlineOutlinedIcon
					style={{
						color: theme.colors.base.red300,
						top: '-1.1vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						paddingTop: '7px',
						scale: '0.8',
					}}
				/> */}
				<span
					style={{
						color: theme.colors.base.red300,
						top: '-0.2vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						height: '12px',
						width: '12px',
						border: '2px solid ' + theme.colors.base.red300,
						borderRadius: '500px',
						alignItems: 'center',
						textAlign: 'center',
						justifyContent: 'center',
						padding: '0px',
						fontSize: '12px',
						fontWeight: '500',
					}}
				>
					!
				</span>
			</Tooltip>
		</div>
	)
}

export const StyledLabel = styled(InputLabel)<any>(
	({ focused, error, style }) => {
		const theme = useTheme()
		return {
			color:
				focused && !error
					? theme.colors.base.green400
					: theme.colors.base.grey600,
			fontWeight: theme.font.weight.normal,
			left: '0.7rem',
			top: '-0.2rem',
			marginTop: '0.2rem',
			fontSize: theme.font.size.caption,
			width: 'auto',
			minHeight: '18px',
			...style,
		}
	},
)
export const TitleComponent = styled('span')<any>(({ style }) => {
	const theme = useTheme()
	return {
		color: theme.colors.base.grey600,
		fontSize: theme.font.size.titles,
		fontWeight: theme.font.weight.bold,
		...style,
	}
})

export const TitleTag = (props: {
	children: React.ReactNode
	style?: CSSProperties
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				position: 'relative',
				margin: '10px',
				// width: '20%',
				// background: theme.colors.base.green200,
				// border: '2px solid ' + theme.colors.base.green200,
				borderRadius: '18px',
				textTransform: 'none',
				fontSize: theme.font.size.titles,
				color: theme.colors.text.titles,
				padding: '6px 0px 0px 0px',
				fontWeight: theme.font.weight.mediumBold,
				...props.style,
			}}
		>
			<span>{props.children}</span>
		</div>
	)
}
export const StatusTitleTag = (props: {
	status: boolean
	style?: CSSProperties
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				position: 'relative',
				margin: '10px',
				// width: '20%',
				background: theme.colors.base.white,
				// border: '2px solid ' + theme.colors.base.green200,
				borderRadius: '18px',
				textTransform: 'none',
				width: '7rem',
				fontSize: theme.font.size.buttons,
				color: theme.colors.text.titles,
				padding: '14px 20px 14px 20px',
				textAlign: 'center',
				...props.style,
			}}
		>
			<span>
				Status:{' '}
				<span
					style={{
						marginLeft: '4px',
						color: props.status
							? theme.colors.status.green
							: theme.colors.status.grey,
					}}
				>
					{props.status ? 'Active' : 'Disabled'}
				</span>
			</span>
		</div>
	)
}

export const StyledMenuItem = styled(Menu)(() => {
	const theme = useTheme()
	return {
		paddingTop: '0px',
		'& 	.MuiMenu-list': {
			minWidth: '185px',
			padding: '0px',
			border: '1px solid ' + theme.colors.base.grey300,
		},
	}
})

export const ExpandableInfoField = (props: {
	label: string
	value: string | string[]
}) => {
	const theme = useTheme()
	const [expanded, setExpanded] = useState(false)
	return (
		<Grid
			item
			xs={11}
			container
			columns={12}
			direction={'row'}
			columnSpacing={0}
			rowSpacing={6}
			style={{ marginLeft: 8, gap: 20 }}
		>
			<Grid item xs={12}>
				<SubmitButton
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						// width: 240,
						marginLeft: -8,
					}}
					onClick={() => setExpanded(!expanded)}
				>
					{props.label}
					{expanded ? (
						<KeyboardArrowUpIcon style={{ color: theme.colors.chip.fill }} />
					) : (
						<KeyboardArrowDownIcon style={{ color: theme.colors.chip.fill }} />
					)}
				</SubmitButton>
			</Grid>
			{expanded && props.value.length > 0 && (
				<Grid
					item
					xs={11.9}
					container
					columns={12}
					columnSpacing={0.5}
					rowSpacing={0.5}
					style={{
						border: '1px solid ' + theme.colors.base.grey300,
						borderRadius: '20px',
						padding: '20px',
					}}
				>
					{(props.value as string[]).map((element: string) => {
						return (
							<Grid
								item
								xs={element.length < 16 ? 1 : 2}
								style={{ display: 'flex' }}
							>
								<span
									style={{
										padding: '6px',
										border: '1px solid ' + theme.colors.base.green200,
										// color: theme.colors.base.white,
										borderRadius: '10px',
										fontSize: theme.font.size.body,
										width: '100%',
										// gridColumnStart: element.length < 16 ? 'span 1' : 'span 2',
										whiteSpace: 'nowrap',
										textAlign: 'center',
										fontWeight: theme.font.weight.skinny,
									}}
								>
									{element}
								</span>
							</Grid>
						)
					})}
				</Grid>
			)}
		</Grid>
	)
}

export const ColoredTag = (props: { value: string; checked: boolean }) => {
	const { value, checked } = props
	const theme = useTheme()
	return (
		<span
			style={{
				color: checked ? theme.colors.base.white : theme.colors.text.inactive,
				background: checked
					? theme.colors.status.green
					: theme.colors.base.grey200,
				padding: '6px 12px',
				borderRadius: '10px',
				whiteSpace: 'nowrap',
				textAlign: 'center',
				fontSize: theme.font.size.caption,
			}}
			key={value}
		>
			{value}
		</span>
	)
}

export const ContactField = (props: {
	contact: any
	user?: any
	ownerEmail?: string
	users?: any
}) => {
	const [decryptedContact, setDecryptedContact] = useState<any>(props.contact)
	const theme = useTheme()

	useEffect(() => {
		const decryptContact = async () => {
			try {
				const decryptedName = await decryptIfNeeded(props.contact.name)
				const decryptedEmail = await decryptIfNeeded(props.contact.email)
				const decryptedSkype = props.contact.skype
					? await decryptIfNeeded(props.contact.skype)
					: ''
				const decryptedRole = props.contact.role
					? await decryptIfNeeded(props.contact.role)
					: ''
				const decryptedPhone = props.contact.phone
					? await decryptIfNeeded(props.contact.phone)
					: ''

				let decryptedGeo: string[] = []

				// Check if `geo` is a string (encrypted) or an array (already decrypted)
				if (props.contact.geo) {
					if (typeof props.contact.geo === 'string') {
						// If geo is a string, decrypt it
						const decryptedGeoString = await decryptIfNeeded(props.contact.geo)

						try {
							// Parse the decrypted string into an array
							decryptedGeo = decryptedGeoString
								? JSON.parse(decryptedGeoString)
								: []
						} catch (error) {
							console.error('Error parsing decrypted geo:', error)
						}
					} else if (Array.isArray(props.contact.geo)) {
						// If geo is an array, decrypt each element
						console.log(
							'Geo is an array, decrypting each element:',
							props.contact.geo,
						)
						decryptedGeo = await Promise.all(
							props.contact.geo.map(async (geo: string) => {
								return await decryptIfNeeded(geo)
							}),
						)
					}
				}

				// Default to 'GLOBAL' if the decrypted geo array is empty or invalid
				if (decryptedGeo.length === 0 || !decryptedGeo[0]) {
					decryptedGeo = ['GLOBAL']
				}

				setDecryptedContact({
					...props.contact,
					name: decryptedName || props.contact.name,
					email: decryptedEmail || props.contact.email,
					skype: decryptedSkype || props.contact.skype,
					phone: decryptedPhone || props.contact.phone,
					geo: decryptedGeo,
					role: decryptedRole || props.contact.role,
				})
			} catch (error) {
				console.error('Error decrypting contact:', error)
			}
		}

		decryptContact()
	}, [props.contact])

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				fontWeight: theme.font.weight.bold,
				padding: '4px 8px 4px 50px',
				fontSize: theme.font.size.caption,
				justifyContent: 'flex-start',
				borderRadius: '12px',
				background: theme.colors.base.grey80,
			}}
		>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '14%',
					fontSize: theme.font.size.caption,
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Name: </span>
				<span>{decryptedContact.name}</span>
			</div>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '25%',
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Email: </span>
				<span>{decryptedContact.email}</span>
			</div>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '18%',
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Role: </span>
				<span>{decryptedContact.role}</span>
			</div>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '25%',
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Skype: </span>
				<span>{decryptedContact.skype}</span>
			</div>
			<div
				style={{
					display: 'flex',
					fontWeight: '500',
					whiteSpace: 'nowrap',
					alignItems: 'center',
					justifyContent: 'flex-end',
					width: '18%',
				}}
			>
				<div
					style={{
						display: 'inherit',
						alignItems: 'flex-end',
						width: '80%',
						gap: '6px',
					}}
				>
					<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Geo: </span>
					<span
						style={{
							display: 'flex',
							gap: '6px',
							fontWeight: '500',
							whiteSpace: 'nowrap',
							alignItems: 'center',
						}}
					>
						{decryptedContact.geo.length > 0 ? (
							<ReactCountryFlag
								style={{
									height: '10px',
									width: '14px',
									borderRadius: '100px',
									border: `1px solid ${theme.colors.black}`,
								}}
								key={'flags'}
								className='roundedFlag'
								countryCode={
									decryptedContact.geo[0] === 'UK'
										? 'GB'
										: (decryptedContact.geo[0] as string)
								}
								svg
							/>
						) : (
							<Public style={{ width: '16px', height: '14px' }} />
						)}
						{decryptedContact.geo.length > 0
							? decryptedContact.geo[0]
							: 'GLOBAL'}
					</span>
				</div>
			</div>
		</div>
	)
}

export const PasswordField = (props: {
	password: any
	popUpVisible: any
	setPopUpVisible: any
	updatePasswords: any
	ownerEmail: any
	setSuccessMessage: any
	setIsSuccessPromptOpened: any
}) => {
	const theme = useTheme()

	const { showPasswordAction } = useActions()
	const { login, users } = useTypedSelector((state) => state)
	const [showPassword, setShowPassword] = React.useState(false)
	const [codeHash, setCodeHash] = React.useState('')
	const [password, setPassword] = React.useState(props.password.password)

	const canViewPasswords = canAccessResource(
		login.user,
		props.ownerEmail,
		users,
	)

	const handleTogglePasswordVisibility = async () => {
		await showPasswordAction(login.user.email, setCodeHash)
		props.setPopUpVisible(true)
	}

	useEffect(() => {
		const getDecryptedPassword = async (password: string) => {
			const message = await decryptMessageForDataSplit(password)
			return message
		}

		if (showPassword && password) {
			getDecryptedPassword(password)
				.then((decryptedPassword) => {
					props.updatePasswords(password, decryptedPassword)
					setPassword(decryptedPassword)
				})
				.catch((error) => {
					console.error('Error during decryption:', error)
					setShowPassword(false)
				})
		}
	}, [showPassword, props.popUpVisible])

	const showPopUp = () => {
		if (props.popUpVisible && codeHash) {
			return (
				<PasswordsPopUp
					advertisers={undefined}
					PopUpVisible={props.popUpVisible}
					setPopUpVisible={props.setPopUpVisible}
					// row={row}
					codeHash={codeHash}
					setLoading={undefined}
					setBlockPasswords={() => {}}
					errorMessage={null}
					setErrorMessage={undefined}
					isErrorPromptOpened={false}
					setIsErrorPromptOpened={null}
					isSuccessPromptOpened={null}
					setIsSuccessPromptOpened={null}
					sessionKey={''}
					search={''}
					setShowPassword={setShowPassword}
				/>
			)
		}
		return null
	}
	const popUp = showPopUp()

	const maxLength: number = 40 // Maximum number of characters to show before truncating the url

	const truncateText = (text: string, maxLength: number) => {
		return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
	}

	const handleCopyToClipboard = (value: string) => {
		navigator.clipboard.writeText(value).then(() => {
			// console.log('Copied to clipboard: ', value);
			props.setSuccessMessage('URL copied to clipboard')
			props.setIsSuccessPromptOpened(true)
		})
	}

	return popUp === null ? (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				fontWeight: theme.font.weight.bold,
				padding: '4px 8px 4px 50px',
				fontSize: theme.font.size.caption,
				justifyContent: 'flex-start',
				borderRadius: '12px',
				background: theme.colors.base.grey80,
			}}
		>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '14%',
					fontSize: theme.font.size.caption,
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>User Name: </span>
				<span>{props.password.username}</span>
			</div>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '25%',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>URL: </span>
				<span>{truncateText(props.password.url, maxLength)}</span>
				<CopyIcon
					style={{
						cursor: 'pointer',
						marginLeft: '8px',
						width: '16px',
						height: '16px',
					}}
					onClick={() => handleCopyToClipboard(props.password.url)}
					title='Copy URL'
				/>
			</div>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '18%',
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Password: </span>
				<span>{showPassword ? password : '••••••••'}</span>
			</div>
			{canViewPasswords && (
				<div
					style={{
						display: 'flex',
						fontWeight: '500',
						whiteSpace: 'nowrap',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: '8%',
					}}
				>
					<IconButton
						// onClick={handleTogglePasswordVisibility}
						style={{ padding: 0 }}
					>
						<VisibilityIcon
							style={{ color: theme.colors.text.titles, fontSize: '1rem' }}
						/>
						<UnborderedButton onClick={handleTogglePasswordVisibility}>
							{' '}
							Send Code
						</UnborderedButton>
					</IconButton>
				</div>
			)}
		</div>
	) : (
		popUp
	)
}
