import { ThemeProvider } from '@emotion/react'
import { Box, Grid, useTheme } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { putAvatar } from '../../state/action-creators'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import {
	PageWrapper,
	StyledBoxComp,
	StyledButtonComp,
	UserNameWithAvatar,
} from '../components/reusableComponents'
import { TitleComponent } from '../components/Informative'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { SingleTab, StyledTab, StyledTabs } from '../components/TabComponent'
const PersonalSettingsPage = (props: {
	darkTheme: boolean
	setDarkTheme: any
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
}) => {
	const theme = useTheme()
	const localStorageKey = 'theme'
	const { login, users, avatars } = useTypedSelector((state) => state)
	const { getAvatars } = useActions()

	// const headers = {
	// 	Authorization: `Token ${login.user.token}`,
	// }

	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)

	const [file, setFile] = useState<any>(null)
	const [resizedFile, setResizedFile] = useState<any>(null)

	const handleDarkThemeChange = (isDarkTheme: boolean) => {
		const newValue = { email: login.user.email, value: isDarkTheme }
		localStorage.setItem(localStorageKey, JSON.stringify(newValue))
		props.setDarkTheme(isDarkTheme)
	}
	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0].type !== 'image/jpeg') {
			props.setErrorMessage('Image should be in JPEG format only!')
			props.setIsErrorPromptOpened(true)
			setFile(null)
		}
		if (e.target.files && e.target.files[0].type === 'image/jpeg') {
			setFile(e.target.files[0])
		}
	}
	let avatarComp = (
		<UserNameWithAvatar
			avatars={
				resizedFile === null
					? avatars.avatars
					: { [login.user.name]: resizedFile }
			}
			value={login.user.name}
			withoutName
			bigPicture
		></UserNameWithAvatar>
	)
	const callback = (newFileUri: any) => {
		setResizedFile(newFileUri)
	}

	const resizeImage = (imageFile: any) => {
		try {
			Resizer.imageFileResizer(imageFile, 150, 150, 'JPEG', 100, 0, callback)
		} catch (err) {
			console.error(err)
		}
	}
	const reader = new FileReader()

	const handleUpdateClick = async () => {
		props.setLoading(true)
		if (!file) {
			return
		}
	}
	useEffect(() => {
		if (resizedFile) {
			let dataToSave = {
				file_title: login.user.name,
				file_name: file.name,
				file_open_link: resizedFile,
				file_type: file.type,
			}
			putAvatar(dataToSave)
		}
	}, [resizedFile])
	useEffect(() => {
		if (file) {
			resizeImage(file)
		}
	}, [file])
	useEffect(() => {
		props.setLoading(false)
	}, [])

	const handleSubmit = async () => {
		// await handleUpdateClick()
		// props.setLoading(false)
		// await fetchData(getAvatars, avatars.avatars)
		await fetchData(getAvatars, avatars.avatars)
		setFile(null)
		setResizedFile(null)
	}
	useEffect(() => {
		if (resizedFile) {
			avatarComp = (
				<UserNameWithAvatar
					avatars={{ [login.user.name]: resizedFile }}
					value={login.user.name}
					withoutName
					bigPicture
				></UserNameWithAvatar>
			)
		} else {
			avatarComp = (
				<UserNameWithAvatar
					avatars={avatars.avatars}
					value={login.user.name}
					withoutName
					bigPicture
				></UserNameWithAvatar>
			)
		}
	}, [resizedFile])

	const tabOptions = [
		{ label: 'Light Mode', value: false },
		{ label: 'Dark Mode', value: true },
	]

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper></PageWrapper>

			<Box
				sx={{
					width: '95%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '90%',
					paddingBottom: '5%',
					gap: '20px',
					marginTop: 18,
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					{avatarComp}
				</div>

				<SubmitButton
					onClick={() => document.getElementById('fileUploader')?.click()}
					tooltip={'.jpg only!'}
					style={{ width: 160, marginTop: 20 }}
				>
					Upload picture
				</SubmitButton>

				<input
					type='file'
					onChange={handleFileChange}
					style={{ display: 'none' }}
					id='fileUploader'
				/>
				{file && (
					<UnborderedButton
						onClick={() => {
							setIsWarningPromptOpened(true)
						}}
						style={{ marginTop: '30px' }}
					>
						Save
					</UnborderedButton>
				)}
				<div
					style={{
						display: 'flex',
						width: 380,
						height: 160,
						backgroundColor: theme.colors.base.grey50,
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						borderRadius: 40,
						boxShadow: '0px 2px 2px 2px ' + theme.colors.base.grey100,
						gap: '24px',
					}}
				>
					<TitleComponent
						style={{
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
						}}
					>
						Color Theme
					</TitleComponent>
					<StyledTabs value={props.darkTheme ? 1 : 0}>
						{tabOptions.map((el: any) => {
							return (
								<StyledTab
									key={el.label}
									style={{
										color:
											el.value === props.darkTheme
												? theme.colors.chip.fill
												: theme.colors.text.titles,
										borderLeft:
											el.value === props.darkTheme
												? '1px solid ' + theme.colors.chip.border
												: 'none',
										background:
											el.value === props.darkTheme
												? theme.colors.base.green200
												: 'inherit',
										minWidth: '8vw',
									}}
									label={el.label}
									onClick={() => handleDarkThemeChange(el.value)}
								/>
							)
						})}
					</StyledTabs>
					{/* <SubmitButton
						style={{
							display: 'flex',
							zIndex: '12',
						}}
						onClick={handleDarkThemeChange}
					>
						Change theme
					</SubmitButton> */}
				</div>

				<WarningPrompt
					message={'Saving current changes?'}
					PopUpVisible={isWarningPromptOpened}
					setPopUpVisible={setIsWarningPromptOpened}
					onAgree={() => {
						handleSubmit()
						setIsWarningPromptOpened(false)
					}}
					onCancel={() => setIsWarningPromptOpened(false)}
				/>
			</Box>
		</ThemeProvider>
	)
}

export default PersonalSettingsPage
