import { ThemeProvider } from '@emotion/react'
import {
	GridActionsCellItem,
	GridColDef,
	GridEditInputCell,
	GridEventListener,
	GridRowEditStopReasons,
	GridRowModes,
	GridRowModesModel,
} from '@mui/x-data-grid-pro'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import './finance.css'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
// import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
// import TableRowsIcon from '@mui/icons-material/TableRows'
import { ReactComponent as MarkEmailReadIcon } from '../../assets/svg/email-history-icon.svg'
import { ReactComponent as TableRowsIcon } from '../../assets/svg/data-icon.svg'

import { Typography, useTheme } from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import saveAs from 'file-saver'
import { useNavigate } from 'react-router'
import {
	extractPaymentLimit,
	formatDateToDdMmYyyy,
	formatDateToMonth,
	formatFinancePaymentDate,
	getApplicableRules,
	getMonthAbbreviation,
	getYear,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../utils/helpers/helperFuncs'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import Guidelines from './Guidelines'
import { StyledTextAreaField } from '../components/Inputs'
import { SubmitButton } from '../components/Buttons'
import { TableComponent } from '../components/TableComponents'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { HeaderFilterComponent } from '../components/TableHelperComponents'

const DemandPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const { login, finance, advertiser, users, settings } = useTypedSelector(
		(state) => state,
	)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_FINANCE),
		editDemand: useIsAuthorized(permissionNames.EDIT_DEMAND),
		editInvoice: useIsAuthorized(permissionNames.EDIT_DEMAND_INVOICE),
	}
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const { getFinanceData, uploadFinanceDemand, downloadFinance } = useActions()
	const sessionKey = 'filters-finance-demand'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [filteredFinance, setFilteredFinance] = useState<any>([])
	const [showInvoices, setShowInvoices] = useState(false)
	const rowCount = showInvoices ? 15 : 6
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [fileDownload, setFileDownload] = useState()
	const [proofToView, setProofToView] = useState<any>()
	const [openImagePreview, setOpenImagePreview] = useState(false)
	const [invoiceInstructions, setInvoiceInstructions] = useState<any>('')
	const [openInstructionsPreview, setOpenInstructionsPreview] = useState(false)
	const [uploadInvoiceTriggered, setUploadInvoiceTriggered] = useState(false)

	const [tableRows, setTableRows] = useState<any>(generateRowsWithIds([]))
	const [filterParams, setFilterParams] = useSearchParams()
	const param = filterParams.get('filter')
	const advertiserList = advertiser.advertiser.map(
		(el: any) => el.advertiser_name,
	)

	const [openFilter, setOpenFilter] = useState(false)
	const [infoOpened, setInfoOpened] = useState(false)
	const [financeFinalConfirmationFilter, setFinanceFinalConfirmationFilter] =
		useState(
			sessionFilters.financeFinalConfirmationFilter
				? sessionFilters.financeFinalConfirmationFilter
				: 0,
		)
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					advertiser: [],
					account_manager: [],
					branch: [],
			  },
	)

	const [applyFilter, setApplyFilter] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		advertiser: [],
		account_manager: [],
		branch: [],
	})
	const [downloadTableRows, setDownloadTableRows] = useState(
		generateRowsWithIds(
			generateRowsByAdvertiser(filteredFinance, advertiser.advertiser),
		),
	)
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const previousMonth = moment().subtract(1, 'months').format('MMMM').toString()
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray?.length > 0
			? sessionFilters.monthFilterArray
			: permissions.editInvoice
			? []
			: [previousMonth],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray ? sessionFilters.yearFilterArray : [],
	)
	const [dataFetched, setDataFetched] = useState(false)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const hasFetched = useRef(false)

	const fetchData = async () => {
		let i = 0
		let result
		do {
			result = await getFinanceData(
				'demand',
				props.errorMessage,
				login.user.email,
				200,
				i,
			)
			i++
			setDataFetched(true)
		} while (
			result &&
			result.length > 0 &&
			window.location.pathname.endsWith('demand')
		)
	}
	useEffect(() => {
		dispatch(emptyFinance())
		//this needs to be added for the StrictMode. Otherwise data is duplicated.
		if (hasFetched.current) {
			return
		}
		hasFetched.current = true
		fetchData()

		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(
					sessionFilters.monthFilterArray.length > 0
						? sessionFilters.monthFilterArray
						: permissions.editInvoice
						? []
						: [previousMonth],
				)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(sessionFilters.yearFilterArray)
			sessionFilters.financeFinalConfirmationFilter &&
				setFinanceFinalConfirmationFilter(
					sessionFilters.financeFinalConfirmationFilter,
				)
		}
	}, [])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				monthFilterArray,
				yearFilterArray,
				financeFinalConfirmationFilter,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [
		applyFilter,
		monthFilterArray,
		yearFilterArray,
		financeFinalConfirmationFilter,
	])

	useEffect(() => {
		setFilteredFinance(Array.isArray(finance.finance) ? finance.finance : [])
		setTableRows(
			generateRowsWithIds(
				generateRowsByAdvertiser(finance.finance, advertiser.advertiser),
			),
		)
	}, [finance.finance])

	useEffect(() => {
		if (param) {
			const advertiserName = advertiserList.find(
				(el: string) => el.toLowerCase() === param,
			)
			customFilter.advertiser = [advertiserName]
			setApplyFilter(true)
		}
	}, [param])

	useEffect(() => {
		let stillFiltering = false
		for (const key in customFilter) {
			if (customFilter[key].length !== 0) {
				stillFiltering = true
				break
			}
		}
		if (!stillFiltering && window.location.search) {
			navigate('/finance/demand')
		}
	}, [applyFilter])
	useEffect(() => {
		if (dataFetched) {
			setOptionsFilter({
				advertiser: new Set(
					tableRows
						.map((element: any) => element.advertiser)
						.filter((el: any) => el.length > 0),
				),
				account_manager: new Set(
					tableRows
						.map((element: any) => element.account_manager)
						.filter((el: any) => el && el.length > 0),
				),
				branch: new Set(
					tableRows
						.map((element: any) => element.branch)
						.filter((el: any) => el && el.length > 0),
				),
			})
		}
	}, [tableRows])

	const possibleFinanceFilter = [
		'All',
		'Missing',
		'Confirmed',
		'Overdue',
		'Non Overdue',
		'Paid',
		'Unpaid',
	]

	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered = finance.finance
		if (props.search.length > 0) {
			filtered = searchFor(props.search, finance.finance, columns)
		}
		//if the selected is "All" then our filter is already done
		// if (
		// 	possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
		// 	'missing'
		// ) {
		// 	filtered = filtered.filter((el: any) => !el.final_confirmation_advertiser)
		// } else if (
		// 	possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
		// 	'confirmed'
		// ) {
		// 	filtered = filtered.filter((el: any) => el.final_confirmation_advertiser)
		// } else if (
		// 	possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
		// 	'overdue'
		// ) {
		// 	filtered = filtered.filter((el: any) => {
		// 		if (el.payment_advertiser) {
		// 			return false
		// 		}
		// 		const value = el.invoice_number_advertiser_added_time
		// 		const currentDate = new Date()
		// 		const currentDate_ms = currentDate.getTime()
		// 		const value_ms = new Date(value).getTime()
		// 		const difference_ms = currentDate_ms - value_ms
		// 		const difference_days = Math.floor(
		// 			difference_ms / (1000 * 60 * 60 * 24),
		// 		)
		// 		// const currentAdvertiser = advertiser.advertiser.filter(
		// 		// 	(adv: any) =>
		// 		// 		adv.advertiser_name.toLowerCase().trim() ===
		// 		// 		el.advertiser.toLowerCase().trim(),
		// 		// )
		// 		const paymentLimit: any = el.payment_limit
		// 			? Number(el.payment_limit)
		// 			: ''
		// 		console.log(
		// 			el,
		// 			paymentLimit <= difference_days,
		// 			paymentLimit,
		// 			difference_days,
		// 			difference_ms,
		// 			value_ms,
		// 			currentDate_ms,
		// 		)
		// 		return paymentLimit <= difference_days
		// 	})
		// } else if (
		// 	possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
		// 	'non overdue'
		// ) {
		// 	filtered = filtered.filter((el: any) => {
		// 		const value = el.invoice_number_advertiser_added_time
		// 		let currentDate = new Date()
		// 		if (el.payment_advertiser) {
		// 			return false
		// 		}
		// 		const currentDate_ms = currentDate.getTime()
		// 		const value_ms = new Date(value).getTime()
		// 		const difference_ms = currentDate_ms - value_ms
		// 		const difference_days = Math.floor(
		// 			difference_ms / (1000 * 60 * 60 * 24),
		// 		)
		// 		// const currentAdvertiser = advertiser.advertiser.filter(
		// 		// 	(adv: any) =>
		// 		// 		adv.advertiser_name.toLowerCase().trim() ===
		// 		// 		el.advertiser.toLowerCase().trim(),
		// 		// )
		// 		const paymentLimit: any = el.payment_limit ? el.payment_limit : ''
		// 		return paymentLimit > difference_days
		// 	})
		// } else if (
		// 	possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
		// 	'paid'
		// ) {
		// 	filtered = filtered.filter((el: any) => {
		// 		return el.payment_advertiser
		// 	})
		// }

		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true

					foundByKey = customFilter[key].includes(data[key])

					if (key === 'publisher_manager' || key === 'account_manager') {
						const values = customFilter[key].map((el: string) =>
							el.toLowerCase(),
						)
						const userFound = users.users.find((e: any) => {
							return (
								values.includes(e.name.toLowerCase()) &&
								data[key].toLowerCase() === e.name.toLowerCase()
							)
						})
						foundByKey = !!userFound
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}
		//this handles the year
		if (yearFilterArray.length !== 0) {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				// console.log(year, yearFilterArray)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = filteredData
		}
		//this handles the months
		if (monthFilterArray.length !== 0) {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = filteredData
		}
		setDownloadTableRows(
			generateRowsWithIds(generateRowsByAdvertiser(arr, advertiser.advertiser)),
		)
		let newRows = generateRowsByAdvertiser(arr, advertiser.advertiser)
		if (showInvoices) {
			newRows = generateRowsByInvoice(
				newRows,
				possibleFinanceFilter[financeFinalConfirmationFilter],
			)
		} else {
			newRows = filterByInvoices(
				newRows,
				possibleFinanceFilter[financeFinalConfirmationFilter],
			)
		}
		arr = generateRowsWithIds(newRows)
		setTableRows(arr)
		setUnfiltered(arr)
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [
		props.search,
		monthFilterArray,
		yearFilterArray,
		applyFilter,
		financeFinalConfirmationFilter,
		filteredFinance,
		showInvoices,
	])

	const handleApplyFilter = () => {
		setApplyFilter(!applyFilter)
	}

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	const handlePreviewClick = (id: any) => async () => {
		navigate('/finance/demand/' + id)
	}

	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}
	const triggerShowInvoices = () => {
		setShowInvoices(!showInvoices)
	}
	useEffect(() => {
		setRowWidth(
			Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
		)
	}, [showInvoices])

	const handleDownloadFinance = () => {
		downloadFinance(
			downloadTableRows,
			'demand',
			setFileDownload,
			props.setErrorMessage,
		)
	}
	const handleRowStyle = (params: any) => {
		const row = params.row
		// console.log(row)
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		//if account manager wasn't found it means that the advertiser is not registered in Varys.
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		if (row.account_manager === '' && !isInEditMode) {
			return 'demand-row-no-advertiser'
		}
		return ''
	}
	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}
	let columns: GridColDef[] = [
		{
			field: 'advertiser',
			headerName: 'Advertiser',
			width: rowWidth,
			editable: false,
			type: 'singleSelect',
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Advertiser'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'advertiser'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderCell: (row) => {
				return (
					<Typography sx={{ fontSize: 14, fontWeight: '600' }}>
						{row.value}
					</Typography>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'branch',
			headerName: 'Legal Entity',
			width: rowWidth,
			editable: false,
			align: 'center',
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Legal Entity'}
						width={rowWidth * 1.4}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'branch'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'account_manager',
			headerName: 'AM',
			width: rowWidth * 1.3,
			editable: false,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'AM'}
						width={rowWidth * 1.4}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'account_manager'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			editable: false,
			width: rowWidth * 0.8,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
	]
	if (showInvoices) {
		columns.push(
			{
				field: 'final_confirmation_advertiser',
				headerName: 'Invoice Confirmation',
				headerAlign: 'center',
				width: rowWidth * 1.4,
				type: 'number',
				align: 'center',
				headerClassName: 'finance-final-header',
				editable: permissions.editInvoice && permissions.edit ? true : false,
				renderCell: ({ value }) => {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'finance-final-cell is-editable'
				},
			},
			{
				field: 'currency',
				headerName: 'Currency',
				headerAlign: 'center',
				width: rowWidth * 0.8,
				align: 'center',
				editable: true,
				type: 'singleSelect',
				valueOptions(params) {
					let options = ['USB', 'EUR', 'WON', 'GBP']
					if (params && params.row && settings.settings.currency.length > 0) {
						options = settings.settings.currency
					}
					return options
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'final_with_vat',
				headerName: 'Amount inc. VAT',
				headerAlign: 'center',
				width: rowWidth * 1.2,
				type: 'number',
				align: 'center',
				editable: false,
				renderCell: ({ value }) => {
					if (value) {
						return (
							<span>
								{Number(value).toLocaleString('en-US', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
							</span>
						)
					} else {
						return <></>
					}
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'invoice_number_advertiser',
				headerName: 'Invoice Number',
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				editable: true,
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'invoice_number_advertiser_added_time_date',
				headerName: 'Sent date',
				width: rowWidth * 0.8,
				type: 'date',
				align: 'left',
				headerAlign: 'left',
				editable: true,
				renderCell: ({ value }) => {
					if (value) {
						const date = formatFinancePaymentDate(value)
						return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
					} else {
						return <></>
					}
				},
				valueGetter: (params) => {
					if (params.value) {
						return new Date(params.value)
					}
				},
				renderEditCell: (params) => {
					if (params.value) {
						const date = moment(params.value).format('YYYY-MM-DD')
						return <GridEditInputCell type={'date'} {...params} value={date} />
					}
					return <></>
				},
				sortComparator: (str1, str2) => {
					return sortDates(str1, str2)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},

			{
				field: 'invoice_number_advertiser_added_time',
				headerName: 'Overdue Days',
				width: rowWidth,
				type: 'number',
				align: 'center',
				headerAlign: 'left',
				editable: false,
				valueGetter: (row) => {
					// console.log(row)
					const value = row.row.invoice_number_advertiser_added_time
					let currentDate
					if (row.row.payment_advertiser) {
						currentDate = moment(row.row.payment_advertiser).startOf('day')
					} else {
						currentDate = moment().startOf('day')
					}
					const valueDate = moment(value).startOf('day')
					const difference_days = currentDate.diff(valueDate, 'days')
					// const outOfPaymentLimit = row.row.payment_limit < difference_days
					return difference_days || ''
				},
				sortComparator: (a, b) => {
					if (!a) {
						return 0
					}
					if (a > b) {
						return 1
					} else {
						return -1
					}
				},
				renderCell: (row) => {
					const difference_days = row.value
					const outOfPaymentLimit = row.row.payment_limit < difference_days
					return (
						<Typography
							sx={{
								fontSize: 14,
								color: outOfPaymentLimit ? 'red' : 'inherit',
							}}
						>
							{row.value ? difference_days : ''}
						</Typography>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},

			{
				field: 'payment_advertiser',
				headerName: 'Payment Date',
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				editable: true,
				type: 'date',
				renderCell: ({ value }) => {
					if (value) {
						const date = formatFinancePaymentDate(value)
						return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
					} else {
						return <></>
					}
				},
				valueGetter: ({ value }) => new Date(value),
				sortComparator: (str1, str2) => {
					return sortDates(str1, str2)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'received_amount_advertiser',
				headerName: 'Received Amount',
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				editable: true,
				type: 'number',
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
				renderCell: ({ value }) => {
					if (value === '') {
						return <></>
					}
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				},
			},
			{
				field: 'bank_fees_advertiser',
				headerName: 'Bank Fees',
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				editable: true,
				type: 'number',
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
			{
				field: 'payment_method_advertiser',
				headerName: 'Payment Method',
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				type: 'singleSelect',
				editable: true,
				valueOptions: settings.settings.paymentMethod,
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
		)
	} else {
		columns.push(
			{
				field: 'total_invoices',
				headerName: 'Total Invoiced (USD)',
				headerAlign: 'center',
				width: rowWidth * 1.2,
				type: 'number',
				align: 'center',
				headerClassName: 'finance-final-header',
				editable: true,
				renderCell: ({ value }) => {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				},
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'finance-final-cell is-editable'
				},
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Edit',
				width: 80,
				cellClassName: 'actions',
				getActions: (row) => {
					const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
					if (isInEditMode) {
						return [
							<GridActionsCellItem
								icon={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											border: 'none',
											height: '28px',
											width: '28px',
											fontSize: '16px',
											cursor: 'pointer',
										}}
									>
										<CheckIcon fontSize={'inherit'} />
									</div>
								}
								label='Save'
								sx={{
									color: 'primary.main',
								}}
								onClick={handleSaveClick(row.id)}
							/>,
							<GridActionsCellItem
								icon={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											border: 'none',

											height: '28px',
											width: '28px',
											fontSize: '16px',
											cursor: 'pointer',
										}}
										className={'finance-action-button'}
									>
										<ClearIcon fontSize={'inherit'} />
									</div>
								}
								label='Cancel'
								className='textPrimary'
								onClick={handleCancelClick(row.id)}
								color='inherit'
							/>,
						]
					}
					return [
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										height: '28px',
										width: '28px',
										fontSize: '16px',
										color: theme.colors.text.titles,
										cursor: 'pointer',
									}}
									className={'finance-action-button'}
								>
									<VisibilityOutlinedIcon
										fontSize={'inherit'}
										style={{ color: 'inherit' }}
									/>
								</div>
							}
							label='Preview'
							className={'finance-action-button'}
							onClick={handlePreviewClick(row.row.uuid)}
							color='inherit'
						/>,
					]
				},
			},
		)
	}

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		if (
			newRow.invoice_number_advertiser &&
			!newRow.invoice_number_advertiser_added_time
		) {
			const formattedDate = moment().format('YYYY-MM-DDT00:00:00.000[Z]')
			newRow.invoice_number_advertiser_added_time = formattedDate
		} else if (
			!newRow.invoice_number_advertiser &&
			newRow.invoice_number_advertiser_added_time
		) {
			newRow.invoice_number_advertiser_added_time = ''
		}
		if (
			!newRow.final_confirmation_advertiser &&
			newRow.final_confirmation_advertiser !== 0
		) {
			newRow.final_confirmation_advertiser = ''
		}
		const updatedRow = { ...newRow, isNew: false }

		const failed = await uploadFinanceDemand(
			newRow,
			login.user.email,
			props.setErrorMessage,
		)
		if (updatedRow.payment_advertiser && updatedRow.proof) {
			updatedRow.proof = ''
		}
		if (
			updatedRow.final_confirmation_advertiser &&
			updatedRow.final_confirmation_advertiser_sent
		) {
			const element = tableRows.find((el: any) => el.uuid === updatedRow.uuid)
			if (element?.final_confirmation_advertiser_sent === true) {
				updatedRow.final_confirmation_advertiser_sent = false
			}
		}
		if (!failed) {
			setTableRows(
				tableRows.map((row: any) =>
					row.uuid === newRow.uuid ? updatedRow : row,
				),
			)
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
		}
		return updatedRow
	}
	const handleDoubleCellClick = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params, event) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params, event) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])

	const renderCustomMessage = (hoveredRow: any, position: any) => {
		let advertiserIsRegistered = true
		if (advertiserList && !hoveredRow.is_creator_row) {
			advertiserIsRegistered = advertiserList.includes(hoveredRow.advertiser)
		}
		return (
			!advertiserIsRegistered && (
				<div
					style={{
						position: 'absolute',
						top: position.y,
						left: position.x,
						width: 'auto',
						height: 'auto',
						backgroundColor: theme.colors.base.white,
						border: '1px solid rgb(196,91,80)',
						display: 'flex',
						borderRadius: '4px',
					}}
				>
					<span
						style={{
							color: 'rgb(196,91,80)',
							fontSize: '14px',
							backgroundColor: 'rgba(240,215,217,1)',
							padding: '8px 30px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{!advertiserIsRegistered && (
							<span>
								The advertiser{' '}
								<span style={{ fontWeight: '600' }}>
									{hoveredRow.advertiser}
								</span>{' '}
								is not registered in Varys.
							</span>
						)}
					</span>
				</div>
			)
		)
	}

	const guidelines = [
		{
			title: 'Advertiser',
			explanation:
				'Name of your advertiser written in the same way that is written in Varys - Demand - Advertiser.',
		},
		{
			title: 'Legal Entity',
			explanation:
				'It is our internal branch with which the advertiser signs the IO and pays. \nExample: KR/IL/ES/BR.',
		},
		{ title: 'AM', explanation: "Name of Campaign's AM. \nExample: Carlos." },
		{
			title: 'Period',
			explanation:
				'Activity Month + Year. \nFor Example, Jan/2024, Feb/2024, Mar/2024, Apr/2024, May/2024, Jun/2024, Jul/2024, Aug/2024.',
		},
		{
			title: 'Invoice Instructions',
			explanation:
				'Specific instructions only in cases where the advertiser requires a particular invoice format.',
		},
		{
			title: 'Proof',
			explanation:
				"A picture in .png or .pdf format that proves the advertiser's confirmation.",
		},
		{ title: 'Final Confirmation', explanation: 'Invoice Amount.' },
		{
			title: 'Currency',
			explanation:
				'Depending on the advertiser, the payment will be made in this currency.',
		},
		{
			title: 'Amount inc. VAT',
			explanation:
				'The VAT needs to be added to the advertiser database. \nExample, 21%/17%/10%.',
		},
		{
			title: 'Invoice Number',
			explanation: 'Number of invoice issued by the finance department.',
		},
		{
			title: 'Sent date',
			explanation: 'The date when we send the invoice to the advertiser.',
		},
		{
			title: 'Overdue Days',
			explanation:
				"From the date of sending, how many days have passed. If it's overdue will show the number in red.",
		},
		{
			title: 'Payment Date',
			explanation: 'When the advertiser makes the payment of that invoice.',
		},
	]

	return (
		<ThemeProvider theme={theme}>
			{openImagePreview && (
				<div
					style={{
						width: '75%',
						height: '75%',
						background: theme.colors.gray,
						position: 'absolute',
						zIndex: '11',
						left: '10%',
						border: '1px solid rgba(0,0,0,0.2)',
						borderRadius: '12px',
						fontSize: '16px',
						color: 'black',
						padding: '40px',
						display: 'flex',
						overflowY: 'auto',
						boxShadow: '2px 2px 2px 3px rgba(206,207,208,1)',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					}}
					key={'testdocviewer'}
				>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							border: 'none',
							borderRadius: '100px',
							height: '28px',
							width: '28px',
							fontSize: '16px',
							position: 'absolute',
							left: '94%',
							top: '2%',
							cursor: 'pointer',
						}}
						onClick={() => {
							setOpenImagePreview(false)
							setProofToView(null)
						}}
					>
						<ClearIcon fontSize={'inherit'} />
					</button>
					<DocViewer
						documents={[{ uri: proofToView }]}
						pluginRenderers={DocViewerRenderers}
					></DocViewer>
					<SubmitButton
						onClick={() => {
							setOpenImagePreview(false)
							setProofToView(null)
						}}
					>
						Close
					</SubmitButton>
				</div>
			)}
			{openInstructionsPreview && (
				<div
					style={{
						width: '45%',
						height: '45%',
						background: theme.colors.gray,
						position: 'absolute',
						zIndex: '11',
						left: '20%',
						border: '1px solid rgba(0,0,0,0.2)',
						borderRadius: '12px',
						fontSize: '16px',
						color: 'black',
						padding: '40px',
						display: 'flex',
						overflowY: 'auto',
						boxShadow: '2px 2px 2px 3px rgba(206,207,208,1)',
						justifyContent: 'space-around',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							border: 'none',
							borderRadius: '100px',
							height: '28px',
							width: '28px',
							fontSize: '16px',
							position: 'absolute',
							left: '94%',
							top: '2%',
							cursor: 'pointer',
						}}
						onClick={() => {
							setOpenInstructionsPreview(false)
							setInvoiceInstructions('')
						}}
					>
						<ClearIcon fontSize={'inherit'} />
					</button>
					<StyledTextAreaField
						label={'Invoice Instructions'}
						value={invoiceInstructions}
						onChange={setInvoiceInstructions}
					/>
					<div
						style={{
							display: 'flex',
							width: '50%',
							justifyContent: 'space-around',
						}}
					>
						{
							<SubmitButton
								onClick={() => {
									setOpenInstructionsPreview(false)
									setUploadInvoiceTriggered(true)
								}}
								disabled={!(permissions.editDemand && permissions.edit)}
							>
								Update
							</SubmitButton>
						}
					</div>
				</div>
			)}
			{infoOpened && (
				<div
					style={{
						background: 'rgba(0,0,0,0.4)',
						width: '100%',
						height: '100%',
						zIndex: '20',
						display: 'flex',
						position: 'absolute',
						top: '0',
						left: '0',
					}}
				></div>
			)}
			<PageWrapper>
				{infoOpened && (
					<Guidelines
						data={guidelines}
						closeFunc={() => setInfoOpened(false)}
					></Guidelines>
				)}
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFinalConfirmationFilter}
						setFilterStatus={setFinanceFinalConfirmationFilter}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						downloadTemplate={handleDownloadFinance}
						getRowClassName={handleRowStyle}
						customSx={{
							'& .demand-row-no-advertiser.MuiDataGrid-row:hover': {
								backgroundColor: 'rgba(252, 234, 235)',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
						}}
						customMessage={renderCustomMessage}
						searchWithTimer={true}
						// checkboxButtonValue={errorFilter}
						// setCheckboxButtonValue={setErrorFilter}
						// checkboxButtonLabel={'Only Errors'}
						infoOpened={infoOpened}
						setInfoOpened={setInfoOpened}
						triggerExtraBolFunction={triggerShowInvoices}
						triggerExtraBolFunctionValue={showInvoices}
						extraBolFunctionLabel={{
							true: 'Demand Data',
							false: 'Invoice History',
							iconTrue: (
								<TableRowsIcon
									style={{ marginRight: 0, color: theme.colors.text.titles }}
								/>
							),
							iconFalse: (
								<MarkEmailReadIcon
									style={{ marginRight: 0, color: theme.colors.text.titles }}
								/>
							),
						}}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

const generateRowsByAdvertiser = (inputRows: any, advertiserList: any) => {
	// console.log(inputRows)
	// console.log(advertiserList)
	// const rows = [...inputRows]
	// const result: any = []
	// const alreadyIn: string[] = []
	// for (const row of rows) {
	// 	let newRow: any = {}
	// 	if (
	// 		!alreadyIn.includes(
	// 			row.advertiser.toLowerCase().trim() +
	// 				moment(row.month, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 		)
	// 	) {
	// 		const currentAdvertiser = advertiserList.filter(
	// 			(adv: any) =>
	// 				adv.advertiser_name.toLowerCase().trim() ===
	// 				row.advertiser.toLowerCase().trim(),
	// 		)
	// 		newRow.advertiser =
	// 			currentAdvertiser.length > 0
	// 				? currentAdvertiser[0].advertiser_name
	// 				: row.advertiser
	// 		newRow.account_manager = row.account_manager
	// 		newRow.branch = row.internal_legal_entity

	// 		newRow.payment_limit = currentAdvertiser[0]
	// 			? extractPaymentLimit(currentAdvertiser[0].payment_terms)
	// 			: ''

	// 		newRow.uuid =
	// 			row.uuid +
	// 			'-demand-' +
	// 			moment(row.month, 'DD/MM/YYYY').format('DD-MM-YYYY')
	// 		newRow.month = row.month
	// 		newRow.invoice_instructions = row.invoice_instructions
	// 		newRow.proof = row.proof
	// 		newRow.currency = row.currency
	// 		newRow.invoice_number_advertiser_added_time_date =
	// 			row.invoice_number_advertiser_added_time
	// 				? formatDateToDdMmYyyy(row.invoice_number_advertiser_added_time)
	// 				: ''
	// 		newRow.final_confirmation_advertiser = row.final_confirmation_advertiser
	// 		newRow.advertiser_vat = row.advertiser_vat
	// 		newRow.final_with_vat =
	// 			Number(row.final_confirmation_advertiser) +
	// 			Number(row.final_confirmation_advertiser) *
	// 				(Number(row.advertiser_vat) / 100)
	// 		newRow.final_confirmation_advertiser_sent =
	// 			row.final_confirmation_advertiser_sent
	// 		newRow.invoice_number_advertiser = row.invoice_number_advertiser
	// 		newRow.invoice_number_advertiser_added_time =
	// 			row.invoice_number_advertiser_added_time
	// 		if (row.payment_advertiser) {
	// 			newRow.payment_advertiser = new Date(row.payment_advertiser)
	// 		} else {
	// 			newRow.payment_advertiser = ''
	// 		}
	// 		if (row.invoice_number_advertiser_added_time) {
	// 			newRow.invoice_number_advertiser_added_time_date = new Date(
	// 				row.invoice_number_advertiser_added_time,
	// 			)
	// 		} else {
	// 			newRow.invoice_number_advertiser_added_time_date = ''
	// 		}
	// 		newRow.total_invoices = row.revenue_converted
	// 		newRow.received_amount_advertiser = row.received_amount_advertiser
	// 		newRow.payment_method_advertiser = row.payment_method_advertiser
	// 		if (newRow.received_amount_advertiser) {
	// 			newRow.bank_fees_advertiser =
	// 				Number(newRow.final_with_vat) -
	// 				Number(newRow.received_amount_advertiser)
	// 		}
	// 		if (row.extra_invoices) {
	// 			for (const invoice of row.extra_invoices) {
	// 				newRow.total_invoices =
	// 					Number(newRow.total_invoices) + Number(invoice.revenue_converted)
	// 				if (invoice.payment_advertiser) {
	// 					invoice.payment_advertiser = new Date(invoice.payment_advertiser)
	// 				}
	// 			}
	// 		}
	// 		newRow.extra_invoices = row.extra_invoices
	// 		alreadyIn.push(
	// 			row.advertiser.toLowerCase().trim() +
	// 				moment(row.month, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 		)
	// 		result.push(newRow)
	// 	}
	// }
	// return result
	return inputRows
}

const generateRowsByInvoice = (inputRows: any, filterStatus: string) => {
	const rows = [...inputRows]
	const result = []
	for (const row of rows) {
		const newObj: any = {}
		newObj.advertiser = row.advertiser
		newObj.branch = row.branch
		newObj.account_manager = row.account_manager
		newObj.month = row.month
		newObj.invoice_number_advertiser = row.invoice_number_advertiser
		newObj.invoice_number_advertiser_added_time =
			row.invoice_number_advertiser_added_time
		newObj.invoice_number_advertiser_added_time_date =
			row.invoice_number_advertiser_added_time_date
		newObj.final_with_vat = row.final_with_vat
		newObj.payment_advertiser = row.payment_advertiser
		newObj.final_confirmation_advertiser = row.final_confirmation_advertiser
		newObj.currency = row.currency
		newObj.received_amount_advertiser = row.received_amount_advertiser
		newObj.payment_method_advertiser = row.payment_method_advertiser
		newObj.is_creator_row = row.is_creator_row
		newObj.payment_limit = row.payment_limit

		if (newObj.received_amount_advertiser) {
			newObj.bank_fees_advertiser =
				Number(newObj.final_with_vat) -
				Number(newObj.received_amount_advertiser)
		}
		result.push(newObj)
		if (row.extra_invoices) {
			for (const invoice of row.extra_invoices) {
				const newInvoiceObj: any = {}
				newInvoiceObj.advertiser = row.advertiser
				newInvoiceObj.branch = row.branch
				newInvoiceObj.account_manager = row.account_manager
				newInvoiceObj.month = row.month
				newInvoiceObj.invoice_number_advertiser =
					invoice.invoice_number_advertiser
				newInvoiceObj.invoice_number_advertiser_added_time =
					invoice.invoice_number_advertiser_added_time
				newInvoiceObj.invoice_number_advertiser_added_time_date = new Date(
					invoice.invoice_number_advertiser_added_time,
				)
				newInvoiceObj.final_with_vat = invoice.final_with_vat
				newInvoiceObj.payment_advertiser = invoice.payment_advertiser
				newInvoiceObj.final_confirmation_advertiser =
					invoice.final_confirmation_advertiser
				newInvoiceObj.currency = invoice.currency
				newInvoiceObj.received_amount_advertiser =
					invoice.received_amount_advertiser
				newInvoiceObj.payment_method_advertiser =
					invoice.payment_method_advertiser
				newInvoiceObj.payment_limit = invoice.payment_limit

				if (newInvoiceObj.received_amount_advertiser) {
					newInvoiceObj.bank_fees_advertiser =
						Number(newInvoiceObj.final_with_vat) -
						Number(newInvoiceObj.received_amount_advertiser)
				}
				result.push(newInvoiceObj)
			}
		}
	}
	if (filterStatus) {
		let filteredArr = []
		if (filterStatus.toLowerCase() === 'all') {
			// console.log('RESULT', result)

			return result
		}
		if (filterStatus.toLowerCase() === 'missing') {
			filteredArr = result.filter(
				(el: any) => !el.final_confirmation_advertiser,
			)
		} else if (filterStatus.toLowerCase() === 'confirmed') {
			filteredArr = result.filter((el: any) => el.final_confirmation_advertiser)
		} else if (filterStatus.toLowerCase() === 'overdue') {
			filteredArr = result.filter((el: any) => {
				if (el.payment_advertiser) {
					return false
				}
				const value = el.invoice_number_advertiser_added_time
				const difference_days = calculateDifferenceDays(value)
				const paymentLimit = el.payment_limit
				// const paymentLimit: any = currentAdvertiser
				// 	? extractPaymentLimit(currentAdvertiser.payment_terms)
				// 	: ''
				return paymentLimit < difference_days
			})
		} else if (filterStatus.toLowerCase() === 'non overdue') {
			filteredArr = result.filter((el: any) => {
				const value = el.invoice_number_advertiser_added_time
				if (el.payment_advertiser) {
					return false
				}
				const difference_days = calculateDifferenceDays(value)
				// const currentAdvertiser = advertiser
				// const paymentLimit: any = currentAdvertiser
				// 	? extractPaymentLimit(currentAdvertiser.payment_terms)
				// 	: ''
				const paymentLimit = el.payment_limit
				return paymentLimit >= difference_days
			})
		} else if (filterStatus.toLowerCase() === 'paid') {
			// console.log(result)
			filteredArr = result.filter((el: any) => {
				return el.payment_advertiser
			})
		} else if (filterStatus.toLowerCase() === 'unpaid') {
			// console.log(result)

			filteredArr = result.filter((el: any) => {
				return !el.payment_advertiser
			})
		}

		// console.log('RESULT', filteredArr)
		return filteredArr
	}
	// console.log('RESULT', result)
	return result
}
const calculateDifferenceDays = (value: string) => {
	let currentDate = new Date()
	const currentDate_ms = currentDate.getTime()
	const value_ms = new Date(value).getTime()
	const difference_ms = currentDate_ms - value_ms
	const difference_days = Math.floor(difference_ms / (1000 * 60 * 60 * 24))
	return difference_days
}
const filterByInvoices = (rows: any, filterStatus: string) => {
	if (filterStatus) {
		let filteredArr = []
		if (filterStatus.toLowerCase() === 'all') {
			return rows
		}
		if (filterStatus.toLowerCase() === 'missing') {
			filteredArr = rows.filter((el: any) => {
				if (!el.final_confirmation_advertiser) {
					return true
				}
				if (el.extra_invoices.length > 0) {
					for (const invoice of el.extra_invoices) {
						if (!invoice.final_confirmation_advertiser) {
							return true
						}
					}
				}

				return false
			})
		} else if (filterStatus.toLowerCase() === 'confirmed') {
			filteredArr = rows.filter((el: any) => {
				if (el.final_confirmation_advertiser) {
					return true
				}
				if (el.extra_invoices.length > 0) {
					for (const invoice of el.extra_invoices) {
						if (invoice.final_confirmation_advertiser) {
							return true
						}
					}
				}

				return false
			})
		} else if (filterStatus.toLowerCase() === 'overdue') {
			filteredArr = rows.filter((el: any) => {
				if (el.payment_advertiser) {
					return false
				}
				const value = el.invoice_number_advertiser_added_time
				const difference_days = calculateDifferenceDays(value)
				const paymentLimit = el.payment_limit
				if (paymentLimit <= difference_days) {
					return true
				}
				if (el.extra_invoices.length > 0) {
					for (const invoice of el.extra_invoices) {
						if (invoice.final_confirmation_advertiser) {
							const invoiceValue = invoice.invoice_number_advertiser_added_time
							const invoice_difference_days =
								calculateDifferenceDays(invoiceValue)
							const invoicePaymentLimit = invoice.payment_limit
							if (invoicePaymentLimit <= invoice_difference_days) {
								return true
							}
						}
					}
				}
				return false
			})
		} else if (filterStatus.toLowerCase() === 'non overdue') {
			filteredArr = rows.filter((el: any) => {
				if (el.payment_advertiser) {
					return false
				}
				const value = el.invoice_number_advertiser_added_time
				const difference_days = calculateDifferenceDays(value)
				const paymentLimit = el.payment_limit
				if (paymentLimit > difference_days) {
					return true
				}
				if (el.extra_invoices.length > 0) {
					for (const invoice of el.extra_invoices) {
						if (invoice.final_confirmation_advertiser) {
							const invoiceValue = invoice.invoice_number_advertiser_added_time
							const invoice_difference_days =
								calculateDifferenceDays(invoiceValue)
							const invoicePaymentLimit = invoice.payment_limit
							if (invoicePaymentLimit > invoice_difference_days) {
								return true
							}
						}
					}
				}
				return false
			})
		} else if (filterStatus.toLowerCase() === 'paid') {
			filteredArr = rows.filter((el: any) => {
				if (el.payment_advertiser) {
					return true
				}
				if (el.extra_invoices.length > 0) {
					for (const invoice of el.extra_invoices) {
						if (invoice.payment_advertiser) {
							return true
						}
					}
				}

				return false
			})
		} else if (filterStatus.toLowerCase() === 'unpaid') {
			filteredArr = rows.filter((el: any) => {
				if (!el.payment_advertiser) {
					return true
				}
				if (el.extra_invoices.length > 0) {
					for (const invoice of el.extra_invoices) {
						if (!invoice.payment_advertiser) {
							return true
						}
					}
				}

				return false
			})
		}

		// console.log('RESULT', filteredArr)
		return filteredArr
	}
	// console.log('RESULT', result)
	return rows
}

export default DemandPage
