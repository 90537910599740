import { ReportFieldsInterface } from '../../pages/reports/aggregated/FiltersPage'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface presetReducerInterface {
	preset: ReportFieldsInterface[] | any
}

const initialState: presetReducerInterface = {
	preset: [],
}

const reducer = (
	state: presetReducerInterface[] | any = initialState,
	action: Action,
): presetReducerInterface[] => {
	switch (action.type) {
		case ActionType.PRESET:
			return action.payload
		default:
			return state
	}
}

export default reducer
