import {
	Button,
	Checkbox,
	DialogActions,
	Grid,
	ToggleButton,
	Typography,
} from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { styled, useTheme } from '@mui/material/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import { PublisherInterface } from '../../models/model.interface'
import { PlusTab, RulesCards, UniqueCards } from '../campaigns/CampaignStyled'
import {
	getAutoSwitchAction,
	saveAutoSwitchRuleAction,
	deleteAutoSwitchRuleAction,
} from '../../state/action-creators'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid'
import {
	makeDictionaryByKey,
	makeDictionaryFromEntries,
} from '../../utils/helpers/helperFuncs'
import AxiosCustom from '../../utils/Axios'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { TitleComponent } from '../components/Informative'
import { ChipInputList } from '../components/ChipInput'

interface HourChoiceInterface {
	0: boolean
	1: boolean
	2: boolean
	3: boolean
	4: boolean
	5: boolean
	6: boolean
	7: boolean
	8: boolean
	9: boolean
	10: boolean
	11: boolean
	12: boolean
	13: boolean
	14: boolean
	15: boolean
	16: boolean
	17: boolean
	18: boolean
	19: boolean
	20: boolean
	21: boolean
	22: boolean
	23: boolean
}

interface DayOfWeekCalendarInterface {
	Monday: string[]
	Tuesday: string[]
	Wednesday: string[]
	Thursday: string[]
	Friday: string[]
	Saturday: string[]
	Sunday: string[]
}

const newDay: string[] = []
const calendarOptions = [
	'0',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
]

const dayOptions = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
]

export const AutoSwitchRulesPage = (rules: any[]) => {
	return rules.map((a) => {
		return AutoSwitchPopUp
	})
}

export const AutoSwitchPopUp = (props: {
	appObject: any
	setPopUpVisible: any
	publisher: any
	rows: any
	setRows: any
	setLoading: any
	reload: any
	row: any
	setRow?: any
	newRule?: boolean
	setNewRulePopUpVisible?: any
	setIsWarningPromptOpened: any
	isWarningPromptOpened: any
	setIsErrorPromptOpened: any
	setIsSuccessPromptOpened: any
	setOnWarningAgree: any
	setWarning: any
	cancelFunc?: any
	setWarningObj: any
	setErrorMessage: any
	rules: any
}) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const closeDetailedView = () => {
		navigate('/autoswitch')
		props.setPopUpVisible(false)
	}
	const { insertLog } = useActions()
	const [agencyAccounts, setAgencyAccounts] = useState<string[]>(
		props.appObject.agency_accounts ? props.appObject.agency_accounts : [''],
	)
	const { app } = useTypedSelector((state) => state)
	const calObject =
		props.appObject &&
		props.appObject.calendar != undefined &&
		props.appObject.uuid
			? JSON.parse(props.appObject.calendar)[0]
			: makeDictionaryFromEntries({
					Monday: [],
					Tuesday: [],
					Wednesday: [],
					Thursday: [],
					Friday: [],
					Saturday: [],
					Sunday: [],
			  })

	const [mondayHours, setMondayHours] = useState(
		calObject ? calObject['Monday'] : newDay,
	)
	const [tuesdayHours, setTuesdayHours] = useState(
		calObject ? calObject['Tuesday'] : newDay,
	)
	const [wednesdayHours, setWednesdayHours] = useState(
		calObject ? calObject['Wednesday'] : newDay,
	)
	const [thursdayHours, setThursdayHours] = useState(
		calObject ? calObject['Thursday'] : newDay,
	)
	const [fridayHours, setFridayHours] = useState(
		calObject ? calObject['Friday'] : newDay,
	)
	const [saturdayHours, setSaturdayHours] = useState(
		calObject ? calObject['Saturday'] : newDay,
	)
	const [sundayHours, setSundayHours] = useState(
		calObject ? calObject['Sunday'] : newDay,
	)
	var initCalendar = (): any => {
		props.setIsWarningPromptOpened(false)
		console.log('calendar initialized')
		const cal: DayOfWeekCalendarInterface = {
			Monday: mondayHours,
			Tuesday: tuesdayHours,
			Wednesday: wednesdayHours,
			Thursday: thursdayHours,
			Friday: fridayHours,
			Saturday: saturdayHours,
			Sunday: sundayHours,
		}
		const report_dictionary = makeDictionaryFromEntries(cal)
		return report_dictionary
	}

	const mspidList = useMemo(
		() =>
			props.publisher.publisher
				.map((p: PublisherInterface) => p.media_source_pid)

				.flat()
				.filter((e: string) =>
					isNaN(parseInt(e.split('_')[e.split('_').length - 1])),
				),
		[],
	)

	let pointer: number = 0
	const [mediaSourcePids, setMediaSourcePids] = useState(
		props.appObject.media_source_pid ? props.appObject.media_source_pid : [''],
	)

	const getTimezone = (app_id: string) => {
		const result = app.appIcons.filter((data: any) => data.app_id === app_id)
		if (typeof result[0].timezone == 'string') {
			return result[0].timezone
		} else {
			return result[0].timezone.timezone
		}
	}

	const getLocalTime = (tzString: string) => {
		let hours = new Date(
			new Date().toLocaleString('en-US', { timeZone: tzString }),
		)
			.getHours()
			.toString()
		if (hours.length < 2) {
			hours = '0' + hours
		}
		let minutes = new Date(
			new Date().toLocaleString('en-US', { timeZone: tzString }),
		)
			.getMinutes()
			.toString()
		if (hours.length < 2) {
			minutes = '0' + minutes
		}
		return hours + ':' + minutes
	}

	const makeRule = () => {
		console.log('LOG1', props.appObject, props.rows[props.appObject.app_id])
		let add = [
			...props.rows[props.appObject.app_id],
			{
				app_id: props.appObject.app_id,
				media_source_pid: [''],
				calendar: JSON.stringify(initCalendar()),
				uuid: undefined,
				created_by: login.user.name,
				last_update: new Date(),
				advertiser_name: props.appObject.advertiser_name,
				agency_accounts: [''],
			},
		]

		const result = Object.assign(props.rows[props.appObject.app_id], add)
		let geg = props.rows
		geg[props.appObject.app_id] = result
		console.log(geg)
		props.setRows(geg)
		props.setRow({ ...props.row })
		props.setPopUpVisible(true)
	}
	const { getAutoSwitchAction } = useActions()
	const { login } = useTypedSelector((state) => state)
	// const headers = {
	// 	Authorization: `Token ${login.user.token}`,
	// }

	let calendar: any[] = [initCalendar()]

	const handleCalendar = (
		event: React.MouseEvent<HTMLElement>,
		newState: any,
		dayName: string,
	) => {
		if (newState.includes('all')) {
			newState = calendarOptions
		}
		if (newState.includes('clear')) {
			newState = []
		}
		switch (dayName) {
			case 'Monday':
				setMondayHours(newState)
				break
			case 'Tuesday':
				setTuesdayHours(newState)
				break
			case 'Wednesday':
				setWednesdayHours(newState)
				break
			case 'Thursday':
				setThursdayHours(newState)
				break
			case 'Friday':
				setFridayHours(newState)
				break
			case 'Saturday':
				setSaturdayHours(newState)
				break
			case 'Sunday':
				setSundayHours(newState)
				break

			default:
				break
		}
		calendar[pointer][dayName] = newState

		// setCalendar({ ...calendar, calendar[changingDay][newState] = !calendar[changingDay][newState] })
	}

	const HourChooser = styled('div')(({ theme }) => ({
		marginTop: '3vh',
	}))
	const trySubmit = async () => {
		try {
			const payload = {
				uuid: null,
				calendar: JSON.stringify(calendar),
				media_source_pid: mediaSourcePids,
				app_id: props.appObject.app_id,
				created_by: login.user.name,
				last_update: new Date(),
				advertiser_name: props.appObject.advertiser_name,
				timezone: getTimezone(props.appObject.app_id),
				agency_accounts: agencyAccounts,
			}
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			props.setLoading(true)
			let response: any = ''

			response = await saveAutoSwitchRuleAction(payload)
			if (typeof response.data.payload !== 'string') {
				console.log(response)
				props.setIsSuccessPromptOpened(true)
			} else {
				props.setErrorMessage(response.data.payload)
				props.setIsErrorPromptOpened(true)
			}
			//log handling
			const handleLog = async (payload: any) => {
				const log = {
					email: login.user.email,
					object: payload.app_id + ' in AutoSwitch',
					change: JSON.stringify('made a new rule'),
				}
				await insertLog(log)
			}
			await handleLog(payload)
			if (props.cancelFunc) {
				await props.cancelFunc()
			} else {
				await props.reload()
			}
		} catch (e) {
			console.error(e)
		}
	}

	const tryUpdate = async () => {
		try {
			const payload = {
				uuid: props.appObject.uuid,
				calendar: JSON.stringify(calendar),
				media_source_pid: mediaSourcePids,
				app_id: props.appObject.app_id,
				created_by: login.user.name,
				last_update: new Date(),
				advertiser_name: props.appObject.advertiser_name,
				timezone: getTimezone(props.appObject.app_id),
				agency_accounts: agencyAccounts,
			}
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			props.setLoading(true)
			let response: any = ''

			console.log(payload)
			response = await saveAutoSwitchRuleAction(payload)
			if (typeof response.data.payload !== 'string') {
				console.log(response)
				props.setIsSuccessPromptOpened(true)
			} else {
				props.setErrorMessage(response.data.payload)
				props.setIsErrorPromptOpened(true)
			}
			//log handling
			const handleLog = async (payload: any) => {
				try {
					const log = {
						email: login.user.email,
						object: payload.app_id + ' in AutoSwitch',
						change: JSON.stringify('updating existing rule'),
					}
					await insertLog(log)
				} catch (err) {
					console.error()
				}
			}
			await handleLog(payload)
			if (props.cancelFunc) {
				await props.cancelFunc()
			} else {
				await props.reload()
			}
		} catch (e) {
			console.error(e)
		}
	}

	const showWarning = () => {
		const payload: any = {
			uuid: props.appObject.uuid,
			calendar: JSON.stringify(calendar),
			media_source_pid: mediaSourcePids,
			app_id: props.appObject.app_id,
			created_by: login.user.name,
			last_update: new Date(),
			advertiser_name: props.appObject.advertiser_name,
			timezone: getTimezone(props.appObject.app_id),
			agency_accounts: agencyAccounts,
		}
		props.setWarningObj(payload)
		console.log('shjow', payload)
		props.setIsWarningPromptOpened(true)
	}

	const loadRules = () => {
		if (props.appObject.media_source_pid) {
			setMediaSourcePids(props.appObject.media_source_pid)
		}

		if (calObject) {
			calendar = makeDictionaryFromEntries(calObject)
		} else {
			calendar = makeDictionaryFromEntries({})
		}
		console.log(calendar)
	}
	useEffect(() => {
		console.log(props.appObject.uuid)
	}, [props.appObject.uuid])

	const calendarComponent = (index: any): JSX.Element[] => {
		const result = dayOptions.map((day: any, i, array) => (
			<ToggleButtonGroup
				value={calendar[index][day]}
				onChange={(e, n) => handleCalendar(e, n, day)}
				aria-label={day}
				color='secondary'
				sx={{
					'& .Mui-selected': {
						background: theme.colors.activeGradient,
					},
					'& .Mui-selected:hover': {
						background: theme.colors.activeGradient,
					},
					'& .MuiToggleButtonGroup-grouped': {
						border: 0,
					},
				}}
				style={{ transform: 'scaleX(0.95) translate(-3%)' }}
			>
				<ToggleButton value={'all'} style={{ width: '10vw' }}>
					{day}
				</ToggleButton>
				{calendarOptions.map((hour) => {
					return (
						<ToggleButton value={hour} key={'&EAFHEiA' + hour}>
							{hour}
						</ToggleButton>
					)
				})}
				<ToggleButton value={'clear'}>{'Clear'}</ToggleButton>
			</ToggleButtonGroup>
		))
		return result
	}

	const renderRulesCards = () => {
		return calendar.map((d, index) => {
			return (
				<RulesCards style={{ border: '1px solid rgba(0, 0, 0, 0.3)' }}>
					<Grid container direction={'row'}>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									width: '100%',
									borderBottom: '1px solid ' + theme.colors.lightGray,
								}}
							>
								<SubmitButton
									style={{
										width: '80px',
										display: 'flex',
									}}
									onClick={
										props.cancelFunc
											? () => props.cancelFunc()
											: closeDetailedView
									}
								>
									<ArrowBackIcon />
								</SubmitButton>

								<TitleComponent>{props.appObject.app_id}</TitleComponent>

								{props.rules.delete && (
									<SubmitButton
										style={{
											width: '80px',
											display: 'flex',
											marginLeft: 'auto',
										}}
										onClick={() =>
											props.cancelFunc ? props.cancelFunc() : showWarning()
										}
									>
										<DeleteIcon />
									</SubmitButton>
								)}
							</div>
						</Grid>

						<Grid item xs={10}>
							<HourChooser>
								{calendar.length > 0 ? (
									calendarComponent(index).map((day) => {
										return day
									})
								) : (
									<></>
								)}
							</HourChooser>
						</Grid>
						<Grid
							item
							xs={2}
							style={{
								display: 'flex',
								background: '#d3d3d340',
								height: '25vh',
								color: 'black',
								textAlign: 'center',
								alignContent: 'center',
								justifyContent: 'center',
								alignItems: 'center',
								transform: 'translate(0%, 40%)',
							}}
						>
							<Grid container xs={12}>
								<span
									style={{
										position: 'relative',
										transform: 'translate(0vw, -8vh)',
										color: 'black',
										textAlign: 'left',
									}}
								>
									Timezone: {getTimezone(props.appObject.app_id)}
									<br></br>
									Time at location:{' '}
									{getLocalTime(getTimezone(props.appObject.app_id))}
								</span>
								<Grid item xs={12} height={'60px'}>
									<span>
										Select <b>HOURS</b> when the <b>PID</b> should be{' '}
										<b>active</b>
										<br />
									</span>
								</Grid>
								<Grid container height={'43px'}>
									<Grid item xs={4}>
										<div
											style={{
												height: '43px',
												width: '33px',
												display: 'flex',
												position: 'absolute',
												marginLeft: '2vh',
												textAlign: 'left',
												alignContent: 'center',
												justifyContent: 'center',
												background: theme.colors.activeGradient,
												alignItems: 'center',
											}}
										>
											{3}
										</div>
									</Grid>
									<Grid
										item
										xs={8}
										style={{
											textAlign: 'left',
											transform: 'translate(0%, -25%)',
										}}
									>
										<br></br>
										<span>: enable</span>
									</Grid>
								</Grid>
								<Grid container height={'43px'}>
									<Grid item xs={4}>
										<div
											style={{
												height: '43px',
												width: '33px',
												display: 'flex',
												position: 'absolute',

												marginLeft: '2vh',

												alignContent: 'center',
												justifyContent: 'center',
												background: 'white',
												alignItems: 'center',
											}}
										>
											{3}
										</div>
									</Grid>
									<Grid
										item
										xs={8}
										style={{
											textAlign: 'left',
											transform: 'translate(0%, -25%)',
										}}
									>
										<br></br>
										<span> : disable</span>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							style={{ display: 'flex', flexDirection: 'column', gap: '18px' }}
						>
							<ChipInputList
								style={{ width: '70vw', marginLeft: '12px' }}
								label='Agency Accounts'
								options={['Tatanka', 'Thing or Two', 'SmartAss']}
								value={agencyAccounts}
								onChange={setAgencyAccounts}
								// onBlur={(e: any) => {
								// 	handleChangeField(e, pointer, 'media_source_pid')
								// }}

								//publishers={publisher}
								openWithClick
							/>
							<ChipInputList
								style={{ width: '70vw', marginLeft: '12px' }}
								label='Media Source PIDs'
								options={mspidList}
								value={mediaSourcePids}
								onChange={setMediaSourcePids}
								// onBlur={(e: any) => {
								// 	handleChangeField(e, pointer, 'media_source_pid')
								// }}
								hint='press ENTER after each PID'
								//publishers={publisher}
								openWithClick
							/>
						</Grid>
						<DialogActions
							style={{ width: '30%', marginTop: '5vh', marginLeft: '35%' }}
						>
							<UnborderedButton
								style={{ margin: 'auto' }}
								onClick={() =>
									props.cancelFunc ? props.cancelFunc() : closeDetailedView()
								}
								label={'Cancel'}
							></UnborderedButton>
							{props.rules.edit && (
								<SubmitButton
									style={{ margin: 'auto' }}
									onClick={() => {
										props.appObject.uuid ? tryUpdate() : trySubmit()
										getAutoSwitchAction()
									}}
								>
									{props.appObject.uuid ? 'Update' : 'Save'}
								</SubmitButton>
							)}
						</DialogActions>
						<PlusTab
							onClick={() => {
								makeRule()
							}}
							disabled={!props.newRule}
							style={{
								top: '5vh',
								width: '10%',
								display: 'flex',
								alignSelf: 'flex-end',
								justifySelf: 'center',
								marginLeft: '45%',
							}}
						>
							ADD +
						</PlusTab>
					</Grid>
				</RulesCards>
			)
		})
	}

	return <>{renderRulesCards()}</>
}
