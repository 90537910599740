import { parseCSV, stableStringify } from './helperFuncs'
import {
	HTTP_RESPONSE_MESSAGES,
	HTTP_STATUS_CODES,
	endpoints,
	getReportRoute,
	isValidReportType,
	reportEndpoints,
} from '../../config'
import { ActionType } from '../../state/action-types'
import { WebSocketManager } from '../../ws/websocketManager'
import AxiosCustom from '../../utils/Axios'
import { Action } from '../../state/actions'
import { Dispatch } from 'redux'
import { addAlert, setTabIndex } from '../../state/action-creators'
import CryptoJS from 'crypto-js'
import { ReportsByUser } from '../../state/reducers/reportReducer'

export const networkColumnOrder = [
	'date', // Date
	'month', // Month
	'day', // Day
	'day_of_the_week', // Day of the Week
	'app_id', // App ID
	'media_source_pid', // Media Source PID
	'platform', // Platform
	'agency', // Agency
	'username', // Username
	'impressions', // Impressions
	'clicks', // Clicks
	'installs', // Installs
	'cr', // CR (Conversion Rate)
]

export const aggregatedColumnOrder = [
	'date', // Date
	'month', // Month
	'day', // Day
	'day_of_the_week', // Day of the Week
	'country', // Country
	'app_id', // App ID
	'os', // OS
	'advertiser_name', // Advertiser Name
	'advertiser_owner', // Advertiser Owner
	'campaign_name', // Campaign Name
	'media_source_pid', // Media Source PID
	'publisher_name', // Publisher Name
	'agency_account', // Agency Account
	'impressions', // Impressions
	'clicks', // Clicks
	'installs', // Installs
	're_attributions', // Re-attributions
	're_engagements', // Re-engagements
	'total_revenue', // Total Revenue
	'level_2_event', // Level 2 Event
	'level_3_event', // Level 3 Event
	'level_4_event', // Level 4 Event
	'level_5_event', // Level 5 Event
	'revenue', // Revenue
	'cost', // Cost
]

export const invalidColumnOrder = [
	'date', // Date
	'month', // Month
	'day', // Day
	'day_of_the_week', // Day of the Week
	'country', // Country
	'app_id', // App ID
	'media_source_pid', // Media Source PID
	'os', // OS
	'advertiser_name', // Advertiser Name
	'impressions', // Impressions
	'clicks', // Clicks
	'installs', // Installs
	'total_revenue', // Total Revenue
]

export const columnDisplayNameMap: Record<string, string> = {
	date: 'Date',
	month: 'Month',
	day: 'Day',
	day_of_the_week: 'Day of the Week',
	app_id: 'App ID',
	media_source_pid: 'Media Source PID',
	platform: 'Platform',
	agency: 'Agency',
	username: 'Username',
	impressions: 'Impressions',
	clicks: 'Clicks',
	installs: 'Installs',
	cr: 'CR', // Conversion Rate
	country: 'Country',
	os: 'OS',
	advertiser_name: 'Advertiser Name',
	advertiser_owner: 'Advertiser Owner',
	campaign_name: 'Campaign Name',
	publisher_name: 'Publisher Name',
	agency_account: 'Agency Account',
	re_attributions: 'Re-attributions',
	re_engagements: 'Re-engagements',
	total_revenue: 'Total Revenue',
	level_2_event: 'Level 2 Event',
	level_3_event: 'Level 3 Event',
	level_4_event: 'Level 4 Event',
	level_5_event: 'Level 5 Event',
	revenue: 'Revenue',
	cost: 'Cost',
}

let errorHandled = false

export const extractReportInfo = (fileUrl: string) => {
	const fileUrlParts = fileUrl.split('/')
	const fileName = fileUrlParts[fileUrlParts.length - 1]
	const reportType = fileName.split('_').slice(0, 2).join('_')
	const reportId = fileName.split('_').slice(-1)[0].split('.')[0].slice(-5)
	return { reportType, reportId }
}

export const prepareDataForRequest = (
	reportFields: any,
	breakdowns: string[],
	statistics: string[],
	encryptedClientId: string,
	userEmail: string,
) => {
	// Normalize to lowercase
	const normalizedBreakdowns = breakdowns.map((b) => b.toLowerCase())
	const normalizedStatistics = statistics.map((s) => s.toLowerCase())

	// Ensure the same structure and order
	const payload = {
		...reportFields,
		breakdowns: normalizedBreakdowns,
		statistics: normalizedStatistics,
		user_email: userEmail, // Ensure user_email is part of the object
	}

	const concatenatedParams = `${userEmail}|${stableStringify(
		payload,
	)}|${JSON.stringify(normalizedBreakdowns)}|${JSON.stringify(
		normalizedStatistics,
	)}`

	const reportToken = CryptoJS.SHA256(concatenatedParams).toString()

	return {
		...reportFields,
		breakdowns: normalizedBreakdowns,
		statistics: normalizedStatistics,
		client_id: encryptedClientId,
		uuid: reportToken, // Use this token to check for duplication
		user_email: userEmail,
	}
}

export const handleWebSocketMessage = async (
	message: any,
	dispatch: Dispatch<Action>,
	setErrorPopup: any,
	setIsReportOpen: any,
	setLoading: any,
	resolve: any,
	reject: any,
	userEmail: string,
	reportType: keyof typeof reportEndpoints,
	reportUuid: string | undefined = undefined,
) => {
	if (message.status === 'success') {
		if (message.file_url) {
			try {
				const response = await fetch(message.file_url)
				const csvText = await response.text()
				const data = parseCSV(csvText)
				// console.log('message', message)
				// console.log('data', data)
				const { reportType, reportId } = extractReportInfo(message.file_url)

				const alertMessage = `The ${reportType}: ${reportId} is ready.`
				const normalizedReportType = reportType.replace('_report', '')

				// Then use the normalizedReportType when calling getReportRoute
				const alertLink = isValidReportType(normalizedReportType)
					? getReportRoute(normalizedReportType)
					: '/'

				dispatch({
					type: ActionType.GENERATE_REPORT_SUCCESS,
					payload: { report: data, userEmail, reportType },
				})

				setIsReportOpen(true)
				setLoading(false)
				dispatch(addAlert(alertMessage, false, alertLink, reportUuid))
				resolve(data)
			} catch (error: any) {
				handleError(
					dispatch,
					setErrorPopup,
					setIsReportOpen,
					setLoading,
					reject,
					'Error parsing report data: ' + error.message,
					reportType,
				)
			}
		} else if (message.message === 'The report is empty.') {
			dispatch({
				type: ActionType.GENERATE_REPORT_SUCCESS,
				payload: { report: [], userEmail, reportType },
			})
			setErrorPopup('The report is empty.')
			setIsReportOpen(false)
			setLoading(false)
			dispatch(
				addAlert(
					`Empty ${reportType.toLowerCase()} has been generated.`,
					false,
				),
			)
			resolve([])
		}
	} else if (message.status === 'error') {
		handleError(
			dispatch,
			setErrorPopup,
			setIsReportOpen,
			setLoading,
			reject,
			message.message,
			reportType,
		)
	}
}

/**
 * Opens a WebSocket connection for the report generation process.
 *
 * The function sets up the WebSocket and handles incoming messages using the
 * `handleWebSocketMessage` function. It also manages WebSocket errors.
 *
 * @param {string} encryptedClientId - The encrypted client ID used for identification in WebSocket communication.
 * @param {Dispatch<Action>} dispatch - Redux dispatch function.
 * @param {function} setErrorPopup - Function to display error popups.
 * @param {function} setIsReportOpen - Function to set the report modal open state.
 * @param {function} setLoading - Function to control loading state during report generation.
 * @param {function} resolve - Promise resolve function for report generation.
 * @param {function} reject - Promise reject function for report generation.
 * @param {string} userEmail - Email of the user generating the report.
 * @param {keyof typeof reportEndpoints} reportType - The type of report to be generated.
 *
 * @returns {WebSocketManager} The WebSocketManager instance to handle communication.
 */
export const openWebSocketConnection = (
	encryptedClientId: string,
	dispatch: Dispatch<Action>,
	setErrorPopup: any,
	setIsReportOpen: any,
	setLoading: any,
	resolve: any,
	reject: any,
	userEmail: string,
	reportType: keyof typeof reportEndpoints,
) => {
	const ws = new WebSocketManager(encryptedClientId)

	ws.onMessage(async (message: any) => {
		await handleWebSocketMessage(
			message,
			dispatch,
			setErrorPopup,
			setIsReportOpen,
			setLoading,
			resolve,
			reject,
			userEmail,
			reportType,
		)
	})

	ws.onError((error: any) => {
		handleError(
			dispatch,
			setErrorPopup,
			setIsReportOpen,
			setLoading,
			reject,
			error.message,
			reportType,
		)
	})

	return ws
}

/**
 * Sends an HTTP request to initiate the report generation process.
 *
 * This function sends a POST request to the server with the necessary report data.
 * It handles both success and error cases, updating the UI and Redux state accordingly.
 *
 * @param {any} data - The data payload for the report request.
 * @param {keyof typeof reportEndpoints} reportType - The type of report being requested.
 * @param {function} setErrorPopup - Function to display error popups.
 * @param {function} setIsReportOpen - Function to set the report modal open state.
 * @param {function} setLoading - Function to control loading state during report generation.
 * @param {function} reject - Promise reject function for handling errors.
 * @param {Dispatch<Action>} dispatch - Redux dispatch function.
 */
export const postReportRequest = (
	data: any,
	reportType: keyof typeof reportEndpoints,
	setErrorPopup: any,
	setIsReportOpen: any,
	setLoading: any,
	reject: any,
	dispatch: Dispatch<Action>,
) => {
	AxiosCustom.post(endpoints.REPORTS + reportEndpoints[reportType], data)
		.then((response: any) => {
			const statusCode = response.status
			const message = HTTP_RESPONSE_MESSAGES[statusCode] || 'Unknown Error'

			if (statusCode === HTTP_STATUS_CODES.CONFLICT) {
				// Handle the conflict response (409) for duplication report
				return
			}

			if (
				statusCode !== HTTP_STATUS_CODES.OK &&
				statusCode !== HTTP_STATUS_CODES.CREATED &&
				statusCode !== HTTP_STATUS_CODES.NO_CONTENT
			) {
				throw new Error(message)
			}

			// Handle success case (e.g., 200 OK)
		})
		.catch((err) => {
			// Optionally, set UI states or dispatch alerts without calling handleError
			setLoading(false)
			setErrorPopup(`HTTP Error in ${reportType.toLowerCase()}: ${err.message}`)
			reject(err.message)
		})
}

/**
 * Fetches report data from the provided S3 file URL.
 *
 * This function retrieves a CSV file from the given URL, parses it into a
 * structured format, and returns the parsed data.
 *
 * @param {string} s3FileUrl - The URL of the report file stored in S3.
 *
 * @returns {Promise<any>} The parsed report data.
 */
export const fetchReportData = async (s3FileUrl: string) => {
	const response = await fetch(s3FileUrl)
	const csvText = await response.text()
	return parseCSV(csvText)
}

const handleError = (
	dispatch: Dispatch<Action>,
	setErrorPopup: any,
	setIsReportOpen: any,
	setLoading: any,
	reject: any,
	errorMessage: string,
	reportType: keyof typeof reportEndpoints,
) => {
	// Only one error handled at a time
	if (!errorHandled) {
		errorHandled = true

		// Aggregate error message for the user
		const userFriendlyMessage = `Error in ${reportType.toLowerCase()}: Report generation failed.`

		// Set the error message for the popup
		setErrorPopup(userFriendlyMessage)

		// Update UI states
		setIsReportOpen(false)
		setLoading(false)

		// Dispatch a single error alert with the generalized message
		dispatch(addAlert(userFriendlyMessage, true))

		// Reject the promise with the generalized error message
		reject(userFriendlyMessage)

		// Optionally, log the detailed error for developers
		console.error(`Detailed error: ${errorMessage}`)

		// Reset the errorHandled flag after the error has been processed
		errorHandled = false
	}
}

/**
 * Checks if a report with the same parameters already exists.
 *
 * This function searches through the user's existing reports to find a report
 * that matches the UUID of the requested report. If a match is found, it returns
 * the existing report to avoid re-generating the same report.
 *
 * @param {any} state - The current Redux state.
 * @param {string} userEmail - The email of the user generating the report.
 * @param {string} uuid - The unique identifier for the requested report.
 *
 * @returns {any | undefined} The existing report if found, otherwise undefined.
 */
export const checkForExistingReport = (
	state: any,
	userEmail: string,
	uuid: string,
) => {
	const emailKey = userEmail.trim().toLowerCase()

	// Access the nested reports object
	const reportsObject = state.reports.reports
	const userReports = reportsObject[emailKey] || {}

	const networkReports = userReports.network || []
	const invalidReports = userReports.invalid || []
	const aggregatedReports = userReports.aggregated || []

	const allReports = [
		...networkReports,
		...invalidReports,
		...aggregatedReports,
	]

	return allReports.find((report: any) => report.uuid === uuid)
}

export const getReportColumnOrderMap = (reportType: string) => {
	const networkColumnOrder = [
		'date',
		'month',
		'day',
		'day_of_the_week',
		'app_id',
		'media_source_pid',
		'platform',
		'agency',
		'username',
		'impressions',
		'clicks',
		'installs',
		'cr',
	]

	const aggregatedColumnOrder = [
		'date',
		'month',
		'day',
		'day_of_the_week',
		'country',
		'app_id',
		'os',
		'advertiser_name',
		'advertiser_owner',
		'campaign_name',
		'media_source_pid',
		'publisher_name',
		'agency_account',
		'impressions',
		'clicks',
		'installs',
		're_attributions',
		're_engagements',
		'total_revenue',
		'level_2_event',
		'level_3_event',
		'level_4_event',
		'level_5_event',
		'revenue',
		'cost',
	]

	const invalidColumnOrder = [
		'date',
		'month',
		'day',
		'day_of_the_week',
		'country',
		'app_id',
		'media_source_pid',
		'os',
		'advertiser_name',
		'impressions',
		'clicks',
		'installs',
		'total_revenue',
	]

	switch (reportType) {
		case 'network':
			return networkColumnOrder
		case 'aggregated':
			return aggregatedColumnOrder
		case 'invalid':
			return invalidColumnOrder
		default:
			return []
	}
}
export const reorderReportColumns = (columns: any, reportType: any) => {
	const columnOrder = getReportColumnOrderMap(reportType)

	return columns.sort((a: any, b: any) => {
		// Find the index of columns in the predefined column order
		const orderA = columnOrder.indexOf(a)
		const orderB = columnOrder.indexOf(b)

		// If a column isn't found in the predefined list, put it at the end
		const adjustedOrderA = orderA === -1 ? Infinity : orderA
		const adjustedOrderB = orderB === -1 ? Infinity : orderB

		return adjustedOrderA - adjustedOrderB
	})
}

export const generatePidListCompanyBased = (
	publisherList: any[],
	companyType: string,
	companyId: string,
	companyTypeConfig: Record<
		string,
		{ shouldInclude: (pub: any, companyId: string) => boolean }
	>,
): string[] => {
	const currentCompanyConfig = companyTypeConfig[companyType] || {
		shouldInclude: () => false, // Default to not including PIDs if the company type is unknown
	}

	return publisherList.reduce((acc: string[], pub: any) => {
		if (currentCompanyConfig.shouldInclude(pub, companyId)) {
			const validPids = pub.media_source_pid.filter(
				(pid: string) => pid.toLowerCase() !== 'all',
			)
			validPids.forEach((pid: string) => {
				if (!acc.includes(pid)) {
					acc.push(pid)
				}
			})
		}
		return acc
	}, [])
}
