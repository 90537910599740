import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from '../../state/reducers'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	removeAlert,
	markAlertsAsSeen,
	closeAlert,
	openAlert,
} from '../../state/action-creators'
import Tooltip from '@mui/material/Tooltip'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'

export interface Alert {
	id: string
	message: string
	isError: boolean
	seen: boolean
	link?: string
	extra_data?: string | undefined
}

const AlertContainer = () => {
	const theme = useTheme()
	const state = useTypedSelector((state: RootState) => state.alerts)
	// console.log(state)
	const alerts = state.alerts
	const show = state.show
	// const alerts = useTypedSelector((state: RootState) => state.alerts.alerts)
	// console.log(test)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [showAlerts, setShowAlerts] = useState(show)
	const [alertPending, setAlertPending] = useState(false)
	const [hover, setHover] = useState(false)
	const [bottomCss, setBottomCss] = useState('-25%')

	useEffect(() => {
		const unseenAlerts = alerts.filter((alert) => !alert.seen)

		if (unseenAlerts.length > 0) {
			setAlertPending(true)
			setShowAlerts(true)
			dispatch(markAlertsAsSeen(unseenAlerts)) // Only dispatch if there are unseen alerts
		}
	}, [alertPending])
	useEffect(() => {
		if (showAlerts === false && state.show !== false) {
			dispatch(closeAlert())
		}
		if (showAlerts === true && state.show !== true) {
			dispatch(openAlert())
		}
		setBottomCss(showAlerts ? '10%' : '-40%')
	}, [showAlerts])

	useEffect(() => {
		setShowAlerts(state.show)
	}, [state.show])

	const handleClick = () => {
		setShowAlerts(!showAlerts)
		if (alertPending) {
			dispatch(markAlertsAsSeen(alerts))
			setAlertPending(false)
		}
	}

	const removeAlertHandler = (id: string) => {
		// console.log('clicked', id)
		dispatch(removeAlert(id))
		if (alerts.length === 1) {
			setShowAlerts(false)
		}
	}

	const handleLinkClick = (link: string) => {
		navigate(link)
	}

	const getDotColor = () => {
		const errorAlerts = alerts.filter((alert: Alert) => alert.isError)
		if (errorAlerts.length > 0) {
			return theme.colors.status.red
		} else if (errorAlerts.length === 0) {
			return theme.colors.status.green
		}
		//  else {
		// 	return `linear-gradient(90deg, ${theme.colors.status.red} 50%, ${theme.colors.status.green} 50%)`
		// }
	}
	return (
		<>
			<div style={{ width: '100%', height: '100%', zIndex: 1 }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column-reverse',
						gap: '12px',
						position: 'fixed',
						bottom: bottomCss,
						right: '4%',
						zIndex: 18,
						transform: 'translateY(0%)',
						transition: 'all 0.4s ease',
					}}
				>
					{alerts.slice(-3).map((alert: Alert) => (
						<AlertComponent
							key={alert.id}
							alert={alert}
							removeAlert={removeAlertHandler}
							onLinkClick={handleLinkClick} // Pass the link click handler
						/>
					))}
				</div>
				<div
					style={{
						color: theme.colors.alerts.grey900,
						display: 'flex',
						bottom: '4%',
						right: '4%',
						position: 'fixed',
						zIndex: 18,
					}}
				>
					<button
						style={{
							background:
								alerts.length === 0
									? theme.colors.alerts.grey250
									: theme.colors.alerts.grey50,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							boxShadow: '0px 2px 2px 1px ' + theme.colors.alerts.grey300,
							borderRadius: '30px',
							height: 40,
							width: 40,
							cursor: alerts.length === 0 ? 'not-allowed' : 'pointer',
							border: '1px solid ' + theme.colors.alerts.grey300,
						}}
						disabled={alerts.length === 0}
						onClick={handleClick}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
					>
						{alertPending && (
							<span
								style={{
									height: '8px',
									width: '8px',
									borderRadius: '12px',
									background: getDotColor(),
									boxShadow: '0px 0px 4px 1px ' + theme.colors.alerts.grey300,
									display: 'flex',
									position: 'absolute',
									top: '5%',
									right: '8%',
								}}
							></span>
						)}
						{showAlerts ? (
							<CloseOutlinedIcon
								style={{
									color: hover
										? theme.colors.status.green
										: theme.colors.alerts.grey600,
									scale: '1.2',
								}}
							/>
						) : (
							<NotificationsNoneOutlinedIcon
								style={{
									color: hover
										? theme.colors.status.green
										: alerts.length > 0
										? theme.colors.alerts.grey600
										: theme.colors.alerts.grey300,
									scale: '1.2',
								}}
							/>
						)}
					</button>
				</div>
			</div>
			<Outlet />
		</>
	)
}

const AlertComponent = ({
	alert,
	removeAlert,
	onLinkClick,
}: {
	alert: Alert
	removeAlert: (id: string) => void
	onLinkClick: (link: string) => void
}) => {
	const theme = useTheme()
	const reportTypes = ['/aggregated', '/invalid', '/network']
	const handleLinkClick = () => {
		let url = alert.link as string
		if (alert.extra_data && reportTypes.includes(alert.link as string)) {
			url = alert.link + '?report=' + alert.extra_data
		}
		onLinkClick(url)
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				width: '20vw',
				background: theme.colors.alerts.grey50,
				boxShadow: '0px 0px 4px 2px ' + theme.colors.alerts.grey600,
				borderRadius: '30px',
				color: theme.colors.alerts.grey900,
				padding: '1rem',
				position: 'relative',
				whiteSpace: 'normal',
				wordWrap: 'break-word',
			}}
		>
			{alert.isError ? (
				<div
					style={{
						width: '25px',
						height: '25px',
						background: theme.colors.alerts.red300,
						display: 'flex',
						borderRadius: '50%',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: '1rem',
					}}
				>
					<CloseOutlinedIcon
						style={{
							scale: '0.9',
							color: theme.colors.alerts.white,
						}}
					/>
				</div>
			) : (
				<div
					style={{
						width: '25px',
						height: '25px',
						background: theme.colors.status.green,
						display: 'flex',
						borderRadius: '50%',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: '1rem',
					}}
				>
					<CheckOutlinedIcon
						style={{
							scale: '0.9',
							color: theme.colors.alerts.white,
						}}
					/>
				</div>
			)}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2px',
					flexGrow: 1,
				}}
			>
				<span
					style={{
						color: theme.colors.alerts.grey300,
						fontSize: theme.font.size.caption,
					}}
				>
					Notification
				</span>
				<span
					style={{
						color: theme.colors.alerts.grey900,
						fontSize: theme.font.size.body,
						wordWrap: 'break-word',
					}}
				>
					{alert.message}
					{alert.link && (
						<span
							style={{
								textDecoration: 'underline',
								color: theme.colors.alerts.green,
								cursor: 'pointer',
								marginLeft: '5px',
							}}
							onClick={handleLinkClick}
						>
							View Report
						</span>
					)}
					{!alert.isError && alert.link && (
						<Tooltip title='The report name is the last 5 digits of the csv file name.'>
							<InfoOutlinedIcon
								style={{
									marginLeft: '5px',
									fontSize: '1rem',
									cursor: 'pointer',
									marginBottom: '-0.2rem',
								}}
							/>
						</Tooltip>
					)}
				</span>
			</div>
			<button
				style={{
					border: 'none',
					position: 'absolute',
					top: '10%',
					right: '6%',
					color: theme.colors.alerts.grey300,
					fontSize: theme.font.size.body,
					cursor: 'pointer',
				}}
				onClick={() => removeAlert(alert.id)}
			>
				x
			</button>
		</div>
	)
}

export default AlertContainer
