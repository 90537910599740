import { FormGroup } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { ChipInputList } from '../../components/ChipInput'
import { CountryListSelector } from '../../components/SelectableInputs'
import styled from 'styled-components'
import { countryList } from '../../components/countryList'
import { useActions } from '../../../hooks/useActions'
import CustomDateRangePicker from '../../components/dateInputs/CustomDateRangePicker'
import { handleDateChange } from '../../../utils/helpers/helperFuncs'
import { generatePidListCompanyBased } from '../../../utils/helpers/reportHelperFuncs'
import { companyTypeConfig } from '../../../config'
import CheckedFilter from '../../components/CheckedFilter'

export const FiltersComponentList = (props: {
	setAppIds: any
	setAdvertisers: any
	setUser: any
	setPid: any
	setPublishers: any
	setCampaignNames: any
	setCampaignIds: any
	setCountries: any
	dataObj: any
	setDataObj: any
	onDateChange?: (startDate: Date | null, endDate: Date | null) => void
}) => {
	const { getPublisherAction } = useActions()
	const { advertiser, users, publisher, campaign, app, login } =
		useTypedSelector((state) => state)

	const [checkedState, setCheckedState] = useState({
		app_id: true,
		advertiser_name: true,
		email: true,
		media_source_pid: true,
		publisher: true,
		campaign_name: true,
		campaign_id: true,
		country: true,
		date_range: true,
	})

	const filters = props.dataObj?.filters || {
		app_id: [],
		advertiser_name: [],
		media_source_pid: [],
		publisher: [],
		email: [],
		campaign_name: [],
		campaign_id: [],
		country: [],
	}

	// Fetch publishers on component mount
	useEffect(() => {
		getPublisherAction() // Fetch publishers
	}, [])

	const makeList = (field: string) => {
		const unfiltered = advertiser.advertiser.map((e: any) => e[field])
		return [...new Set(unfiltered)]
	}
	const companyType = login.companyType
	const companyId = login.user.company_id

	const appIdList = app.app
		.filter((item: any) => {
			return item.company_id === companyId
		})
		.map((e: any) => e.app_id)

	// Filter PIDs based on the dynamic configuration
	const pidList: string[] = generatePidListCompanyBased(
		publisher.publisher,
		companyType,
		companyId,
		companyTypeConfig,
	)

	const publisherList = publisher.publisher.map((e: any) => e.publisher_name)
	// const campaignNameList = campaign.campaign.map((e: any) => e.campaign_name)
	const userList = users.users
		.filter((user: any) => !user.blocked)
		.map((e: any) => e.email)

	return (
		<FormGroup
			key={'form-group-2'}
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '18px',
				flexWrap: 'wrap',
				width: '100%',
			}}
		>
			<CheckedFilter
				id={'date_range'}
				onChange={(checked: boolean) =>
					setCheckedState({ ...checkedState, date_range: checked })
				}
				checked={checkedState.date_range}
				label={'Date Range'}
				placeholder={'Select Date Range'}
				arrayOnChange={() => {}}
				array={[]}
				disabled={false}
				value={[]}
			>
				<CustomDateRangePicker
					startDate={null}
					endDate={null}
					onDateChange={(startDate, endDate) =>
						handleDateChange(
							startDate,
							endDate,
							props.setDataObj,
							props.onDateChange,
						)
					}
				/>
			</CheckedFilter>
			{companyType === 'agency' && (
				<CheckedFilter
					id={'advertiser_name'}
					onChange={(checked: boolean) =>
						setCheckedState({ ...checkedState, advertiser_name: checked })
					}
					checked={checkedState.advertiser_name}
					label={'Advertiser'}
					arrayOnChange={props.setAdvertisers}
					array={makeList('advertiser_name')}
					disabled={filters.advertiser_name === undefined}
					value={filters.advertiser_name}
				/>
			)}
			{companyType !== 'publisher' && (
				<CheckedFilter
					id={'publisher_name'}
					onChange={(checked: boolean) =>
						setCheckedState({ ...checkedState, publisher: checked })
					}
					checked={checkedState.publisher}
					label={'Publisher'}
					arrayOnChange={props.setPublishers}
					array={publisherList}
					disabled={filters.publisher_name === undefined}
					value={filters.publisher_name}
				/>
			)}
			<CheckedFilter
				id={'email'}
				onChange={(checked: boolean) =>
					setCheckedState({ ...checkedState, email: checked })
				}
				checked={checkedState.email}
				label={'User'}
				arrayOnChange={props.setUser}
				array={userList}
				disabled={filters.email === undefined}
				value={filters.email}
			/>
			{companyType !== 'publisher' && (
				<CheckedFilter
					id={'app_id'}
					onChange={(checked: boolean) =>
						setCheckedState({ ...checkedState, app_id: checked })
					}
					checked={checkedState.app_id}
					label={'App Id'}
					arrayOnChange={props.setAppIds}
					array={appIdList}
					disabled={filters.app_id === undefined}
					value={filters.app_id}
				/>
			)}
			{companyType !== 'advertiser' && (
				<CheckedFilter
					id={'media_source_pid'}
					onChange={(checked: boolean) =>
						setCheckedState({ ...checkedState, media_source_pid: checked })
					}
					checked={checkedState.media_source_pid}
					label={'PID'}
					arrayOnChange={props.setPid}
					array={pidList}
					disabled={filters.media_source_pid === undefined}
					value={filters.media_source_pid}
				/>
			)}

			<CheckedFilter
				id={'country'}
				onChange={(checked: boolean) =>
					setCheckedState({ ...checkedState, country: checked })
				}
				checked={checkedState.country}
				label={'Country'}
				arrayOnChange={props.setCountries}
				array={countryList}
				disabled={filters.country === undefined}
				value={filters.country}
			/>
		</FormGroup>
	)
}
