import React, { useEffect, useState } from 'react'
import { InputFieldText, StyledTextAreaField } from '../components/Inputs'
import { Checkbox, useTheme } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { CountryListSelector, DropList } from '../components/SelectableInputs'
import { SubmitButton } from '../components/Buttons'
import success from '../../assets/success-icon.svg'
import ReCAPTCHA from 'react-google-recaptcha'
import { countryList } from '../components/countryList'

const SignUp = (props: {
	setCurrentView: any
	onSignUp: any
	errorMsg?: string
	handleTermsPopUp?: any
}) => {
	const theme = useTheme()
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [confirmPassword, setConfirmPassword] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [companyName, setCompanyName] = useState<string>('')
	const [geo, setGeo] = useState<string[]>([])
	const [clientType, setClientType] = useState<string>('')
	const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false)
	const [notARobot, setNotARobot] = useState<boolean>(false)
	const [isSubmit, setIsSubmit] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [notes, setNotes] = useState('')

	const signUpHandler = () => {
		props.onSignUp({ email, name, clientType, companyName, geo })
		// if (!props.errorMsg) {
		// 	setIsSubmit(true)
		// }
	}

	useEffect(() => {
		if (props.errorMsg) {
			setErrorMessage(props.errorMsg)
		} else setErrorMessage('')
	}, [props.errorMsg])

	const handleSetEmail = (e: string) => {
		setErrorMessage('')
		setEmail(e)
	}
	const handleRecaptcha = async (token: any) => {
		const request = await fetch('http://localhost:4000/api/recaptcha', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ token }),
		})
		const result = await request.json()
		if (result.success) {
			setNotARobot(true)
		}
	}

	const isValidPassword = (password: string) => {
		const hasLetters = /[a-zA-Z]/.test(password)
		const hasNumbers = /\d/.test(password)
		const isLongEnough = password.length > 6
		return hasLetters && hasNumbers && isLongEnough
	}
	const workingOnIt = false
	return (
		<>
			{!workingOnIt ? (
				!isSubmit && (
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: '0.938rem',
						}}
					>
						<div style={{ display: 'flex' }}>
							<InputFieldText
								onChange={(e: string) => setName(e)}
								type='text'
								label='Point of Contact'
								value={name}
								style={{ width: '80%' }}
							/>
							<DropList
								onChange={(e: string) => setClientType(e)}
								label='Type of Client'
								value={clientType}
								options={['Advertiser', 'Publisher']}
								style={{ width: '80%' }}
							/>
						</div>
						<InputFieldText
							onChange={(e: string) => setCompanyName(e)}
							type='text'
							label='Company Name'
							value={companyName}
							style={{ width: '100%' }}
						/>
						<CountryListSelector
							label='Head Office Country'
							options={countryList}
							value={geo}
							singular
							onChange={(e: any) => setGeo(e)}
							noGlobal
						/>
						<InputFieldText
							errormessage={errorMessage ? errorMessage : undefined}
							onChange={(e: string) => handleSetEmail(e)}
							type='email'
							label='Email'
							value={email}
							style={{ width: '100%' }}
						/>

						{/* <StyledTextAreaField
							label='Notes'
							value={notes}
							onChange={setNotes}
						/> */}

						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 12,
							}}
						>
							<ReCAPTCHA
								sitekey={process.env.REACT_APP_RECAPTCHA as string}
								onChange={(token: any) => handleRecaptcha(token)}
							/>
						</div>

						{/* <div
							style={{
								width: '100%',
								display: 'flex',
								gap: '3.125rem',
							}}
						>
							<InputFieldText
								errormessage={
									isValidPassword(password)
										? password !== confirmPassword
											? 'Password needs to match confirm password'
											: undefined
										: 'Password must be over 6 characters, including letters and numbers.'
								}
								onChange={(e: string) => setPassword(e)}
								type='password'
								label='Password'
								value={password}
								style={{ width: '100%' }}
							/>
							<InputFieldText
								errormessage={
									password !== confirmPassword
										? 'Confirm password needs to match password'
										: undefined
								}
								onChange={(e: string) => setConfirmPassword(e)}
								type='password'
								label='Confirm Password'
								value={confirmPassword}
								style={{ width: '100%' }}
							/>
						</div> */}
						<div
							style={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Checkbox
									checked={agreeToTerms}
									key={'hi'}
									onChange={() => setAgreeToTerms(!agreeToTerms)}
									inputProps={{
										'aria-label': 'controlled',
									}}
									icon={
										<CheckBoxOutlineBlankIcon
											style={{ scale: '0.8', color: theme.colors.base.grey900 }}
										/>
									}
									checkedIcon={
										<CheckBoxIcon
											style={{
												scale: '0.8',
												color: theme.colors.base.green200,
											}}
										/>
									}
								/>
								<span
									style={{
										color: theme.colors.base.grey600,
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
									}}
								>
									I agree with the{' '}
									<button
										style={{
											border: 'none',
											display: 'flex',
											color: theme.colors.base.blue300,
											fontSize: 'inherit',
											marginLeft: -2,
											cursor: 'pointer',
										}}
										onClick={() => props.handleTermsPopUp()}
									>
										terms of use
									</button>
								</span>
							</div>
						</div>
						<SubmitButton
							onClick={signUpHandler}
							disabled={
								!email ||
								!name ||
								!clientType ||
								!agreeToTerms ||
								!companyName ||
								!notARobot
							}
							style={{
								width: '101%',
								borderRadius: '4px',
								margin: 0,
								// background: theme.colors.base.green200,
								// border: '2px solid ' + theme.colors.base.green200,
								textTransform: 'none',
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
							}}
							sx={{
								'&.Mui-disabled': {
									background: theme.colors.base.grey100,
								},
								'&:hover': {
									background: theme.colors.base.green20,
									border: '2px solid ' + theme.colors.base.green200,
									color: theme.colors.base.grey900,
								},
								'&.Mui-focused': {
									background: theme.colors.base.green100,
								},
							}}
						>
							Sign Up
						</SubmitButton>
					</div>
				)
			) : (
				<span style={{ fontSize: theme.font.size.titles }}>Coming soon</span>
			)}
			{/* {isSubmit && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '2.5rem',
					}}
				>
					<img
						style={{
							textAlign: 'center',
							width: '5.375rem',
							color: theme.colors.base.green200,
						}}
						src={success}
						alt='success'
					/>
					<span
						style={{
							textAlign: 'center',
							color: theme.colors.base.green200,
							fontSize: '4rem',
							fontWeight: theme.font.weight.bold,
						}}
					>
						Success!
					</span>
					<span
						style={{ color: theme.colors.base.grey900, textAlign: 'center' }}
					>
						Your signup request has been received by the Varys team. Please
						await further instructions on how to activate your account.
					</span>
				</div>
			)} */}
		</>
	)
}

export default SignUp
