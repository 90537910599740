import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, ThemeProvider, DialogActions, useTheme } from '@mui/material'
import { DropList, CountryListSelector } from '../components/SelectableInputs'
// import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { PageWrapper } from '../components/reusableComponents'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import EditPageHeader from '../components/EditPageHeader'
import CreatorCompanyInfoComponent from './CreatorCompanyInfoComponent'
import defaultIcon from '../../assets/default-icon.webp'
import { InputFieldText } from '../components/Inputs'
import { ChipInputList } from '../components/ChipInput'
import { countryList } from '../components/countryList'

interface Capability {
	uuid: string
	type: string
	gender: { male: number; female: number }
	age: {
		age13to17: number
		age18to24: number
		age25to34: number
		age35to44: number
		age45to54: number
		age55toInfinity: number
	}
	geo: { country: string; value: number }[]
	engagement_rate: number
	rate_per_video: number
	vertical: string[]
	notes: string
}

interface CapabilitiesInfoContainerProps {
	capabilities: Partial<Capability>
	onUpdate: (updatedCapabilities: Partial<Capability>) => void
}

const CapabilitiesInfoContainer: React.FC<CapabilitiesInfoContainerProps> = ({
	capabilities = {},
	onUpdate,
}) => {
	const theme = useTheme()
	const { settings } = useTypedSelector((state) => state)

	const typeOptions = ['Instagram', 'TikTok', 'YouTube', 'Twitter', 'Facebook']
	const geoFields = Array(4).fill(null)

	const handleChange = (field: keyof Capability, value: any) => {
		onUpdate({ ...capabilities, [field]: value })
	}

	const handleGenderChange = (gender: 'male' | 'female', value: number) => {
		const newGender = { ...capabilities.gender, [gender]: value }
		handleChange('gender', newGender)
	}

	const handleAgeChange = (
		ageGroup: keyof Capability['age'],
		value: number,
	) => {
		const newAge = { ...capabilities.age, [ageGroup]: value }
		handleChange('age', newAge)
	}

	const handleGeoChange = (
		index: number,
		field: 'country' | 'value',
		value: any,
	) => {
		const newGeo = [...(capabilities.geo || [])]
		while (newGeo.length < 5) {
			// Ensure we always have 5 geo entries
			newGeo.push({ country: '', value: 0 })
		}
		newGeo[index] = {
			...newGeo[index],
			[field]: field === 'value' ? Number(value) : value,
		}
		onUpdate({ ...capabilities, geo: newGeo })
	}

	return (
		<React.Fragment key={capabilities.uuid || 'new'}>
			<Grid
				container
				spacing={2}
				style={{
					fontSize: theme.font.size.body,
					width: '90%',
					marginTop: '20px',
				}}
			>
				<DropList
					label='Type'
					options={typeOptions}
					value={capabilities.type}
					onChange={(value: any) => {
						handleChange('type', value)
					}}
				/>
				<Grid item xs={6}>
					<InputFieldText
						label='Male %'
						type='number'
						value={capabilities.gender?.male || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleGenderChange('male', Number(e))
						}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<InputFieldText
						label='Female %'
						type='number'
						value={capabilities.gender?.female || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleGenderChange('female', Number(e))
						}
						fullWidth
					/>
				</Grid>
				{Object.entries(capabilities.age || {}).map(([key, value]) => (
					<Grid item xs={4} key={key}>
						<InputFieldText
							label={`Age ${key
								.replace('age', '')
								.replace('to', '-')
								.replace('Infinity', '+')} %`}
							type='number'
							value={value || ''}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								handleAgeChange(key as keyof Capability['age'], Number(e))
							}
							fullWidth
						/>
					</Grid>
				))}
				{geoFields.map((_, index) => (
					<React.Fragment key={index}>
						<Grid item xs={6}>
							<CountryListSelector
								onChange={(value: any) =>
									handleGeoChange(index, 'country', value[0])
								}
								value={
									capabilities.geo?.[index]?.country
										? [capabilities.geo[index].country]
										: []
								}
								label={`Geo ${index + 1}`}
								options={countryList}
								singular
							/>
						</Grid>
						<Grid item xs={6}>
							<InputFieldText
								label={`Geo ${index + 1} %`}
								type='number'
								value={capabilities.geo?.[index]?.value || ''}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									handleGeoChange(index, 'value', e)
								}
								fullWidth
							/>
						</Grid>
					</React.Fragment>
				))}
				<Grid item xs={6}>
					<InputFieldText
						label='Engagement Rate %'
						type='number'
						value={capabilities.engagement_rate || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange('engagement_rate', Number(e))
						}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<InputFieldText
						label='Rate Per Video %'
						type='number'
						value={capabilities.rate_per_video || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange('rate_per_video', Number(e))
						}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<ChipInputList
						label='Vertical'
						value={capabilities.vertical || []}
						onChange={(value: string[]) => handleChange('vertical', value)}
						options={settings.settings.vertical}
					/>
				</Grid>
				<Grid item xs={12}>
					<InputFieldText
						type='string'
						label='Notes'
						value={capabilities.notes || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange('notes', e)
						}
						multiline
					/>
				</Grid>
			</Grid>
			<div
				style={{
					borderBottom: '1px solid ' + theme.colors.base.grey300,
					width: '95%',
					marginTop: '20px',
					marginBottom: '20px',
				}}
			></div>
		</React.Fragment>
	)
}

const CreatorCompanyEdit = (props: {
	setLoading: (loading: boolean) => void
	setPopUpVisible: (visible: boolean) => void
	companyData: any
	onSave: (data: any) => void
}) => {
	const theme = useTheme()
	// const { insertCreatorAction } = useActions()
	const navigate = useNavigate()
	// const { login } = useTypedSelector((state) => state)

	const [name, setName] = useState(props.companyData.name)
	const [userName, setUserName] = useState(props.companyData.username)
	const [campaign, setCampaign] = useState(props.companyData.campaign)
	const [type, setType] = useState(props.companyData.type)
	const [email, setEmail] = useState(props.companyData.email)
	const [gender, setGender] = useState(props.companyData.gender)
	const [geo, setGeo] = useState(props.companyData.geo)
	const [portfolio, setPortfolio] = useState(props.companyData.portfolio)
	const [address, setAddress] = useState(props.companyData.address)
	const [status, setStatus] = useState(props.companyData.status)
	const [instagram, setInstagram] = useState(props.companyData.instagram)
	const [tiktok, setTiktok] = useState(props.companyData.tiktok)
	const [youtube, setYoutube] = useState(props.companyData.youtube)
	const [linkedin, setLinkedin] = useState(props.companyData.linkedin)
	const [fiverr, setFiverr] = useState(props.companyData.fiverr)
	const [hasAgent, setHasAgent] = useState(props.companyData.has_agent)
	const [capabilities, setCapabilities] = useState(
		props.companyData.capabilities,
	)

	const [isValid, setIsValid] = useState(true)
	// console.log(props.companyData)

	useEffect(() => {
		setIsValid(true)
	}, [name, userName, type, email, gender, geo, address])

	const handleSubmit = async () => {
		if (isValid) {
			const payload = {
				name,
				username: userName,
				campaign,
				type,
				email,
				gender,
				geo,
				portfolio,
				address,
				status,
				instagram,
				tiktok,
				youtube,
				linkedin,
				fiverr,
				has_agent: hasAgent,
				creator_id: props.companyData.id, // we should remove this once creator_id is removed
				capabilities,
			}

			props.setLoading(true)
			try {
				// console.log(payload)
				props.onSave(payload)
				// await insertCreatorAction(payload, ()=> {})
				// // props.onSave(payload)
				// navigate('/companySettings')
			} catch (error) {
				console.error('Error updating creator:', error)
			} finally {
				props.setLoading(false)
			}
		}
	}

	const closeDetailedView = () => {
		navigate('/companySettings')
		props.setPopUpVisible(false)
	}

	return (
		<ThemeProvider theme={theme}>
			<EditPageHeader
				onBack={closeDetailedView}
				tabValue={0}
				handleChangeTab={() => {}}
				hasPreview={false}
				options={[{ label: 'Company Information', tabIndex: 0 }]}
				arialabel='creator edit tabs'
			/>
			<CompanyInformationHeader
				name={name}
				iconUrl={props.companyData.creator_icon || defaultIcon}
			/>
			<PageWrapper
				style={{
					margin: '2vh 8vh',
					maxHeight: '200%',
					overflow: 'visible',
					height: 'fit-content',
				}}
			>
				<CreatorCompanyInfoComponent
					name={name}
					setName={setName}
					userName={userName}
					setUserName={setUserName}
					campaign={campaign}
					setCampaign={setCampaign}
					type={type}
					setType={setType}
					email={email}
					setEmail={setEmail}
					gender={gender}
					setGender={setGender}
					geo={geo}
					setGeo={setGeo}
					portfolio={portfolio}
					setPortfolio={setPortfolio}
					address={address}
					setAddress={setAddress}
					// isValid={isValid}
					instagram={instagram}
					setInstagram={setInstagram}
					tiktok={tiktok}
					setTiktok={setTiktok}
					youtube={youtube}
					setYoutube={setYoutube}
					linkedin={linkedin}
					setLinkedin={setLinkedin}
					fiverr={fiverr}
					setFiverr={setFiverr}
					// ownerEmail=''
					// setOwnerEmail={() => {}}
					hasAgent={hasAgent}
					setHasAgent={setHasAgent}
				/>
				<h2
					style={{
						fontSize: theme.font.size.h3,
						color: 'black',
						marginTop: '40px',
						marginBottom: '20px',
					}}
				>
					Capabilities
				</h2>
				<CapabilitiesInfoContainer
					capabilities={capabilities}
					onUpdate={setCapabilities}
				/>
				<DialogActions
					style={{
						paddingBottom: '80px',
						display: 'flex',
						justifyContent: 'center',
						gap: 160,
					}}
				>
					<UnborderedButton onClick={closeDetailedView} label='Cancel' />
					<SubmitButton disabled={!isValid} onClick={handleSubmit}>
						Update
					</SubmitButton>
				</DialogActions>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default CreatorCompanyEdit
