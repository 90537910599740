import { FormGroup } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { ChipInputList } from '../../components/ChipInput'
import { CountryListSelector } from '../../components/SelectableInputs'
import { DatePickerComponent } from '../../components/dateInputs/DateInputs'
import styled from 'styled-components'
import CustomDateRangePicker from '../../components/dateInputs/CustomDateRangePicker'
import { handleDateChange } from '../../../utils/helpers/helperFuncs'
import { CompanyType, companyTypeConfig } from '../../../config'
import { generatePidListCompanyBased } from '../../../utils/helpers/reportHelperFuncs'
import { SmallLoading } from '../../../assets/svg/loading'
import CheckedFilter from '../../components/CheckedFilter'

const login_array = [
	{
		username: 'idan@thingortwo.agency',
		password: '!@Yxbdfnx625!@',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'shaked@tatanka.agency',
		password: 'XU!bqSdnqui5!@C5',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'adops@thingortwo.es',
		password: '3R!Yn^axS.n7kfH3!',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'adops@smartass.media',
		password: '$SFDbse2cw87i0',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'updates@thing02.com',
		password: 'vbN!SD#e5!dv99',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'support@pinkads.agency',
		password: 'yn2NQUV4@K4Dric6',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'support@admint.media',
		password: 'S!F4p8Ldmdur#knA-g',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
]

export const NetworksComponentList = (props: {
	setAppIds: any
	setAgency: any
	setAppName: any
	setPid: any
	setPlatform: any
	setUsername: any
	setPmdAfPrt: any
	dataObj: any
	setDataObj: any
	onDateChange?: (startDate: Date | null, endDate: Date | null) => void
}) => {
	const { networks, app, publisher, login } = useTypedSelector((state) => state)
	const [loading, setLoading] = useState(true) // Loading state for PIDs

	const [checkedState, setCheckedState] = useState({
		app_id: true,
		app_name: true,
		agency: true,
		media_source_pid: true,
		platform: true,
		pmd_af_prt: true,
		username: true,
		date_range: true,
	})

	const filters = props.dataObj?.filters || {
		app_id: [],
		app_name: [],
		media_source_pid: [],
		platform: [],
		agency: [],
		pmd_af_prt: [],
		username: [],
	}

	const companyType = login.companyType as CompanyType

	const makeList = (field: string) => {
		const unfiltered = networks.networks.map((e: any) => e[field])
		return [...new Set(unfiltered)]
	}
	const companyId = login.user.company_id

	const appIdList = app.app
		.filter((item: any) => {
			return item.company_id === companyId
		})
		.map((e: any) => e.app_id)

	const pidList: string[] = generatePidListCompanyBased(
		publisher.publisher,
		companyType,
		companyId,
		companyTypeConfig,
	)

	const agencyList = makeList('agency/pmd_(af_prt)')

	return (
		<FormGroup
			key={'form-group-2'}
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '18px',
				flexWrap: 'wrap',
				width: '100%',
			}}
		>
			<CheckedFilter
				id={'date_range'}
				onChange={(checked: boolean) =>
					setCheckedState({ ...checkedState, date_range: checked })
				}
				checked={checkedState.date_range}
				label={'Date Range'}
				placeholder={'Select Date Range'}
				arrayOnChange={() => {}}
				array={[]}
				disabled={false}
				value={[]}
			>
				<CustomDateRangePicker
					startDate={null}
					endDate={null}
					onDateChange={(startDate, endDate) =>
						handleDateChange(
							startDate,
							endDate,
							props.setDataObj,
							props.onDateChange,
						)
					}
				/>
			</CheckedFilter>
			<CheckedFilter
				id={'platform'}
				onChange={(checked: boolean) =>
					setCheckedState({ ...checkedState, platform: checked })
				}
				checked={checkedState.platform}
				label={'Platform'}
				arrayOnChange={props.setPlatform}
				array={['ios', 'android']}
				disabled={filters.platform === undefined}
				value={filters.platform}
			/>
			{/* <CheckedFilter
				id={'app_name'}
				onChange={(checked: boolean) =>
					setCheckedState({ ...checkedState, app_name: checked })
				}
				checked={checkedState.app_name}
				label={'App Name'}
				arrayOnChange={props.setAppName}
				array={makeList('app_name')}
				disabled={filters.app_name === undefined}
				value={filters.app_name}
			/> */}
			<CheckedFilter
				id={'agency'}
				onChange={(checked: boolean) =>
					setCheckedState({ ...checkedState, agency: checked })
				}
				checked={checkedState.agency}
				label={'Agency'}
				arrayOnChange={props.setAgency}
				array={agencyList}
				disabled={filters.agency === undefined}
				value={filters.agency}
			/>
			{companyType !== 'publisher' && (
				<CheckedFilter
					id={'app_id'}
					onChange={(checked: boolean) =>
						setCheckedState({ ...checkedState, app_id: checked })
					}
					checked={checkedState.app_id}
					label={'App Id'}
					arrayOnChange={props.setAppIds}
					array={appIdList}
					disabled={filters.app_id === undefined}
					value={filters.app_id}
				/>
			)}
			{companyType !== 'advertiser' && (
				<CheckedFilter
					id={'media_source_pid'}
					onChange={(checked: boolean) =>
						setCheckedState({ ...checkedState, media_source_pid: checked })
					}
					checked={checkedState.media_source_pid}
					label={'PID'}
					arrayOnChange={props.setPid}
					array={pidList}
					disabled={filters.media_source_pid === undefined}
					value={filters.media_source_pid}
				/>
			)}
			{/* <CheckedFilter
				id={'username'}
				onChange={(checked: boolean) =>
					setCheckedState({ ...checkedState, username: checked })
				}
				checked={checkedState.username}
				label={'Username'}
				arrayOnChange={props.setUsername}
				array={login_array.map((e) => e.username)}
				disabled={filters.username === undefined}
				value={filters.username}
			/> */}
		</FormGroup>
	)
}
