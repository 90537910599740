// Function to check if a user has a specific permission
export const hasPermission = (
	userPermissions: bigint,
	permissionValue: bigint,
): boolean => {
	return (userPermissions & permissionValue) === permissionValue
}

export const permissionNames = {
	BLOCK_USERS: 'BLOCK_USERS',
	CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
	VIEW_NEWS_PUBLISHERS: 'VIEW_NEWS_PUBLISHERS',
	VIEW_NEWS_ADVERTISERS: 'VIEW_NEWS_ADVERTISERS',
	// VIEW_LOGINS: 'VIEW_LOGINS',
	// VIEW_USER_LOGS: 'VIEW_USER_LOGS',
	// VIEW_ADMIN_LOGS: 'VIEW_ADMIN_LOGS',
	// VIEW_SUPER_LOGS: 'VIEW_SUPER_LOGS',
	// VIEW_DESIGNER_LOGS: 'VIEW_DESIGNER_LOGS',
	VIEW_DETAILED_LOGS: 'VIEW_DETAILED_LOGS',
	// VIEW_PERSONAL_SETTINGS: 'VIEW_PERSONAL_SETTINGS',
	PREVIEW_ADVERTISERS: 'PREVIEW_ADVERTISERS',
	SEE_ALL_ADVERTISERS: 'SEE_ALL_ADVERTISERS',
	ACCESS_TO_APPS: 'ACCESS_TO_APPS',
	DOWNLOAD_CONTACT_LIST: 'DOWNLOAD_CONTACT_LIST',
	EDIT_ADVERTISER_OWNER_ONLY: 'EDIT_ADVERTISER_OWNER_ONLY',
	VIEW_ADVERTISER_CONTACTS: 'VIEW_ADVERTISER_CONTACTS',
	DOWNLOAD_ADVERTISERS_LIST: 'DOWNLOAD_ADVERTISERS_LIST',
	PREVIEW_PUBLISHERS: 'PREVIEW_PUBLISHERS',
	VIEW_LINKS: 'VIEW_LINKS',
	EDIT_SKYPE: 'EDIT_SKYPE',
	DELETE_PUBLISHER_OWNER_ONLY: 'DELETE_PUBLISHER_OWNER_ONLY',
	ADD_NEW_PUBLISHERS: 'ADD_NEW_PUBLISHERS',
	VIEW_PUBLISHER_CONTACTS: 'VIEW_PUBLISHER_CONTACTS',
	DOWNLOAD_PUBLISHERS_LIST: 'DOWNLOAD_PUBLISHERS_LIST',
	ACCESS_TO_MEDIA_PLAN: 'ACCESS_TO_MEDIA_PLAN',
	PREVIEW_CAMPAIGNS: 'PREVIEW_CAMPAIGNS',
	OPENING_HISTORY: 'OPENING_HISTORY',
	ADD_NEW_CAMPAIGNS: 'ADD_NEW_CAMPAIGNS',
	ADD_UNIQUE_PAYOUTS: 'ADD_UNIQUE_PAYOUTS',
	EDIT_OTHER_PARAMETERS: 'EDIT_OTHER_PARAMETERS',
	DOWNLOAD_CAMPAIGN_REPORT: 'DOWNLOAD_CAMPAIGN_REPORT',
	EDIT_MAIN_PARAMETERS: 'EDIT_MAIN_PARAMETERS',
	VIEW_COST: 'VIEW_COST',
	VIEW_PROFIT: 'VIEW_PROFIT',
	EDIT_DEMAND: 'EDIT_DEMAND',
	SEND_EMAILS: 'SEND_EMAILS',
	VIEW_DEMAND: 'VIEW_DEMAND',
	VIEW_GLOBAL: 'VIEW_GLOBAL',
	VIEW_SUPPLY: 'VIEW_SUPPLY',
	VIEW_CONTROL: 'VIEW_CONTROL',
	VIEW_PERSONAL_FINANCE: 'VIEW_PERSONAL_FINANCE',
	VIEW_BALANCE_IN: 'VIEW_BALANCE_IN',
	VIEW_BALANCE_OUT: 'VIEW_BALANCE_OUT',
	VIEW_MEDIA_BUYING: 'VIEW_MEDIA_BUYING',
	EDIT_DEMAND_INVOICE: 'EDIT_DEMAND_INVOICE',
	VIEW_INFLUENCERS: 'VIEW_INFLUENCERS',
	EDIT_CREATOR_INVOICE: 'EDIT_CREATOR_INVOICE',
	VIEW_INFLUENCERS_SUPPLY: 'VIEW_INFLUENCERS_SUPPLY',
	EDIT_SUPPLY_INVOICE: 'EDIT_SUPPLY_INVOICE',
	VIEW_AGGREGATED_P360_REPORT: 'VIEW_AGGREGATED_P360_REPORT',
	VIEW_AGGREGATED: 'VIEW_AGGREGATED',
	VIEW_USERS: 'VIEW_USERS',
	ADD_EDIT_USERS: 'ADD_EDIT_USERS',
	DELETE_USERS: 'DELETE_USERS',
	VIEW_NEWS: 'VIEW_NEWS',
	ADD_EDIT_NEWS: 'ADD_EDIT_NEWS',
	VIEW_LOGS: 'VIEW_LOGS',
	VIEW_SETTINGS: 'VIEW_SETTINGS',
	ADD_EDIT_SETTINGS: 'ADD_EDIT_SETTINGS',
	VIEW_ADVERTISER: 'VIEW_ADVERTISER',
	ADD_EDIT_ADVERTISER: 'ADD_EDIT_ADVERTISER',
	DELETE_ADVERTISER: 'DELETE_ADVERTISER',
	VIEW_PUBLISHER: 'VIEW_PUBLISHER',
	ADD_EDIT_PUBLISHER: 'ADD_EDIT_PUBLISHER',
	DELETE_PUBLISHER: 'DELETE_PUBLISHER',
	VIEW_CAMPAIGN: 'VIEW_CAMPAIGN',
	ADD_EDIT_CAMPAIGN: 'ADD_EDIT_CAMPAIGN',
	DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
	VIEW_APP_P360: 'VIEW_APP_P360',
	ADD_EDIT_APP_P360: 'ADD_EDIT_APP_P360',
	DELETE_APP_P360: 'DELETE_APP_P360',
	VIEW_NETWORK: 'VIEW_NETWORK',
	ADD_EDIT_NETWORK: 'ADD_EDIT_NETWORK',
	VIEW_RAWDATA: 'VIEW_RAWDATA',
	ADD_EDIT_RAWDATA: 'ADD_EDIT_RAWDATA',
	VIEW_PASSWORDS: 'VIEW_PASSWORDS',
	ADD_EDIT_PASSWORDS: 'ADD_EDIT_PASSWORDS',
	VIEW_DOCUMENTS: 'VIEW_DOCUMENTS',
	ADD_EDIT_DOCUMENTS: 'ADD_EDIT_DOCUMENTS',
	VIEW_FINANCE: 'VIEW_FINANCE',
	ADD_EDIT_FINANCE: 'ADD_EDIT_FINANCE',
	VIEW_DASHBOARD: 'VIEW_DASHBOARD',
	VIEW_AUTOSWITCH: 'VIEW_AUTOSWITCH',
	ADD_EDIT_AUTOSWITCH: 'ADD_EDIT_AUTOSWITCH',
	VIEW_APPSFLYER_ACCOUNTS: 'VIEW_APPSFLYER_ACCOUNTS',
	VIEW_ADS_PLACEMENT: 'VIEW_ADS_PLACEMENT',
	VIEW_INVALID: 'VIEW_INVALID',
	DELETE_PASSWORDS: 'DELETE_PASSWORDS',
	DELETE_FINANCE: 'DELETE_FINANCE',
	DELETE_DOCUMENTS: 'DELETE_DOCUMENTS',
	ADD_EDIT_USER_PERMISSIONS: 'ADD_EDIT_USER_PERMISSIONS',
	VIEW_PUBLISHER_PASSWORDS: 'VIEW_PUBLISHER_PASSWORDS',
	VIEW_ADVERTISER_PASSWORDS: 'VIEW_ADVERTISER_PASSWORDS',
	EDIT_COMPANY_DATA: 'EDIT_COMPANY_DATA',
} as const

export const sumPermissions = (
	selectedPermissions: String[],
	allPermissions: Array<{ name: string; id: string; value: string }>,
): bigint => {
	return selectedPermissions.reduce((acc, permissionName) => {
		const permission = allPermissions.find((p) => p.name === permissionName)
		return permission ? acc | BigInt(permission.value) : acc
	}, BigInt(0))
}
