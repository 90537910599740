import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Grid, useTheme } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageWrapper } from '../components/reusableComponents'
import ReactCountryFlag from 'react-country-flag'
import { Property } from 'csstype'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { UnborderedButton } from '../components/Buttons'
import { StatusTitleTag, TitleTag } from '../components/Informative'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ReactComponent as GoodQualityIcon } from '../../assets/svg/medal-icon.svg'
import { ReactComponent as ChatIcon } from '../../assets/svg/chat-icon.svg'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import defaultIcon from '../../assets/default-icon.webp'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'

const CreatorPreviewPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	setUniversalEdit: any
	viewRecord: any
	setViewRecord: any
	setUniversalDelete: any
	PopUpVisible: any
	setPopUpVisible: any
	isCompanyPersonalView?: boolean
	companyData?: any
}) => {
	const theme = useTheme()
	const { creator, users, settings, login } = useTypedSelector((state) => state)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_PUBLISHER),
		viewPublisherContact: useIsAuthorized(
			permissionNames.VIEW_PUBLISHER_CONTACTS,
		),
	}
	const navigate = useNavigate()
	const params = useParams()

	let row: any
	if (props.isCompanyPersonalView) {
		row = props.companyData
	} else {
		row = creator.creator.find((e: any) => {
			return String(e.id).trim() === params.id!.trim()
		})
	}
	const [skypeAllowedUsers, setSkypeAllowedUsers] = useState<string[]>([])
	const [skypeGroupUserAllowed, setSkypeGroupUserAllowed] =
		useState<boolean>(false)
	const closeDetailedView = () => {
		if (props.isCompanyPersonalView) {
			navigate('/companySettings')
		} else {
			navigate('/creators')
		}
		props.setPopUpVisible(false)
	}
	const openEdit = () => {
		navigate('/creators/' + row.id)
	}
	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				// marginTop: '20px',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<InformationContainer
				creator={row}
				closeDetailedView={closeDetailedView}
				openEdit={openEdit}
				skypeGroupUserAllowed={skypeGroupUserAllowed}
				permissionContacts={permissions.viewPublisherContact}
				permissionsGeneral={permissions}
			/>
			<div
				style={{
					paddingBottom: '10px',
					marginTop: -20,
					marginBottom: 20,
					borderBottom: '1px solid ' + theme.colors.base.grey300,
					width: '95%',
				}}
			></div>

			{row &&
				row.capabilities &&
				row.capabilities.length > 0 &&
				row.capabilities.map((capability: any) => {
					return <CapabilitiesInfoContainer capabilities={capability} />
				})}
		</PageWrapper>
	)
}

const InformationContainer = (props: {
	creator: any
	closeDetailedView: any
	openEdit: any
	skypeGroupUserAllowed: boolean
	permissionContacts: boolean
	permissionsGeneral: any
}) => {
	const theme = useTheme()
	const { users, login, settings } = useTypedSelector((state) => state)

	const handleClickedLink = (link: string) => {
		if (!link.startsWith('http')) {
			return window.open('http://' + link)
		}
		return window.open(link)
	}

	const financialContacts: any = []
	const mainContacts: any = []

	//the grid is ordered like this because otherwise if the value is bigger it will move the height of the entire row.
	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
				<div style={{ display: 'flex', alignItems: 'center', width: '90%' }}>
					<UnborderedButton
						style={{
							width: '80px',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
						onClick={props.closeDetailedView}
						icon={
							<ArrowBackIosIcon
								style={{ height: '16px', marginRight: '0px' }}
							/>
						}
					>
						Back
					</UnborderedButton>
					{props.permissionsGeneral.edit && (
						<UnborderedButton
							style={{
								display: 'flex',
								marginLeft: 'auto',
							}}
							onClick={props.openEdit}
							icon={<EditIcon />}
						>
							Edit
						</UnborderedButton>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						width: '100%',
						gap: '10px',
						marginBottom: '0.5rem',
					}}
				>
					<CompanyInformationHeader
						name={props.creator?.name}
						iconUrl={props.creator?.creator_icon || defaultIcon}
						style={{
							gap: '.6rem',
						}}
					/>
					<StatusTitleTag status={props.creator?.status} />
				</div>
				<Grid
					container
					columnSpacing={2}
					rowSpacing={2}
					direction='row'
					columns={12}
					style={{
						fontSize: theme.font.size.body,
						fontWeight: theme.font.weight.normal,
					}}
				></Grid>
				{/* </div> */}
				<Grid
					container
					columnSpacing={0}
					direction='column'
					columns={12}
					style={{
						paddingInlineStart: '1rem',
						fontSize: theme.font.size.body,
						fontWeight: theme.font.weight.normal,
					}}
				>
					<Grid
						container
						columnSpacing={0}
						rowSpacing={2}
						columns={12}
						direction='row'
					>
						<Grid
							item
							xs={2}
							container
							columnSpacing={0}
							rowSpacing={2}
							columns={12}
							direction='column'
						>
							<Grid item xs={3}>
								<InfoField
									label={'Full Name'}
									value={props.creator?.name}
									whiteSpace={'normal'}
								/>
							</Grid>
							<Grid item xs={3}>
								<InfoField
									label={'Gender'}
									value={props.creator?.gender}
									whiteSpace={'normal'}
								/>
							</Grid>
							<Grid item xs={3}>
								<InfoField
									label={'Payment Terms'}
									value={props.creator?.payment_term}
									whiteSpace={'normal'}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={2}
							container
							columnSpacing={0}
							rowSpacing={2}
							columns={12}
							direction='column'
						>
							<Grid item xs={3}>
								<InfoField
									label={'Username'}
									value={props.creator?.username}
									whiteSpace={'normal'}
								/>
							</Grid>
							<Grid item xs={3}>
								<InfoField
									label={'Country'}
									value={
										props.creator?.geo.length > 0 ? props.creator?.geo[0] : ''
									}
									whiteSpace={'normal'}
								/>
							</Grid>
							<Grid item xs={3}>
								<InfoField
									label={'Vat %'}
									value={props.creator?.vat}
									whiteSpace={'normal'}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={2}
							container
							columnSpacing={0}
							rowSpacing={2}
							columns={12}
							direction='column'
						>
							<Grid item xs={3}>
								<InfoField
									label={'Type'}
									value={props.creator?.type}
									whiteSpace={'normal'}
								/>
							</Grid>
							<Grid item xs={3}>
								<InfoField
									label={'Link to Portfolio'}
									value={props.creator?.portfolio}
									whiteSpace={'normal'}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={2}
							container
							columnSpacing={0}
							rowSpacing={2}
							columns={12}
							direction='column'
						>
							<Grid item xs={3}>
								<InfoField
									label={'Creator ID'}
									value={props.creator?.creator_id}
									whiteSpace={'normal'}
								/>
							</Grid>
							<Grid item xs={3}>
								<InfoField
									label={'Link to IO'}
									value={props.creator?.link_to_io}
									whiteSpace={'normal'}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={2}
							container
							columnSpacing={0}
							rowSpacing={2}
							columns={12}
							direction='column'
						>
							<Grid item xs={3}>
								<InfoField
									label={'Email'}
									value={props.creator?.email}
									whiteSpace={'normal'}
								/>
							</Grid>
							<Grid item xs={3}>
								<InfoField
									label={'Link to Draft'}
									value={props.creator?.link_to_drafts}
									whiteSpace={'normal'}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={2}
							container
							columnSpacing={0}
							rowSpacing={2}
							columns={12}
							direction='column'
						>
							<Grid item xs={3}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										gap: 4,
										position: 'relative',
										// marginLeft: 8,
									}}
								>
									<span style={{ color: theme.colors.base.grey600 }}>
										{'Badges'}:
									</span>
									<div
										style={{
											display: 'flex',
											gap: 6,
											marginTop: -5,
											position: 'absolute',
											left: 50,
										}}
									>
										<GoodQualityIcon
											style={{
												color: props.creator?.good_communication_badge
													? theme.colors.status.green
													: theme.colors.base.grey300,
												scale: '0.82',
											}}
										/>
										<ChatIcon
											style={{
												color: props.creator?.high_quality_work_badge
													? theme.colors.status.green
													: theme.colors.base.grey300,
												scale: '0.9',
												marginTop: -2,
											}}
										/>
									</div>
								</div>
							</Grid>
							<Grid item xs={3}>
								<InfoField
									label={'Owner'}
									value={props.creator?.owner}
									whiteSpace={'normal'}
								/>
							</Grid>
						</Grid>

						<Grid item xs={12} style={{ marginTop: 35 }}>
							<InfoField
								label={'Campaign'}
								value={props.creator?.campaign}
								whiteSpace={'normal'}
								isArray
							/>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</>
	)
}

const InfoField = (props: {
	label: string
	value: string | string[]
	isArray?: boolean
	isCountry?: boolean
	whiteSpace?: Property.WhiteSpace
	textDecoration?: Property.TextDecoration
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				gap: '6px',
				// fontWeight: '500',
				whiteSpace: props.whiteSpace ? props.whiteSpace : 'nowrap',
				alignItems: props.isCountry ? 'center' : 'flex-start',
				flexWrap: 'wrap',
				flexDirection: props.isArray ? 'column' : 'row',
			}}
		>
			<span style={{ color: theme.colors.base.grey600 }}>{props.label}:</span>
			{props.isCountry ? (
				Array.isArray(props.value) ? (
					props.value.map((country: string) => {
						return (
							<>
								<ReactCountryFlag
									style={{
										height: '9px',
										borderRadius: '100px',
										border: `1px solid ${theme.colors.base.grey900}`,
									}}
									key={'flags'}
									className='roundedFlag'
									countryCode={country === 'UK' ? 'GB' : country}
									svg
								/>
								<span style={{ overflowWrap: 'anywhere' }}>{country}</span>
							</>
						)
					})
				) : (
					<>
						<ReactCountryFlag
							style={{
								height: '9px',
								borderRadius: '100px',
								border: `1px solid ${theme.colors.base.grey900}`,
							}}
							key={'flags'}
							className='roundedFlag'
							countryCode={props.value === 'UK' ? 'GB' : props.value}
							svg
						/>
						<span style={{ overflowWrap: 'anywhere' }}>{props.value}</span>
					</>
				)
			) : props.isArray ? (
				props.value?.length > 0 && (
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							width: '100%',
							maxHeight: '11rem',
							overflow: 'auto',
							gap: '4px',
							// border: '1px solid ' + theme.colors.base.grey200,
							borderRadius: '10px',
							padding: '1rem',
							marginInlineStart: -18,
							marginTop: -10,
						}}
					>
						{(props.value as string[]).map((element: string) => {
							return (
								<span
									key={element + 'key'}
									style={{
										padding: '6px',
										color: theme.colors.base.grey900,
										border: '1px solid ' + theme.colors.base.grey300,
										// background: theme.colors.base.green200,
										borderRadius: '10px',
										fontSize: theme.font.size.body,
										gridColumnStart:
											element && element.length < 16 ? 'span 1' : 'span 2',
										whiteSpace: 'nowrap',
										textAlign: 'center',
									}}
								>
									{element}
								</span>
							)
						})}
					</div>
				)
			) : props.value &&
			  typeof props.value === 'string' &&
			  ((props.value as string).startsWith('https://') ||
					(props.value as string).startsWith('http://')) ? (
				<a
					href={props.value as string}
					target={'_blank'}
					rel='noreferrer'
					style={{
						overflowWrap: 'anywhere',
						textDecoration: props.textDecoration,
					}}
				>
					Click to Open
				</a>
			) : (
				<span
					style={{
						overflowWrap: 'anywhere',
						textDecoration: props.textDecoration,
					}}
				>
					{props.value}
				</span>
			)}
		</div>
	)
}

const CapabilitiesInfoContainer = (props: { capabilities: any }) => {
	const theme = useTheme()
	const { capabilities } = props
	// console.log(capabilities)

	return (
		<React.Fragment key={capabilities.uuid}>
			<Grid
				container
				columnSpacing={0}
				rowSpacing={2}
				direction='column'
				columns={12}
				key={capabilities.uuid}
				style={{
					paddingInlineStart: '1rem',
					fontSize: theme.font.size.body,
					fontWeight: theme.font.weight.normal,
					width: '90%',
				}}
			>
				<Grid
					container
					columnSpacing={10}
					rowSpacing={4}
					columns={12}
					direction='row'
				>
					<Grid item xs={12}>
						<InfoField
							label={'Type'}
							value={capabilities.type}
							whiteSpace={'normal'}
							textDecoration={'underline'}
						/>
					</Grid>
					<Grid
						item
						xs={2}
						container
						columnSpacing={0}
						rowSpacing={2}
						columns={12}
						direction='column'
					>
						<Grid item xs={2}>
							<span
								style={{
									color: theme.colors.base.grey600,
									fontSize: theme.font.size.body,
									textDecoration: 'underline',
								}}
							>
								{'Gender'}:
							</span>
						</Grid>
						<Grid item xs={2} style={{ marginLeft: 12 }}>
							<InfoField
								label={'Male'}
								value={capabilities.gender.male + '%'}
								whiteSpace={'normal'}
							/>
						</Grid>
						<Grid item xs={2} style={{ marginLeft: 12 }}>
							<InfoField
								label={'Female'}
								value={capabilities.gender.female + '%'}
								whiteSpace={'normal'}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={3}
						container
						columnSpacing={0}
						rowSpacing={2}
						columns={12}
						direction='column'
					>
						<Grid item xs={2}>
							<span
								style={{
									color: theme.colors.base.grey600,
									fontSize: theme.font.size.body,
									textDecoration: 'underline',
								}}
							>
								{'Age'}:
							</span>
						</Grid>
						<Grid item xs={2} style={{ marginLeft: 12 }}>
							<InfoField
								label={'18-24'}
								value={capabilities.age.age18to24 + '%'}
								whiteSpace={'normal'}
							/>
						</Grid>
						<Grid item xs={2} style={{ marginLeft: 12 }}>
							<InfoField
								label={'25-34'}
								value={capabilities.age.age25to34 + '%'}
								whiteSpace={'normal'}
							/>
						</Grid>
						<Grid item xs={5} style={{ marginLeft: 12 }}>
							<InfoField
								label={'35-44'}
								value={capabilities.age.age35to44 + '%'}
								whiteSpace={'normal'}
							/>
						</Grid>
						<Grid
							item
							xs={4}
							style={{ display: 'flex', alignItems: 'flex-end' }}
						>
							<InfoField
								label={'45-54'}
								value={capabilities.age.age45to54 + '%'}
								whiteSpace={'normal'}
							/>
						</Grid>
						<Grid item xs={2}>
							<InfoField
								label={'55+'}
								value={capabilities.age.age55toInfinity + '%'}
								whiteSpace={'normal'}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={2}
						container
						columnSpacing={0}
						rowSpacing={2}
						columns={12}
						direction='column'
					>
						<Grid item xs={2}>
							<span
								style={{
									color: theme.colors.base.grey600,
									fontSize: theme.font.size.body,
									textDecoration: 'underline',
								}}
							>
								{'Geo'}:
							</span>
						</Grid>
						{capabilities.geo.map((el: any) => {
							return (
								<Grid item xs={2} key={el.uuid} style={{ marginLeft: 12 }}>
									<InfoField
										label={el.geo[0].substring(0, 2)}
										value={el.value + '%'}
										whiteSpace={'normal'}
									/>
								</Grid>
							)
						})}
					</Grid>
					<Grid
						item
						xs={4.8}
						container
						columnSpacing={0}
						rowSpacing={2}
						columns={12}
						direction='row'
					>
						<Grid item xs={6}>
							<InfoField
								label={'Engagement Rate'}
								value={capabilities.engagement_rate + '%'}
								whiteSpace={'normal'}
							/>
						</Grid>
						<Grid item xs={6}>
							<InfoField
								label={'Rate Per Video'}
								value={capabilities.rate_per_video + '%'}
								whiteSpace={'normal'}
							/>
						</Grid>
						<Grid item xs={12}>
							<InfoField
								label={'Vertical'}
								value={capabilities.vertical}
								whiteSpace={'normal'}
								isArray
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{ mt: -2 }}>
						<InfoField
							label={'Notes'}
							value={capabilities.notes}
							whiteSpace={'normal'}
						/>
					</Grid>
				</Grid>
			</Grid>
			<div
				style={{
					paddingBottom: '10px',
					borderBottom: '1px solid ' + theme.colors.base.grey300,
					width: '95%',
					marginBottom: 20,
				}}
			></div>
		</React.Fragment>
	)
}

export default CreatorPreviewPage
