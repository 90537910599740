import { ActionType } from '../action-types'
import { Action } from '../actions'
import { Alert } from '../../utils/notifications/AlertContainer'

interface AlertState {
	alerts: Alert[]
	show: boolean
}

const initialState: AlertState = {
	alerts: [],
	show: false,
}

const alertReducer = (
	state: AlertState = initialState,
	action: Action,
): AlertState => {
	switch (action.type) {
		case ActionType.ADD_ALERT: {
			while (state.alerts.length > 2) {
				state.alerts.shift()
			}
			return {
				...state,
				alerts: [...state.alerts, action.payload],
				show: true,
			}
		}
		case ActionType.REMOVE_ALERT: {
			const newState = state.alerts.filter(
				(alert) => alert.id !== action.payload,
			)

			return {
				...state,
				alerts: newState,
				show: newState.length > 0,
			}
		}
		case ActionType.MARK_ALERTS_AS_SEEN:
			return {
				...state,
				alerts: action.payload,
			}
		// case ActionType.OPEN:
		// 	return {
		// 		...state,
		// 		show: true,
		// 	}
		// case ActionType.CLOSE:
		// 	return {
		// 		...state,
		// 		show: false,
		// 	}
		default:
			return state
	}
}

export default alertReducer
