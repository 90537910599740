import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router'
import { useActions } from '../hooks/useActions'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { fetchData } from '../utils/helpers/navigationHelper'
import { CLIENT_VERSION } from '../config'
import moment from 'moment'
import { getVersionAction } from '../state/action-creators'
import { FirstLoading, Loading } from '../assets/svg/loading'

export const LoadingComponent = ({
	setLoadingFromComponent,
	loadingFromComponent,
}: {
	setLoadingFromComponent: any
	loadingFromComponent: boolean
}) => {
	const [isDownloading, setIsDownloading] = useState(false)
	const [firstDownloadDone, setFirstDownloadDone] = useState(false)
	const [progress, setProgress] = useState(0)

	const {
		usersAction,
		getAdvertiserAction,
		getPublisherAction,
		getCampaignAction,
		getAppsAction,
		logOutAction,
		getSettingsAction,
		getPasswordsAction,
		getAvatars,
		getDashboardAction,
		getCampaignHistoryAction,
		getLogsAction,
		getAccountsAction,
		getAppsIcons,
		getp360aggregated,
		getSalesPlanAppsAction,
		getPublisherWishList,
		getCreatorsAction,
		// getHandshakeAction,
		getLoggedInUser,
		fetchRoles,
		getPermissions,
		getLearningAction,
		// getCompanySettings,
	} = useActions()

	const {
		users,
		login,
		advertiser,
		publisher,
		campaign,
		app,
		logs,
		passwords,
		campaign_history,
		learning,
		accounts,
		dashboard,
		avatars,
		version,
		p360aggregated,
		salesplan,
		settings,
		wishlist,
		creator,
		// handshake,
		roles,
	} = useTypedSelector((state) => {
		return state
	})
	function updateProgress(completed: number, total: number) {
		const progressPercentage = (completed / total) * 100
		setProgress(progressPercentage)
	}
	useEffect(() => {
		const lastFetchTime = localStorage.getItem('lastFetchTime')
		const dateIsOneDayOld = moment().diff(moment(lastFetchTime), 'hours') >= 24
		// const dateIsOneDayOld = moment().diff(moment(lastFetchTime), 'seconds') >= 5
		if (!dateIsOneDayOld) {
			setFirstDownloadDone(true)
		}
	}, [])
	useEffect(() => {
		setIsDownloading(true)
		const checkVersion = async () => {
			// const fheaders = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			const result = await getVersionAction(CLIENT_VERSION)
			if (result !== undefined && result.data.successful !== true) {
				sessionStorage.clear()
				window.location.reload()
			}
		}
		const mapFetching: any = {
			first_download: [
				() => getLoggedInUser(),
				() => getSettingsAction(),
				() => usersAction(),
				() => getPermissions(),
				() => fetchData(usersAction, users.users),
				() => fetchData(getAdvertiserAction, advertiser.advertiser),
				() => fetchData(getPublisherAction, publisher.publisher),
				() => fetchData(getCreatorsAction, creator.creator),
				// () => fetchData(getHandshakeAction, handshake),
				() => fetchData(getCampaignAction, campaign.campaign),
				() =>
					fetchData(
						getCampaignHistoryAction,
						campaign_history.campaign_history,
					),
				() => fetchData(getAppsAction, app.app),
				() => fetchData(getLogsAction, logs.logs),
				() => fetchData(getPasswordsAction, passwords),
				() => fetchData(getAccountsAction, accounts),
				() => fetchData(getAvatars, avatars.avatars),
				() => fetchData(getPublisherWishList, wishlist),
				() => fetchData(getAppsIcons, app.appIcons),
				() => fetchData(fetchRoles, roles),
				// () => getCompanySettings(),
				() => getLearningAction(),
			],
			campaigns: [
				() => fetchData(getAppsIcons, app.appIcons),
				() => fetchData(getCampaignAction, campaign.campaign),
				() => fetchData(getAppsAction, app.app),
			],
			advertisers: [
				() => fetchData(getAdvertiserAction, advertiser.advertiser),
				() => fetchData(getAppsAction, app.app),
			],
			publishers: [
				() => fetchData(getPublisherAction, publisher.publisher),
				// () => fetchData(getHandshakeAction, handshake.handshake),
				() => fetchData(getCreatorsAction, creator.creator),
			],
			companysettings: [
				() => fetchData(getPublisherAction, publisher.publisher),
				() => fetchData(getAdvertiserAction, advertiser.advertiser),
				() => fetchData(getAppsAction, app.app),
				() => fetchData(getCreatorsAction, creator.creator),
			],
			users: [() => fetchData(usersAction, users.users), () => fetchRoles()],
			learning: [() => getLearningAction()],
			passwords: [() => fetchData(getPasswordsAction, passwords.passwords)],
			apps: [() => fetchData(getAppsAction, app.app)],
			settings: [
				() => getSettingsAction(),
				() => fetchRoles(),
				// () => getCompanySettings(),
			],
			logs: [() => fetchData(getLogsAction, logs.logs)],
			publisherwishlist: [() => fetchData(getPublisherWishList, wishlist)],
			appsflyeraccounts: [() => fetchData(getAccountsAction, accounts)],
		}
		const lastFetchTime = localStorage.getItem('lastFetchTime')
		const dateIsOneDayOld = moment().diff(moment(lastFetchTime), 'hours') >= 24
		// const dateIsOneDayOld = moment().diff(moment(lastFetchTime), 'seconds') >= 5
		if (dateIsOneDayOld) {
			mapFetching.first_download.push(
				() => fetchData(getp360aggregated, p360aggregated.P360),
				() => fetchData(getDashboardAction, dashboard),
				() => fetchData(getSalesPlanAppsAction, salesplan),
			)
		}

		const keyToDownload = convertPathToMapKey(window.location.pathname)
		const downloadData = async () => {
			if (keyToDownload !== '' && mapFetching[keyToDownload]) {
				if (keyToDownload === 'first_download') {
					const promises = mapFetching[keyToDownload].map((promiseFunc: any) =>
						promiseFunc(),
					)

					if (keyToDownload === 'first_download') {
						let completed = 0
						const total = promises.length
						updateProgress(completed, total)
						promises.forEach((promise: any) => {
							promise.then(() => {
								completed++
								updateProgress(completed, total)
							})
						})
					}
				}
				// console.log(mapFetching[keyToDownload])
				await Promise.all(
					mapFetching[keyToDownload].map((promiseFunc: any) => promiseFunc()),
				)
					.then(() => {
						// setProgress(100)
						// setFirstLoading(false)
						// console.log(mapFetching[keyToDownload])
						setIsDownloading(false)
						if (keyToDownload === 'first_download') {
							localStorage.setItem('lastFetchTime', moment().toISOString())
							setProgress(100)
							setFirstDownloadDone(true)
						}
					})
					.catch((error) => {
						console.error(
							'Error with',
							keyToDownload,
							mapFetching[keyToDownload],
						)
						console.error('Error during fetching data: ', error)
						setIsDownloading(false)
					})
			} else {
				setIsDownloading(false)
			}
		}
		checkVersion()
		downloadData()
	}, [window.location.pathname])

	return firstDownloadDone ? (
		<>
			{(isDownloading || loadingFromComponent) && <Loading loading={true} />}
			<Outlet />
		</>
	) : (
		<FirstLoading progress={progress} />
	)
}

const convertPathToMapKey = (string: string) => {
	// console.log(string)
	let result = ''

	const mappings = {
		'/news': 'first_download',
		'/campaigns': 'campaigns',
		'/advertisers': 'advertisers',
		'/users': 'users',
		'/publishers': 'publishers',
		'/creators': 'publishers',
		'/passwords': 'passwords',
		'/documents': 'learning',
		'/settings': 'settings',
		'/apps': 'apps',
		'/logs': 'logs',
		'/publisherwishlist': 'publisherwishlist',
		'/appsflyeraccounts': 'appsflyeraccounts',
		'/companySettings': 'companysettings',
	}

	for (const key in mappings) {
		if (string.startsWith(key)) {
			result = mappings[key as keyof typeof mappings]
			break
		}
	}
	// console.log('result = ', result)
	return result
}
