import React, { useState } from 'react'
import { InputFieldText } from '../../components/Inputs'
import { Grid, useTheme } from '@mui/material'
import { DropList } from '../../components/SelectableInputs'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { SubmitButton, UnborderedButton } from '../../components/Buttons'
import moment from 'moment'
import { useActions } from '../../../hooks/useActions'

const AddCampaign = (props: { closeFunc: any; setErrorMessage: any }) => {
	const theme = useTheme()
	const { createCreatorFinanceRow } = useActions()
	const { advertiser, login } = useTypedSelector((state) => state)
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const [campaignName, setCampaignName] = useState<undefined | string>(
		undefined,
	)
	const [advertiserName, setAdvertiserName] = useState<undefined | string>(
		undefined,
	)
	const [revenue, setRevenue] = useState<undefined | number>(undefined)
	const [platform, setPlatform] = useState<string>('')
	const [month, setMonth] = useState<string>('')
	const [year, setYear] = useState<string>('')

	const advertiserList: any = advertiser.advertiser
		.sort((adv1: any, adv2: any) =>
			adv1.advertiser_name.localeCompare(adv2.advertiser_name),
		)
		.map((adv: any) => adv.advertiser_name)

	const createRow = async () => {
		const period = moment(month + '/' + year, 'MMMM/YYYY').format('01/MM/YYYY')
		const obj = {
			campaign: campaignName,
			advertiser: advertiserName,
			revenue,
			platform: platform,
			month: period,
		}
		const failed = await createCreatorFinanceRow(
			obj,
			login.user.email,
			props.setErrorMessage,
		)
		if (!failed) {
			props.closeFunc()
		}
	}
	const getYearsArray = () => {
		const startYear = 2024
		const currentYear = moment().year()
		const yearsArray = []

		for (let year = startYear; year <= currentYear; year++) {
			yearsArray.push(year.toString())
		}
		return yearsArray as string[]
	}

	return (
		<div
			style={{
				display: 'flex',
				width: '70%',
				height: '30%',
				position: 'absolute',
				background: theme.colors.base.white,
				zIndex: 21,
				top: '20%',
				left: '12%',
				border: '1px solid ' + theme.colors.base.grey300,
				borderRadius: '6px',
				padding: '60px 40px',
				flexDirection: 'column',
			}}
		>
			<button
				onClick={() => props.closeFunc()}
				style={{
					color: 'black',
					border: 'none',
					width: '2%',
					height: '2%',
					right: '30px',
					top: '12px',
					display: 'flex',
					position: 'absolute',
					fontSize: theme.font.size.body,
					cursor: 'pointer',
				}}
			>
				X
			</button>
			<Grid
				container
				columns={12}
				columnSpacing={6}
				rowSpacing={4}
				direction='row'
				style={{ width: '98%' }}
			>
				<Grid item xs={6}>
					<InputFieldText
						label='Campaign Name'
						type='text'
						value={campaignName}
						onChange={setCampaignName}
						errormessage={!campaignName ? 'Campaign is mandatory' : undefined}
					/>
				</Grid>
				<Grid item xs={3}>
					<DropList
						label='Platform'
						options={['IM', 'UGC']}
						value={platform}
						onChange={(value: any) => {
							setPlatform(value)
						}}
						errormessage={!platform ? 'Platform is mandatory' : undefined}
					/>
				</Grid>
				<Grid item xs={3}>
					<DropList
						label='Advertiser'
						options={advertiserList || []}
						value={advertiserName}
						onChange={(value: any) => {
							setAdvertiserName(value)
						}}
						errormessage={
							!advertiserName ? 'Advertiser is mandatory' : undefined
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<InputFieldText
						label='Total Revenue'
						type='number'
						value={revenue}
						onChange={setRevenue}
						errormessage={!revenue ? 'Total Revenue is mandatory' : undefined}
					/>
				</Grid>
				<Grid item xs={2}>
					<DropList
						label='Month'
						options={[
							'January',
							'February',
							'March',
							'April',
							'May',
							'June',
							'July',
							'August',
							'September',
							'October',
							'November',
							'December',
						]}
						value={month}
						onChange={(value: any) => {
							setMonth(value)
						}}
						errormessage={!month ? 'Month is mandatory' : undefined}
					/>
				</Grid>
				<Grid item xs={2}>
					<DropList
						label='Year'
						options={getYearsArray()}
						value={year}
						onChange={(value: any) => {
							setYear(value)
						}}
						errormessage={!year ? 'Year is mandatory' : undefined}
					/>
				</Grid>
			</Grid>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexGrow: 1,
					alignItems: 'flex-end',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						height: '30px',
						gap: 60,
					}}
				>
					<UnborderedButton onClick={() => props.closeFunc()}>
						Cancel
					</UnborderedButton>
					<SubmitButton
						onClick={() => createRow()}
						disabled={
							!campaignName || !advertiserName || !revenue || !year || !month
						}
					>
						Create
					</SubmitButton>
				</div>
			</div>
		</div>
	)
}

export default AddCampaign
