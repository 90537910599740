import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Grid, useTheme } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageWrapper } from '../components/reusableComponents'
import ReactCountryFlag from 'react-country-flag'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Public } from '@mui/icons-material'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LanguageIcon from '@mui/icons-material/Language'
import { ReactComponent as DriveIcon } from '../../assets/svg/driveIcon.svg'
import { Property } from 'csstype'
import {
	ContactField,
	ExpandableInfoField,
	TitleComponent,
	PasswordField,
} from '../components/Informative'
import {
	canAccessResource,
	decryptContacts,
	formatDisplayValue,
	getApplicableRules,
} from '../../utils/helpers/helperFuncs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { StatusTitleTag, TitleTag } from '../components/Informative'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ReactComponent as MedalIcon } from '../../assets/svg/medal-icon.svg'
import { ReactComponent as GraphBarsIcon } from '../../assets/svg/graph-bars-icon.svg'
import { ReactComponent as LockerIcon } from '../../assets/svg/locker-icon.svg'
import { ReactComponent as CartIcon } from '../../assets/svg/cart-icon.svg'
import { CompanyInfoComponent } from '../advertisers/AdvertiserCompanyInfoComponent'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import defaultIcon from '../../assets/default-icon.webp'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { ContactInterface } from '../components/ContactComponent'

//we use this component to render the labels for every info.
const InfoField = (props: {
	label: string
	value: string | string[]
	isArray?: boolean
	isCountry?: boolean
	whiteSpace?: Property.WhiteSpace
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				gap: '6px',
				fontWeight: theme.font.weight.normal,
				fontSize: theme.font.size.body,
				whiteSpace: props.whiteSpace ? props.whiteSpace : 'nowrap',
				alignItems: props.isCountry ? 'center' : 'flex-start',
				flexWrap: 'wrap',
				flexDirection: props.isArray ? 'column' : 'row',
			}}
		>
			<span style={{ color: theme.colors.base.grey600 }}>{props.label}:</span>
			{props.isCountry ? (
				Array.isArray(props.value) ? (
					props.value.map((country: string) => {
						return (
							<>
								<ReactCountryFlag
									style={{
										height: '9px',
										borderRadius: '100px',
										border: `1px solid ${theme.colors.base.grey900}`,
									}}
									key={'flags'}
									className='roundedFlag'
									countryCode={country === 'UK' ? 'GB' : country}
									svg
								/>
								<span style={{ fontWeight: '600', overflowWrap: 'anywhere' }}>
									{country}
								</span>
							</>
						)
					})
				) : (
					<>
						<ReactCountryFlag
							style={{
								height: '9px',
								borderRadius: '100px',
								border: `1px solid ${theme.colors.base.grey900}`,
							}}
							key={'flags'}
							className='roundedFlag'
							countryCode={props.value === 'UK' ? 'GB' : props.value}
							svg
						/>
						<span style={{ fontWeight: '600', overflowWrap: 'anywhere' }}>
							{props.value}
						</span>
					</>
				)
			) : props.isArray && props.value ? (
				props.value.length > 0 && (
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							width: '94%',
							maxHeight: '11rem',
							overflow: 'auto',
							gap: '4px',
							border: '1px solid ' + theme.colors.base.grey200,
							borderRadius: '10px',
							padding: '1rem',
							// marginInlineStart: '-1rem',
						}}
					>
						{(props.value as string[]).map((element: string) => {
							return (
								<span
									key={element + 'key'}
									style={{
										padding: '6px',
										color: theme.colors.base.grey900,
										border: '1px solid ' + theme.colors.base.grey300,
										// background: theme.colors.base.green200,
										borderRadius: '10px',
										fontSize: theme.font.size.body,
										gridColumnStart: element.length < 16 ? 'span 1' : 'span 2',
										whiteSpace: 'nowrap',
										textAlign: 'center',
										fontWeight: '500',
									}}
								>
									{element}
								</span>
							)
						})}
					</div>
				)
			) : (
				<span
					style={{
						fontWeight: theme.font.weight.normal,
						overflowWrap: 'anywhere',
					}}
				>
					{props.value}
				</span>
			)}
		</div>
	)
}
//it works the same way but for contacts.
// const ContactField = (props: { contact: any }) => {
// 	return (
// 		<div
// 			style={{
// 				display: 'flex',
// 				flexDirection: 'row',
// 				fontWeight: theme.font.weight.bold,
// 				padding: '4px 8px 4px 50px',
// 				fontSize: theme.font.size.body,
// 				justifyContent: 'flex-start',
// 				borderRadius: '12px',
// 				background: theme.colors.base.grey100,
// 			}}
// 		>
// 			<div
// 				style={{
// 					whiteSpace: 'nowrap',
// 					display: 'inherit',
// 					gap: '6px',
// 					width: '14%',
// 					fontSize: '12px',
// 				}}
// 			>
// 				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Name: </span>
// 				<span>{props.contact.name}</span>
// 			</div>
// 			<div
// 				style={{
// 					whiteSpace: 'nowrap',
// 					display: 'inherit',
// 					gap: '6px',
// 					width: '25%',
// 				}}
// 			>
// 				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Email: </span>
// 				<span>{props.contact.email}</span>
// 			</div>
// 			<div
// 				style={{
// 					whiteSpace: 'nowrap',
// 					display: 'inherit',
// 					gap: '6px',
// 					width: '18%',
// 				}}
// 			>
// 				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Role: </span>
// 				<span>{props.contact.role}</span>
// 			</div>
// 			<div
// 				style={{
// 					whiteSpace: 'nowrap',
// 					display: 'inherit',
// 					gap: '6px',
// 					width: '25%',
// 				}}
// 			>
// 				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Skype: </span>
// 				<span>{props.contact.skype}</span>
// 			</div>
// 			<div
// 				style={{
// 					display: 'flex',
// 					fontWeight: '500',
// 					whiteSpace: 'nowrap',
// 					alignItems: 'center',
// 					justifyContent: 'flex-end',
// 					width: '18%',
// 				}}
// 			>
// 				<div
// 					style={{
// 						display: 'inherit',
// 						alignItems: 'flex-end',
// 						width: '80%',
// 						gap: '6px',
// 					}}
// 				>
// 					<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Geo: </span>
// 					<span
// 						style={{
// 							display: 'flex',
// 							gap: '6px',
// 							fontWeight: '500',
// 							whiteSpace: 'nowrap',
// 							alignItems: 'center',
// 						}}
// 					>
// 						{props.contact.geo.length > 0 ? (
// 							<ReactCountryFlag
// 								style={{
// 									height: '10px',
// 									width: '14px',
// 									borderRadius: '100px',
// 									border: `1px solid ${theme.colors.black}`,
// 								}}
// 								key={'flags'}
// 								className='roundedFlag'
// 								countryCode={
// 									props.contact.geo[0] === 'UK'
// 										? 'GB'
// 										: (props.contact.geo[0] as string)
// 								}
// 								svg
// 							/>
// 						) : (
// 							<Public style={{ width: '16px', height: '14px' }} />
// 						)}
// 						{props.contact.geo.length > 0 ? props.contact.geo[0] : 'GLOBAL'}{' '}
// 					</span>
// 				</div>
// 			</div>
// 		</div>
// 	)
// }
//this is the container that has all the infolabels.

interface Password {
	password: string
	username: string
	url: string
}
const InformationContainer = (props: {
	publisher: any
	closeDetailedView: any
	openEdit: any
	skypeGroupUserAllowed: boolean
	permissionContacts: boolean
	permissionsGeneral: any
	PopUpVisible: any
	setPopUpVisible: any
	setIsSuccessPromptOpened: any
	setSuccessMessage: any
	contacts: any
}) => {
	const theme = useTheme()
	const { users, login, settings } = useTypedSelector((state) => state)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_PUBLISHER),
		viewPublisherContact: useIsAuthorized(
			permissionNames.VIEW_PUBLISHER_CONTACTS,
		),
		viewPasswords: useIsAuthorized(permissionNames.VIEW_PUBLISHER_PASSWORDS),
	}
	const [filteredPids, setFilteredPids] = useState<any>([])
	const [dummyPids, setDummyPids] = useState<any>([])
	const [passwords, setPasswords] = useState<Password[]>(
		props.publisher.passwords || [],
	)
	const canView = canAccessResource(login.user, props.publisher.email, users)
	useEffect(() => {
		const arr = []
		const dummies = []
		if (props.publisher.external_id) {
			for (const pid of props.publisher.media_source_pid) {
				if (!pid.endsWith(props.publisher.external_id)) {
					arr.push(pid)
				} else {
					dummies.push(pid)
				}
			}
		}
		setFilteredPids(arr)
		setDummyPids(dummies)
	}, [props.publisher])

	const handleClickedLink = (link: string) => {
		if (!link.startsWith('http')) {
			return window.open('http://' + link)
		}
		return window.open(link)
	}
	const financialContacts: any = []
	const mainContacts: any = []
	props.permissionContacts &&
		props.contacts &&
		props.contacts.forEach((contact: any) => {
			if (contact.type === 'finance') {
				financialContacts.push(contact)
			} else {
				mainContacts.push(contact)
			}
		})
	const badges = props.publisher.media_buying_capabilities?.badges
	const updatePasswords = (oldPassword: string, newPassword: string) => {
		const updatedPasswords = passwords.map((pwd) =>
			pwd.password === oldPassword ? { ...pwd, password: newPassword } : pwd,
		)
		setPasswords(updatedPasswords)
	}

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
				<div style={{ display: 'flex', alignItems: 'center', width: '85%' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}
					>
						<UnborderedButton
							style={{
								width: '80px',
								display: 'flex',
								justifyContent: 'flex-start',
								color: theme.colors.text.titles,
							}}
							onClick={props.closeDetailedView}
							icon={<ArrowBackIosIcon style={{ height: '16px' }} />}
						>
							Back
						</UnborderedButton>
					</div>
					{permissions.edit && (
						<UnborderedButton
							style={{
								display: 'flex',
								marginLeft: 'auto',
								color: theme.colors.text.titles,
							}}
							onClick={props.openEdit}
							icon={<EditIcon />}
						>
							Edit
						</UnborderedButton>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
						paddingRight: '8rem',
					}}
				>
					<CompanyInformationHeader
						name={props.publisher.publisher_name}
						iconUrl={props.publisher.publisher_icon || defaultIcon}
						style={{
							gap: '.6rem',
						}}
					/>
					<StatusTitleTag status={props.publisher.status} />
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '10px',
						}}
					>
						{props.publisher.link_to_website && (
							<button
								style={{
									display: 'flex',
									alignItems: 'center',
									padding: '0px',
									border: 'none',
									cursor: 'pointer',
									color: theme.colors.text.titles,
								}}
								onClick={() =>
									handleClickedLink(props.publisher.link_to_website)
								}
							>
								<LanguageIcon />
							</button>
						)}
						{props.publisher.link_to_linkedin && (
							<button
								style={{
									display: 'flex',
									alignItems: 'center',
									padding: '0px',
									border: 'none',
									cursor: 'pointer',
									color: theme.colors.text.titles,
								}}
								onClick={() =>
									handleClickedLink(props.publisher.link_to_linkedin)
								}
							>
								<LinkedInIcon />
							</button>
						)}
						{props.permissionsGeneral.viewLinks &&
							props.publisher.link_to_io && (
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										padding: '0px',
										border: 'none',
										cursor: 'pointer',
										color: theme.colors.text.titles,
									}}
									onClick={() => handleClickedLink(props.publisher.link_to_io)}
								>
									<FolderOpenIcon />
								</button>
							)}
						{props.permissionsGeneral.viewLinks &&
							props.publisher.link_to_pub && (
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										padding: '0px',
										border: 'none',
										cursor: 'pointer',
										color: theme.colors.text.titles,
									}}
									onClick={() => handleClickedLink(props.publisher.link_to_pub)}
								>
									<DriveIcon />
								</button>
							)}
					</div>
				</div>
				<Grid
					container
					columns={12}
					columnSpacing={2}
					rowSpacing={2}
					direction='row'
					style={{ marginTop: 0, fontSize: theme.font.size.body }}
				>
					{props.publisher.status === false && (
						<>
							<Grid item xs={3}>
								<InfoField
									label={'Pause Reason'}
									value={props.publisher.pause_reason}
								/>
							</Grid>
							<Grid item xs={9}>
								<InfoField
									label={'Pause Explanation'}
									value={props.publisher.pause_explanation}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={3}>
						<InfoField
							label={'Legal Name'}
							value={props.publisher?.legal_name}
							whiteSpace={
								props.publisher.legal_name &&
								props.publisher.legal_name.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Address'}
							value={props.publisher?.address}
							whiteSpace={'normal'}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Sales Representative'}
							value={formatDisplayValue(props.publisher?.sales_rep)}
						/>
					</Grid>
					<Grid item xs={3}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								gap: 4,
								position: 'relative',
								// marginLeft: 8,
							}}
						>
							<span style={{ color: theme.colors.base.grey600 }}>
								{'Badges'}:
							</span>
							<div
								style={{
									display: 'flex',
									gap: 6,
									marginTop: -5,
									position: 'absolute',
									left: 50,
								}}
							>
								<MedalIcon
									style={{
										color: badges?.good_quality
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '0.85',
										marginTop: 2,
									}}
								/>
								<LockerIcon
									style={{
										color: badges?.low_fraud
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '0.8',
									}}
								/>
								<GraphBarsIcon
									style={{
										color: badges?.high_volume
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '0.8',
										marginTop: -11,
										marginLeft: -10,
									}}
								/>
								<CartIcon
									style={{
										color: badges?.high_cr
											? theme.colors.status.green
											: theme.colors.base.grey300,
										scale: '0.8',
										marginTop: 5,
										marginLeft: -12,
									}}
								/>
							</div>
						</div>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Head Office Geo'}
							value={props.publisher?.geo}
							isCountry
						/>
					</Grid>
					<Grid item xs={3}>
						{props.permissionsGeneral.viewLinks && (
							<InfoField
								label={'Skype Group'}
								value={
									props.publisher?.public_skype === true ? 'Open' : 'Private'
								}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						{props.permissionsGeneral.viewLinks && (
							<InfoField
								label={'Skype Group Link'}
								value={
									props.publisher.public_skype
										? props.publisher?.skype_group
										: props?.skypeGroupUserAllowed
										? props.publisher?.skype_group
										: '******'
								}
								whiteSpace={
									props.publisher?.skype_group &&
									props.publisher.skype_group?.length > 20
										? 'normal'
										: 'nowrap'
								}
							/>
						)}
					</Grid>
					{/* empty place for styling*/}
					<Grid item xs={3}>
						<InfoField label={'Owner'} value={props.publisher?.email} />
					</Grid>
					<Grid item xs={12}>
						<InfoField
							label={'External ID'}
							value={props.publisher?.external_id}
						/>
					</Grid>
					<Grid item xs={12}></Grid>

					<Grid item xs={3}>
						<InfoField
							label={'Finance ID'}
							value={formatDisplayValue(props.publisher?.finance_id)}
						/>
					</Grid>

					<Grid item xs={3}>
						<InfoField
							label={'Payment Term'}
							value={props.publisher?.payment_term}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField label={'Vat'} value={props.publisher?.vat} />
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Internal Legal Entity'}
							value={props.publisher?.branch}
						/>
					</Grid>
					{/* a place saved for 'Certifications' */}
					<Grid item xs={6}>
						<TitleComponent
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.base.grey600,
								fontWeight: theme.font.weight.bold,
							}}
						>
							Brief:
						</TitleComponent>
						<div
							style={{
								border: '1px solid ' + theme.colors.base.grey200,
								padding: '4px 14px 4px 14px',
								fontSize: theme.font.size.body,
								borderRadius: '8px',
								width: '78.5%',
								minHeight: '8vh',
								lineHeight: '1.4',
								whiteSpace: 'pre-wrap',
								color: theme.colors.base.grey900,
							}}
						>
							<p>
								{props.publisher.notes
									? props.publisher.notes
									: 'No brief at the moment'}
							</p>
						</div>
					</Grid>
					<Grid item xs={6}>
						<TitleComponent
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.base.grey600,
								fontWeight: theme.font.weight.bold,
							}}
						>
							Special Request:
						</TitleComponent>
						<div
							style={{
								border: '1px solid ' + theme.colors.base.grey200,
								padding: '4px 14px 4px 14px',
								fontSize: theme.font.size.body,
								borderRadius: '8px',
								width: '78.5%',
								lineHeight: '1.5',
								minHeight: '8vh',
								whiteSpace: 'pre-wrap',
								color: theme.colors.base.grey900,
							}}
						>
							<p>
								{props.publisher.media_buying_capabilities?.special_request_list
									? props.publisher.media_buying_capabilities
											.special_request_list
									: 'No Special Request at the moment'}
							</p>
						</div>
					</Grid>
					<Grid item xs={11.4}>
						<InfoField
							label={"PID's"}
							value={
								filteredPids.length > 0
									? filteredPids
									: props.publisher?.media_source_pid
							}
							isArray={true}
						/>
					</Grid>

					{dummyPids.length > 0 && (
						<Grid item xs={12}>
							<ExpandableInfoField label={'Dummy PIDs'} value={dummyPids} />
						</Grid>
					)}

					{permissions.viewPasswords && passwords.length > 0 && (
						<Grid item xs={11}>
							<TitleComponent
								style={{
									fontSize: theme.font.size.body,
									alignSelf: 'flex-start',
								}}
							>
								Passwords
							</TitleComponent>
							<div
								style={{
									display: 'flex',
									gap: '12px',
									flexDirection: 'column',
									padding: '12px 0px 12px 0px',
									width: '100%',
								}}
							>
								{passwords.map((passwordItem: Password) => {
									return (
										<PasswordField
											popUpVisible={props.PopUpVisible}
											setPopUpVisible={props.setPopUpVisible}
											password={{
												password: passwordItem.password,
												username: passwordItem.username,
												url: passwordItem.url,
											}}
											updatePasswords={updatePasswords}
											ownerEmail={props.publisher.email}
											setSuccessMessage={props.setSuccessMessage}
											setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
										/>
									)
								})}
							</div>
						</Grid>
					)}

					{mainContacts.length > 0 &&
						(canView || permissions.viewPublisherContact) && (
							<Grid item xs={11}>
								<TitleComponent
									style={{
										fontSize: theme.font.size.body,
										alignSelf: 'flex-start',
									}}
								>
									Contacts
								</TitleComponent>
								<div
									style={{
										display: 'flex',
										gap: '12px',
										flexDirection: 'column',
										padding: '12px 0px 12px 0px',
										width: '100%',
									}}
								>
									{mainContacts.map((contact: any) => {
										return (
											<ContactField
												contact={contact}
												user={login.user}
												ownerEmail={props.publisher.email}
												users={users}
												key={contact.name}
											></ContactField>
										)
									})}
								</div>
							</Grid>
						)}
					{financialContacts.length > 0 &&
						(canView || permissions.viewPublisherContact) && (
							<Grid item xs={11}>
								<TitleComponent
									style={{
										fontSize: theme.font.size.body,
										alignSelf: 'flex-start',
									}}
								>
									Finance
								</TitleComponent>
								<div
									style={{
										display: 'flex',
										gap: '12px',
										flexDirection: 'column',
										padding: '12px 0px 12px 0px',
										width: '100%',
									}}
								>
									{financialContacts.map((contact: any) => {
										return (
											<ContactField
												contact={contact}
												user={login.user}
												ownerEmail={props.publisher.email}
												users={users}
												key={contact.name}
											></ContactField>
										)
									})}
								</div>
							</Grid>
						)}
				</Grid>
			</div>
		</>
	)
}
//this is the capability container. We use it to show the capabilities.
const CapabilityContainer = (props: {
	value: any
	label: string
	isTrafficIndicator?: boolean
}) => {
	const theme = useTheme()
	const calculateAmountOfRows = (values: any[]) => {
		let result = 0
		if (!values || values.length === 0) {
			return result
		}
		for (const element of values) {
			//since inside the container, if a value is bigger than 12 length it's going to use 2 grid columns we use this to know the final amount of rows.
			if (element.value.length > 12) {
				result += 2
			} else {
				result++
			}
		}
		return Math.ceil(result / 3)
	}
	//we assume that in 1 row-grid we can have at max something between 3 and 4 rows of values:
	const gridMaxAmountOfRows = 4
	const gridRowHeightFrames = Math.ceil(
		calculateAmountOfRows(props.value) / gridMaxAmountOfRows,
	)
	return (
		props.value && (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
				<span
					style={{
						fontSize: theme.font.size.body,
						fontWeight: theme.font.weight.normal,
						color: theme.colors.base.grey900,
						paddingInlineStart: '1rem',
					}}
				>
					{props.label}
				</span>
				<div
					style={{
						gridRowStart: `span ${gridRowHeightFrames}`,
						// border: '1px solid rgb(141,22,108)',
						border: '1px solid ' + theme.colors.base.grey200,
						borderRadius: '20px',
						padding: '20px 20px 30px 20px',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
						height: 'calc(100% - 50px)',
					}}
				>
					<div
						style={{
							display: 'grid',
							gap: '12px',
							gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
							gridAutoFlow: 'dense',
						}}
					>
						{props.value.map((element: any) => {
							if (props.isTrafficIndicator) {
								if (element.value.includes('CR')) {
									return (
										<div
											style={{
												// border: '1px solid ' + theme.colors.lightGray,
												//background: theme.colors.lightGray,
												color: theme.colors.text.titles,
												border: '2px solid ' + theme.colors.status.green,
												// border: '1px solid ' + 'rgb(175,24,98)',
												padding: '6px',
												borderRadius: '10px',
												whiteSpace: 'nowrap',
												textAlign: 'center',
												fontWeight: '500',
												fontSize: theme.font.size.caption,
												gridColumnStart: 'span 2',
											}}
											key={element.value}
										>
											CR
											<span
												style={{
													color: theme.colors.base.white,
													background: theme.colors.status.green,
													padding: '2px 4px 2px 4px',
													borderRadius: '4px',
													whiteSpace: 'nowrap',
													textAlign: 'center',
													fontWeight: '500',
													fontSize: '9px',
													marginLeft: '8px',
												}}
											>
												{element.value}
											</span>
										</div>
									)
								} else {
									return (
										<span
											style={{
												// border: '1px solid ' + theme.colors.lightGray,
												//background: theme.colors.lightGray,
												color: theme.colors.text.titles,
												border: '2px solid ' + theme.colors.status.green,

												// border: '1px solid ' + 'rgb(175,24,98)',
												padding: '6px',
												borderRadius: '10px',
												whiteSpace: 'nowrap',
												textAlign: 'center',
												fontWeight: '500',
												fontSize: theme.font.size.caption,
												gridColumnStart: 'span 2',
											}}
											key={element.value}
										>
											Quality
											<span
												style={{
													color: theme.colors.base.white,
													background: theme.colors.status.green,
													padding: '2px 4px 2px 4px',
													borderRadius: '4px',
													whiteSpace: 'nowrap',
													textAlign: 'center',
													fontWeight: '500',
													fontSize: '9px',
													marginLeft: '8px',
													// color: theme.colors.black,
												}}
											>
												{element.value}
											</span>
										</span>
									)
								}
							} else
								return (
									<span
										style={{
											// border: '1px solid ' + theme.colors.lightGray,
											//background: theme.colors.lightGray,
											color: theme.colors.text.titles,
											border: '2px solid ' + theme.colors.status.green,
											// border: '1px solid ' + 'rgb(175,24,98)',
											padding: '6px',
											borderRadius: '10px',
											whiteSpace: 'nowrap',
											textAlign: 'center',
											fontWeight: '500',
											fontSize: theme.font.size.caption,
											gridColumnStart:
												element.value.length < 12 ? 'span 1' : 'span 2',
										}}
										key={element.value}
									>
										{element.value}
									</span>
								)
						})}
					</div>
				</div>
			</div>
		)
	)
}
//mostly the same. The only difference is that this one is not a checkbox but it also has a value on it that we want to render.
const ValuedCapabilityContainer = (props: { value: any; label: string }) => {
	const theme = useTheme()
	// console.log(props)
	const calculateAmountOfRows = (values: any[]) => {
		let result = 0
		if (!values || values.length === 0) {
			return result
		}
		for (const element of values) {
			//since inside the container, if a value is bigger than 12 length it's going to use 2 grid columns we use this to know the final amount of rows.
			if (element.value.length > 12) {
				result += 2
			} else {
				result++
			}
		}
		return Math.ceil(result / 4)
	}
	//we assume that in 1 row-grid we can have at max something between 3 and 4 rows of values:
	const gridMaxAmountOfRows = 4
	const gridRowHeightFrames = Math.ceil(
		calculateAmountOfRows(props.value) / gridMaxAmountOfRows,
	)
	return (
		props.value && (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
				<span
					style={{
						fontSize: theme.font.size.body,
						fontWeight: theme.font.weight.normal,
						color: theme.colors.text.titles,
						paddingInlineStart: '1rem',
					}}
				>
					{props.label}
				</span>
				<div
					style={{
						gridRowStart: `span ${gridRowHeightFrames}`,
						// border: '1px solid rgb(141,22,108)',
						border: '1px solid ' + theme.colors.base.grey200,
						borderRadius: '20px',
						padding: '20px 20px 30px 20px',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
						height: 'calc(100% - 50px)',
					}}
				>
					<div
						style={{
							display: 'grid',
							gap: '12px',
							gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
							gridAutoFlow: 'dense',
						}}
					>
						{props.value.map((element: any) => {
							return (
								<span
									style={{
										// border: '1px solid ' + theme.colors.lightGray,
										//background: theme.colors.lightGray,
										color: theme.colors.text.titles,
										border: '2px solid ' + theme.colors.status.green,
										// border: '1px solid ' + 'rgb(175,24,98)',
										padding: '6px',
										borderRadius: '10px',
										whiteSpace: 'nowrap',
										textAlign: 'center',
										fontWeight: '500',
										fontSize: theme.font.size.caption,
										gridColumnStart: 'span 2',
									}}
									key={element.value}
								>
									{element.value}
									{element.inputValue && (
										<span
											style={{
												color: theme.colors.base.white,
												background: theme.colors.status.green,
												padding: '2px 4px 2px 4px',
												borderRadius: '4px',
												whiteSpace: 'nowrap',
												textAlign: 'center',
												fontWeight: '500',
												fontSize: '9px',
												marginLeft: '8px',
											}}
										>
											{element.inputValue}
										</span>
									)}
								</span>
							)
						})}
					</div>
				</div>
			</div>
		)
	)
}
//same logic but for countries.
const CountryCapabilityContainer = (props: { value: any; label: string }) => {
	const theme = useTheme()
	// console.log(props)
	const globalSelected = props.value.length >= 244
	const calculateAmountOfRows = (values: any[]) => {
		let result = 0
		if (!values || values.length === 0) {
			return result
		}
		for (const element of values) {
			//since inside the container, if a value is bigger than 12 length it's going to use 2 grid columns we use this to know the final amount of rows.
			if (element.value.length > 24) {
				result += 4
			} else if (element.volume && element.payout) {
				result += 3
			} else if (element.value.length > 12) {
				result += 2
			} else {
				result++
			}
		}
		return Math.ceil(result / 4)
	}
	const calculateGridLength = (value: any) => {
		let result = 'span 1'
		if (value.value.length > 12) {
			result = 'span 2'
		}
		if (value.payout && value.volume) {
			result = 'span 3'
		}
		if (value.value.length > 24) {
			result = 'span 4'
		}
		return result
	}
	//we assume that in 1 row-grid we can have at max something between 3 and 4 rows of values:
	const gridMaxAmountOfRows = 4.9
	const gridRowHeightFrames = globalSelected
		? 1
		: Math.ceil(calculateAmountOfRows(props.value) / gridMaxAmountOfRows)
	return (
		props.value && (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
				<span
					style={{
						fontSize: theme.font.size.body,
						fontWeight: theme.font.weight.normal,
						color: theme.colors.base.grey900,
						paddingInlineStart: '1rem',
					}}
				>
					{props.label}
				</span>
				<div
					style={{
						gridRowStart: `span ${gridRowHeightFrames}`,
						// border: '1px solid rgb(141,22,108)',
						border: '1px solid ' + theme.colors.base.grey200,
						borderRadius: '20px',
						padding: '20px 20px 30px 20px',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
						height: 'calc(100% - 50px)',
					}}
				>
					<div
						style={{
							display: 'grid',
							gap: '12px',
							gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
							gridAutoFlow: 'dense',
						}}
					>
						{globalSelected ? (
							<span
								style={{
									// border: '1px solid ' + theme.colors.lightGray,
									//background: theme.colors.lightGray,
									color: theme.colors.text.titles,
									border: '2px solid ' + theme.colors.status.green,
									// border: '1px solid ' + 'rgb(175,24,98)',
									padding: '6px',
									borderRadius: '10px',
									whiteSpace: 'nowrap',
									textAlign: 'center',
									fontWeight: '500',
									fontSize: theme.font.size.caption,
									gridColumnStart: 'span 1',
								}}
								key={'Global'}
							>
								{'Global'}
							</span>
						) : (
							props.value.map((element: any) => {
								return (
									<span
										style={{
											// border: '1px solid ' + theme.colors.lightGray,
											//background: theme.colors.lightGray,
											color: theme.colors.text.titles,
											border: '2px solid ' + theme.colors.status.green,
											// border: '1px solid ' + 'rgb(175,24,98)',
											flexWrap: 'wrap',
											padding: '6px',
											borderRadius: '10px',
											// whiteSpace: 'nowrap',
											textAlign: 'center',
											fontWeight: '500',
											fontSize: theme.font.size.caption,
											gridColumnStart: calculateGridLength(element),
										}}
										key={element.value}
									>
										{element.value}
										{element.volume && element.volume !== 'N/A' && (
											<span
												style={{
													color: theme.colors.base.white,
													background: theme.colors.status.green,
													padding: '2px 4px 2px 4px',
													borderRadius: '4px',
													whiteSpace: 'nowrap',
													textAlign: 'center',
													fontWeight: '500',
													fontSize: '9px',
													marginLeft: '8px',
												}}
											>
												{element.volume}
											</span>
										)}
										{element.payout && (
											<span
												style={{
													color: theme.colors.base.white,
													background: theme.colors.status.green,
													padding: '2px 4px 2px 4px',
													borderRadius: '4px',
													whiteSpace: 'nowrap',
													textAlign: 'center',
													fontWeight: '500',
													fontSize: '9px',
													marginLeft: '8px',
												}}
											>
												{element.payout + ' $'}
											</span>
										)}
									</span>
								)
							})
						)}
					</div>
				</div>
			</div>
		)
	)
}
//the container for every capability. We have one for each posible capability group.
const CapabilitiesInfoContainer = (props: { capabilities: any }) => {
	return (
		<div>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr 1fr',
					gridAutoRows: 'auto',
					gap: '20px',
					width: '92%',
					alignItems: 'flex-start',
				}}
			>
				<CapabilityContainer
					value={
						props.capabilities.type_publisher &&
						props.capabilities.type_publisher.length > 0
							? props.capabilities.type_publisher
							: [{ value: 'Not Specified' }]
					}
					label={'Type of Publisher'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.devices && props.capabilities.devices.length > 0
							? props.capabilities.devices
							: [{ value: 'Not Specified' }]
					}
					label={'Devices'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.available_platforms &&
						props.capabilities.available_platforms.length > 0
							? props.capabilities.available_platforms
							: [{ value: 'Not Specified' }]
					}
					label={'Available Platforms'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.fraud_tools &&
						props.capabilities.fraud_tools.length > 0
							? props.capabilities.fraud_tools
							: [{ value: 'Not Specified' }]
					}
					label={'Fraud Tool'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.ad_type && props.capabilities.ad_type.length > 0
							? props.capabilities.ad_type
							: [{ value: 'Not Specified' }]
					}
					label={'Ad Type'}
				/>
				<ValuedCapabilityContainer
					value={
						props.capabilities.format_types &&
						props.capabilities.format_types.length > 0
							? props.capabilities.format_types
							: [{ value: 'Not Specified' }]
					}
					label={'Ad Format Type'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.payment_model &&
						props.capabilities.payment_model.length > 0
							? props.capabilities.payment_model
							: [{ value: 'Not Specified' }]
					}
					label={'Payment Model'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.traffic_indicators &&
						props.capabilities.traffic_indicators.length > 0
							? props.capabilities.traffic_indicators
							: [{ value: 'Not Specified' }]
					}
					label={'Traffic Indicators'}
					isTrafficIndicator={props.capabilities.traffic_indicators}
				/>
				<CapabilityContainer
					value={
						props.capabilities.abilities &&
						props.capabilities.abilities.length > 0
							? props.capabilities.abilities
							: [{ value: 'Not Specified' }]
					}
					label={'Abilities'}
				/>
				<ValuedCapabilityContainer
					value={
						props.capabilities.daily_cap &&
						props.capabilities.daily_cap.length > 0
							? props.capabilities.daily_cap
							: [{ value: 'Not Specified' }]
					}
					label={'Daily Cap'}
				/>
				<CapabilityContainer
					value={
						props.capabilities.relevant_categories &&
						props.capabilities.relevant_categories.length > 0
							? props.capabilities.relevant_categories
							: [{ value: 'Not Specified' }]
					}
					label={'Verticals'}
				/>
				<CountryCapabilityContainer
					value={
						props.capabilities.country && props.capabilities.country.length > 0
							? props.capabilities.country
							: [{ value: 'Not Specified' }]
					}
					label={'Country - Volume - Payout'}
				/>
			</div>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					gridAutoRows: '170px',
					gap: '12px',
					width: '95%',
					marginTop: '20px',
				}}
			></div>
		</div>
	)
}
//the main preview page render.
const PublisherPreviewPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	setUniversalEdit: any
	viewRecord: any
	setViewRecord: any
	setUniversalDelete: any
	PopUpVisible: any
	setPopUpVisible: any
	isCompanyPersonalView?: boolean
	companyData?: any
	setSuccessMessage: any
}) => {
	const theme = useTheme()
	const { publisher, users, settings, login } = useTypedSelector(
		(state) => state,
	)

	const [contacts, setContacts] = useState<ContactInterface[]>([])

	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_PUBLISHER),
		viewPublisherContact: useIsAuthorized(
			permissionNames.VIEW_PUBLISHER_CONTACTS,
		),
		viewLinks: useIsAuthorized(permissionNames.VIEW_LINKS),
	}

	const navigate = useNavigate()
	const params = useParams()
	let row: any
	if (props.isCompanyPersonalView) {
		row = props.companyData
	} else {
		row =
			publisher.publisher.find((e: any) => {
				return e.id.toString() === params.id!.trim()
			}) || {}
	}

	const canView = canAccessResource(login.user, row.email, users)

	useEffect(() => {
		const decryptContactsData = async () => {
			if (
				row &&
				row.contacts &&
				(canView || permissions.viewPublisherContact)
			) {
				try {
					const decryptedContacts = await decryptContacts(row.contacts)

					setContacts(decryptedContacts)
				} catch (error) {
					console.error('Failed to decrypt contact information:', error)
				}
			}
		}
		decryptContactsData()
	}, [row.contacts, canView])

	const [skypeAllowedUsers, setSkypeAllowedUsers] = useState<string[]>([])
	const [skypeGroupUserAllowed, setSkypeGroupUserAllowed] =
		useState<boolean>(false)
	const closeDetailedView = () => {
		if (props.isCompanyPersonalView) {
			navigate('/companySettings')
		} else {
			navigate('/publishers')
		}
		props.setPopUpVisible(false)
	}
	const openEdit = () => {
		navigate('/publishers/' + row.id)
	}

	useEffect(() => {
		const allowedList = []
		if (settings.settings && users.users) {
			for (const teamLeader of settings.settings.teams) {
				for (const user of users.users) {
					if (
						(teamLeader === user.name ||
							user.role === 'super' ||
							user.position?.toLowerCase() === 'publisher manager' ||
							user.position?.toLowerCase() ===
								'global publisher managers team lead') &&
						allowedList.indexOf(user.email) === -1
					) {
						allowedList.push(user.email)
					}
				}
			}
			setSkypeAllowedUsers(allowedList)
		}
	}, [settings, users])

	useEffect(() => {
		if (skypeAllowedUsers.length > 0) {
			if (skypeAllowedUsers.indexOf(login.user.email) >= 0) {
				setSkypeGroupUserAllowed(true)
			}
		}
	}, [skypeAllowedUsers])

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<InformationContainer
				publisher={row}
				closeDetailedView={closeDetailedView}
				openEdit={openEdit}
				skypeGroupUserAllowed={skypeGroupUserAllowed}
				permissionContacts={permissions.viewPublisherContact || canView}
				permissionsGeneral={permissions}
				PopUpVisible={props.PopUpVisible}
				setPopUpVisible={props.setPopUpVisible}
				setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
				setSuccessMessage={props.setSuccessMessage}
				contacts={contacts}
			/>
			<div
				style={{
					paddingBottom: '10px',
					borderBottom: '1px solid ' + theme.colors.base.grey300,
					width: '92%',
				}}
			></div>
			{row?.media_buying_capabilities && (
				<CapabilitiesInfoContainer
					capabilities={row.media_buying_capabilities}
				/>
			)}
		</PageWrapper>
	)
}

export default PublisherPreviewPage
