import React, { useRef, useState, useEffect, useMemo } from 'react'
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TablePagination,
	TableSortLabel,
	useTheme,
	TableCellProps,
	TableContainer,
	Tooltip,
} from '@mui/material'
import styled from '@emotion/styled'
import { TotalRow } from './TableComponents'
import {
	formatColumnName,
	roundColumnValuesNumbers,
} from '../../utils/helpers/helperFuncs'
import { reorderReportColumns } from '../../utils/helpers/reportHelperFuncs'
import { SmallLoading } from '../../assets/svg/loading'
import { generatePaginationOptions } from '../../utils/helpers/tableHelper'

interface CustomReportTableProps {
	reports: any[]
	reportNames: string[]
	totalRow?: { [key: string]: string | number | null }
	reportType: string
}

type Order = 'asc' | 'desc'

interface StyledTableCellProps extends TableCellProps {
	isTotalRevenue?: boolean
	isTotalRow?: boolean
}

const StyledTableCell: React.FC<StyledTableCellProps> = ({
	isTotalRevenue,
	...props
}) => {
	const theme = useTheme()
	const style: React.CSSProperties = {
		fontWeight: 'bold',
		fontSize: '0.9rem',
		textTransform: 'capitalize' as const,
		color: theme.colors.text.titles,
		borderBottom: `0.1rem solid ${theme.colors.base.grey900}`,
		backgroundColor: isTotalRevenue ? theme.colors.base.grey200 : 'inherit',
		width: 'auto',
		textAlign: 'left',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: '8px',
	}

	return <TableCell style={style} {...props} />
}

export const StyledTableBodyCell: React.FC<StyledTableCellProps> = ({
	isTotalRevenue,
	isTotalRow,
	...props
}) => {
	const theme = useTheme()
	const cellRef = useRef<HTMLDivElement>(null)
	const [isTruncated, setIsTruncated] = useState(false)

	useEffect(() => {
		if (cellRef.current) {
			setIsTruncated(cellRef.current.scrollWidth > cellRef.current.clientWidth)
		}
	}, [props.children])

	const style = {
		backgroundColor: isTotalRow
			? theme.colors.base.grey300
			: isTotalRevenue
			? theme.colors.base.grey200
			: 'inherit',
		color: theme.colors.text.titles,
		fontWeight: isTotalRow ? 'bold' : 'normal',
		width: 'auto',
		textAlign: 'left' as 'left',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: '8px',
	}

	return (
		<Tooltip title={isTruncated ? props.children : ''} arrow>
			<TableCell style={style} {...props}>
				<div
					ref={cellRef}
					style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					{props.children}
				</div>
			</TableCell>
		</Tooltip>
	)
}

export const StyledTableRow = styled(TableRow)<{ isTotalRow?: boolean }>`
	&& {
		font-weight: ${({ isTotalRow }) => (isTotalRow ? 'bold' : 'normal')};
		font-size: ${({ isTotalRow }) => (isTotalRow ? '1rem' : 'inherit')};
		width: 100%;
		background-color: ${({ isTotalRow, theme }) =>
			isTotalRow ? 'red' : 'inherit'};
	}
`

const CustomReportTable: React.FC<CustomReportTableProps> = ({
	reports,
	reportNames,
	totalRow,
	reportType,
}) => {
	const theme = useTheme()

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(() => {
		const paginationOptions = generatePaginationOptions(reports.length)
		return typeof paginationOptions[0] === 'number' ? paginationOptions[0] : 4
	})
	const [order, setOrder] = useState<Order>('asc')
	const [orderBy, setOrderBy] = useState<string>('')
	const [visibleRows, setVisibleRows] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const isAllRows = rowsPerPage === -1

	useEffect(() => {
		if (!isAllRows) {
			const start = page * rowsPerPage
			const end = start + rowsPerPage
			setVisibleRows(reports.slice(start, end))
		}
	}, [page, rowsPerPage, reports, isAllRows])

	useEffect(() => {
		if (reports && reports.length > 0 && !isAllRows) {
			const start = page * rowsPerPage
			const end = start + rowsPerPage
			setVisibleRows(reports.slice(start, end))
		}
	}, [reports, page, rowsPerPage, isAllRows])

	const memoizedRows = useMemo(() => {
		if (!reports) return []
		return isAllRows
			? visibleRows
			: reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
	}, [isAllRows, visibleRows, page, rowsPerPage, reports])

	// Early return after hooks
	if (!reports || reports.length === 0) {
		return <Box>No report data available</Box>
	}

	const integerColumns = [
		'Impressions',
		'Clicks',
		'Installs',
		'Re-attributions',
		'Re-engagements',
		'Total Revenue',
	]

	const floatColumns = ['Cr']

	const columns = Object.keys(reports[0])
	const sortedColumns = reorderReportColumns(columns, reportType)

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const newRowsPerPage = parseInt(event.target.value, 10)
		setRowsPerPage(newRowsPerPage)
		setPage(0)
		if (newRowsPerPage !== -1) {
			// For specific page sizes
			const start = 0
			const end = newRowsPerPage
			setVisibleRows(reports.slice(start, end))
		} else {
			// When "All" is selected
			setIsLoading(true)
			setVisibleRows([]) // Clear current rows
			loadAllDataInChunks()
		}
	}

	const loadAllDataInChunks = () => {
		const chunkSize = 100 // Adjust as needed
		let loadedRows: any[] = []
		let currentIndex = 0

		const loadChunk = () => {
			if (currentIndex < reports.length) {
				const nextChunk = reports.slice(currentIndex, currentIndex + chunkSize)
				loadedRows = [...loadedRows, ...nextChunk]
				setVisibleRows([...loadedRows]) // Update visible rows
				currentIndex += chunkSize
				setTimeout(loadChunk, 0) // Load next chunk asynchronously
			} else {
				setIsLoading(false) // All data loaded
			}
		}

		loadChunk()
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string,
	) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	return (
		<Box
			sx={{
				width: '100%',
				background: theme.colors.base.white,
				color: theme.colors.text.titles + ' !important',
			}}
		>
			{isLoading && (
				<Box sx={{ textAlign: 'center', padding: '1rem' }}>
					<SmallLoading />
				</Box>
			)}

			{!isLoading && (
				<>
					<TableContainer
						sx={{
							width: '100%',
							overflowX: 'auto',
							background: theme.colors.base.white,
							color: theme.colors.text.titles,
						}}
					>
						<Table
							sx={{
								minWidth: 'max-content',
							}}
						>
							<TableHead>
								<TableRow>
									{sortedColumns.map((columnKey: any) => (
										<TableCell
											sortDirection={orderBy === columnKey ? order : false}
											sx={{
												width: `${100 / sortedColumns.length}%`,
												minWidth: '150px',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											<TableSortLabel
												active={orderBy === columnKey}
												direction={orderBy === columnKey ? order : 'asc'}
												onClick={(event) => handleRequestSort(event, columnKey)}
												style={{ color: theme.colors.text.titles }}
											>
												{formatColumnName(columnKey)}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>

							<TableBody>
								{memoizedRows.map((report, rowIndex) => (
									<StyledTableRow
										key={rowIndex}
										isTotalRow={report['date'] === 'Total'}
									>
										{sortedColumns.map((columnKey: any) => (
											<StyledTableBodyCell
												key={columnKey}
												isTotalRevenue={columnKey === 'total_revenue'}
												isTotalRow={report['date'] === 'Total'}
												sx={{
													minWidth: '150px',
												}}
											>
												{columnKey === 'Cr'
													? roundColumnValuesNumbers(
															'CR', // Convert 'Cr' to 'CR' in the body cell
															report[columnKey],
															integerColumns,
															floatColumns,
													  )
													: roundColumnValuesNumbers(
															columnKey,
															report[columnKey],
															integerColumns,
															floatColumns,
													  )}
											</StyledTableBodyCell>
										))}
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}

			<TablePagination
				rowsPerPageOptions={generatePaginationOptions(reports.length)} // Dynamic options
				component='div'
				count={reports.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Box>
	)
}

export default CustomReportTable
