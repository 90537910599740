import React, { useEffect, useState } from 'react'
import { CountryListSelector } from './SelectableInputs'
import { ChipInputList } from './ChipInput'
import { useTheme } from '@mui/material'
import { CSSProperties } from 'react'

const styles = {
	checkedFilterContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '260px',
		position: 'relative' as CSSProperties['position'], // Corrected to match CSSProperties
	},
	placeholderText: (theme: any): CSSProperties => ({
		position: 'absolute',
		left: '20%',
		top: '50%',
		color: theme.colors.text.grey, // Use theme color dynamically
		pointerEvents: 'none',
		padding: '0 19px',
	}),
}

const CheckedFilter = (props: {
	id: any
	onChange: (checked: boolean) => void
	checked: boolean
	label: string
	arrayOnChange: any
	array: any[]
	value?: any[]
	disabled?: boolean
	setDataObj?: any
	dataObj?: any
	placeholder?: string
	children?: React.ReactNode
}) => {
	const [extraFilter, setExtraFilter] = useState(false)
	const [isLoading, setIsLoading] = useState(false) // Loading state for PID
	const [pidOptions, setPidOptions] = useState<string[]>([]) // All PIDs for lazy loading
	const [visibleOptions, setVisibleOptions] = useState<string[]>([]) // PIDs visible to user
	const chunkSize = 100 // Number of PIDs to load at once
	const theme = useTheme()

	// Check if the field requires special handling (e.g., media_source_pid)
	useEffect(() => {
		const possibleFilters = [
			'app_id',
			'media_source_pid',
			'campaign_name',
			'campaign_id',
			'country',
			'agencyAccounts',
		]
		if (possibleFilters.includes(props.id)) {
			setExtraFilter(true)
		}
	}, [props.id])

	// Special case for PID lazy loading
	useEffect(() => {
		if (props.id === 'media_source_pid' && props.checked) {
			setIsLoading(true)

			setPidOptions(props.array || []) // Set full PID list
			setVisibleOptions((prev) => [
				...prev,
				...(props.array || []).slice(0, chunkSize),
			]) // Load first chunk
			setIsLoading(false)
		}
	}, [props.checked, props.id, props.array])

	// Function to load more PIDs (lazy loading)
	const loadMoreOptions = () => {
		if (visibleOptions.length < pidOptions.length) {
			setVisibleOptions((prev) => [
				...prev,
				...pidOptions.slice(prev.length, prev.length + chunkSize),
			])
		}
	}

	// Automatically load more options when scrolling
	const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const bottom =
			e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
			e.currentTarget.clientHeight
		if (bottom) {
			loadMoreOptions()
		}
	}

	// Render PID filter with lazy loading and loading indicator
	if (props.id === 'media_source_pid') {
		return (
			<div style={styles.checkedFilterContainer}>
				{props.checked ? (
					<div key={props.id} style={{ display: 'flex', width: '100%' }}>
						{props.children ? (
							props.children
						) : (
							<div style={{ width: '100%', position: 'relative' }}>
								{(!props.value || props.value.length === 0) && (
									<span style={styles.placeholderText(theme)}>
										{props.placeholder}
									</span>
								)}

								<div
									style={{ maxHeight: '200px' }} // Scrollable container for PIDs
									onScroll={handleScroll}
								>
									<ChipInputList
										{...props}
										onChange={props.arrayOnChange}
										options={visibleOptions} // Show only visible options
										value={props.value || ['']}
										openWithClick={true}
										style={{ width: '100%' }}
									/>
								</div>
							</div>
						)}
					</div>
				) : (
					<></>
				)}
			</div>
		)
	}

	// Default behavior for other fields
	return (
		<div style={styles.checkedFilterContainer}>
			{props.checked ? (
				props.label !== 'Country' ? (
					<div key={props.id} style={{ display: 'flex', width: '100%' }}>
						{props.children ? (
							props.children
						) : (
							<div style={{ width: '100%', position: 'relative' }}>
								{(!props.value || props.value.length === 0) && (
									<span style={styles.placeholderText(theme)}>
										{props.placeholder}
									</span>
								)}
								<ChipInputList
									{...props}
									onChange={props.arrayOnChange}
									options={props.array}
									value={props.value || ['']} // default empty string for unfilled values
									openWithClick={true}
									style={{ width: '100%' }}
								/>
							</div>
						)}
					</div>
				) : (
					<div key={props.id} style={{ display: 'flex', width: '100%' }}>
						{(!props.value || props.value.length === 0) && (
							<span style={styles.placeholderText(theme)}>
								{props.placeholder}
							</span>
						)}
						<CountryListSelector
							{...props}
							onChange={props.arrayOnChange}
							options={props.array}
							value={props.value || ['']} // default empty string for unfilled values
							style={{ width: '100%' }}
						/>
					</div>
				)
			) : (
				<></>
			)}
		</div>
	)
}

export default CheckedFilter
