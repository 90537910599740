import { DialogActions, DialogContent, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { StyledDialog } from '../components/reusableComponents'
import { InputFieldText } from '../components/Inputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { PasswordInterface } from '../components/PasswordComponent'
import { decryptMessageForDataSplit } from '../../crypto/cryptoUtils'

interface SimplePasswordsEditProps {
	PopUpVisible: boolean
	setPopUpVisible: any
	row?: PasswordInterface | null
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isAdding: boolean
	setIsAdding: any
	handleSave: (data: {
		username: string
		url: string
		password: string
	}) => void
}

const SimplePasswordsEdit = (props: SimplePasswordsEditProps): JSX.Element => {
	const theme = useTheme()
	const [username, setUsername] = useState(props.row?.username || '')
	const [url, setUrl] = useState(props.row?.url || '')
	const [password, setPassword] = useState(props.row?.password || '')
	const [passwordValue, setPasswordValue] = useState(
		props.row?.password ? '**********' : '',
	)
	const [showFullPassword, setShowFullPassword] = useState(false)
	const [isDecrypted, setIsDecrypted] = useState(false)

	const nullifyFields = () => {
		setUsername('')
		setUrl('')
		setPassword('')
		setShowFullPassword(false)
	}

	const handleSubmit = async () => {
		props.setLoading(true)

		props.handleSave({
			username,
			url,
			password: props.isAdding ? passwordValue : password,
		})

		props.setLoading(false)
		props.setPopUpVisible(false)
		nullifyFields()
	}

	useEffect(() => {
		if (props.row) {
			setUsername(props.row.username || '')
			setUrl(props.row.url || '')
			setPassword(props.row.password || '')
		}
	}, [props.row])

	const handleDecryptPassword = async () => {
		try {
			if (isDecrypted || !password.includes(':')) {
				setPassword(password)
				setShowFullPassword(true)
				return
			}
			const decryptedPassword = await decryptMessageForDataSplit(password)
			setPassword(decryptedPassword)
			setShowFullPassword(true)
			setIsDecrypted(true)
		} catch (error) {
			console.error('Error during decryption:', error)
			props.setErrorMessage('Failed to decrypt password.')
		}
	}

	const toggleShowPassword = () => {
		if (!showFullPassword) {
			handleDecryptPassword()
		} else {
			setShowFullPassword(false)
		}
	}

	return (
		<StyledDialog
			open={props.PopUpVisible}
			style={{ zIndex: '20' }}
			sx={{
				width: 600,
				maxWidth: 600,
				mx: 'auto',
			}}
		>
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
					padding: '40px 60px 60px 60px',
					background: theme.colors.base.white,
				}}
			>
				<InputFieldText
					label='Username'
					type='text'
					value={username}
					onChange={setUsername}
				/>
				<InputFieldText label='URL' type='text' value={url} onChange={setUrl} />
				<InputFieldText
					label='Password'
					type={showFullPassword ? 'text' : 'password'}
					value={showFullPassword ? password : passwordValue}
					onChange={(e: any) => {
						showFullPassword ? setPassword(e) : setPasswordValue(e)
					}}
					placeholder={props.isAdding ? 'Enter your password' : '********'}
					disabled={!props.isAdding && !showFullPassword}
				/>
				{!props.isAdding && password && (
					<UnborderedButton
						onClick={toggleShowPassword}
						style={{ alignSelf: 'flex-start', marginTop: '12px' }}
					>
						{showFullPassword ? 'Hide Password' : 'Show Password'}
					</UnborderedButton>
				)}
			</DialogContent>
			<DialogActions style={{ background: theme.colors.base.white }}>
				<UnborderedButton
					style={{ margin: 'auto', height: 120 }}
					onClick={() => {
						props.setPopUpVisible(false)
						nullifyFields()
					}}
					label='Cancel'
				></UnborderedButton>
				<SubmitButton style={{ margin: 'auto' }} onClick={handleSubmit}>
					{props.isAdding ? 'Add' : 'Update'}
				</SubmitButton>
			</DialogActions>
		</StyledDialog>
	)
}

export default SimplePasswordsEdit
