import { subDays } from 'date-fns'
import { Dispatch } from 'redux'
import {
	ReportType,
	endpoints,
	reportEndpoints,
	reverseReportTypeMapping,
} from '../../config'
import { PublisherInterface, UserInterface } from '../../models/model.interface'
import { isUuid } from '../../pages/campaigns/CampaignComponents'
import { downloadEverything } from '../../pages/dashboard/Dashboard'
import { NetworkFieldsInterface } from '../../pages/reports/network/FiltersPage'
import AxiosCustom from '../../utils/Axios'
import { formatDateToDdMmYyyy } from '../../utils/helpers/helperFuncs'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import {
	capitalizeFirstLetter,
	cellGenerator,
} from '../../utils/helpers/tableHelper'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import { persistor } from '../store'
import { RootState } from '../reducers'
import axios from 'axios'
import {
	checkForExistingReport,
	fetchReportData,
	handleWebSocketMessage,
	openWebSocketConnection,
	postReportRequest,
	prepareDataForRequest,
} from '../../utils/helpers/reportHelperFuncs'
import { v4 as uuidv4 } from 'uuid'
import { Alert } from '../../utils/notifications/AlertContainer'
import {
	decryptMessageForWebSocket,
	encryptMessageForWebSocket,
	decryptMessageForDataSplit,
} from '../../crypto/cryptoUtils'
import { ReportsByUser } from '../reducers/reportReducer'

export interface loginParams {
	email: string
	password: string
	maintenance: boolean
}

export interface signUpParams {
	name: string
	type: string
	email: string
	company: string
	country: string
	// password: string
}

export interface newPasswordParams {
	email: string
	newPassword: string
}

export interface userParams {
	Authorization: string
}

export interface updateAppParams {
	app_id: string
	status: boolean
	email: string
	created_by: string
}

interface ReportFieldsInterface {
	from_date: Date
	to_date: Date
	breakdowns: string[]
	statistics: string[]
	filters: any
	user_email?: string
}

//------------NEWS OPERATIONS ------------

export const getNewsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.NEWS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_NEWS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_NEWS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			window.location.replace(window.location.origin)
		}
	}
}

export const postNewsAction = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = {
				post: {
					id: 1,
					topics: obj.topics,
					deleted: obj.deleted,
				},
			}
			const { data } = await AxiosCustom.post(endpoints.NEWS, payload, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_NEWS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.POST_NEWS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const postNewsImageAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			function base64Encode(str: any) {
				var CHARS =
					'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
				var out = '',
					i = 0,
					len = str.length,
					c1,
					c2,
					c3
				while (i < len) {
					c1 = str.charCodeAt(i++) & 0xff
					if (i == len) {
						out += CHARS.charAt(c1 >> 2)
						out += CHARS.charAt((c1 & 0x3) << 4)
						out += '=='
						break
					}
					c2 = str.charCodeAt(i++)
					if (i == len) {
						out += CHARS.charAt(c1 >> 2)
						out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
						out += CHARS.charAt((c2 & 0xf) << 2)
						out += '='
						break
					}
					c3 = str.charCodeAt(i++)
					out += CHARS.charAt(c1 >> 2)
					out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
					out += CHARS.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6))
					out += CHARS.charAt(c3 & 0x3f)
				}
				return out
			}
			const obj = { post: payload }
			const { data } = await AxiosCustom.post(
				endpoints.NEWS_I,
				JSON.stringify(obj),
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_NEWS,
					},
				},
			)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.POST_IMAGE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

// --------- LOGS OPERATION --------

export const getLogsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.LOGS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_LOGS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_LOGS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getLogsByDateAction = (
	// headers: any,
	from_date: Date,
	to_date: Date,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { logs: { from_date, to_date } }
			const { data } = await AxiosCustom.post(endpoints.LOGS, payload, {
				headers: {
					'x-permission-name': permissionNames.VIEW_LOGS,
				},
			})

			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_LOGS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertLog = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const log = {
				log: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.LOGS + '/add', log, {
				headers: {
					'x-permission-name': permissionNames.VIEW_LOGS,
				},
			})

			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.INSERT_LOG,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

// --------- LEARNING OPERATION --------

export const getLearningAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.DOCUMENTS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_DOCUMENTS,
				},
			})
			// const learning = {
			// 	allowedPlatforms: JSON.parse(data.payload[0].allowedPlatforms),
			// 	vertical: JSON.parse(data.payload[0].vertical),
			// 	capabilities: JSON.parse(data.payload[0].capabilities),
			// 	salesRepresentative: JSON.parse(data.payload[0].salesRepresentative),
			// 	platforms: JSON.parse(data.payload[0].platforms),
			// 	contactRole: JSON.parse(data.payload[0].contactRole),
			// }
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_DOCUMENTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const addLearningRow = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setIsSuccessPromptOpened: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const learning = {
				learning: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.DOCUMENTS, learning, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_DOCUMENTS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				setLoading(false)
				return
			}
			setIsSuccessPromptOpened(true)
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateLearningRow = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setIsSuccessPromptOpened: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const learning = {
				learning: obj,
			}
			const { data } = await AxiosCustom.put(endpoints.DOCUMENTS, learning, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_DOCUMENTS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				setLoading(false)
				return
			}
			setIsSuccessPromptOpened(true)
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}
}
export const removeRow = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setIsSuccessPromptOpened: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataSend = { learning: obj }

			const { data } = await AxiosCustom.delete(
				endpoints.DOCUMENTS + `/${obj.id}`,
				{
					data: dataSend,
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_DOCUMENTS,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setIsSuccessPromptOpened(true)
			dispatch({
				type: ActionType.REMOVE_DOCUMENTS,
				payload: obj,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
// --------- SETTINGS OPERATION --------

export const getCompanySettings = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.COMPANY_SETTINGS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_SETTINGS, // add company settings permissions
				},
			})
			dispatch({
				type: ActionType.GET_COMPANY_SETTINGS,
				payload: data.payload[0],
			})
		} catch (err) {}
	}
}

export const updateCompanySettings = (settings: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.COMPANY_SETTINGS,
				settings,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_SETTINGS, // add company settings permissions
					},
				},
			)
			// console.log('updated settings for company settings: ', data)
			dispatch({
				type: ActionType.UPDATE_COMPANY_SETTINGS,
				payload: data.payload,
			})
		} catch (err) {}
	}
}

export const getSettingsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.SETTINGS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_SETTINGS,
				},
			})
			const settings = {
				allowedPlatforms: data.payload[0].allowedPlatforms
					? data.payload[0].allowedPlatforms
					: [],
				vertical: data.payload[0].vertical ? data.payload[0].vertical : [],
				creatorVertical: data.payload[0].creatorVertical
					? data.payload[0].creatorVertical
					: [],
				capabilities: data.payload[0].capabilities
					? data.payload[0].capabilities
					: [],
				salesRepresentative: data.payload[0].salesRepresentative
					? data.payload[0].salesRepresentative
					: [],
				teams: data.payload[0].teams ? data.payload[0].teams : [],
				fraudTools: data.payload[0].fraudTools
					? data.payload[0].fraudTools
					: [],
				platforms: data.payload[0].platforms ? data.payload[0].platforms : [],
				contactRole: data.payload[0].contactRole
					? data.payload[0].contactRole
					: [],
				banners: data.payload[0].banners ? data.payload[0].banners : [],
				videos: data.payload[0].videos ? data.payload[0].videos : [],
				paymentTerms: data.payload[0].paymentTerms
					? data.payload[0].paymentTerms
					: [],
				pauseReason: data.payload[0].pauseReason
					? data.payload[0].pauseReason
					: [],
				trafficRestrictions: data.payload[0].trafficRestrictions
					? data.payload[0].trafficRestrictions
					: [],
				existingChannels: data.payload[0].existingChannels
					? data.payload[0].existingChannels
					: [],
				communicationChannel: data.payload[0].communicationChannel
					? data.payload[0].communicationChannel
					: [],
				targetAudience: data.payload[0].targetAudience
					? data.payload[0].targetAudience
					: [],
				targetAudienceOthers: data.payload[0].targetAudienceOthers
					? data.payload[0].targetAudienceOthers
					: [],
				campaignGoals: data.payload[0].campaignGoals
					? data.payload[0].campaignGoals
					: [],
				currency: data.payload[0].currency ? data.payload[0].currency : [],
				paymentMethod: data.payload[0].paymentMethod
					? data.payload[0].paymentMethod
					: [],
				branches: data.payload[0].branches ? data.payload[0].branches : [],
				vatValues: data.payload[0].vatValues ? data.payload[0].vatValues : [],
				// permissionGroups: data.payload[0].permissionGroups
				// 	? data.payload[0].permissionGroups
				// 	: [],
				teamLeaders: data.payload[0].teamLeaders
					? data.payload[0].teamLeaders
					: [],
				userPositions: data.payload[0].userPositions
					? data.payload[0].userPositions
					: [],
				// userTypes: data.payload[0].userTypes ? data.payload[0].userTypes : [],
			}
			dispatch({
				type: ActionType.GET_SETTINGS,
				payload: settings,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateSettings = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const settings = {
				settings: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.SETTINGS, settings, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_SETTINGS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data)
				return
			}
			setSucessMessage(true)
			dispatch({
				type: ActionType.UPDATE_SETTINGS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
} // -------- ROLES OPERATIONS ------------

export const fetchRoles = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.GET_ROLES, {
				headers: {
					'x-permission-name': permissionNames.VIEW_SETTINGS, // TODO: MATAN -> need permissions for roles as well
				},
			})
			dispatch({
				type: ActionType.FETCH_ROLES,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const updateRoles = (
	rolesArray: any[],
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.put(
				endpoints.UPDATE_ROLES,
				rolesArray,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_SETTINGS, // TODO: MATAN -> need permissions for roles as well
					},
				},
			)
			setSucessMessage('Roles Updated successfully')
			if (data && data.length > 0) {
				// console.log(data)
				dispatch({
					type: ActionType.FETCH_ROLES,
					payload: data,
				})
			}
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deleteRole = (
	roleId: number,
	setErrorMessage: any,
	setSuccessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(
				`${endpoints.DELETE_ROLE}/${roleId}`,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_SETTINGS, // TODO: MATAN -> need permissions for roles as well
					},
				},
			)
			setSuccessMessage('Role deleted successfully')
			dispatch({
				type: ActionType.FETCH_ROLES,
				payload: data,
			})
		} catch (err) {
			console.error(err)
			setErrorMessage('Failed to delete role')
		}
	}
}

// --------- PERMISSIONS OPERATION ----------

export const getPermissions = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.LIST_ALL_PERMISSIONS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_SETTINGS,
				},
			})
			if (data && data.length > 0) {
				dispatch({
					type: ActionType.SETPERMISSIONS,
					payload: data,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
// --------- USERS OPERATION --------
export const loginAction = (
	user: loginParams,
	setErrorMessage: any,
	navigate: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data, status, statusText } = await AxiosCustom.post(
				endpoints.LOGIN,
				{
					user,
				},
			)
			if (status !== 200) {
				console.log(status, statusText)
				setErrorMessage(statusText)
				return statusText
			}

			localStorage.setItem('userToken', btoa(data.payload))
			const isProd = !window.location.hostname.includes('localhost')
			const isStage = window.location.hostname.includes('stage.varys.io')
			window.location.href = `http${isProd ? 's' : ''}://${
				data.payload.subdomain
			}.${
				isProd ? (isStage ? 'stage.varys.io' : 'varys.io') : 'localhost:3000'
			}/auth?token=${btoa(JSON.stringify(data.payload))}`
			return true
		} catch (err: any) {
			if (err.response?.status === 503) {
				navigate('/maintenance')
			}
			setErrorMessage(err)
			return err
		}
	}
}

export const signUpAction = (user: signUpParams, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			let { data } = await AxiosCustom.post(
				endpoints.SIGNUP,
				{ user },
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_USERS,
					},
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
		} catch (error) {
			console.error(error)
		}
	}
}

export const resetPasswordAction = (email: string, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			let { data } = await AxiosCustom.post(
				endpoints.RESET_PASSWORD,
				{ email },
				{
					headers: {
						'x-permission-name': permissionNames.CHANGE_USER_PASSWORD,
					},
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.successful
			}
		} catch (error) {
			console.error(error)
		}
	}
}

export const resetPasswordWithToken = (
	email: string,
	password: string,
	token: string,
	setErrorMessage: any,
) => {
	return async () => {
		try {
			let { data } = await AxiosCustom.post(
				endpoints.CHANGE_PASSWORD_WITH_TOKEN,
				{ email, password, token },
				{
					headers: {
						'x-permission-name': permissionNames.CHANGE_USER_PASSWORD,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.successful
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const logOutAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// let { data } = await AxiosCustom.post(endpoints.LOGOUT)
			// localStorage.clear()
			sessionStorage.clear()
			const theme = localStorage.getItem('theme')
			localStorage.clear()
			if (theme) {
				localStorage.setItem('theme', theme)
			}
			// console.log('Clearing sessionStorage and redirecting...')
			const isProd = window.location.hostname.includes('varys.io')
			const isStage = window.location.hostname.includes('stage.varys.io')
			window.location.href = `http${isProd ? 's' : ''}://${
				isProd ? (isStage ? 'stage.varys.io' : 'varys.io') : 'localhost:3000'
			}`
			setTimeout(() => {
				dispatch({
					type: ActionType.LOGOUT,
					payload: undefined,
				})
			}, 500)
		} catch (err) {
			console.error('Error during logout:', err)
		}
	}
}

export const usersAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			let { data } = await AxiosCustom.get(endpoints.USER, {
				headers: {
					'x-permission-name': permissionNames.VIEW_USERS,
				},
			})
			if (!data.successful) {
				return
			}

			data.payload.forEach((user: UserInterface) => {
				user.name = capitalizeFirstLetter(user.name)
			})
			dispatch({
				type: ActionType.GET_USERS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const forgotPasswordAction = (email: string, setCodeHash: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const emailobj = { mail: email }
			let { data } = await AxiosCustom.post(
				endpoints.FORGOTPASSWORD,
				emailobj,
				{
					headers: {
						'x-permission-name': permissionNames.CHANGE_USER_PASSWORD,
					},
				},
			)
			if (!data.successful) {
				return data.payload
			}
			setCodeHash(data.payload)
			// dispatch({
			// 	type: ActionType.GET_USERS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const showPasswordAction = (
	// headers: any,
	email: string,
	setCodeHash: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const emailobj = { mail: email }
			let { data } = await AxiosCustom.post(endpoints.SHOW_PASSWORD, emailobj, {
				headers: {
					'x-permission-name': permissionNames.VIEW_PASSWORDS,
				},
			})
			if (!data.successful) {
				return
			}
			setCodeHash(data.payload)
			// dispatch({
			// 	type: ActionType.GET_USERS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertUser = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const user = { user: obj }
			const { data } = await AxiosCustom.post(endpoints.REGISTER, user, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_USERS,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.INSERT_USER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const editUser = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const user = { user: obj }
			const { data } = await AxiosCustom.put(endpoints.USER, user, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_USERS,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			// dispatch({
			// 	type: ActionType.EDIT_USER,
			// 	payload: obj,
			// })
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deleteUser = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.USER, {
				data: {
					user: obj,
				},
				headers: {
					'x-permission-name': permissionNames.DELETE_USERS,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.DELETE_USER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const resetPassword = (
	// headers: any,
	obj: newPasswordParams,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const user = { user: obj }
			const { data } = await AxiosCustom.post(endpoints.PASSWORD, user, {
				headers: {
					'x-permission-name': permissionNames.CHANGE_USER_PASSWORD,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.RESET_PASSWORD,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getLoggedInUser = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.LOGGED_IN_USER, {
				headers: {
					'x-permission-name': permissionNames.VIEW_USERS,
				},
			})
			// console.log('after getting logged in user', data)
			// if (!data.successful) {
			//     return
			// }
			dispatch({
				type: ActionType.LOGIN,
				payload: data.payload,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}
}
// ------- APPS OPERATIONS--------
export const getAppsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APP, {
				headers: {
					'x-permission-name': permissionNames.ACCESS_TO_APPS,
				},
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_APP,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const getAppsIcons = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.ICONS, {
				headers: {
					'x-permission-name': permissionNames.ACCESS_TO_APPS,
				},
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_ICONS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const updateAppP360 = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.P360APP, app, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_APP_P360,
				},
			})
			if (data.payload.successful === false) {
				setErrorMessage(data.payload.payload)
				return data.payload.payload
			}
			setSucessMessage(true)
			dispatch({
				type: ActionType.UPDATE_APP_P360,
				payload: app,
			})
			return
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const removeAppP360 = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.REMOVEP360APP, app, {
				headers: {
					'x-permission-name': permissionNames.DELETE_APP_P360,
				},
			})
			if (data.payload.successful === false) {
				setErrorMessage(data.payload.payload)
				return data.payload
			}
			setSucessMessage(true)
			dispatch({
				type: ActionType.REMOVE_APP_P360,
				payload: app,
			})
			return
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const insertApp = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.APP, app, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_APP_P360,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.ADD_APP,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const insertP360Rule = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.P360APPADD, app, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_APP_P360,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.INSERT_P360_RULE,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const removeP360Rule = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.P360APPREMOVE, app, {
				headers: {
					'x-permission-name': permissionNames.DELETE_APP_P360,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.REMOVE_P360_RULE,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const updateAppInfo = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.APPUPDATE, app, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_APP_P360,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.UPDATE_APP_INFO,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const updateAppsAction = (
	obj: updateAppParams,
	row: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.put(endpoints.APP, app) //TODO: MATAN -> what is the permission name for that?
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			row.status = !row.status

			dispatch({
				type: ActionType.UPDATE_APP,
				payload: row,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
// ------- ADVERTISER OPERATIONS--------

export const getAdvertiserAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.ADVERTISER, {
				headers: {
					'x-permission-name': permissionNames.SEE_ALL_ADVERTISERS,
				},
			})

			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_ADVERTISER,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const getAdvertiserByCompanyId = (companyId: number) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.ADVERTISER + '/company/' + companyId,
				{
					headers: {
						'x-permission-name': permissionNames.SEE_ALL_ADVERTISERS,
					},
				},
			)
			// console.log(data)
			return data
			// dispatch({
			//     type: ActionType.GET_ADVERTISER_BY_COMPANY_ID,
			//     payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const getAdvertiserReport = (
	setDownloadFile: any,
	rows: any,
	// headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			const { data } = await AxiosCustom.post(
				// TODO: MATAN -> what is the permissions name for this?
				endpoints.ADVERTISER + '/report',
				report,
			)
			if (!data.successful) {
				return
			}
			// dispatch({
			// 	type: ActionType.GET_ADVERTISER_REPORT,
			// 	payload: data.payload,
			// })
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}
export const getAdvertiserContacts = (setDownloadFile: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.ADVERTISER + '/contacts') // TODO: MATAN -> what is the permissions name for this?
			if (!data.successful) {
				return
			}
			// dispatch({
			// 	type: ActionType.GET_ADVERTISER_CONTACTS,
			// 	payload: data.payload,
			// })
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertAdvertiserAction = (
	obj: any,
	setErrorMessage: any,
	navigateToNewAdvertiser: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const advertiser = { advertiser: obj }
			const { data } = await AxiosCustom.post(
				endpoints.ADVERTISER,
				advertiser,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_ADVERTISER,
					},
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return { success: false, error: data.payload }
			}

			const newPayload = { ...obj, id: data.payload.advertiserId }

			// Dispatch action if the API call was successful
			dispatch({
				type: ActionType.INSERT_ADVERTISER,
				payload: newPayload,
			})

			navigateToNewAdvertiser(data.payload.advertiserId)
		} catch (err) {
			setErrorMessage(err)
			return { success: false, error: err }
		}
	}
}

export const updateAdvertiserAction = (
	// headers: any,
	obj: any,
	ruleSet: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const advertiser = { advertiser: obj, rule_set: ruleSet }
			const { data } = await AxiosCustom.put(endpoints.ADVERTISER, advertiser, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_ADVERTISER,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload.message)

				return data.payload.message
			}
			// const newObj = {
			// 	advertiser_name: obj.advertiser_name,
			// 	email: obj.ownerEmail,
			// 	status: obj.status,
			// 	platforms: obj.platform,
			// 	app: obj.campaigns,
			// }

			dispatch({
				type: ActionType.INSERT_ADVERTISER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
		}
	}
}
export const updateAdvertiserMetaAction = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const advertiser = { advertiser: obj }
			const { data } = await AxiosCustom.put(
				endpoints.ADVERTISER_META,
				advertiser,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_ADVERTISER,
					},
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload.message)

				return data.payload.message
			}
			// const newObj = {
			// 	advertiser_name: obj.advertiser_name,
			// 	email: obj.ownerEmail,
			// 	status: obj.status,
			// 	platforms: obj.platform,
			// 	app: obj.campaigns,
			// }

			dispatch({
				type: ActionType.INSERT_ADVERTISER_META,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
		}
	}
}
export const getPasswordsByAdvertiserId = async (id: number) => {
	try {
		const { data } = await AxiosCustom.get(
			endpoints.ADVERTISER + `/password/${id}`,
			{
				headers: {
					'x-permission-name': permissionNames.VIEW_ADVERTISER_PASSWORDS,
				},
			},
		)
		return data
	} catch (err) {
		console.error(err)
		return []
	}
}
export const deleteAdvertiser = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.ADVERTISER, {
				data: {
					advertiser: obj,
					headers: {
						'x-permission-name': permissionNames.DELETE_ADVERTISER,
					},
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.DELETE_ADVERTISER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

// --------- ICONS OPERATION --------

export const deleteIcon = (
	userType: keyof typeof endpoints,
	name: string,
	id: number,
	// headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(
				// TODO: MATAN -> what is the permission name for icons?
				`${endpoints[userType]}/${id}/icon`,
			)

			if (data.successful) {
				switch (userType) {
					case 'ADVERTISER':
						dispatch({
							type: ActionType.DELETE_ADVERTISER_ICON,
							payload: { name, iconUrl: null },
						})
						break
					case 'PUBLISHER':
						dispatch({
							type: ActionType.DELETE_PUBLISHER_ICON,
							payload: { name, iconUrl: null },
						})
						break
					case 'CREATOR':
						dispatch({
							type: ActionType.DELETE_CREATOR_ICON,
							payload: { name, iconUrl: null },
						})
						break
					default:
						throw new Error(`Unsupported user type: ${userType}`)
				}

				return data
			} else {
				throw new Error('Failed to delete icon:', data.message)
			}
		} catch (error: any) {
			return { successful: false, payload: error.message }
		}
	}
}
export const uploadIcon = (
	userType: keyof typeof endpoints,
	file: File,
	name: string,
	id: number,
) => {
	return async (dispatch: Dispatch<Action>) => {
		const formData = new FormData()
		formData.append('file', file)
		formData.append(`${userType.toLowerCase()}_name`, name)
		formData.append('id', id.toString())

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}

		try {
			const response = await AxiosCustom.post(
				`${endpoints[userType]}/uploadIcon`,
				formData,
				config,
			)

			if (response.data.successful) {
				switch (userType) {
					case 'ADVERTISER':
						dispatch({
							type: ActionType.UPDATE_ADVERTISER_ICON,
							payload: { name, iconUrl: response.data?.payload },
						})
						break
					case 'PUBLISHER':
						dispatch({
							type: ActionType.UPDATE_PUBLISHER_ICON,
							payload: { name, iconUrl: response.data?.payload },
						})
						break
					case 'CREATOR':
						dispatch({
							type: ActionType.UPDATE_CREATOR_ICON,
							payload: { name, iconUrl: response.data?.payload },
						})
						break
					default:
						throw new Error(`Unsupported user type: ${userType}`)
				}
				return response.data.payload
			} else {
				throw new Error(`Failed to upload file: ${response.data.message}`)
			}
		} catch (error: any) {
			console.error(`Upload icon error: ${error.message}`)
			return { successful: false, payload: error.message }
		}
	}
}

// ------- PUBLISHER OPERATIONS--------

export const getPublisherAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.PUBLISHER, {
				headers: {
					'x-permission-name': permissionNames.VIEW_PUBLISHER,
				},
			})
			if (!data.successful) {
				return
			}

			// No decryption here, just dispatch the data as it is
			dispatch({
				type: ActionType.GET_PUBLISER,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertPublisherAction = (
	// headers: any,
	obj: PublisherInterface,
	handshakeArr: any,
	setErrorMessage: any,
	navigateToNewPublisher: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const publisher = { publisher: obj, handshake: handshakeArr }
			const { data } = await AxiosCustom.post(endpoints.PUBLISHER, publisher, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_PUBLISHER,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			const newPayload = { ...obj, id: data.payload.id }
			dispatch({
				type: ActionType.INSERT_PUBLIHSER,
				payload: newPayload,
			})
			navigateToNewPublisher(data.payload.id)
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getPublisherReport = (
	setDownloadFile: any,
	rows: any,
	skypeGroupUserAllowed: boolean,
	// headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const publisher = {
				publisher: {
					skype_private_allowed: skypeGroupUserAllowed,
					report: rows,
				},
			}
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER + '/report',
				publisher,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_PUBLISHERS_LIST,
					},
				},
			)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_PUBLISHER_REPORT,
				payload: data.payload,
			})
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}
export const getPublisherReportPid = (
	setDownloadFile: any,
	rows: any,
	skypeGroupUserAllowed: boolean,
	// headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const publisher = {
				publisher: {
					skype_private_allowed: skypeGroupUserAllowed,
					report: rows,
				},
			}
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER + '/reportpid',
				publisher,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_PUBLISHERS_LIST,
					},
				},
			)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_PUBLISHER_REPORT,
				payload: data.payload,
			})
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}
export const getPublisherContacts = (setDownloadFile: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.PUBLISHER + '/contacts',
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_PUBLISHER_CONTACTS,
					},
				},
			)
			if (!data.successful) {
				return
			}
			// dispatch({
			// 	type: ActionType.GET_PUBLISHER_CONTACTS,
			// 	payload: data.payload,
			// })
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const updatePublisherAction = (
	// headers: any,
	obj: PublisherInterface,
	handshakeArr: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const objCopy = { ...obj }
			const publisher = { publisher: obj, handshake: handshakeArr }
			const { data } = await AxiosCustom.put(endpoints.PUBLISHER, publisher, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_PUBLISHER,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.INSERT_PUBLIHSER,
				payload: objCopy,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getPasswordsByPublisherId = async (id: number) => {
	try {
		const { data } = await AxiosCustom.get(
			endpoints.PUBLISHER + `/password/${id}`,
			{
				headers: {
					'x-permission-name': permissionNames.VIEW_PUBLISHER_PASSWORDS,
				},
			},
		)
		return data
	} catch (err) {
		console.error(err)
		return []
	}
}
export const updatePublisherMasterAction = (
	// headers: any,
	obj: PublisherInterface,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const objCopy = { ...obj }
			const publisher = { publisher: obj }
			const { data } = await AxiosCustom.put(
				endpoints.PUBLISHER_MASTER,
				publisher,
				{},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.INSERT_PUBLIHSER_MASTER,
				payload: objCopy,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const updatePublisherMetaAction = (
	// headers: any,
	obj: PublisherInterface,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const objCopy = { ...obj }
			const publisher = { publisher: obj }
			const { data } = await AxiosCustom.put(
				endpoints.PUBLISHER_META,
				publisher,
				{},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.INSERT_PUBLIHSER_META,
				payload: objCopy,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const deletePublisher = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.PUBLISHER, {
				data: {
					publisher: obj,
				},
				headers: {
					'x-permission-name': permissionNames.DELETE_PUBLISHER,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}

			// dispatch({
			// 	type: ActionType.DELETE_PUBLISHER,
			// 	payload: obj,
			// })
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const uploadAdFileToStorage = async (payload: any) => {
	const obj = { post: payload }
	try {
		const { data } = await AxiosCustom.post(
			// TODO: MATAN -> what is the permission name for this?
			endpoints.ADS_PLACEMENT + '/file',
			JSON.stringify(obj),
		)
	} catch (err) {
		console.error(err)
	}
}
export const getPublisherWishList = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.PUBLISHER_WISHLIST) // TODO: MATAN -> what is the permission name for wishlist
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.GETWISHLIST,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const addPublisherWishList = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataToUpload = { data: obj }
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER_WISHLIST,
				dataToUpload,
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			if (obj.uuid) {
				dispatch({
					type: ActionType.UPDATEWISHLIST,
					payload: obj,
				})
			} else {
				dispatch({
					type: ActionType.ADDWISHLIST,
					payload: obj,
				})
			}
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const deletePublisherWishList = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER_WISHLIST_DELETE,
				obj,
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.DELETEWISHLIST,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

// ------- SALES PLAN OPERATIONS -------
export const getSalesPlanAppsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.SALES_PLAN) // TODO: MATAN -> sales plan permission names?
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_SALES_PLAN_APPS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			window.location.replace(window.location.origin)
		}
	}
}

export const downloadAppListAction = (
	setFileDownload: any,
	rows: any,
	// headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			const { data } = await AxiosCustom.post(
				endpoints.SALES_PLAN + '/download',
				report,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}

// ------- CAMPAIGN OPERATIONS--------
export const getCampaignAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.CAMPAIGN, {
				headers: {
					'x-permission-name': permissionNames.VIEW_CAMPAIGN,
				},
			})
			if (!data.successful) {
				return
			}
			// console.log('data from campaigns', data.payload)
			dispatch({
				type: ActionType.GET_CAMPAIGN,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const reevaluateAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				// TODO: MATAN -> what is this?
				endpoints.APPSFLYER + '/reevaluate',
				undefined,
			)
			if (!data.successful) {
				//setErrorMessage(data.payload)
				return
			}

			dispatch({
				type: ActionType.REEVALUATE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}

export const getCampaignsInternalReport = (
	setFileDownload: any,
	rows: any,
	// headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGN + '/internalreport',
				report,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_CAMPAIGN_REPORT,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}
export const getCampaignsExternalWithCostReport = (
	setFileDownload: any,
	rows: any,
	// headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			// console.log(report)
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGN + '/externalreportwithcost',
				report,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_CAMPAIGN_REPORT,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}
export const getCampaignsExternalWithoutCostReport = (
	setFileDownload: any,
	rows: any,
	// headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			// console.log(report)
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGN + '/externalreportwithoutcost',
				report,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_CAMPAIGN_REPORT,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}

export const insertCampaignAction = (
	// headers: any,
	obj: any,
	ruleSet: any,
	setErrorMessage: any,
	setNewUuid: any,
	campaignObject: any,
) => {
	// console.log('ss')

	return async (dispatch: Dispatch<Action>) => {
		try {
			const campaign = { campaign: obj, rule_set: ruleSet }
			// console.log('>>')

			const { data } = await AxiosCustom.put('campaign', campaign, {
				headers: {
					'x-permission-name': permissionNames.ADD_NEW_CAMPAIGNS, // TODO: MATAN -> should reconsider if we need add or just add_edit permissions
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
			}
			if (Array.isArray(data.payload)) {
				for (const element of data.payload) {
					if (!isUuid(element.uuid)) {
						return data.payload
					} else {
						setNewUuid(data.payload)
						// if (campaignObject.uniques[element.role]) {
						// 	campaignObject.uniques[element.role].uuid = element.uuid
						// }
					}
				}
			} else if (isUuid(data.payload)) {
				setNewUuid(data.payload)
			}

			return data.payload
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const saveCampaignHistoryAction = async (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setNewUuid: any,
	campaignObject: any,
) => {
	try {
		const campaign = { campaign: obj }
		const { data } = await AxiosCustom.put(
			endpoints.CAMPAIGNHISTORY,
			campaign,
			{
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_CAMPAIGN,
				},
			},
		)
		if (!data.successful) {
			setErrorMessage(data.payload)
		}

		return data.payload
	} catch (err) {
		setErrorMessage(err)
		console.error(err)
	}
}
export const duplicateCampaignAction = (
	// headers: any,
	obj: any,
	ruleSet: any,
	setErrorMessage: any,
	setNewUuid: any,
) => {
	// console.log('ss')

	return async (dispatch: Dispatch<Action>) => {
		try {
			const campaign = { campaign: obj, rule_set: ruleSet }
			// console.log('???????')

			const { data } = await AxiosCustom.put('campaign/duplicate', campaign, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_CAMPAIGN,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
			}
			if (Array.isArray(data.payload)) {
				for (const element of data.payload) {
					if (!isUuid(element.uuid)) {
						return data.payload
					}
				}
				setNewUuid(data.payload)
			} else if (isUuid(data.payload)) {
				setNewUuid(data.payload)
			}

			return data.payload
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getCampaignHistoryAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.CAMPAIGNHISTORY, {
				headers: {
					'x-permission-name': permissionNames.VIEW_CAMPAIGN, // TODO: MATAN -> we might need a separate permission for campaign history
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_CAMPAIGN_HISTORY,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const getCampaignHistoryByMainDtoAction = (
	// headers: any,
	dto: any,
	setHistoryLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGNHISTORY,
				{
					campaign: dto,
				},
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_CAMPAIGN, // TODO: MATAN -> we might need a separate permissions for this.
					},
				},
			)
			if (!data.successful) {
				return
			}
			setHistoryLoading(false)
			dispatch({
				type: ActionType.GET_CAMPAIGN_HISTORY,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const getCampaignPids = (
	// headers: any,
	obj: any,
	setPidList: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { app_id: obj }
			const { data } = await AxiosCustom.post(endpoints.CAMPAIGN, payload, {
				headers: {
					'x-permission-name': permissionNames.VIEW_CAMPAIGN,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setPidList(data.payload)
			dispatch({
				type: ActionType.INSERT_CAMPAIGN,
				payload: data,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deleteCampaign = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.CAMPAIGN, {
				data: {
					campaign: obj,
				},
				headers: {
					'x-permission-name': permissionNames.DELETE_CAMPAIGN,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.DELETE_CAMPAIGN,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

// ------- APPSFLYER OPERATIONS--------
export const getAppsflyerAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APPSFLYER, {
				headers: {
					'x-permission-name': permissionNames.VIEW_AGGREGATED,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.GET_APPSFLYER,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getRefreshNetworkAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dates: { from: any; to: any } = {
				to: new Date().toISOString().split('T')[0],
				from: new Date(),
			}
			dates.from.setDate(new Date().getDate() - 1)
			dates.from = dates.from.toISOString().split('T')[0]
			const { data } = await AxiosCustom.post(endpoints.NETWORK_U, {
				network: {
					from_date: dates.from,
					to_date: dates.to,
				},
				headers: {
					'x-permission-name': permissionNames.VIEW_NETWORK,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.UPDATE_NETWORK,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getRefreshAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER_U,
				undefined,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_NETWORK,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.UPDATE_APPSFLYER,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const saveAppsflyerAction = (
	setFileDownload: any,
	// headers: any,
	obj: any,
	user_email: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataObj = { save: { report: obj, user_email: user_email } }
			const save = JSON.stringify(dataObj)
			const { data } = await AxiosCustom.post(endpoints.SAVE, save, {
				headers: {
					'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
				},
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.SAVE_APPSFLYER,
				payload: data.payload,
			})
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const getFiltersAction = (
	// headers: any,
	obj: ReportFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
	report: any,
	setGraph: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { appsflyer: obj }
			const { data } = await AxiosCustom.post(endpoints.APPSFLYER, filters, {
				headers: {
					'x-permission-name': permissionNames.VIEW_AGGREGATED,
				},
			})
			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setGraph(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl) // TODO: matan -> what are we doing for the storage?

					if (result.data) {
						resultStatus = result.data
					}
					if (result.data.payload === 'failed') {
						setErrorPopup('Error')
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl) // TODO: matan -> what are we doing for the storage?
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}

			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,

			dispatch({
				type: ActionType.SEND_APPSFLYER,
				payload: result.data.payload.report.report,
			})
			setGraph(result.data.payload.report.graph)
			setData(result.data.payload.report.report)
			setIsReportOpen(true)

			// setData(data.payload)
			// setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			// setIsReportOpen(!!report)
			// setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}
//-------- ADJUST --------------- // TODO: MATAM -> we have no permissions for Adjust yet
export const getAdjustFiltersAction = (
	// headers: any,
	obj: ReportFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
	report: any,
	setGraph: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { adjust: obj }
			const { data } = await AxiosCustom.post(endpoints.ADJUST, filters)
			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setGraph(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl)

					if (result.data) {
						resultStatus = result.data
					}
					if (result.data.payload === 'failed') {
						setErrorPopup('Error')
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl)
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}

			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,

			dispatch({
				type: ActionType.SEND_APPSFLYER,
				payload: result.data.payload.report.report,
			})

			setGraph(result.data.payload.report.graph)
			setData(result.data.payload.report)
			setIsReportOpen(true)

			// setData(data.payload)
			// setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			// setIsReportOpen(!!report)
			// setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}
// ------- APP OPERATIONS--------
export const getAppsByAdvertiserAction = (advertiser: string) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APP + '/' + advertiser, {
				headers: {
					'x-permission-name': permissionNames.ACCESS_TO_APPS,
				},
			})

			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_APP,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getEventsByAppsAction = (
	// headers: any,
	appId: string,
	setEventList: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.APPSFLYER + '/' + appId,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_AGGREGATED,
					},
				},
			)
			// console.log(data)
			if (!data.successful) {
				return
			}

			setEventList(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export function delay(milliseconds: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, milliseconds)
	})
}
//------------NAVIGATION ACTIONS---------------//

export const navigationAction = (data: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			dispatch({
				type: ActionType.NAVIGATION,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

//------------PRESEARCH ACTIONS---------------//

export const presearchAction = (data: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			dispatch({
				type: ActionType.PRESEARCH,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

//------------PRESETS ACTIONS---------------// TODO: MATAN -> what is this and what permissions does this require?
export const getPresetsAction = (user: string) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const url = endpoints.PRESET + (user !== undefined ? '/' + user : '')
			const { data } = await AxiosCustom.get(url)
			dispatch({
				type: ActionType.PRESET,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updatePresetAction = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const preset = { preset: obj }
			const { data } = await AxiosCustom.put(endpoints.PRESET, preset)
			dispatch({
				type: ActionType.PRESET,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getp360aggregated = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.p360aggregated, {
				headers: {
					'x-permission-name': permissionNames.VIEW_AGGREGATED_P360_REPORT,
				},
			})
			dispatch({
				type: ActionType.P360AGGREGATED,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deletePresetAction = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const preset = { preset: obj }
			const { data } = await AxiosCustom.put(
				endpoints.PRESET + 'delete',
				preset,
			)
			dispatch({
				type: ActionType.PRESET,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

//---------------NETWORK ACTIONS----------------//

export const getNetworkAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.NETWORK, {
				headers: {
					'x-permission-name': permissionNames.VIEW_NETWORK,
				},
			})

			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_NETWORK,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const postNetworkAction = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { network: obj }
			const { data } = await AxiosCustom.post(endpoints.NETWORK, payload, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_NETWORK,
				},
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.POST_NETWORK,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getNetworksFiltersAction = (
	// headers: any,
	obj: NetworkFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { network: obj }
			const { data } = await AxiosCustom.post(endpoints.NETWORK, filters, {
				headers: {
					'x-permission-name': permissionNames.VIEW_NETWORK,
				},
			})

			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}
			const timer = Date.now()
			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl)

					if (result.data) {
						resultStatus = result.data
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl)
			dispatch({
				type: ActionType.POST_NETWORK,
				payload: result.data.payload.report,
			})

			setData(result.data.payload.report)
			setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			setIsReportOpen(false)
			setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

export const getRawdataReportAction = (
	// headers: any,
	obj: any,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { rawdata: obj }
			const { data } = await AxiosCustom.post(endpoints.RAWDATA, filters, {
				headers: {
					'x-permission-name': permissionNames.VIEW_RAWDATA,
				},
			})

			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl) // TODO: MATAN -> storage permissions?

					if (result.data) {
						resultStatus = result.data
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl) // TODO: MATAN -> storage permissions?
			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,
			// })
			dispatch({
				type: ActionType.POST_RAWDATA,
				payload: result.data.payload.report,
			})

			setData(result.data.payload.report)
			setIsReportOpen(true)
		} catch (err) {
			setIsReportOpen(false)
			setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

export const getRawdataRefreshAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const obf = {
				rawdata: {
					from_date: subDays(new Date(), 1),
					to_date: new Date(),
				},
			}
			const { data } = await AxiosCustom.post(
				endpoints.RAWDATA + '/update',
				obf,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_RAWDATA,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.GET_RAWDATA,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getUpdatedAtAction = (
	// headers: any,
	report: string,
	setLastRefreshed: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { update: { report: report } }
			const { data } = await AxiosCustom.get(endpoints.UPDATE + '/' + report) // TODO: MATAN -> wtf is ths??
			if (!data.successful) {
				return
			}
			setLastRefreshed(data.payload.updatedAt)
			dispatch({
				type: ActionType.UPDATE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getPasswordsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.PASSWORDS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_PASSWORDS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETPASSWORDS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updatePasswordsAction = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(endpoints.PASSWORDS, payload, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_PASSWORDS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.GETPASSWORDS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deletePasswordsAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.PASSWORDS + 'delete',
				payload,
				{
					headers: {
						'x-permission-name': permissionNames.DELETE_PASSWORDS,
					},
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETPASSWORDS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
//===========  invalid

export const getInvalidAction = (
	// headers: any,
	obj: ReportFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { invalid: obj }
			const { data } = await AxiosCustom.post(endpoints.INVALID, filters, {
				headers: {
					'x-permission-name': permissionNames.VIEW_INVALID,
				},
			})
			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl)

					if (result.data) {
						resultStatus = result.data
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl)

			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,
			// })
			dispatch({
				type: ActionType.SEND_APPSFLYER,
				payload: result.data.payload.report,
			})

			setData(result.data.payload.report)
			setIsReportOpen(true)

			// setData(data.payload)
			// setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			setIsReportOpen(false)
			setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

// ==========accounts for appsflyer CRON ====================

export const getAccountsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APPSFLYER_ACCOUNTS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const putAvatar = async (payload: any) => {
	try {
		const obj = { avatar: payload }
		await AxiosCustom.put(endpoints.AVATAR, obj) // TODO: MATAN -> no permissions for that or any avatar action actually
		await new Promise((resolve) => setTimeout(resolve, 10000))
	} catch (err) {
		console.error(err)
	}
}
function blobToBase64(blob: Blob) {
	return new Promise((resolve, _) => {
		const reader = new FileReader()
		reader.onloadend = () => resolve(reader.result)
		reader.readAsDataURL(blob)
	})
}
const arrayBufferToBase64 = (buffer: any) => {
	var binary = ''
	var bytes = [].slice.call(new Uint8Array(buffer))
	bytes.forEach((b) => (binary += String.fromCharCode(b)))
	return window.btoa(binary)
}
export const getAvatars = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// const { data } = await AxiosCustom.get(endpoints.AVATAR, {
			// 	headers,
			// })
			let { data } = await AxiosCustom.get(endpoints.USER)
			// console.log(data)
			// data.payload.forEach((user: UserInterface) => {
			// 	user.name = capitalizeFirstLetter(user.name)
			// })
			let imageArray: any = {}
			for (let user of data.payload) {
				// console.log(user)
				if (user.image_uuid) {
					const respomse = await fetch(
						'https://varys-avatar-storage.s3.eu-central-1.amazonaws.com/' +
							user.image_uuid,
					)

					const arrayBuffer = await respomse.arrayBuffer()
					const url = arrayBufferToBase64(arrayBuffer)

					//
					// let srcFnal = Buffer.from(url as any).toString('base64')
					let srcFnal = url
						.replace('data', 'data:')
						.replace('base64', ';base64,')

					imageArray[user.name] = srcFnal
				}
			}
			dispatch({
				type: ActionType.AVATAR,
				payload: imageArray,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const addAccountsAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER_ACCOUNTS,
				payload,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
					},
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateAccountsAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.put(
				endpoints.APPSFLYER_ACCOUNTS,
				payload,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
					},
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deleteAccountsAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER_ACCOUNTS + 'delete',
				payload,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
					},
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
// ==========FINANCE ======================
export const downloadFinanceTemplateAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + '/downloadtemplate', // TODO: MATAN -> what permission is this?
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceMBTemplateAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + '/downloadmediabuyingtemplate', // TODO: MATAN -> what permissions is this?
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceCreatorTemplateAction = (
	// headers: any,
	mainUuid: string,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + '/downloadcreatortemplate/' + mainUuid,
				{
					headers: {
						'x-permission-name': permissionNames.EDIT_CREATOR_INVOICE, // TODO: MATAN -> is this the right one?
					},
				},
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceFilledTemplateAction = (
	// headers: any,
	rows: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete rows.id
			delete rows.isNew
			delete rows.hierarchy
			const dataToSave = { rows: rows }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadfilledtemplate',
				obj,
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceFilledCreatorTemplateAction = (
	// headers: any,
	rows: any,
	uuid: string,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete rows.id
			delete rows.isNew
			delete rows.hierarchy
			const dataToSave = { rows: rows }
			const obj = { data: dataToSave, uuid: uuid }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadfilledcreatortemplate',
				obj,
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceTemplate = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadtemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceMBTemplate = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadmediabuyingtemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceControlTemplate = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadcontroltemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceCreatorTemplate = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'x-permission-name': permissionNames.EDIT_CREATOR_INVOICE,
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadcreatortemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const saveProofFinance = (
	// headers: any,
	payload: any,
	refresh: any,
	setErrorMessage: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			// console.log(payload)
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadprooffinance',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.UPLOAD_PROOF_FINANCE,
				payload: data.payload,
			})
			refresh()
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updaterow',
				obj,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_FINANCE,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadControlFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatecontrolrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadMBFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatemediabuyingrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceCreatorRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatecreatorrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount = payload.new_row.cost
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinancePublisher = (
	// headers: any,
	payload: any,
	type: string,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload, type }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancepublisherrow',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_PUBLISHER,
					payload: obj,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadRollOverFinance = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadfinancerollover',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_ROLLOVER,
					payload: payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceDemand = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancedemandrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_DEMAND,
					payload: payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadInvoiceDemand = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updateinvoice',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				// console.log('data from upate', data.payload)
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
				return data
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const removeInvoiceDemand = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// console.log('deleting', payload)
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/removeinvoice',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// payload.new_row.final_amount =
				// 	payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateInvoiceInstructionsFinance = (
	// headers: any,
	payload: any,
	type: string,
	refreshFunc: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const obj = { data: payload, type }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updateinvoiceinstructions',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_INSTRUCTIONS_FINANCE,
					payload: { payload: data.payload, type },
				})
				refreshFunc()
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const getProofImageFinance = (
	// headers: any,
	payload: any,
	type: string,
	setImageSrc: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const obj = { data: payload, type }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/getproofimage',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				let dataToUse = undefined
				if (type === 'demand') {
					dataToUse = data.payload.proof
				} else if (type === 'publisher') {
					dataToUse = data.payload.proof_publisher
				}
				const srcTo64 = arrayBufferToBase64(dataToUse.Body.data)
				let src = ''
				if (dataToUse.ContentType.includes('pdf')) {
					src = `data:application/pdf;base64,${srcTo64}`
				} else if (dataToUse.ContentType.includes('png')) {
					src = `data:image/jpeg;base64,${srcTo64}`
				}
				setImageSrc(src)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const removeProofImageFinance = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const obj = { data: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/removeproofimage',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// console.log(payload, data)
				dispatch({
					type: ActionType.DELETE_PROOF_FINANCE,
					payload: payload,
				})
				setLoading(false)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinance = (
	// headers: any,
	payload: any,
	type: any,
	setFileDownload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			const dataToSave = { rows: payload, type: type }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloaddata',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })
				setFileDownload(data.payload.payload)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceTipalti = (
	// headers: any,
	payload: any,
	setFileDownload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			const dataToSave = { rows: payload }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadtipaltireport',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })
				setFileDownload(data.payload.payload)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceBreakdownFile = (
	// headers: any,
	payload: any,
	month: any,
	setFileDownload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			const dataToSave = { rows: payload, month: month }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadsupplybreakdown',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })

				setFileDownload(data.payload)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const sendEmailAdvertiserFinance = (
	// headers: any,
	payload: any,
	mainRow: any,
	user: any,
	currentRows: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataToSave = { data: payload, user: user, main_row: mainRow }
			const obj = { data: dataToSave }
			// console.log(obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/sendemailadvertiser',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.SEND_EMAIL_FINANCE_ADVERTISER,
					payload: { row: payload, main_row: mainRow },
				})
				return false
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const sendEmailFinance = (
	// headers: any,
	payload: any,
	type: any,
	setErrorMessage: any,
	setLastSend: any,
	setFinanceFinalSent?: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataToSave = { data: payload, type: type }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/sendemail',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.SEND_EMAIL_FINANCE,
					payload: { payload, type },
				})
				const currentTime = new Date()
				const currentTimeISO = currentTime.toISOString()
				setLastSend(formatDateToDdMmYyyy(currentTimeISO))
				if (type !== 'status') {
					setFinanceFinalSent(true)
				}
				return true
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const deleteFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/deleterow',
				obj,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_FINANCE,
					},
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.DELETE_FINANCE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deleteFinanceCreatorRow = (
	// headers: any,
	payload: any,
	mainUuid: string,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			payload.email_for_logs = email
			const obj = { row: payload, mainUuid: mainUuid }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/deletecreatorrow',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			// dispatch({
			// 	type: ActionType.DELETE_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const deleteFinanceCreatorCampaignRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/deletecreatorcampaignrow',
				obj,
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			// dispatch({
			// 	type: ActionType.DELETE_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadBalanceIn = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancebalanceinrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_BALANCEIN,
					payload: payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadBalanceOut = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancebalanceoutrow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_BALANCEOUT,
					payload: payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const createCreatorFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			payload.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/createcreatorfinancerow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.CREATE_CREATOR_CAMPAIGN,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const editCreatorFinanceRow = (
	// headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			payload.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/editcreatorfinancerow',
				obj,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE_BALANCEOUT,
				// 	payload: payload,
				// })
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const getFinanceData = (
	// headers: any,
	type: string,
	setErrorMessage: any,
	email: string = '',
	chunkSize: number = 200,
	index: number = 0,
	controller: any = new AbortController().signal,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.FINANCE + `/getdata/`, {
				params: {
					type: type,
					chunkSize: chunkSize,
					index: index,
					email: email,
				},
				signal: controller,
				headers: {
					'x-permission-name': permissionNames.VIEW_FINANCE,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.GET_FINANCE,
				payload: data.payload,
				index: index,
			})
			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}
export const getInternalFinanceData = (
	// headers: any,
	type: string,
	setErrorMessage: any,
	endpoint: string,
	email: string = '',
	chunkSize: number = 200,
	index: number = 0,
	controller: any = new AbortController().signal,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + `/getinternaldata/`,
				{
					params: {
						type,
						chunkSize,
						index,
						email,
						endpoint,
					},
					signal: controller,
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.GET_FINANCE,
				payload: data.payload,
				index: index,
			})
			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}
// ==========dashboard ====================

export const getDashboardAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const data = await downloadEverything()
			if (data === undefined) {
				return
			}
			// console.log(data)
			dispatch({
				type: ActionType.GETDASHBOARD,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

// ==================AutoSwitch====================

export const getAutoSwitchAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.AUTOSWITCH, {
				headers: {
					'x-permission-name': permissionNames.VIEW_AUTOSWITCH,
				},
			})
			// console.log('GET ', { data })
			if (data === undefined) {
				return
			}

			dispatch({
				type: ActionType.GETAUTOSWITCH,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const saveAutoSwitchRuleAction = async (payload: any) => {
	try {
		const pl = { payload: payload }
		const data = await AxiosCustom.post(endpoints.AUTOSWITCH, pl, {
			headers: {
				'x-permission-name': permissionNames.ADD_EDIT_AUTOSWITCH,
			},
		})
		if (data === undefined) {
			return
		}
		return data
		// dispatch({
		// 	type: ActionType.PUTAUTOSWITCH,
		// 	payload: data,
		// })
	} catch (err) {
		console.error(err)
	}
}

export const deleteAutoSwitchRuleAction = async (
	payload: any,
	// headers: any,
) => {
	try {
		const pl = {
			data: payload,
			headers: { 'x-permission-name': permissionNames.ADD_EDIT_AUTOSWITCH },
		}

		const data = await AxiosCustom.delete(endpoints.AUTOSWITCH, pl)
		if (data === undefined) {
			return
		}

		return data
	} catch (err) {
		console.error(err)
	}
}

export const getVersionAction = async (payload: any) => {
	try {
		const pl = { payload: payload }
		const data = await AxiosCustom.post(endpoints.VERSION, pl)
		if (data === undefined) {
			return
		}

		return data
	} catch (err) {
		console.error(err)
		return
	}
}

// creators / influencers
export const getCreatorsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.CREATOR) // TODO: MATAN -> no permissions for influencers / creators
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_CREATOR,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const insertCreatorAction = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const creator = { data: obj }
			const { data } = await AxiosCustom.post(endpoints.CREATOR, creator)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return null // Return null in case of failure
			}

			// Dispatch the action to update the state
			dispatch({
				type: ActionType.INSERT_CREATOR,
				payload: data.payload,
			})

			return data.payload // Ensure this returns the full payload
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
			return null // Handle any errors by returning null
		}
	}
}

export const deleteCreator = (id: number, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.CREATOR, {
				data: { id },
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
		} catch (err) {
			setErrorMessage(err)
		}
	}
}
export const insertCreatorsAction = (formData: FormData) => {
	return async () => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(endpoints.CREATORS, formData, {
				headers: updatedHeaders,
			})
			if (!data.successful) {
				return data.payload
			}
			// dispatch({
			// 	type: ActionType.INSERT_CREATORS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

//HANDSHAKE
export const insertPidHandshakeAction = (formData: FormData) => {
	return async () => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.HANDSHAKE + '/updatelist', // TODO: MATAN -> handshake permissions?
				formData,
				{
					headers: updatedHeaders,
				},
			)
			if (!data.successful) {
				return data.payload
			}
			// dispatch({
			// 	type: ActionType.INSERT_CREATORS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const updateCreatorAction = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const creator = { data: obj }
			const { data } = await AxiosCustom.put(
				`${endpoints.CREATOR}/${obj.id}`,
				creator,
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.UPDATE_CREATOR,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getHandshakeAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.HANDSHAKE)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GETHANDSHAKE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const updateHandshake = (
	headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.HANDSHAKE,
				{ handshake: payload },
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			// console.log('update', data)
			// dispatch({
			// 	type: ActionType.GETHANDSHAKE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

/**
 * Action creator for generating a report based on the provided report fields.
 *
 * This function handles the entire process of generating a report. It checks
 * if a report with the same parameters has already been generated (to avoid duplication),
 * opens a WebSocket connection for report generation, and handles responses
 * received over WebSocket.
 *
 * @param {any} headers - The headers for the report request.
 * @param {any} reportFields - The fields used for generating the report (breakdowns, statistics).
 * @param {keyof typeof reportEndpoints} reportType - The type of report to be generated.
 * @param {function} setErrorPopup - Function to display an error popup message.
 * @param {function} setLoading - Function to set the loading state during report generation.
 * @param {function} setIsReportOpen - Function to control the visibility of the report modal.
 * @param {string} userEmail - The email of the user generating the report.
 *
 * @returns {function} A thunk function that handles the report generation process.
 */
export const generateReportAction = (
	headers: any,
	reportFields: any,
	reportType: keyof typeof reportEndpoints,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
	userEmail: string,
): ((dispatch: Dispatch<Action>, getState: () => any) => Promise<any>) => {
	// Thunk function that gets dispatched

	return async (dispatch: Dispatch<Action>, getState: () => any) => {
		return new Promise(async (resolve, reject) => {
			try {
				// Mapping report type and validating its existence

				const mappedReportType =
					reverseReportTypeMapping[
						reportType as keyof typeof reverseReportTypeMapping
					]

				if (!mappedReportType) {
					// Handle the case where there is no corresponding ReportType
					throw new Error(`Invalid report type: ${reportType}`)
				}
				// Start loading and dispatch report generation start action

				setLoading(true)
				dispatch({ type: ActionType.GENERATE_REPORT_START })
				// Format breakdowns and statistics for the report

				const breakdowns = reportFields.breakdowns.map((e: string) =>
					e.toLowerCase().split(' ').join('_'),
				)
				const statistics = reportFields.statistics.map((e: string) =>
					e.toLowerCase().split(' ').join('_'),
				)

				const clientId = `client_${Date.now()}`
				const encryptedClientId = await encryptMessageForWebSocket(clientId)
				const data = prepareDataForRequest(
					reportFields,
					breakdowns,
					statistics,
					encryptedClientId,
					userEmail,
				)
				// console.log('uuid in index', data.uuid)
				// Check if the report has already been generated

				const state = getState()

				const userReports = state.reports.reports[userEmail] || {}

				const reports = userReports[mappedReportType] || []

				const existingReport = checkForExistingReport(
					state,
					userEmail,
					data.uuid,
				)

				if (existingReport) {
					// If report already exists, resolve with existing report data

					const existReportData = await fetchReportData(
						existingReport.s3_file_url,
					)

					if (reports.length > 0) {
						const existingIndex = reports.findIndex(
							(report: any) => report.uuid === existingReport.uuid,
						)

						if (existingIndex !== -1) {
							dispatch<any>(
								setTabIndex(userEmail, mappedReportType, existingIndex),
							)
						}

						resolve({
							data: existReportData,
							index: existingIndex,
						})
					}
					return
				}

				// If no existing report, create a new one
				const ws = openWebSocketConnection(
					encryptedClientId,
					dispatch,
					setErrorPopup,
					setIsReportOpen,
					setLoading,
					resolve,
					reject,
					userEmail,
					reportType,
				)
				// On WebSocket open, post the report request

				ws.onOpen(() => {
					postReportRequest(
						data,
						reportType,
						setErrorPopup,
						setIsReportOpen,
						setLoading,
						reject,
						dispatch,
					)
				})

				ws.onMessage(async (message: any) => {
					await handleWebSocketMessage(
						message,
						dispatch,
						setErrorPopup,
						setIsReportOpen,
						setLoading,
						resolve,
						reject,
						userEmail,
						reportType,
						data.uuid,
					)

					if (message.status === 'success' && message.file_url) {
						const state = getState()
						const userReports = state.reports.reports[userEmail] || {}
						const reports = userReports[mappedReportType] || []
						// console.log(reports)
						if (reports.length > 0) {
							const newReportIndex = reports.length - 1

							dispatch<any>(
								setTabIndex(userEmail, mappedReportType, newReportIndex),
							)
						}
					}
				})
			} catch (err: unknown) {
				setErrorPopup((err as Error).message)
				setIsReportOpen(false)
				setLoading(false)
				reject((err as Error).message)
			}
		})
			.then(() => {
				dispatch(loadAllReportsAction(userEmail) as any)
			})
			.catch((error: any) => {
				dispatch({
					type: ActionType.GENERATE_REPORT_FAILURE,
					payload: error.message,
				})
				setLoading(false)
				setIsReportOpen(false)
			})
	}
}

export const loadAllReportsAction = (userEmail: string) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionType.LOAD_ALL_REPORTS_START })
		try {
			const response = await AxiosCustom.get(
				`${endpoints.REPORTS}${reportEndpoints.ALL_REPORTS_PER_USER}`,
				{
					params: { user_email: userEmail },
				},
			)

			if (response.data.successful) {
				const allReports = response.data.payload
				const networkReports = allReports.filter(
					(report: any) => report.type === 'NETWORK',
				)
				const invalidReports = allReports.filter(
					(report: any) => report.type === 'INVALID',
				)
				const aggregatedReports = allReports.filter(
					(report: any) => report.type === 'AGGREGATED',
				)

				dispatch({
					type: ActionType.LOAD_ALL_REPORTS_SUCCESS,
					payload: {
						userEmail,
						reports: {
							network: networkReports,
							invalid: invalidReports,
							aggregated: aggregatedReports,
						},
					},
				})
			} else {
				throw new Error('Failed to load reports')
			}
		} catch (error: any) {
			dispatch({
				type: ActionType.LOAD_ALL_REPORTS_FAILURE,
				payload: error.message,
			})
		}
	}
}

export const updateReportNameAction = (
	headers: any,
	reportId: number,
	newName: string,
	setErrorPopup: (message: string) => void,
) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionType.UPDATE_REPORT_NAME_START })
		try {
			const { data } = await AxiosCustom.put(
				`${endpoints.REPORTS}/${reportId}/name`,
				{ newName },
				{ headers },
			)

			if (!data.successful) {
				throw new Error(data.payload)
			}

			dispatch({
				type: ActionType.UPDATE_REPORT_NAME_SUCCESS,
				payload: { reportId, newName },
			})
		} catch (error: any) {
			setErrorPopup(error.message)
			dispatch({
				type: ActionType.UPDATE_REPORT_NAME_FAILURE,
				payload: error.message,
			})
		}
	}
}

export const deleteReportAction = (
	headers: any,
	reportId: number,
	setErrorPopup: (message: string) => void,
) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionType.DELETE_REPORT_START })
		try {
			const { data } = await AxiosCustom.delete(
				`${endpoints.REPORTS}/${reportId}`,
				{ headers },
			)

			if (!data.successful) {
				throw new Error(data.payload)
			}

			dispatch({
				type: ActionType.DELETE_REPORT_SUCCESS,
				payload: { reportId },
			})
		} catch (error: any) {
			setErrorPopup(error.message)
			dispatch({
				type: ActionType.DELETE_REPORT_FAILURE,
				payload: error.message,
			})
		}
	}
}

export const addAlert = (
	message: string,
	isError: boolean = false,
	link?: string, // Optional link parameter
	reportUuid?: string | undefined,
): Action => {
	return {
		type: ActionType.ADD_ALERT,
		payload: {
			id: uuidv4(),
			message,
			isError,
			seen: false,
			link,
			extra_data: reportUuid,
		},
	}
}

export const removeAlert = (id: string): Action => {
	return {
		type: ActionType.REMOVE_ALERT,
		payload: id,
	}
}

export const markAlertsAsSeen = (alerts: Alert[]): Action => {
	return {
		type: ActionType.MARK_ALERTS_AS_SEEN,
		payload: alerts.map((alert) => ({ ...alert, seen: true })),
	}
}
export const openAlert = (): Action => {
	return {
		type: ActionType.OPEN,
		payload: [],
	}
}
export const closeAlert = (): Action => {
	return {
		type: ActionType.CLOSE,
		payload: [],
	}
}

export const setTabIndex = (
	userEmail: string,
	reportType: keyof ReportsByUser,
	tabIndex: number,
) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_TAB_INDEX,
			payload: {
				userEmail,
				reportType,
				tabIndex,
			},
		})
	}
}
