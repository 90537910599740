import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Typography, Button, TextField, useTheme } from '@mui/material'
import coloredLogo from '../../assets/varys-logo-colored.png'
import hero from '../../assets/locked-out-hero.png'
import { useActions } from '../../hooks/useActions'
import success from '../../assets/success-icon.svg'

const ResetPasswordPage = () => {
	const theme = useTheme()
	const { resetPasswordWithToken } = useActions()
	const navigate = useNavigate()

	const [isSubmit, setIsSubmit] = useState(false)
	const [password, setPassword] = useState('')
	const [rePassword, setRePassword] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [token, setToken] = useState('')
	const [email, setEmail] = useState('')
	const [passwordChanged, setPasswordChanged] = useState(false)
	const query = new URLSearchParams(useLocation().search)

	const fetchTokenFromUrl = () => {
		const fetchedToken = query.get('rpfe') || ''

		const decodedToken = atob(fetchedToken)
		const [hashedToken, decodedEmail] = decodedToken.split(':')
		setEmail(decodedEmail)
		setToken(hashedToken)
	}

	useEffect(() => {
		fetchTokenFromUrl()
		// console.log(`email is: ${email}, token is: ${token}`)
	}, [])

	const handleReset = async () => {
		if (password !== rePassword) {
			setErrorMessage('Passwords do not match')
			return
		}
		setIsSubmit(true)

		try {
			// Simulate a password reset request
			const response = await resetPasswordWithToken(
				email,
				password,
				token,
				setErrorMessage,
			)
			if (response === undefined) {
				setPasswordChanged(true)
			}
			// console.log('Resetting password for:', email)
		} catch (error) {
			setErrorMessage('Failed to reset password.')
		}

		setIsSubmit(false)
	}

	const handleSetPassword = (e: string) => {
		setErrorMessage('')
		setPassword(e)
	}
	const handleSetRePassword = (e: any) => {
		setErrorMessage('')
		setRePassword(e)
	}

	const handleNavigateHome = (e: any) => {
		navigate('/')
	}

	return (
		<div
			style={{
				display: 'flex',
				height: '100vh',
			}}
		>
			<div
				style={{
					flex: 1,
					backgroundImage: theme.colors.gradientLoginPage,
					backgroundSize: 'cover',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						width: '50%',
						height: '50%',
					}}
				>
					<img
						src={hero}
						alt='secondary-hero'
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'contain',
						}}
					/>
				</div>
			</div>

			<div
				style={{
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					backgroundColor: theme.colors.base.white,
					color: theme.colors.base.grey900,
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						position: 'absolute',
						top: '5%',
						left: '88%',
						transition: 'left 0.5s ease-in-out',
					}}
				>
					<div style={{ width: '3.75rem' }}>
						<img src={coloredLogo} alt='logo' style={{ width: '100%' }} />
					</div>
					<span style={{ fontSize: '1.75rem', color: '#242D32' }}>Varys</span>
				</div>
				{passwordChanged ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							gap: '10px',
						}}
					>
						<img
							style={{
								textAlign: 'center',
								width: '5.375rem',
								color: theme.colors.base.green200,
							}}
							src={success}
							alt='success'
						/>
						<span
							style={{
								textAlign: 'center',
								color: theme.colors.base.green200,
								fontSize: '4rem',
								fontWeight: theme.font.weight.bold,
							}}
						>
							Success!
						</span>
						<span
							style={{ color: theme.colors.base.grey900, textAlign: 'center' }}
						>
							Your password was successfully changed. You can safely use your
							new credentials.
						</span>
						<Button
							variant='contained'
							size='large'
							onClick={handleNavigateHome}
							style={{
								backgroundColor: theme.colors.base.green200,
								color: '#FFFFFF',
								margin: '10px',
								width: '300px',
							}}
						>
							Go to Home
						</Button>
					</div>
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '10px',
						}}
					>
						<Typography
							style={{
								color: '#242D32',
								fontSize: '46px',
								fontWeight: '600',
								font: 'Lato',
							}}
						>
							Reset Password
						</Typography>
						{errorMessage && (
							<Typography
								style={{
									color: 'red',
									fontSize: '20px',
									fontWeight: '400',
									font: 'Lato',
								}}
							>
								{errorMessage}
							</Typography>
						)}
						<Typography
							style={{
								color: '#242D32',
								fontSize: '20px',
								fontWeight: '400',
								font: 'Lato',
							}}
						>
							Reset Password for: {email}
						</Typography>
						<TextField
							type='password'
							value={password}
							onChange={(e) => handleSetPassword(e.target.value)}
							label='New password'
							variant='outlined'
							style={{ margin: '10px', width: '300px' }}
						/>
						<TextField
							type='password'
							value={rePassword}
							onChange={(e) => handleSetRePassword(e.target.value)}
							label='Repeat new password'
							variant='outlined'
							style={{ margin: '10px', width: '300px' }}
						/>
						<Button
							variant='contained'
							size='large'
							onClick={handleReset}
							// disabled={validSubmit}
							style={{
								backgroundColor: theme.colors.base.green200,
								color: theme.colors.base.white,
								margin: '10px',
								width: '300px',
							}}
						>
							{isSubmit ? 'Resetting...' : 'Reset Password'}
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

export default ResetPasswordPage
